export const capitalizeName = name => (name ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase() : '');
export const capitalizeSentence = sentence => {
  if (sentence) {
    const words = sentence.split(' ');
    const capitalization = words.map(word => capitalizeName(word));
    return capitalization.join(' ');
  }
  return '';
};

export const getCountryName = (countries, countryId) => {
  const country = countries && countries.filter(x => x.id === countryId)[0];
  return (country && country.country_name) || '';
};
