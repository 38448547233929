import React, { useState } from 'react';
import { multipleSort } from '../../../utils/sort';
// Hooks
import useTranslations from '../../../hooks/useTranslations';
import useRoles from '../../../hooks/useRoles';
// Components
import Progress from '../../Progress/Progress';
import Accordion from '../../Accordion/Accordion';
import SubSkillItem from '../../SubSkillItem/SubSkillItem';
// Assets
import tooltip from '../../../assets/profile/tooltip.svg';
import arrow from '../../../assets/header/arrow-small.svg';
import timerIcon from '../../../assets/timer.svg';
import './trainingSkills.scss';

const TrainingSkills = ({ className, skills = [], waiting, finish }) => {
  const { t } = useTranslations();
  const [active, setActive] = useState(true);
  const [key, setKey] = useState();
  const { roles, isOneCampusRole } = useRoles();

  function toggleAccordion(e) {
    setKey(e);
    setActive(active => (e === key ? !active : true));
  }

  return (
    <div className={`custom-card relative non-selectable bg-white dark:bg-dark-bold ${className}`}>
      <span className=' float-right font-smaller dark:text-white'>{t('ADVANCE_BLOCKER_SKILLS')}</span>
      <div className='pb-2'>
        <span className='text-alkemy-black text-base  font-bold dark:text-white'>{t('ADVANCE_SKILLS')}</span>
        <span className='text-base whitespace-nowrap mr-2  font-weight-500 dark:text-white'>
          {' - '}
          {t('ADVANCE_TRAINING')}
        </span>
        <img src={tooltip} alt={t('TOOLTIP')} className='inline' />
      </div>
      {waiting && (
        <div className='bg-gray flex items-center w-full py-3 px-2 rounded  font-bold dark:bg-dark-normal dark:text-white my-3'>
          <img src={timerIcon} className='mr-2' />
          {isOneCampusRole(roles.APPLICANT)
            ? t('ADVANCE_WAITING_CHALLENGE_CORRECTION')
            : t('ADVANCE_WAITING_ACCELERATION')}
        </div>
      )}
      <div className='relative'>
        <div className='flex flex-col'>
          <div className='progress-title flex my-3 w-full h-full absolute align-items-stretch'>
            <div className='progress-dropdown' />
          </div>
        </div>
        {skills.map((skill, index) => (
          <div key={index} className='flex my-2 pt-1 items-end relative'>
            <div className='table-progress relative overflow-hidden grow'>
              <div
                className='my-1 relative flex items-end pointer'
                onClick={() => {
                  toggleAccordion(index);
                }}>
                <div>
                  <span className='progress-skill-title text-alkemy-black  font-weight-500 inline-block dark:text-white'>
                    {t(skill?.key ?? skill?.displayName ?? skill?.name)}
                  </span>
                  {!waiting && (
                    <span className='progress-skill-counter  font-weight-500 ml-2'>
                      {skill.totalEngagement ? Math.floor(skill.totalEngagement) : 0}%
                    </span>
                  )}
                </div>
              </div>
              <Progress percent={waiting ? 2 : skill?.totalEngagement} className='mb-1' />
              {!!skill?.subSkills?.length && (
                <Accordion open={active && index === key}>
                  <ul className='list-group flex flex-wrap flex-row'>
                    {multipleSort(skill.subSkills, 'approved', 'currentScore', 'DESC').map((subSkill, index) => (
                      <SubSkillItem subSkill={subSkill} key={index} />
                    ))}
                  </ul>
                </Accordion>
              )}
            </div>
            <div
              className='progress-dropdown progress-dropdown-position cursor-pointer z-index-1 right-0 items-center justify-center mt-3 pt-1'
              onClick={() => {
                toggleAccordion(index);
              }}>
              <div className='flex items-center justify-end sm:justify-between progress-dropdown w-full'>
                {!!skill?.subSkills?.length && (
                  <img
                    src={arrow}
                    alt={t('ARROW')}
                    className={`ml-1 dropdown-arrow mb-1 ${key === index && active ? 'dropdown-arrow-open' : ''}`}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='flex'>
        <div className='progress-dropdown' />
      </div>
    </div>
  );
};

export default TrainingSkills;
