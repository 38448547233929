import { Document, Page } from 'react-pdf';
import React, { useState } from 'react';
import Spinner from '../Spinner/Spinner';

const PDFViewer = ({ pdf }) => {
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(true);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setLoading(false);
  }

  return (
    <>
      <Spinner show={loading} />
      <div
        id='pdf-viewer-div'
        className='w-full flex-shrink'
        style={{ overflowY: 'scroll', display: loading ? 'none' : 'flex', height: 'calc(100% - 9rem)' }}>
        <Document
          file={pdf}
          options={{ workerSrc: '/pdf.worker.js' }}
          onLoadSuccess={pdf => onDocumentLoadSuccess(pdf)}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
    </>
  );
};

export default PDFViewer;
