import { useRef } from 'react';
import leftArrow from '../../assets/news/left-arrow.svg';
import rightArrow from '../../assets/news/right-arrow.svg';
import { useContextState } from '../../redux/contextState';
import './ScrollContainer.scss';

const ScrollContainer = ({ children, className, blurred = true }) => {
  const containerRef = useRef(null);
  const scrollRef = useRef(null);
  const { contextState } = useContextState();

  const handleScroll = increment => {
    const width = containerRef.current.offsetWidth;
    const scrollLeft = scrollRef.current.scrollLeft;

    const toScroll = increment ? scrollLeft + width / 2 : scrollLeft - width / 2;
    scrollRef.current.scroll(toScroll, 0);
  };

  return (
    <div
      className={`flex w-full overflow-auto hide-scrollbar dark:bg-dark-bold relative ${className}`}
      ref={containerRef}>
      <div
        className={`cursor-pointer flex items-center scroll-arrow absolute h-full pr-4 top-0 left-0 dark:bg-dark-bold ${
          blurred && contextState.theme !== 'dark' ? 'scroll-container-left' : ''
        }`}
        onClick={() => handleScroll()}>
        <img src={leftArrow} />
      </div>
      <div
        className={`cursor-pointer flex items-center scroll-arrow absolute dark:bg-dark-bold h-full pl-4 top-0 right-0 ${
          blurred && contextState.theme !== 'dark' ? 'scroll-container-right ' : ''
        }`}
        onClick={() => handleScroll(true)}>
        <img src={rightArrow} />
      </div>
      <div className='flex overflow-auto hide-scrollbar smooth-scroll px-3' ref={scrollRef} id='scroll'>
        {children}
      </div>
    </div>
  );
};

export default ScrollContainer;
