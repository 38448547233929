import React from 'react';
import './viewPdf.css';

const ViewPdf = props => {
  const { pdf } = props;
  return (
    <div className='flex flex-col pdf-container-view p-3'>
      <iframe src={pdf || ''} width='100%' height='480' title={pdf} />
    </div>
  );
};

export default ViewPdf;
