export const formatTokenPayload = (token, audience) => {
  const result = {};

  for (const key in token) {
    if (key.startsWith(audience)) {
      const newKey = key.replace(audience, '');
      result[newKey] = token[key];
    } else {
      result[key] = token[key];
    }
  }

  return result;
};
