import { Skeleton } from 'antd';
import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import Banner from '../../components/Banner/Banner';
import JobCard from '../../components/JobCard/JobCard';
import LottieScreen from '../../components/LottieScreen/LottieScreen';
import { useJobPosts } from '../../hooks/useJobPosts';
import useTranslations from '../../hooks/useTranslations';
import { calculateDateRange } from '../../utils/jobs/filters';
import TrashIcon from '../../assets/trash.svg';
import './JobPosts.scss';
const JobPosts = () => {
  const { t } = useTranslations();
  const bottomRef = useRef();
  const {
    jobs,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    loadingFiltersData,
    filters,
    filtersData,
    onChangeFilter,
    updateFilter,
    cleanFilters,
  } = useJobPosts();

  const handleObserver = entries => {
    const target = entries[0];
    if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    });

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, [bottomRef, handleObserver]);

  const datesOptions = [
    { id: 1, value: 1, label: t('JOBS_LABEL_DATE_1_DAY') },
    { id: 2, value: 2, label: t('JOBS_LABEL_DATE_LAST_WEEK') },
    { id: 3, value: 3, label: t('JOBS_LABEL_DATE_LAST_MONTH') },
    { id: 4, value: 4, label: t('JOBS_LABEL_DATE_MORE_THAN_1_MONTH') },
  ];

  const rolesOptions = filtersData?.roles?.map(x => ({
    id: x?.id,
    value: x?.id,
    label: t(x?.whitelabelName) ?? x?.displayName,
  }));
  const studyAreasOptions = filtersData?.studyAreas?.map(x => ({
    id: x?.id,
    value: x?.id,
    label: t(x?.whitelabelName) ?? x?.displayName,
  }));
  const technologiesOptions = filtersData?.technologies?.map(x => ({ id: x?.id, value: x?.id, label: x?.displayName }));
  const countriesOptions = filtersData?.countries?.map(x => ({ id: x?.id, value: x?.id, label: x?.displayName }));

  const citiesOptions = filters?.countryIds
    ? filtersData?.cities
        ?.filter(x => x.countryId == filters.countryIds)
        ?.map(x => ({ id: x?.id, value: x?.id, label: x?.displayName, countryIds: x?.countryId }))
    : filtersData?.cities?.map(x => ({ id: x?.id, value: x?.id, label: x?.displayName, countryIds: x?.countryId }));

  const seniorityOptions = filtersData?.seniorities?.map(x => ({ id: x?.id, value: x?.id, label: x?.displayName }));
  const modalitiesOptions = filtersData?.modalities?.map(x => ({ id: x?.id, value: x?.id, label: x?.displayName }));
  const workModalitiesOptions = filtersData?.workModalities?.map(x => ({
    id: x?.id,
    value: x?.id,
    label: x?.displayName,
  }));

  return (
    <div className='relative w-full pb-5'>
      <Banner text={t('JOB_POST')} svg='🧰' height='5rem' />
      <section className='flex flex-wrap w-full justify-start mt-4'>
        <section className='flex w-full justify-center'>
          {loadingFiltersData ? (
            <div
              data-testid='skeleton'
              className='flex flex-wrap gap-y-4 w-full lg:w-1/2 xl:w-1/2 justify-start items-center mt-4'>
              <Skeleton loading={loadingFiltersData} active />
            </div>
          ) : (
            <div
              data-testid='jobs-filters'
              className='flex flex-wrap gap-y-4 gap-x-4 w-full lg:w-1/2 xl:w-1/2 justify-start items-center mt-4'>
              <div className='w-full sm:w-5/12 bg-white dark:bg-dark-normal '>
                <Select
                  isClearable
                  isMulti={false}
                  name='date'
                  placeholder={t('JOBS_LABEL_FILTER_DATE')}
                  options={datesOptions}
                  value={filters?.dateOption ? datesOptions?.find(x => x.id == filters?.dateOption) : null}
                  className='my-react-select-container'
                  classNamePrefix='my-react-select'
                  onChange={(selectedOption, x) => {
                    const { startDate, endDate } = calculateDateRange(selectedOption?.id);
                    updateFilter({
                      startDate: startDate?.toISOString(),
                      endDate: endDate?.toISOString(),
                      dateOption: selectedOption?.id,
                    });
                  }}
                  styles={{ color: 'blue' }}
                />
              </div>
              <div className='w-full sm:w-5/12'>
                <Select
                  isMulti={false}
                  name='roles'
                  isClearable
                  placeholder={t('JOB_LABEL_ROLE')}
                  options={rolesOptions}
                  value={filters?.rolesId?.length ? rolesOptions?.find(x => x.id == filters?.rolesId) : null}
                  className='my-react-select-container'
                  classNamePrefix='my-react-select'
                  onChange={(selectedOption, x) => {
                    onChangeFilter('rolesId', selectedOption?.id);
                  }}
                />
              </div>
              <div className='w-full sm:w-5/12'>
                <Select
                  isMulti={false}
                  name='studyArea'
                  isClearable
                  placeholder={t('JOB_LABEL_STUDY_AREA')}
                  options={studyAreasOptions}
                  value={
                    filters?.studyAreasId?.length ? studyAreasOptions?.find(x => x.id == filters?.studyAreasId) : null
                  }
                  className='my-react-select-container'
                  classNamePrefix='my-react-select'
                  onChange={(selectedOption, x) => {
                    onChangeFilter('studyAreasId', [selectedOption?.id]);
                  }}
                />
              </div>
              <div className='w-full sm:w-5/12'>
                <Select
                  isMulti
                  isClearable
                  name='seniorities'
                  placeholder={t('JOB_LABEL_SENIORITY')}
                  options={seniorityOptions}
                  value={seniorityOptions?.filter(x => filters.seniorities.includes(x?.id))}
                  className='my-react-select-container'
                  classNamePrefix='my-react-select'
                  onChange={(selectedOption, x) => {
                    onChangeFilter(
                      'seniorities',
                      selectedOption?.map(x => x?.id),
                    );
                  }}
                />
              </div>
              <div className='w-full sm:w-5/12'>
                <Select
                  isMulti
                  isClearable
                  name='workModalities'
                  placeholder={t('JOB_LABEL_CONTRACT_MODALITY')}
                  options={workModalitiesOptions}
                  value={workModalitiesOptions?.filter(x => filters?.workModalities.includes(x?.id))}
                  className='my-react-select-container'
                  classNamePrefix='my-react-select'
                  onChange={(selectedOption, x) => {
                    onChangeFilter(
                      'workModalities',
                      selectedOption?.map(x => x?.id),
                    );
                  }}
                />
              </div>
              <div className='w-full sm:w-5/12'>
                <Select
                  isMulti
                  isClearable
                  name='modalities'
                  placeholder={t('JOB_LABEL_MODALITY')}
                  options={modalitiesOptions}
                  value={modalitiesOptions?.filter(x => filters.modalities.includes(x?.id))}
                  className='my-react-select-container'
                  classNamePrefix='my-react-select'
                  onChange={(selectedOption, x) => {
                    onChangeFilter(
                      'modalities',
                      selectedOption?.map(x => x?.id),
                    );
                  }}
                />
              </div>
              <div className='w-full sm:w-11/12'>
                <Select
                  isMulti
                  isClearable
                  name='technologies'
                  placeholder={t('JOB_LABEL_TECHNOLOGIES')}
                  value={technologiesOptions?.filter(x => filters?.technologiesId?.includes(x?.id))}
                  options={technologiesOptions}
                  className='my-react-select-container'
                  classNamePrefix='my-react-select'
                  onChange={(selectedOption, x) => {
                    onChangeFilter(
                      'technologiesId',
                      selectedOption?.map(x => x?.id),
                    );
                  }}
                />
              </div>
              <div className='w-full sm:w-5/12'>
                <Select
                  isClearable
                  isMulti={false}
                  name='country'
                  placeholder={t('JOBS_LABEL_FILTER_COUNTRY')}
                  options={countriesOptions}
                  value={filters?.countryIds ? countriesOptions?.find(x => x.id == filters?.countryIds) : null}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  onChange={(selectedOption, x) => {
                    updateFilter({
                      countryIds: selectedOption?.id,
                    });
                  }}
                />
              </div>
              {filters?.countryIds && citiesOptions?.length ? (
                <div className='w-full sm:w-5/12'>
                  <Select
                    isMulti={false}
                    name='city'
                    isClearable
                    placeholder={t('JOBS_LABEL_FILTER_CITY')}
                    options={citiesOptions}
                    value={filters?.cityIds ? citiesOptions?.find(x => x.id == filters?.cityIds) : null}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onChange={(selectedOption, x) => {
                      updateFilter({
                        cityIds: selectedOption?.id,
                      });
                    }}
                  />
                </div>
              ) : null}
              <div className='w-full'>
                <div className='flex items-center justify-start'>
                  <a
                    className='font-montserrat border-0 flex items-center hover:text-blue-700 hover:underline dark:text-white'
                    onClick={() => cleanFilters()}>
                    <img className='mr-2 text-blue-500 dark:text-white' src={TrashIcon} />
                    {t('QUALITATIVE_FILTERS_CLEAR')}
                  </a>
                </div>
              </div>
            </div>
          )}
        </section>
      </section>
      {isFetching && !isFetchingNextPage ? (
        <section data-testid='skeleton' className='flex w-full justify-center'>
          <div className='flex w-full flex-col justify-center items-center'>
            <div className='w-full lg:w-1/2 xl:w-1/2'>
              {[1, 2, 3].map(x => (
                <div key={'skeleton-' + x} className='my-10'>
                  <Skeleton loading={isFetching} active avatar />
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : (
        <section className='flex w-full justify-center mt-4'>
          <div className='flex w-full flex-col justify-center items-center'>
            {isError ? (
              <LottieScreen
                title={t('JOB_POSTS_ERROR_TITLE')}
                subtitle={t('JOB_POSTS_ERROR_SUBTITLE')}
                lottie={{
                  src: 'https://lottie.host/8584bdc0-6b5c-476f-8372-25a1664c5a56/gsuV5bzrcJ.json',
                  loop: 'true',
                }}
              />
            ) : (
              <>
                {jobs?.length ? (
                  jobs?.map(job => (
                    <div key={'job-' + job?.hashId} className='w-full lg:w-1/2 xl:w-1/2'>
                      <JobCard key={job?.id} job={job} />
                    </div>
                  ))
                ) : (
                  <LottieScreen
                    title={t('JOB_POSTS_EMPTY_TITLE')}
                    subtitle={t('JOB_POSTS_EMPTY_SEARCH_SUBTITLE')}
                    lottie={{
                      src: 'https://lottie.host/83d1c0c1-5524-4ddb-bb60-df0874e8c758/E1DeZBwiKx.json',
                      loop: 'false',
                    }}
                  />
                )}
              </>
            )}
            <div ref={bottomRef} />
            {isFetchingNextPage && (
              <div data-testid='skeleton' className='w-full lg:w-1/2 xl:w-1/2'>
                <Skeleton loading={isFetchingNextPage} active avatar />
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default JobPosts;
