import React from 'react';
import useTranslations from '../../hooks/useTranslations';
import './trainingIntegrator.css';

const TrainingIntegrator = ({ className, integrators }) => {
  const { t } = useTranslations();
  return (
    <div className={`card-profile flex flex-col sm:flex-row items-center flex-wrap dark:bg-dark-bold ${className}`}>
      <span className='report-pre-title text-alkemy-black  font-bold sm:mr-4 dark:text-white'>
        {t('ADVANCE_PRE_TRAINING')}
      </span>
      {integrators.map((skill, index) => (
        <div key={index} className='sm:mr-4 mt-3 sm:mt-0 py-1 flex items-center'>
          <span className='report-pre-skill  mr-2 dark:text-white'>{skill?.name}</span>
          <span className='report-pre-skill-number text-alkemy-black font-roboto font-bold'>
            {skill?.submission?.score}
          </span>
        </div>
      ))}
    </div>
  );
};

export default TrainingIntegrator;
