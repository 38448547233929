import { useEffect, useRef, useState } from 'react';
import arrowIcon from '../../assets/header/arrow-small.svg';
import worldIcon from '../../assets/header/world-icon.svg';
import useClickOutside from '../../hooks/useClickOutside';
import useTranslations from '../../hooks/useTranslations';
import CountryFlag from '../CountryFlag/CountryFlag.js';
import { languagesList } from '../../utils/constants';
import './DropdownLanguage.scss';

const DropdownLanguage = ({ className, position = 'right', mobile }) => {
  const [open, setOpen] = useState(false);
  const [placeholder, setPlaceholder] = useState(false);
  const ref = useRef(null);
  useClickOutside(ref, () => setOpen(false), true);
  const { changeLanguageKey, key } = useTranslations();

  const handleClick = value => {
    changeLanguageKey(value);
    setOpen(false);
    setPlaceholder(value?.toUpperCase() ?? '');
  };

  useEffect(() => {
    if (key) {
      const selectedOption = languagesList.find(option => option.value && option.value === key);
      changeLanguageKey(selectedOption?.value ?? null);
      setPlaceholder(selectedOption?.value?.toUpperCase() ?? '');
    }
  }, []);

  return (
    <div
      className={`self-end sm:self-center  dropdown-lang-max-height ${open && 'dropdown-lang-container-index'} ${
        mobile ? 'flex ml-4' : 'hidden lg:flex'
      }`}>
      <div
        ref={ref}
        className={`rounded font-roboto font-bold non-selectable bg-transparent dropdown-lang-select-container relative w-max-content ${className} ${
          !mobile && 'dark:bg-dark-normal'
        }`}>
        <div
          onClick={() => setOpen(state => !state)}
          className='dropdown-lang-select-button cursor-pointer p-2 w-full flex justify-between'>
          <img src={worldIcon} alt='world' />
          <span
            data-testid={`language-label-${placeholder}`}
            className='dropdown-lang-select-placeholder overflow-hidden mx-1 dark:text-white'>
            {placeholder ?? ''}
          </span>
          <img
            src={arrowIcon}
            alt='arrow'
            className={`dropdown-lang-select-arrow mx-1 ${open && 'dropdown-lang-select-arrow-open'}`}
          />
        </div>
        <ul
          className={`dropdown-lang-select-options-container bg-white dark:bg-dark-bold overflow-auto custom-scrollbar m-0 absolute ${
            open && 'dropdown-lang-select-options-container-open min-w-max py-2'
          } ${position === 'right' ? 'left-0' : 'right-0'}`}>
          {languagesList.map((option, i) => (
            <li
              className={`p-2 cursor-pointer dropdown-lang-select-option bg-white dark:bg-dark-bold dark:text-white flex ${
                key === option.value && 'dropdown-lang-selected-option'
              }`}
              key={i}
              onClick={() => handleClick(option.value)}
              onKeyPress={() => handleClick(option.value)}
              role='option'
              aria-selected
              data-testid={`language-option-${option.label}`}>
              <CountryFlag countryIso={option.iso} size='sm' className='mr-3' />
              {option.label ?? option.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropdownLanguage;
