import React from 'react';
import { Progress, Tooltip } from 'antd';
import useTranslations from '../../hooks/useTranslations';
import useMissingData from '../../hooks/useMissingData';
import './ProfileCompleteProgress.scss';
import { Link } from 'react-router-dom';
import { useContextState } from '../../redux/contextState';
import { useState } from 'react';
import { useEffect } from 'react';
import { roles } from '../../constants/enums';

const ProfileCompleteProgress = () => {
  const { missingData, percentage } = useMissingData();
  const [currentPercentage, setCurrentPercentage] = useState();
  const { contextState } = useContextState();
  const { t } = useTranslations();

  useEffect(() => {
    setCurrentPercentage(percentage);
  }, [percentage]);

  return currentPercentage === 100 ||
    contextState?.profile?.role?.includes(roles.ALKYMER) ||
    contextState?.profile?.role?.includes(roles.PREACCELERATION) ? null : (
    <Link
      to={`/profile${window.location.hash ? window.location.hash : ''}`}
      className='cursor-pointer relative mr-3'
      data-testid='profile-complete-link'>
      <Tooltip
        title={
          <>
            <span>{t('HEADER_PROFILE_MISSING_DATA')}</span>
            <ul className='px-3 pt-2'>
              {missingData.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </>
        }
        placement='bottom'>
        <Progress
          type='circle'
          width={32}
          strokeWidth={10}
          strokeColor={{
            '0%': '#58c1f5',
            '100%': '#58c1f5',
          }}
          percent={percentage}
        />
      </Tooltip>
    </Link>
  );
};

export default ProfileCompleteProgress;
