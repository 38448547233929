import { useState, useEffect } from 'react';
import ReactPageScroller from 'react-page-scroller';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import { imgs } from '../../constants/LoginImages';
import { useHistory } from 'react-router-dom';
import { queryToObject } from '../../utils/processQueryString';
import { isNullOrUndefined } from '../../utils/typesUtils';
import { useContextState } from '../../redux/contextState';

const NewForm = () => {
  const [img, setImg] = useState(null);
  const [pageScroller, setPageScrollet] = useState(0);
  const history = useHistory();
  const { contextState } = useContextState();

  useEffect(() => {
    if (!img) {
      setImg(imgs[Math.floor(Math.random() * imgs.length)]);
    }
  }, []);

  useEffect(() => {
    if (!img) {
      setImg(imgs[Math.floor(Math.random() * imgs.length)]);
    }
  }, []);
  useEffect(() => {
    if (queryToObject(history.location.search) && queryToObject(history.location.search)?.page) {
      if (queryToObject(history.location.search)?.page === '2') {
        setPageScrollet(1);
      } else if (queryToObject(history.location.search)?.page === '3') {
        setPageScrollet(2);
      } else {
        setPageScrollet(0);
      }
    }
  }, [window.location.search]);

  window.onpopstate = () => {
    history.push('/steps?page=1');
  };

  return (
    <div className='container-fluid'>
      <div className='flex flex-col lg:flex-row no-gutter logged-out-container'>
        <div className='bg-white dark:bg-dark-bold flex w-full lg:w-2/5 justify-center items-center p-5 m-0'>
          <ReactPageScroller
            customPageNumber={pageScroller}
            pageOnChange={e => setPageScrollet(e)}
            blockScrollDown
            blockScrollUp={true}>
            <Step1
              backStep={() => setPageScrollet(pageScroller - 1)}
              nextStep={() => setPageScrollet(pageScroller + 1)}
            />
            <Step2
              backStep={() => setPageScrollet(pageScroller - 1)}
              nextStep={() => setPageScrollet(pageScroller + 1)}
            />
            <Step3
              backStep={() => setPageScrollet(pageScroller - 1)}
              nextStep={() => setPageScrollet(pageScroller + 1)}
            />
          </ReactPageScroller>
        </div>
        <div
          className='hidden lg:block logged-out-bg-image relative w-3/5 h-full'
          style={{ backgroundImage: `url(${img})` }}>
          <svg xmlns='http://www.w3.org/2000/svg' className='absolute bottom-0 left-0' viewBox='0 0 1440 320'>
            <path
              fill='#52C0F7'
              fillOpacity='1'
              d='M0,288L48,277.3C96,267,192,245,288,229.3C384,213,480,203,576,197.3C672,192,768,192,864,202.7C960,213,1056,235,1152,234.7C1248,235,1344,213,1392,202.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default NewForm;
