import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { months } from '../../utils/constants';
import DropDownSelect from '../DropDownSelect/DropDownSelect';
import { generateArrayOfDays, generateArrayOfYears } from '../../utils/calendarUtils';
import './DatePicker.scss';
import useTranslations from '../../hooks/useTranslations';

const DatePicker = ({ nextStep, toggleLock, date, setDate, changeDate }) => {
  const [monthDays, setMonthDays] = useState([]);
  const years = generateArrayOfYears();
  const { t } = useTranslations();
  useEffect(() => {
    generateArrayOfDays(moment(`${date.year}-${date.month}`, 'YYYY-MM').daysInMonth(), setMonthDays);
  }, [date.month, date.year]);

  return (
    <div className='flex flex-col'>
      <div className='flex w-75 lg:ml-5 mb-3' onFocus={() => toggleLock()}>
        <DropDownSelect
          className='px-2 mx-3'
          options={months.map(item => ({ ...item, name: t(item.name) }))}
          selected={date.month}
          setState={state => {
            setDate({
              ...date,
              month: state.id,
            });
          }}
          placeholder={t('MONTH')}
          onClickAction={toggleLock}
          selectedPlaceholder
          dataTestId='step1-dropdown-month-ul'
        />
        <DropDownSelect
          options={years}
          className='px-2 mx-3'
          placeholder={t('YEAR')}
          selected={date.year}
          setState={state => {
            setDate({
              ...date,
              year: state.id,
            });
          }}
          onClickAction={toggleLock}
          selectedPlaceholder
          dataTestId='step1-dropdown-year-ul'
        />
      </div>
      <div className='days-container' data-testid='step1-calendar-days-div'>
        {monthDays.map(item => (
          <button
            onClick={e => {
              setDate({
                ...date,
                day: item,
              });
            }}
            className={`w-full border-0 font-bold  font-small text-center ${
              date.day === item ? 'bg-maya-blue text-white' : 'bg-light-gray text-gray-500'
            }`}
            key={item}
            data-testid={`step1-calendar-day-${item}`}>
            {item}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DatePicker;
