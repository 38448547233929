export function generateArrayOfDays(daysInMonth, callback) {
  const days = Array.from({ length: daysInMonth }, (_, num) => num + 1);
  const prevDays = Array.from({ length: 31 }, (_, num) => num + 1);

  return !days.length ? callback(prevDays) : callback(days);
}

export function generateArrayOfYears() {
  const max = new Date().getFullYear();
  const min = max - 80;
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push({ id: i, name: i });
  }
  return years;
}
