import useTranslations from '../../hooks/useTranslations';
import { auth0Client } from '../../auth0/auth0';
import { Window } from '../../helpers/window';
import Header from '../../components/Header/Header';
import { useContextState } from '../../redux/contextState';
import './maintenance.scss';

const Maintenance = () => {
  const { t } = useTranslations();
  const { contextState } = useContextState();
  return (
    <>
      <Header profile={contextState} editable={contextState.editable} redirectProfile={() => {}} maintenance={true} />
      <div className='flex flex-col items-center'>
        <span
          data-testid='maintenance-screen-title'
          className='maintenance-title text-alkemy-black-bold text-center  font-bold mt-7 pt-5'>
          {t('MAINTENANCE_TITLE')}
        </span>
        <span className='maintenance-subtitle text-center mt-2'>{t('MAINTENANCE_SUBTITLE')}</span>
        <button
          className='maintenance-button border-0 mt-5'
          onClick={() => {
            if (Window()) {
              auth0Client().logout({ returnTo: window.location.origin });
            }
          }}>
          <span className='maintenance-button-text font-roboto font-bold'>🤓 {t('MAINTENANCE_BUTTON')}</span>
        </button>
      </div>
    </>
  );
};

export default Maintenance;
