import { useState } from 'react';
import { Modal, Skeleton } from 'antd';
import { useContextState } from '../../../redux/contextState';
import Ticket from '../../../components/Ticket/Ticket';
import useTranslations from '../../../hooks/useTranslations';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { TrackEvent } from '../../../utils/segmentUtils';
import { handleRedirect } from '../../../utils/handleRedirect';

const MyChallenges = ({ loading }) => {
  const { contextState } = useContextState();
  const { profile } = contextState;
  const { challenges, screenings } = contextState.profileChallenges;
  const stateToDisplay = [...challenges, ...screenings];
  const { t } = useTranslations();
  const history = useHistory();

  const applyScreenings = async challenge => {
    TrackEvent('screening-start', { userId: profile.id });
    handleRedirect(
      `${process.env.RAZZLE_RUNTIME_SCREENINGS}?technologyId=${challenge?.technologyId}&levelId=${challenge?.level}&userId=${contextState.profile.id}&token=`,
      t,
    );
  };

  return (
    <div className='description-container bg-white dark:bg-dark-bold rounded mx-auto p-4 pt-0 mb-3 '>
      <div className='flex flex-col mt-1'>
        {stateToDisplay?.length > 0 && (
          <>
            <span className='text-base font-bold text-alkemy-black dark:text-white'>
              {t('MY_CHALLENGES_SECTION_TITLE')}
            </span>
            <Link to='/results' className='w-fit text-sm font-medium text-purple dark:text-white pointer mt-3'>
              {t('RESULTS_GO_TO_HISTORY')}
            </Link>
          </>
        )}
      </div>
      <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-2'>
        {!loading
          ? // ?.filter((_, index) => index <= 3)
            stateToDisplay?.reverse()?.map((challenge, index) => (
              <div className='p-0 my-2' key={index} data-testid='my-challenges-container-div'>
                <div className='h-full justify-between max-h-[16rem] bg-white dark:bg-dark-normal pt-2 px-3 pb-1 challenges-cards flex flex-col dark:border-gray-500 border-2 border-alkemy-blue'>
                  <span
                    className='font-semibold text-alkemy-black dark:text-white mt-2 text-base h-auto max-h-[6rem] text-center'
                    data-testid='my-challenges-technology-span'>
                    {`${t('MY_CHALLENGES_CARD_TITLE')} ${challenge?.technology?.displayName}`}
                  </span>
                  <div>
                    <div className='flex flex-col'>
                      <span
                        className='font-montserrat font-medium text-xs dark:text-white'
                        data-testid='my-challenges-status-span'>
                        {t('STATUS')}:
                      </span>
                      <Ticket
                        text={
                          challenge?.reviewedAt || (challenge?.startDate && challenge?.endDate)
                            ? t('CHALLENGE_CORRECTED')
                            : !challenge?.url || (challenge?.startDate && !challenge?.endDate)
                            ? t('CHALLENGE_WITHOUT_SENDING')
                            : t('CHALLENGE_IN_REVIEW')
                        }
                        className={`${
                          (challenge?.reviewedAt && challenge?.technology?.evaluationFormat === 'CHALLENGE') ||
                          (challenge?.startDate && challenge?.endDate)
                            ? 'ticket-sprint-text'
                            : !challenge?.url || (challenge?.startDate && !challenge?.endDate)
                            ? 'ticket-not-send-text'
                            : 'ticket-review-text'
                        } ticket-padding font-bold font-roboto capitalize mr-auto mt-1`}
                      />
                    </div>
                    <div className='flex flex-col mt-2 h-7 mb-4'>
                      {challenge?.datetime && challenge?.url ? (
                        <>
                          <span
                            className='font-montserrat dark:text-white text-xs font-semibold'
                            data-testid='my-challenges-shipping-span'>
                            {t('SHIPPING_DATE')}:
                          </span>
                          <span
                            className='font-bold text-blue-800 dark:text-white text-xs'
                            data-testid='my-challenges-date-span'>
                            {moment(challenge?.datetime).format('DD/MM/YYYY')}
                          </span>
                        </>
                      ) : (
                        challenge?.endDate && (
                          <>
                            <span
                              className='font-montserrat dark:text-white text-xs font-semibold'
                              data-testid='my-challenges-shipping-end-span'>
                              {t('SHIPPING_DATE')}:
                            </span>
                            <span
                              className='font-bold text-blue-800 dark:text-white text-xs'
                              data-testid='my-challenges-end-date-span'>
                              {moment(challenge?.endDate).format('DD/MM/YYYY')}
                            </span>
                          </>
                        )
                      )}
                    </div>
                    <div className='w-full flex justify-center mb-2 h-8'>
                      {challenge?.endDate || challenge?.reviewedAt ? (
                        <button
                          className='button-apply-challenge py-2 px-5'
                          onClick={() => history.push(`/resources`)}
                          data-testid='my-challenges-resources-button'>
                          <span
                            className='whitespace-nowrap button-apply-challenge-text font-roboto font-bold text-white'
                            data-testid='my-challenges-resources-span'>
                            {t('GO_TO_RESOURCES')}
                          </span>
                        </button>
                      ) : challenge?.startDate ? (
                        <button
                          className='button-apply-challenge py-2 px-5 h-fit'
                          onClick={() => applyScreenings(challenge)}
                          data-testid='my-challenges-start-screening-button'>
                          <span
                            className='whitespace-nowrap button-apply-challenge-text font-roboto font-bold text-white'
                            data-testid='my-challenges-start-screening-span'>
                            {t('HOME_CONTINUE_STARTED_SCREENING')}
                          </span>
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ))
          : [1, 2, 3, 4].map(it => (
              <div className='p-0 my-2' key={it}>
                <div className='bg-white dark:bg-dark-normal pt-3 px-3 pb-1 challenges-cards flex flex-col dark:border-gray-500 border-2 border-alkemy-blue'>
                  <Skeleton.Input
                    active={true}
                    size='small'
                    className='w-24 h-5'
                    data-testid='my-challenges-input-one'
                  />
                  <Skeleton.Input
                    active={true}
                    size='small'
                    className='w-10 mt-2 h-4'
                    data-testid='my-challenges-input-two'
                  />
                  <Skeleton.Input
                    active={true}
                    size='small'
                    className='w-14 mt-2 h-4'
                    data-testid='my-challenges-input-three'
                  />
                  <Skeleton.Input
                    active={true}
                    size='small'
                    className='w-28 mt-2 h-4'
                    data-testid='my-challenges-input-four'
                  />
                  <Skeleton.Input
                    active={true}
                    size='small'
                    className='w-24 mt-2 h-4'
                    data-testid='my-challenges-input-five'
                  />
                  <Skeleton.Input
                    active={true}
                    size='small'
                    className='w-24 mt-3 mb-4 h-6 mx-auto'
                    data-testid='my-challenges-input-six'
                  />
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default MyChallenges;
