import React from 'react';

const UserIcon = ({ selected, className }) => {
  return (
    <svg
      className={className}
      width='16'
      height='16'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 8.5C10.21 8.5 12 6.71 12 4.5C12 2.29 10.21 0.5 8 0.5C5.79 0.5 4 2.29 4 4.5C4 6.71 5.79 8.5 8 8.5ZM8 10.5C5.33 10.5 0 11.84 0 14.5V16.5H16V14.5C16 11.84 10.67 10.5 8 10.5Z'
        fill={`${selected ? '#52C0F7' : '#BDBDBD'}`}
      />
    </svg>
  );
};

export default UserIcon;
