import React from 'react';

const EditPen = () => {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 18H21V19.5H0V18ZM17.55 5.25C18.15 4.65 18.15 3.75 17.55 3.15L14.85 0.45C14.25 -0.15 13.35 -0.15 12.75 0.45L1.5 11.7V16.5H6.3L17.55 5.25ZM13.8 1.5L16.5 4.2L14.25 6.45L11.55 3.75L13.8 1.5ZM3 15V12.3L10.5 4.8L13.2 7.5L5.7 15H3Z'
        fill='#5552F9'
      />
    </svg>
  );
};

export default EditPen;
