import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

function LottieScreen(props) {
  const history = useHistory();
  return (
    <div data-testid='lottie-screen' className='flex flex-col align-items-center'>
      <lottie-player
        src={props?.lottie?.src}
        autoplay={props.lottie.autoplay ?? false}
        style={{ width: '300px', height: '300px' }}></lottie-player>
      <div className='flex flex-col items-center justify-center'>
        <span className='text-alkemy-black text-sm font-semibold dark:text-white'>{props?.title}</span>

        {props?.urlRedirect ? (
          <span
            className='empty-subtitle-redirect text-xs mt-3 dark:text-white cursor-pointer '
            onClick={() => history.push(props?.urlRedirect)}>
            {props?.subtitle}
          </span>
        ) : (
          <span className='empty-subtitle text-xs mt-3 dark:text-white'>{props?.subtitle}</span>
        )}
      </div>
    </div>
  );
}

LottieScreen.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  lottie: PropTypes.shape({
    src: PropTypes.string,
    loop: PropTypes.string,
    autoplay: PropTypes.string,
  }),
};

export default LottieScreen;
