import React from 'react';
import emptyFeedback from '../../../assets/feedback-empty.svg';
import useTranslations from '../../../hooks/useTranslations';
import './feedback.scss';

const Feedback = ({ className, feedback }) => {
  const { t } = useTranslations();

  return (
    <div className={`custom-card bg-white dark:bg-dark-bold p-4 ${className}`}>
      <span className='text-base text-alkemy-black  font-bold dark:text-white'>{t('ADVANCE_FEEDBACK_TITLE')}</span>
      {feedback?.length ? (
        feedback?.map((feed, index) => (
          <div key={index} data-testid={`feedback${index}`}>
            <div className='mt-5 flex items-center'>
              <img src={feed.mentor.picture} alt={feed.name} className='rounded-full feedback-img' />
              <span className='feedback-name ml-2 font-roboto font-bold'>
                {feed.mentor.displayFirstName} {feed.mentor.displayLastName}
              </span>
              <span className='feedback-ticket mx-2 font-roboto font-bold dark:text-white'>{t('ADVANCE_MENTOR')}</span>
            </div>
            <div className='mt-3'>
              <span className='text-alkemy-black-bold  dark:text-white'>&quot;{feed.feedback}&quot;</span>
            </div>
          </div>
        ))
      ) : (
        <div className='w-full flex flex-col items-center mt-1' data-testid='feedbackEmptyState'>
          <img src={emptyFeedback} />
          <span className=' font-bold text-base mb-2 dark:text-white'>{t('ADVANCE_FEEDBACK_EMPTY')}</span>
          <span className=' text-sm text-gray-500 dark:text-white'>{t('ADVANCE_FEEDBACK_WAITING')}</span>
        </div>
      )}
    </div>
  );
};

export default Feedback;
