import React from 'react';
import facebookIcon from '../../assets/facebook-icon.svg';
import linkedinIcon from '../../assets/linkedin-icon.svg';
import twitterIcon from '../../assets/twitter-icon.svg';
import downloadIcon from '../../assets/download-icon.svg';
import copyIcon from '../../assets/copy-icon.svg';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import './CopyCertificate.scss';
import useTranslations from '../../hooks/useTranslations';

const CopyCertificate = ({ title, link, className, boldTitle, text, isEvaluation, certificateData }) => {
  const { t } = useTranslations();
  const regexHtml = /<\/?b>/g;

  const [copied, copy] = useCopyToClipboard(text.replace(regexHtml, ''));
  const pngLink = isEvaluation ? certificateData?.pngUrl : link + '.png';
  const pdfLink = isEvaluation ? certificateData?.pdfUrl : link + '.pdf';
  return (
    <div data-testid='copy-certificate-container' className={`w-full lg:px-2 mb-5 ${className}`}>
      <span className=' font-small text-muted font-smaller dark:text-white'>
        {title} <span className=' font-bold'>{boldTitle}</span>
      </span>
      {(link || (isEvaluation && certificateData)) && (
        <>
          <div className='w-full bg-link flex flex-col justify-between px-3 py-3 mt-2 rounded relative items-center bg-gray-200 dark:bg-dark-normal'>
            <span
              className='link-container text-muted  font-smaller mb-5 pb-2 dark:text-white'
              dangerouslySetInnerHTML={{ __html: text }}
            />
            <button
              disabled={typeof link === 'undefined'}
              onClick={copy}
              className={`border-0 bg-transparent absolute right-0 m-3 bottom-0 font-small  ml-2 whitespace-nowrap font-bold ${
                link ? 'text-maya-blue' : 'text-muted'
              }`}>
              <img src={copyIcon} />
            </button>
          </div>
          <div className='pt-2 flex flex-row items-center'>
            <FacebookShareButton url={pngLink} className='Demo__some-network__share-button flex items-end'>
              <img src={facebookIcon} />
            </FacebookShareButton>
            <LinkedinShareButton url={pngLink} className='Demo__some-network__share-button flex items-end'>
              <img src={linkedinIcon} />
            </LinkedinShareButton>
            <TwitterShareButton url={pngLink} className='Demo__some-network__share-button flex items-end'>
              <img src={twitterIcon} />
            </TwitterShareButton>
            <a
              href={pdfLink}
              data-testid='download-certificate-anchor'
              className='flex items-end hover-maya-blue h-full text-decoration-none  font-bold text-maya-blue ml-3'
              target='_blank'
              rel='noopener noreferrer'>
              <span className='flex items-end'>{t('CERTIFICATE_DOWNLOAD')}</span>{' '}
              <img className='ml-2' src={downloadIcon} />
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default CopyCertificate;
