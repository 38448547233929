import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { ActionTypes, useContextState } from '../../redux/contextState';
import useTranslations from '../useTranslations';
import './CompleteProfileModal.scss';

export default function useCompleteProfileModal() {
  const { t } = useTranslations();
  const { confirm } = Modal;
  const history = useHistory();
  const { setContextState } = useContextState();

  const renderCompleteProfileModal = url => {
    const modal = confirm({
      className: 'reminder-modal-wrapper',
      title: t('REMINDER_COMPLETE_PROFILE'),
      okText: t('COMPLETE_PROFILE_MODAL_COMPLETE'),
      cancelText: t('COMPLETE_PROFILE_MODAL_CLOSE'),
      okButtonProps: {
        className: 'custom-modal-button custom-modal-button-complete text-white',
        id: 'custom-modal-button-id',
      },
      cancelButtonProps: {
        className: 'custom-modal-button redmindme-later-button text-lightGray',
      },
      onCancel() {
        modal.destroy();
      },
      onOk() {
        setContextState({
          type: ActionTypes.SetRedirectScreening,
          value: { redirect: true, url },
        });
        history.push('/profile');
      },
    });
  };

  return {
    renderCompleteProfileModal,
  };
}
