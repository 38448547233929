import React, { useEffect, useState } from 'react';
import BackgroundTop from '../BackgroundTop/BackgroundTop';
import { useContextState } from '../../redux/contextState';
import rightArrow from '../../assets/challenges/right-arrow.svg';
import Switch from '../Switch/Switch';
import useTranslations from '../../hooks/useTranslations';
import { useHistory } from 'react-router-dom';
import { getTextColor } from '../../utils/determineColor';
import './Banner.css';

const Banner = ({
  text,
  isSwitch,
  height = '7rem',
  toggleAcceleration,
  isAcceleration,
  icon,
  svg,
  backButton,
  withSpacing = true,
}) => {
  const defaultColor = '#58c1f5';
  const { contextState } = useContextState();
  const [color, setColor] = useState(defaultColor);
  const { t } = useTranslations();
  const history = useHistory();

  useEffect(() => {
    setColor(
      contextState?.whitelabel?.behaviors?.CUSTOM_BRANDING
        ? contextState?.whitelabel?.colors?.BANNER_COLOR_1
        : defaultColor,
    );
  }, [contextState?.whitelabel]);

  return (
    <>
      {withSpacing && <BackgroundTop color={color} height={height} />}
      <div
        data-testid='banner-container'
        className='relative px-2 md:px-16 lg:px-32 xl:px-44 py-5 non-selectable bg-alkemy-blue'>
        <div
          style={{ color: getTextColor(color) }}
          className={`description-container text-xl font-bold text-white rounded mx-auto relative lg:pl-0 pt-1 ${
            contextState?.whitelabel?.behaviors?.BANNER_BLACK_TEXT && 'text-dark'
          }`}>
          {svg && (
            <span className='mr-2 text-2xl' data-testid='banner-svg-span'>
              {svg}
            </span>
          )}
          {icon && <img src={icon} className='mr-2 banner-icon-size inline' data-testid='banner-icon-img' />}
          {text}
          {isSwitch && (
            <div
              className='description-container text-xl font-bold text-white rounded mx-auto lg:pl-0 pt-1 absolute top-0 right-0 flex'
              data-testid='banner-switch-container'>
              <Switch
                isBlack={contextState?.whitelabel?.behaviors?.SWITCH_BLACK_TEXT}
                onChange={() => toggleAcceleration()}
                isActive={!isAcceleration}
                classNames='mx-2'
              />
            </div>
          )}
        </div>
      </div>
      {backButton && (
        <button
          onClick={() => history.goBack()}
          className='absolute flex items-center top-0 m-3 left-0 border-maya-blue rounded-full px-2 py-1 font-small  text-maya-blue font-bold bg-white back-button-border'
          data-testid='banner-back-button'>
          <img src={rightArrow} className='mr-2' data-testid='banner-arrow-img' />
          {t('BANNER_SECTION_GO_BACK')}
        </button>
      )}
    </>
  );
};

export default Banner;
