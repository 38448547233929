import { Window } from '../helpers/window';
import { refreshToken } from './refreshToken';

export const handleRedirect = async (redirectUrl, t, target = false) => {
  const newToken = await refreshToken(t);
  if (Window()) {
    if (newToken) {
      if (target) {
        window.open(`${redirectUrl}${newToken}`, '_blank');
      } else {
        window.location.href = `${redirectUrl}${newToken}`;
      }
    } else {
      const prevToken = window.localStorage.getItem('token');
      if (target) {
        window.open(`${redirectUrl}${prevToken}`, '_blank');
      } else {
        window.location.href = `${redirectUrl}${prevToken}`;
      }
    }
  }
};
