import { auth0Client } from '../../auth0/auth0';
import useTranslations from '../../hooks/useTranslations';
import './unauthorizated.css';

const Unauthorizated = () => {
  const { t } = useTranslations();
  const logout = () => {
    auth0Client().logout({ returnTo: window.location.origin });
  };
  return (
    <div className='unauthorizated-bg relative'>
      <img
        src={`${process.env.RAZZLE_RUNTIME_ASSETS}/images/59a84827-e8cc-435d-8155-2fe6837a622f.webp`}
        className='absolute bottom-0 right-0'
        width='150px'
        height='150px'
      />
      <div className='flex justify-center h-full mx-5'>
        <div className='unauth-box flex flex-col items-center pb-5 text-center px-3 mt-5 shadow-lg overflow-hidden pt-4 dark:bg-alkemy-black-bold'>
          <span className='font-roboto unauth-text block dark:text-white'>{t('UNAUTHORIZATED_PAGE_TITLE')}</span>
          <button
            className='unauth-button border-0 rounded-lg uppercase text-white p-2 block font-bold mx-auto mt-5 px-4 cursor-pointer'
            onClick={() => logout()}>
            {t('HEADER_SIGN_OUT')}
          </button>
          <div>
            <lottie-player
              src='https://assets1.lottiefiles.com/private_files/lf30_m6j5igxb.json'
              background='transparent'
              speed='1'
              loop
              autoplay></lottie-player>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unauthorizated;
