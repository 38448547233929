import { ActionTypes, useContextState } from '../../redux/contextState';
import DropDown from '../Dropdown/Dropdown';
import NotificationsDropdown from '../NotificationsDropdown/NotificationsDropdown';
import { Link } from 'react-router-dom';
import useSidebar from '../../hooks/useSidebar';
import useTranslations from '../../hooks/useTranslations';
import { Window } from '../../helpers/window';
import { auth0Client } from '../../auth0/auth0';
import ProfileCompleteProgress from '../ProfileCompleteProgress/ProfileCompleteProgress';
import DropdownLanguage from '../DropdownLanguage/DropdownLanguage';
import MenuBurger from '../../assets/header/menu-burger';
import CustomSVG from '../CustomSVG/CustomSVG';
import whereIGo from '../../utils/whereIGo';
import DarkModeIcon from '../../assets/header/dark-mode';
import LightModeIcon from '../../assets/header/light-mode';
import PersonOutlineIcon from '../../assets/sideBar/person-outline-sidebar';
import CrossSidebarIcon from '../../assets/header/icon-check-sidebar';
import alkemyLogo from '../../assets/header/logo.svg';
import './Header.scss';

const Header = ({ profile, editable, redirectProfile, maintenance = false }) => {
  const { t } = useTranslations();
  const { contextState, setContextState } = useContextState();
  const { collapsed, setCollapsed } = useSidebar();

  const optionList = [
    {
      icon: <PersonOutlineIcon />,
      title: t('MENU_ITEM_4'),
      className: 'dropdown-text font-roboto font-bold',
      link: '/profile',
    },
    {
      icon: contextState?.theme === 'light' ? <DarkModeIcon /> : <LightModeIcon />,
      title: contextState?.theme === 'light' ? t('DARK_MODE') : t('LIGHT_MODE'),
      className: 'dropdown-text font-roboto font-bold',
      action: () => {
        if (contextState?.theme === 'light') {
          setContextState({
            type: ActionTypes.SetTheme,
            value: 'dark',
          });
          localStorage.setItem('theme', 'dark');
        } else {
          setContextState({
            type: ActionTypes.SetTheme,
            value: 'light',
          });
          localStorage.setItem('theme', 'light');
        }
      },
    },
    {
      icon: <CrossSidebarIcon />,
      title: t('HEADER_SIGN_OUT'),
      className: 'dropdown-text font-roboto font-bold',
      action: () => {
        if (Window()) {
          if (window.location.hash) {
            window.localStorage.setItem('prevHash', window.location.hash);
          }
          auth0Client().logout({ returnTo: window.location.origin });
        }
      },
    },
  ];

  return (
    <header className='header sticky flex items-center bg-light-normal dark:bg-dark-normal justify-between flex-wrap'>
      <div className='flex flex-col flex-wrap'>
        {maintenance ? (
          <img
            data-testid='maintenance-img'
            src={
              contextState?.whitelabel?.behaviors?.CUSTOM_BRANDING
                ? contextState?.whitelabel?.images?.HEADER_LOGO
                : alkemyLogo
            }
            className='logo'
            alt={t('LOGO')}
          />
        ) : (
          <Link to={whereIGo(contextState.profile)}>
            <img
              data-testid='not-maintenance-img'
              src={
                contextState?.whitelabel?.behaviors?.CUSTOM_BRANDING
                  ? contextState?.whitelabel?.images?.HEADER_LOGO
                  : alkemyLogo
              }
              className='logo'
              alt={t('LOGO')}
            />
          </Link>
        )}
      </div>
      {!maintenance && (
        <div className='py-2 sm:items-center header-dropdown-max-height flex'>
          <NotificationsDropdown className='mr-3' options={contextState?.profileNotifications} />
          <DropdownLanguage />
          <ProfileCompleteProgress />
          <DropDown
            profile={profile.profile}
            options={optionList}
            editable={editable}
            redirectProfile={redirectProfile}
            className='hidden lg:block'
          />
          <CustomSVG
            onClick={() => setCollapsed(!collapsed)}
            alt={t('MENU')}
            className='block lg:hidden cursor-pointer mt-1 text-grey dark:text-white'>
            <MenuBurger />
          </CustomSVG>
        </div>
      )}
    </header>
  );
};
export default Header;
