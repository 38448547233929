import { useEffect, useState } from 'react';
import Banner from '../../components/Banner/Banner';
import Ticket from '../../components/Ticket/Ticket';
import Spinner from '../../components/Spinner/Spinner';
import { ActionTypes, useContextState } from '../../redux/contextState';
import { formatDate } from '../../utils/formatDate';
import { months } from '../../utils/constants';
import moment from 'moment';
import { DeleteProfileChallenge, patchProfileChallenge } from '../../api/userService';
import Input from '../../components/Input';
import { Button, Modal, notification, Tooltip } from 'antd';
import { CheckOutlined, EditOutlined, LockOutlined } from '@ant-design/icons';
import { githubRegex } from '../../constants/regexValidations';
import isComplete from '../../utils/steps/isComplete';
import { ticketClass } from '../../utils/ticketClass';
import './challenge.css';
import { useHistory } from 'react-router-dom';
import useTranslations from '../../hooks/useTranslations';
import useMissingData from '../../hooks/useMissingData';
import Container from '../../components/Container/Container';

const { confirm } = Modal;
const Challenge = props => {
  const history = useHistory();
  const { baseRequirements } = useMissingData();
  const [challenge, setChallenge] = useState(props.challenge);
  const { contextState, setContextState } = useContextState();
  const [repository, setRepository] = useState(props?.challenge?.url || '');
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const { t } = useTranslations();
  useEffect(() => {
    if (contextState?.profile && isComplete(contextState?.profile) === 2) {
      setEnabled(true);
    }
  }, [contextState?.profile]);
  const handleChange = e => {
    setRepository(e.target.value);
  };

  const handleSave = id => {
    if (!enabled) {
      notification.open({
        message: (
          <span>
            {t('SEND_REPOSITORY_COMPLETE_PROFILE')}
            {baseRequirements.map((item, index) => (index === baseRequirements.length - 1 ? `${item}.` : `${item}, `))}
          </span>
        ),
        btn: (
          <Button type='primary' size='small' onClick={() => history.push('/profile')}>
            {t('COMPLETE')}
          </Button>
        ),
      });
      return;
    }
    if (!repository?.match(githubRegex)) {
      notification.open({
        message: t('REPOSITORY_INVALID'),
        icon: <i className='fas fa-times' style={{ color: '#52C0F7' }} />,
      });
      setEditing(false);
      setRepository(challenge?.url ?? '');
    } else {
      confirm({
        title: t('CHALLENGE_FINAL_LINK'),
        okText: t('CHALLENGE_FINAL_LINK_CONFIRM'),
        cancelText: t('CANCEL'),
        onOk() {
          patchProfileChallenge(id, {
            url: repository,
          })
            .then(res => {
              const obj = Object.values(contextState?.profileChallenges?.challenges)?.find(
                challenge => challenge.id === id,
              );
              setContextState({
                type: ActionTypes.SetProfileChallenges,
                value: {
                  screenings: [...contextState?.profileChallenges?.screenings],
                  challenges: [
                    ...contextState?.profileChallenges?.challenges?.filter(c => c.id !== id),
                    { ...obj, url: res.url, datetime: res.datetime },
                  ],
                },
              });
              setChallenge({ ...challenge, url: res.url });
              notification.open({
                message: t('INSCRIPTION_REPOSITORY_EDITED'),
                icon: <i className='fas fa-check' style={{ color: '#52C0F7' }} />,
              });
              setEditing(false);
            })
            .catch(() => {
              notification.open({
                message: t('SEND_REPOSITORY_ERROR'),
                icon: <i className='fas fa-times' style={{ color: '#52C0F7' }} />,
              });
              setEditing(false);
            });
        },
        onCancel() {
          setEditing(false);
          setRepository(challenge?.url ?? '');
        },
      });
    }
  };

  const handleDeleteChallenge = id => {
    if (challenge?.assignedAt || challenge?.reviewedAt) {
      notification.open({
        message: t('CHALLENGE_REVIEWED_AT_MESSAGE'),
        icon: <i className='fas fa-check' style={{ color: '#52C0F7' }} />,
      });
      return;
    }
    confirm({
      title: t('UNSUSCRIBE_CHALLENGE_MESSAGE'),
      okText: t('UNSUSCRIBE'),
      cancelText: t('CANCEL'),
      onOk() {
        DeleteProfileChallenge(id).then(() => {
          setContextState({
            type: ActionTypes.SetProfileChallenges,
            value: contextState?.profileChallenges?.challenges?.filter(challenge => challenge.id !== id),
          });
          setChallenge({});
          history.push('/inscription');
        });
      },
    });
  };

  return (
    <div className='relative w-full pb-5'>
      <Banner text={`Mi challenge`} svg='🚀' />{' '}
      <Container>
        {loading ? (
          <div className='description-container bg-white dark:bg-dark-bold rounded m-auto py-4 px-5'>
            <Spinner show={loading} />
          </div>
        ) : (
          <>
            {Object.keys(challenge).length ? (
              <div className='description-container bg-white dark:bg-dark-bold rounded m-auto py-5 px-5'>
                <div className='flex  flex-row justify-between'>
                  <Ticket
                    text={challenge?.technology?.technologyType || 'Backend'}
                    className={`${ticketClass(
                      challenge?.technology?.technologyType,
                    )} ticket-padding font-bold font-roboto uppercase mr-3`}
                  />
                  <span className='font-medium'>🚀</span>
                </div>
                <div className='flex flex-col lg:flex-row justify-center mt-4'>
                  <div className='flex flex-col width-know-card-50 pr-0 pr-lg-5 dark:text-white'>
                    <span className=' font-bold title-technology dark:text-white'>{t('CHALLENGE')}</span>
                    <span className=' text-alkemy-black-bold font-bold text-xl dark:text-white'>
                      {challenge?.technology?.displayName}
                    </span>
                    <span className=' text-alkemy-black-bold font-bold mt-3 title-repository dark:text-white'>
                      {t('REPOSITORY')}
                    </span>
                    <div className='input-challenge-container pr-4 bg-white dark:bg-dark-bold'>
                      {challenge?.url && !editing ? (
                        <div className='flex items-center'>
                          <span className=' w-full span-challenge-text font-bold truncate my-1 dark:text-white'>
                            {challenge?.url}
                          </span>
                          {!challenge?.assignedAt && !challenge?.reviewedAt && (
                            <Tooltip title={t('EDIT')}>
                              <Button
                                onClick={() => setEditing(true)}
                                className='flex justify-center items-center'
                                icon={<EditOutlined />}
                              />
                            </Tooltip>
                          )}
                        </div>
                      ) : (
                        <div className='flex items-center'>
                          <Input
                            type='text'
                            name='gitUrl'
                            disabled={!editing}
                            className='w-full'
                            classNameInput='input-challenge-text'
                            disableMargin={true}
                            editable={true}
                            defaultValue={repository}
                            onChange={handleChange}
                            error={false ? { message: t('URL_VALIDATED_ERROR') } : null}
                          />
                          {editing ? (
                            <Tooltip title={enabled ? t('PROFILE_MENU_SAVE') : t('NOT_PERMITTED')}>
                              <Button
                                onClick={() => handleSave(challenge?.id)}
                                className='ml-3 flex justify-center items-center'
                                icon={enabled ? <CheckOutlined /> : <LockOutlined />}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title={t('EDIT')}>
                              <Button
                                onClick={() => setEditing(true)}
                                className='ml-3 flex justify-center items-center'
                                icon={<EditOutlined />}
                              />
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </div>
                    {challenge?.datetime && challenge?.url && (
                      <div className='mt-2'>
                        <span className=' font-weight-500 title-cohort dark:text-white'>{t('SHIPPING_DATE')}: </span>
                        <span className=' font-bold title-cohort dark:text-white'>
                          {moment(challenge?.datetime).format('DD/MM/YYYY')}
                        </span>
                      </div>
                    )}
                    <div className='mt-3 mb-2'>
                      <span className=' text-alkemy-black-bold font-bold title-repository dark:text-white'>
                        {t('STATUS')}
                      </span>
                      <div className='flex  flex-row mt-1'>
                        <Ticket
                          text={
                            !challenge?.url
                              ? t('CHALLENGE_WITHOUT_SENDING')
                              : challenge?.reviewedAt
                              ? t('CHALLENGE_CORRECTED')
                              : t('CHALLENGE_IN_REVIEW')
                          }
                          className={`${
                            !challenge?.url
                              ? 'ticket-not-send-text'
                              : challenge?.reviewedAt
                              ? 'ticket-sprint-text'
                              : 'ticket-review-text'
                          } ticket-padding font-bold font-roboto uppercase mr-3`}
                        />
                      </div>
                    </div>
                    <div className={`flex flex-col mt-3`}>
                      <span className=' text-alkemy-black-bold font-bold title-repository mb-2 dark:text-white'>
                        {t('SEND_DEADLINE')}
                      </span>
                      {challenge?.technology?.announcements
                        ?.sort((a, b) => new Date(a.deadline) - new Date(b.deadline))
                        .slice(0, 3)
                        ?.map((announcement, index) => (
                          <div
                            className={`flex flex-col mt-1 ${
                              challenge?.technology?.announcements?.length - 1 === index && 'pb-4'
                            }`}
                            key={announcement.id}>
                            <div className='flex  flex-row'>
                              <span className=' text-cohort text-alkemy-black-bold font-weight-500 dark:text-white'>
                                {formatDate(t, new Date(announcement?.deadline))} - {t('ACCELERATION_OF')}{' '}
                              </span>
                              <span className=' font-bold text-cohort text-alkemy-black-bold dark:text-white'>
                                &nbsp;{t(months[new Date(moment(announcement?.trainingStartDate))?.getMonth()]?.name)}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className={`flex flex-col mt-3 mb-5 pb-5`}>
                      <span className=' text-alkemy-black-bold font-bold title-repository mb-2 dark:text-white'>
                        {t('NEED_HELP_SOLVE_CHALLENGE')}
                      </span>
                      <span className=' dark:text-white'>{t('SKILL_UP_TEXT')}</span>
                      <span className=' mt-3 dark:text-white'>
                        {t('GO_TO_THIS')}&nbsp;
                        <a
                          href='https://forms.gle/CqBkXuh9LXpS2R4Z6'
                          className='text-underline span-link-typeform'
                          rel='noreferrer'
                          target='_blank'>
                          {t('LINK_SKILL_UP')}
                        </a>
                        &nbsp;{t('SKILL_UP_TEXT_1')}
                      </span>
                    </div>
                    {!challenge?.reviewedAt ? (
                      <button
                        className='mb-0 lg:mb-3 absolute bottom-0 button-unsuscribe button-unsuscribe-text font-roboto font-bold px-4 py-2'
                        onClick={() => handleDeleteChallenge(challenge.id)}>
                        {t('UNSUSCRIBE')}
                      </button>
                    ) : (
                      <button
                        className='mb-0 lg:mb-3 absolute bottom-0 button-unsuscribe button-unsuscribe-text font-roboto font-bold px-4 py-2'
                        onClick={() => history.push('/inscription')}>
                        {t('SEND_AGAIN')}
                      </button>
                    )}
                  </div>
                  <div className='flex flex-row width-know-card-50 '>
                    <div className='flex flex-col pdf-container self-start w-full px-3 pt-3 pb-0 bg-white dark:bg-dark-bold'>
                      <span className=' font-bold pdf-title text-alkemy-black-bold mt-2 mb-3 dark:text-white'>
                        {t('SLOGAN')}
                      </span>
                      <iframe
                        src={challenge?.technology?.announcements[0]?.challengeUrl || ''}
                        width='100%'
                        height='480'
                        title={challenge?.technology?.displayName}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='description-container bg-white rounded m-auto py-4 px-5 flex flex-col justify-center items-center'>
                <span className=''>{t('NOT_THESE_CHALLENGE')}</span>
                <button
                  className='my-4 button-unsuscribe button-unsuscribe-text font-roboto font-bold px-2 py-2'
                  onClick={() => history.push('/inscription')}>
                  {t('CERTIFY')}
                </button>
              </div>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default Challenge;
