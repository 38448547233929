import ReactIf from './ReactIf';
import { Route, Redirect } from 'react-router-dom';
import Dashboard from '../layouts/Dashboard/Dashboard';
import whereIGo from '../utils/whereIGo';

const ProtectedRoute = props => {
  const { component: Component, isAuthorized, isLogin, dashboard, profile, ...rest } = props;
  let path;
  if (!isAuthorized) {
    path = whereIGo(profile);
  }

  return (
    <Route
      {...rest}
      render={() => (
        <div>
          <ReactIf condition={!isLogin}>
            <Redirect to='/register' />
          </ReactIf>
          <ReactIf condition={!isAuthorized}>
            <Redirect to={path || ''} />
          </ReactIf>
          <ReactIf condition={isAuthorized}>{dashboard ? <Dashboard>{Component}</Dashboard> : <Component />}</ReactIf>
        </div>
      )}
    />
  );
};

export default ProtectedRoute;
