import { useContextState } from '../redux/contextState';
import useRoles from '../hooks/useRoles';
import { useHistory } from 'react-router-dom';
import HomeIcon from '../assets/sideBar/home.jsx';
import NewsIcon from '../assets/sideBar/news-icon';
import BookmarkIcon from '../assets/sideBar/bookmark';
import CalendarIcon from '../assets/sideBar/calendar_today';
import CheckIcon from '../assets/sideBar/icon-check';
import ChartIcon from '../assets/sideBar/icon-chart';
import CertificateIcon from '../assets/sideBar/certificate';
import AcademyIcon from '../assets/sideBar/academy';
import ScienceIcon from '../assets/sideBar/science-icon';
import PersonOutlineIcon from '../assets/sideBar/person-outline-sidebar';
import TrainingIcon from '../assets/sideBar/training';
import JobBagIcon from '../assets/sideBar/JobBag.jsx';
import { roles } from './enums.js';

export const useSidebarRoutes = t => {
  const { contextState } = useContextState();
  const { location } = useHistory();
  const { challenges, screenings } = contextState.profileChallenges;
  const { isRole, isOneCampusRole } = useRoles();

  const isSelected = paths => paths?.some(path => path === location.pathname);

  const routes = [
    {
      path: '/profile',
      label: t('MENU_ITEM_4'),
      icon: <PersonOutlineIcon />,
      conditionToShow: isRole(roles.ALKYMER) || isRole(roles.PREACCELERATION) || isRole(roles.ADMIN),
      exceptRole: [],
      selected: isSelected(['/profile']),
      isMobile: true,
    },
    {
      path: '/home',
      label: t('SIDEBAR_HOME'),
      icon: <HomeIcon />,
      selected: isSelected(['/home']),
      conditionToShow: !contextState?.whitelabel?.behaviors?.HIDE_INSCRIPTION_OPTION,
    },
    {
      path: '/news',
      label: t('MENU_ITEM_16'),
      conditionToShow: isRole(roles.ALKYMER) || isRole(roles.PREACCELERATION) || isRole(roles.ADMIN),
      selected: isSelected(['/news']),
      icon: <NewsIcon />,
    },
    {
      path: '/resources',
      label: t('MENU_ITEM_RESOURCES'),
      icon: <BookmarkIcon />,
      conditionToShow:
        (isRole(roles.ALKYMER) || isRole(roles.PREACCELERATION) || isRole(roles.ADMIN) || isRole(roles.APPLICANT)) &&
        !isRole(roles.EXCLUSIVEUSER),
      selected: isSelected(['/resources']),
    },
    {
      path: `${process.env.RAZZLE_RUNTIME_ACADEMY_URL}?token=`,
      label: t('MENU_ITEM_9'),
      exceptRole: [],
      icon: <AcademyIcon />,
      conditionToShow:
        (isRole(roles.ALKYMER) ||
          isRole(roles.PREACCELERATION) ||
          isRole(roles.BETATESTER) ||
          isRole(roles.ADMIN) ||
          isRole(roles.APPLICANT)) &&
        !isRole(roles.EXCLUSIVEUSER),
      isAcademy: true,
    } /*
    {
      path: '/welcome',
      label: t('MENU_ITEM_17'),
      selected: isSelected(['/welcome']),
      exceptRole: [],
      conditionToShow: isRole(roles.APPLICANT) && contextState?.whitelabel?.routes?.includes('WELCOME'),
      icon: <CalendarIcon />,
    },*/,
    {
      path: '/results',
      label: t('SIDEBAR_RESULTS'),
      conditionToShow:
        challenges?.length > 0 ||
        screenings?.length > 0 ||
        contextState.screenings?.some(item => item.status === 'FINISHED'),
      selected: isSelected(['/results']),
      icon: <ChartIcon />,
    },
    {
      label: t('MENU_TITLE_1'),
      icon: <CheckIcon />,
      exceptRole: [],
      conditionToShow:
        isOneCampusRole(roles.APPLICANT) &&
        !contextState?.whitelabel?.behaviors?.HIDE_INSCRIPTION_OPTION &&
        !isRole(roles.EXCLUSIVEUSER),
      selected: isSelected(['/inscription', '/challenges']),
      subRoutes: [
        {
          path: '/inscription',
          label: t('MENU_ITEM_1'),
        },
        {
          path: '/challenges',
          label: t('MENU_ITEM_2'),
        },
      ],
    },
    {
      path: '/job-posts',
      label: t('JOB_POST'),
      icon: <JobBagIcon />,
      conditionToShow:
        (isRole(roles.ALKYMER) || isRole(roles.APPLICANT) || isRole(roles.ADMIN)) && !isRole(roles.EXCLUSIVEUSER),
      selected: isSelected(['/job-posts']),
    } /*
    {
      label: t('MENU_TITLE_2'),
      icon: <TrainingIcon />,
      conditionToShow: isRole(roles.ALKYMER) || isRole(roles.ADMIN),
      subRoutes: [
        {
          path: '/advance',
          label: t('MENU_ITEM_5'),
        },
        {
          path: '/my-group',
          label: t('MENU_ITEM_8'),
        },
        {
          path: '/methodology',
          label: t('MENU_ITEM_6'),
        },
        {
          path: '/project',
          label: t('MENU_ITEM_7'),
        },
        {
          path: '/jobs',
          label: t('MENU_ITEM_11'),
        },
        *  {
          path: '/content',
          label: t('MENU_ITEM_19'),
        }, *
        {
          path: '/mentor',
          label: t('MENU_ITEM_20'),
        },
      ],
      selected: isSelected(['/advance', '/my-group', '/methodology', '/project', '/jobs', '/mentor']),
    },*/,
    {
      label: t('MENU_TITLE_2'),
      icon: <ChartIcon />,
      conditionToShow: isRole(roles.PREACCELERATION) && !isRole(roles.ALKYMER),
      subRoutes: [
        {
          path: '/advance',
          label: t('MENU_ITEM_5'),
        },
        {
          path: '/my-group',
          label: t('MENU_ITEM_8'),
        },
        {
          path: '/methodology',
          label: t('MENU_ITEM_6'),
        },
        {
          path: '/project',
          label: t('MENU_ITEM_7'),
        },
        {
          path: '/jobs',
          label: t('MENU_ITEM_11'),
        },
        /* {
          path: '/content',
          label: t('MENU_ITEM_19'),
        }, */
      ],
      selected: isSelected(['/advance', '/my-group', '/methodology', '/project', '/jobs']),
    },
    {
      path: '/calendar',
      label: t('MENU_ITEM_10'),
      icon: <CalendarIcon />,
      exceptRole: [],
      conditionToShow: isRole(roles.ALKYMER) || isRole(roles.PREACCELERATION) || isRole(roles.ADMIN),
      selected: isSelected(['/calendar']),
    },
    /*
    {
      path: '/certificate',
      label: t('MENU_ITEM_15'),
      icon: <CertificateIcon />,
      conditionToShow: isRole(roles.ALKYMER) || isRole(roles.PREACCELERATION) || isRole(roles.ADMIN),
      selected: isSelected(['/certificate']),
    },
     {
      label: t('MENU_ITEM_19'),
      icon: contentIcon,
      exceptRole: [],
      conditionToShow: isOneCampusRole(roles.APPLICANT),
      iconSelected: contentIconSelected,
      path: '/content',
    }, 
    {
      label: t('MENU_ITEM_2'),
      icon: <CheckIcon />,
      conditionToShow:
        ((isRole(roles.ALKYMER) || isRole(roles.PREACCELERATION)) &&
          contextState?.whitelabel?.routes?.includes('INSCRIPTION')) ||
        isRole(roles.ADMIN),
      subRoutes: [
        {
          path: '/inscription',
          label: t('MENU_ITEM_1'),
        },
        {
          path: '/challenges',
          label: t('MENU_ITEM_2'),
        },
      ],
      selected: isSelected(['/inscription', '/challenges']),
    },*/
    {
      path: `${process.env.RAZZLE_RUNTIME_SCREENINGS}?technologyId=5&token=`,
      label: 'Screening - Beta test',
      exceptRole: [],
      icon: <ScienceIcon />,
      conditionToShow: isRole(roles.BETATESTER),
      isAcademy: true,
      isScreening: true,
    },
    {
      path: '/certificates',
      label: t('MY_CERTIFICATES_LABEL'),
      icon: <CertificateIcon />,
      selected: isSelected(['/certificates']),
      conditionToShow: !isRole(roles.EXCLUSIVEUSER),
    },
  ];

  return {
    desktopRoutes: routes.filter(route => route.conditionToShow && !route.isMobile),
    mobileRoutes: routes.filter(route => route.conditionToShow),
  };
};
