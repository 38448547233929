import { FilePdfOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getJob } from '../../../api/jobPostService';
import Banner from '../../../components/Banner/Banner';
import Spinner from '../../../components/Spinner/Spinner';
import useTranslations from '../../../hooks/useTranslations';
import Error404 from '../../404/404';
import Error from '../../Error/Error';
import { Skeleton } from 'antd';
import DiamondIcon from '../../../assets/Diamond';
import EmptyTech from '../../../assets/mentor/logo_alkemy.svg';
import SafeContainer from '../../../components/SafeContainer/SafeContainer';
const fetchJobDetail = async id => await getJob(id);

const JobDetail = () => {
  const { id } = useParams();
  const { t } = useTranslations();
  const { data, isLoading, isError, refetch, error } = useQuery(['job', id], () => fetchJobDetail(id), {
    enabled: Boolean(id),
    retry: 2,
  });
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  useEffect(() => {
    refetch();
  }, []);

  const sections = [
    {
      title: t('JOB_LABEL_LOCATION'),
      content: (
        <>
          <div data-testid='jobDetail-address' className='flex items-center justify-center mx-2 flex-wrap gap-y-2'>
            {data?.address
              ?.sort((a, b) => (a?.countryId > b?.countryId ? 1 : -1))
              ?.map(a => (
                <>
                  <span className='country-text mx-2 dark:text-white flex'>
                    {a?.location != '' ? `${a?.location}, ` : null}
                    {a?.city ? `${a?.city?.displayName}, ` : null}
                    {a?.country?.displayName}
                    <img
                      src={`${process.env.RAZZLE_RUNTIME_ASSETS}/assets/countries/${a?.country?.isoCode}.svg`}
                      className='country-flag-img D-Flex mx-2 mt-1'
                      aria-hidden='true'
                    />
                  </span>
                </>
              ))}
          </div>
        </>
      ),
    },
    {
      title: t('JOB_LABEL_CONTRACT_MODALITY'),
      content: (
        <>
          <span data-testid='jobDetail-workModality' className='country-text mx-2 dark:text-white'>
            {t(data?.workModality)}
          </span>
        </>
      ),
    },
    {
      title: t('JOB_LABEL_MODALITY'),
      content: (
        <>
          <span data-testid='jobDetail-modality' className='country-text mx-2 dark:text-white'>
            {t(data?.modality)}
          </span>
        </>
      ),
    },
    {
      title: t('JOB_LABEL_ROLE'),
      content: (
        <>
          <span data-testid='jobDetail-rol' className='country-text mx-2 dark:text-white'>
            {t(data?.rol?.displayName)}
          </span>
        </>
      ),
    },
    {
      title: t('JOB_LABEL_SENIORITY'),
      content: (
        <>
          <span data-testid='jobDetail-seniority' className='country-text mx-2 dark:text-white'>
            {t(data?.seniority)}
          </span>
        </>
      ),
    },
    {
      title: t('JOB_LABEL_SALARY'),
      content: (
        <>
          <span data-testid='jobDetail-salary' className='country-text mx-2 dark:text-white'>
            {data?.currency} {data?.salary}
          </span>
        </>
      ),
      hide: data?.salaryInCentsMax?.toString() === '0',
    },
  ];

  return (
    <div data-testid='jobDetail-page' className='relative w-full pb-5'>
      <Banner text={t('JOB_POST')} svg='🧰' height='5rem' />
      <div data-testid='jobDetail-data-container' className='flex items-center justify-center w-full my-4'>
        {isLoading ? (
          <>
            {/* <Spinner show /> */}
            <div className='flex flex-col gap-8 w-full items-center my-8'>
              <div data-testid='spinner' className='w-full lg:w-1/2 xl:w-1/2  flex gap-8'>
                <Skeleton loading={isLoading} active />
              </div>
              <div data-testid='spinner' className='w-full lg:w-1/2 xl:w-1/2  flex gap-8'>
                <Skeleton loading={isLoading} active />
              </div>
              <div data-testid='spinner' className='w-full lg:w-1/2 xl:w-1/2  flex gap-8'>
                <Skeleton loading={isLoading} active />
              </div>
            </div>
          </>
        ) : (
          <>
            {isError ? (
              error?.response?.status == 404 ? (
                <Error404 text={'No se encuentra la oferta de trabajo'} />
              ) : (
                <Error showHeader={false} />
              )
            ) : (
              <>
                {data && (
                  <div className='w-full lg:w-1/2 xl:w-1/2 mx-4'>
                    <section data-testid='jobDetail-header' className='flex items-center justify-between w-full my-4'>
                      <div className='flex items-center my-4'>
                        <img
                          className='rounded-full'
                          src={data?.company?.picture || ''}
                          alt={t('COMPANY_LOGO')}
                          width={'100px'}
                          height={'100px'}
                        />
                        <section className='flex flex-col mb-4 ml-2'>
                          <span className='font-montserrat text-md dark:text-white'>{data?.createdAt}</span>
                          <span
                            data-testid='name-button'
                            rel='noreferrer noopener'
                            className='font-montserrat text-2xl font-bold dark:text-white'>
                            <div className='flex items-center gap-2'>
                              <div className='flex-1 break-words'>
                                <span> {data?.title}</span>
                              </div>
                              <div className='flex-shrink-0'>
                                {data?.isPremium && <DiamondIcon className={'h-6'} />}
                              </div>
                            </div>
                          </span>
                          {/* <span className='font-montserrat text-xl font-medium'>{data?.seniority}</span> */}
                          <span className='font-montserrat text-xl font-semibold dark:text-white'>
                            {data?.company?.displayName}
                          </span>
                        </section>
                      </div>
                      {data?.fileUrl && (
                        <div>
                          <div
                            className='p-2 px-4 rounded-xl shadow-md flex flex-col items-center justify-center gap-1 cursor-pointer bg-white dark:bg-dark-normal dark:text-white'
                            onClick={() => window.open(data?.fileUrl, '_blank')}>
                            <FilePdfOutlined style={{ fontSize: '1.5em' }} />
                            {t('JOB_LABEL_FILE_ATTACHED')}
                          </div>
                        </div>
                      )}
                    </section>

                    <section
                      data-testid='jobDetail-sections'
                      className='flex flex-wrap items-center justify-start gap-4'>
                      {sections?.map((section, i) => {
                        if (section?.hide) return null;
                        return (
                          <div
                            key={section?.title + i}
                            className='flex-grow flex flex-col items-center justify-center rounded-2xl shadow-md px-6 py-4 gap-1 bg-white dark:bg-dark-normal dark:text-white'>
                            <span
                              data-testid='name-button'
                              rel='noreferrer noopener'
                              className='font-montserrat text-xl font-bold dark:text-white'>
                              {section?.title}
                            </span>
                            <div className='mx-2'>{section?.content}</div>
                          </div>
                        );
                      })}
                    </section>

                    {data?.technologies && data?.technologies?.length ? (
                      <section className='my-8' data-testid='jobDetail-technologies'>
                        <span
                          data-testid='name-button'
                          rel='noreferrer noopener'
                          className='font-montserrat text-xl font-bold dark:text-white'>
                          {t('JOB_LABEL_TECHNOLOGIES')}
                        </span>
                        <section className='px-4 flex w-full mb-4'>
                          <div className='flex flex-wrap justify-start gap-2'>
                            {data?.technologies?.map((technology, index) => (
                              <div
                                key={technology?.displayName + index}
                                className='flex flex-col items-center justify-center rounded-2xl gap-1'>
                                <span
                                  key={index}
                                  className='inline-block  font-montserrat text-sm font-semibold px-3 py-2 rounded-full m-1 dark:text-white'>
                                  <div className='flex justify-center my-1'>
                                    <img
                                      className='rounded-full border-2 border-white '
                                      src={technology?.imageUrl || EmptyTech}
                                      alt={t('PROFILE_MENU_LOGO_USER')}
                                      style={{ height: '2em' }}
                                    />
                                  </div>
                                  {technology?.displayName}
                                </span>
                              </div>
                            ))}
                          </div>
                        </section>
                      </section>
                    ) : null}

                    <section className='my-8' data-testid='jobDetail-description'>
                      {data?.description && data?.description?.trim()?.length !== 0 && (
                        <>
                          <span
                            data-testid='name-button'
                            rel='noreferrer noopener'
                            className='font-montserrat text-xl font-bold dark:text-white'>
                            {t('JOB_LABEL_DESCRIPTION')}
                          </span>
                          <div className='block items-center whitespace-pre-line text-lg my-4 dark:text-white break-words'>
                            {showFullDescription || (!showFullDescription && data?.description?.length < 350) ? (
                              <SafeContainer content={data?.description} />
                            ) : (
                              <SafeContainer content={data?.description.slice(0, 350)} />
                            )}
                          </div>
                          {data?.description.length > 350 && (
                            <div className='flex justify-content whitespace-pre-line text-md dark:text-white'>
                              <a
                                href='#'
                                onClick={toggleDescription}
                                style={{ color: '#SDF23A' }}
                                className='mr-0 ml-auto'>
                                {showFullDescription ? t('JOB_DESCRIPTION_SHOW_LESS') : t('JOB_DESCRIPTION_SHOW_MORE')}
                              </a>
                            </div>
                          )}
                        </>
                      )}
                      <div className='flex items-center justify-center'>
                        <a
                          onClick={() => window.open(`${data?.screeningUrl}&jobApplied=${data?.hashId}`, '_blank')}
                          className='w-1/2 px-5 py-1 mt-4 bg-maya-blue font-bold rounded-2xl text-white hover:cursor-pointer text-center'
                          data-testid='job-button-apply'>
                          {t('APPLY')}
                        </a>
                      </div>
                    </section>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default JobDetail;
