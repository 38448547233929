import React, { useEffect } from 'react';
import { useContextState, ActionTypes } from '../../redux/contextState';
import { getAccelerationInfo } from '../../api/userService';
import useTranslations from '../../hooks/useTranslations';
import useUserAdvance from '../../hooks/useUserAdvance';
// Components
import Banner from '../../components/Banner/Banner';
import EmptyComponent from '../../components/EmptyComponent/EmptyComponent';
import Container from '../../components/Container/Container';
import ViewPdf from '../../components/ViewPdf/ViewPdf';
import Spinner from '../../components/Spinner/Spinner';

const Methodology = () => {
  const { contextState, setContextState } = useContextState();
  const { t } = useTranslations();
  const { loading, profile } = useUserAdvance();

  useEffect(() => {
    if (profile?.groups?.length) {
      getAccelerationInfo('METODOLOGY', undefined, profile?.groups[0]?.group?.companyId).then(res => {
        setContextState({
          type: ActionTypes.SetPdf,
          value: res,
        });
      });
    }
  }, [profile]);

  return (
    <div className='mb-2 pb-4 w-full'>
      <div>
        <Banner text={`${t('METHODOLOGY_TITLE')}`} icon={contextState?.whitelabel?.images?.BANNER_LOGO} />
        {loading ? (
          <Spinner show />
        ) : !contextState.pdf.length ? (
          <Container>
            <div className='flex flex-col description-container mx-auto'>
              <div className='custom-card'>
                <div className='flex flex-col text-center sm:flex-row py-4 sm:my-2 px-3 md:px-4 relative sm:text-center md:text-left bg-white dark:bg-dark-bold'>
                  <div className='flex justify-around items-center w-full h-full flex-wrap'>
                    <EmptyComponent
                      className='py-5'
                      title={t('METHODOLOGY_EMPTY_TITLE')}
                      subtitle={t('METHODOLOGY_EMPTY_SUBTITLE')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        ) : (
          <Container>
            <ViewPdf pdf={contextState.pdf.length ? contextState.pdf[0].assetUrl : ''} />
          </Container>
        )}
      </div>
    </div>
  );
};

export default Methodology;
