import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useTranslations from '../../../hooks/useTranslations';
import { useContextState } from '../../../redux/contextState';
import NewStyleInput from '../../NewStyleInput/NewStyleInput';
import { urlRegex } from '../../../constants/regexValidations';

const PortfolioCardFirstInput = ({ value, update, editable, portfoliosUrl, children }) => {
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: value,
  });

  const [edit, setEdit] = useState(editable);
  const { t } = useTranslations();
  useEffect(() => {
    if (Object.keys(value).length === 1) {
      setEdit(true);
    }
  }, []);

  return (
    <>
      <NewStyleInput
        className={'my-3'}
        register={register}
        validations={{
          pattern: urlRegex,
          required: {
            value: true,
            message: t('PORTFOLIO_URL_CANNOT_BE_EMPTY'),
          },
          validate: {
            noRepeated: currentValue => {
              return !portfoliosUrl.some(el => el.url === currentValue) || t('PORTFOLIO_URL_CANNOT_BE_REPEATED');
            },
          },
        }}
        disabled={!edit}
        error={{
          message:
            (errors.url?.type === 'pattern' && t('PORTFOLIO_MUST_INTRODUCE_A_VALID_URL')) ||
            (errors.url?.type === 'required' && t('PORTFOLIO_URL_CANNOT_BE_EMPTY')) ||
            (errors.url?.type === 'noRepeated' && t('PORTFOLIO_URL_CANNOT_BE_REPEATED')),
        }}
        title={t('PORTFOLIO_INPUT_TITLE')}
        name='url'
      />
      {children}
      <button
        data-testid='button-add-portfolio'
        className='mt-3 w-fit ml-auto card-button-edit bg-white dark:bg-dark-bold rounded-full font-bold px-5 text-alkemy-button border-maya-blue'
        onClick={handleSubmit(() => {
          update(watch());
          setValue('url', '');
        })}>
        {t('PORTFOLIO_ADD')}
      </button>
    </>
  );
};

export default PortfolioCardFirstInput;
