import { useRef, useState } from 'react';
import useTranslations from '../../../hooks/useTranslations';
import useClickOutside from '../../../hooks/useClickOutside';
import './DropdownButton.scss';
import ArrowSmall from '../../../assets/profile/ArrowSmall';

const DropdownButton = ({
  options = [],
  setState,
  allOption,
  id = 'id',
  disabled,
  contentText,
  contentStyles = '',
}) => {
  const { t } = useTranslations();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useClickOutside(ref, () => setOpen(false), true);

  const handleClick = value => {
    setState(value);
    setOpen(false);
  };

  return (
    <div ref={ref} className='w-full max-w-[12rem] min-w-[4rem]'>
      <div aria-hidden onClick={() => !disabled && setOpen(state => !state)} className='flex'>
        <button
          type='button'
          disabled={disabled}
          className={`${contentStyles} text-alkemy-blue disabled:cursor-not-allowed batch-action-button p-1 justify-between items-center flex maya-blue-thin-border align-items-center border rounded-lg`}>
          <span
            className={`text-xs font-montserrat font-bold truncate ${
              disabled ? 'text-gray-500' : 'text-dark-bold dark:text-white'
            }`}>
            {contentText}
          </span>
          <ArrowSmall className={`ml-3 stroke-current dropdown-arrow ${open && 'dropdown-arrow-open'}`} width='10' />
        </button>
      </div>
      <ul
        className='w-[9.55rem] dropdown-button-options-container dark:bg-dark-normal dark:text-white bg-white overflow-auto custom-scrollbar m-0 absolute rounded py-2 gray-subtitle'
        data-open={open}>
        {allOption && (
          <li
            className='py-1 px-3 cursor-pointer dropdown-selec-option text-xs font-montserrat letter-spacing-05'
            onClick={() => handleClick({ name: t('ALL'), id: null })}
            onKeyPress={() => handleClick({ name: t('ALL'), id: null })}
            role='option'
            aria-selected>
            {t('ALL')}
          </li>
        )}
        {options.map((option, i) => (
          <div key={i} className='d-flex'>
            <li
              className='py-1 px-1 cursor-pointer hover:bg-slate-200 dark:hover:bg-dark-bold dropdown-selec-option text-xs font-montserrat font-italic'
              key={i}
              onClick={() => {
                handleClick({
                  id: option.id,
                  ...option,
                });
              }}
              onKeyPress={() => {
                handleClick({
                  id: option.id,
                  ...option,
                });
              }}
              role='option'
              aria-selected>
              {option.name}
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default DropdownButton;
