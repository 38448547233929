const mockedSocialMedia = [
  {
    id: 0,
    profile_url: '',
  },
  {
    id: 1,
    profile_url: '',
  },
];

const codeCountry = [
  '+54',
  '+591',
  '+58',
  '+52',
  '+51',
  '+380',
  '+56',
  '+375',
  '+598',
  '+57',
  '+93',
  '+355',
  '+213',
  '+1-684',
  '+376',
  '+244',
  '+1-264',
  '+672',
  '+1-268',
  '+374',
  '+297',
  '+61',
  '+43',
  '+994',
  '+1-242',
  '+973',
  '+880',
  '+1-246',
  '+32',
  '+501',
  '+229',
  '+1-441',
  '+975',
  '+387',
  '+267',
  '+55',
  '+246',
  '+1-284',
  '+673',
  '+359',
  '+226',
  '+257',
  '+855',
  '+237',
  '+1',
  '+238',
  '+1-345',
  '+236',
  '+235',
  '+86',
  '+61',
  '+61',
  '+269',
  '+682',
  '+506',
  '+385',
  '+53',
  '+599',
  '+357',
  '+420',
  '+243',
  '+45',
  '+253',
  '+1-767',
  '+1-809',
  '+670',
  '+593',
  '+20',
  '+503',
  '+240',
  '+291',
  '+372',
  '+251',
  '+500',
  '+298',
  '+679',
  '+358',
  '+33',
  '+689',
  '+241',
  '+220',
  '+995',
  '+49',
  '+233',
  '+350',
  '+30',
  '+299',
  '+1-473',
  '+1-671',
  '+502',
  '+44-14',
  '+224',
  '+245',
  '+592',
  '+509',
  '+504',
  '+852',
  '+36',
  '+354',
  '+91',
  '+62',
  '+98',
  '+964',
  '+353',
  '+44-16',
  '+972',
  '+39',
  '+225',
  '+1-876',
  '+81',
  '+44-15',
  '+962',
  '+7',
  '+254',
  '+686',
  '+383',
  '+965',
  '+996',
  '+856',
  '+371',
  '+961',
  '+266',
  '+231',
  '+218',
  '+423',
  '+370',
  '+352',
  '+853',
  '+389',
  '+261',
  '+265',
  '+60',
  '+960',
  '+223',
  '+356',
  '+692',
  '+222',
  '+230',
  '+262',
  '+691',
  '+373',
  '+377',
  '+976',
  '+382',
  '+1-664',
  '+212',
  '+258',
  '+95',
  '+264',
  '+674',
  '+977',
  '+31',
  '+599',
  '+687',
  '+64',
  '+505',
  '+227',
  '+234',
  '+683',
  '+850',
  '+1-670',
  '+47',
  '+968',
  '+92',
  '+680',
  '+970',
  '+507',
  '+675',
  '+595',
  '+63',
  '+64',
  '+48',
  '+351',
  '+1-787',
  '+974',
  '+242',
  '+262',
  '+40',
  '+7',
  '+250',
  '+590',
  '+290',
  '+1-869',
  '+1-758',
  '+590',
  '+508',
  '+1-784',
  '+685',
  '+378',
  '+239',
  '+966',
  '+221',
  '+381',
  '+248',
  '+232',
  '+65',
  '+1-721',
  '+421',
  '+386',
  '+677',
  '+252',
  '+27',
  '+82',
  '+211',
  '+34',
  '+94',
  '+249',
  '+597',
  '+47',
  '+268',
  '+46',
  '+41',
  '+963',
  '+886',
  '+992',
  '+255',
  '+66',
  '+228',
  '+690',
  '+676',
  '+1-868',
  '+216',
  '+90',
  '+993',
  '+1-649',
  '+688',
  '+1-340',
  '+256',
  '+971',
  '+44',
  '+1',
  '+998',
  '+678',
  '+379',
  '+84',
  '+681',
  '+212',
  '+967',
  '+260',
  '+263',
];

const mockedProfile = {
  skills: { points: [], points_total: 0 },
  tech_skills: [],
  profile: {
    id: 0,
    user_id: 0,
    nationality_id: 0,
    first_name: '',
    middle_name: null,
    last_name: '',
    birthdate: '',
    age: 0,
    sex: '',
    identification_type: null,
    identification_number: null,
    created_at: '',
    updated_at: '',
    extra_data: null,
    avatar_url: '',
    jira_id: null,
    hiring_status_id: 0,
    current_sprint: null,
    time_availability: null,
    company_name: null,
    last_step_completed: '',
    race: [{ name: '' }],
  },
  role: '',
};

const mockedLinkLms = {
  url: 'https://academy.alkemy.org/login?token=e0bc96ef6df91b282f0638ebbfcde3cf',
};

const mockedVideos = [
  {
    id: 0,
    profile_id: 0,
    video_id: null,
    external_url: '',
    status: 'pending',
    size_kb: null,
    length: null,
    format: null,
  },
];

const mockedAdvance = {
  userId: '2313',
  groupId: '50',
  currentTech: 'reactjs',
  globalLevel: 2,
  totalEngagement: 66.58461538461539,
  velocity: {
    current: 9.333333333333334,
    history: [11, 8, 9],
  },
  skills: [
    {
      currentLevel: 3,
      currentEngagement: 33.33333333333333,
      approvedLevel: 2,
      subSkills: [
        {
          blocker: false,
          approved: true,
          currentScore: 4,
          level: 1,
          name: 'persistencia del token',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: false,
          currentScore: 0,
          level: 2,
          name: 'verificación de usuario',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: false,
          currentScore: 0,
          level: 3,
          name: 'ruta',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
      ],
      name: 'autenticación',
      id: '88a68721-a8ab-4232-be44-53e6246e1db4',
      firstLevel: 1,
    },
    {
      currentLevel: 1,
      currentEngagement: 33,
      approvedLevel: 3,
      subSkills: [
        {
          blocker: false,
          approved: true,
          currentScore: 3,
          level: 1,
          name: 'user feedback i',
          initialScore: 3,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: true,
          currentScore: 6,
          level: 1,
          name: 'validación de formularios i',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: true,
          currentScore: 0,
          level: 1,
          name: 'componentes de contenido i',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: true,
          currentScore: 0,
          level: 1,
          name: 'componentes de listados i',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: true,
          currentScore: 0,
          level: 1,
          name: 'componentes de navegación i',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: false,
          currentScore: 0,
          level: 2,
          name: 'media queries',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: true,
          currentScore: 0,
          level: 2,
          name: 'librería de frontend',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: true,
          currentScore: 0,
          level: 3,
          name: 'user feedback ii',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: true,
          currentScore: 2,
          level: 3,
          name: 'componentes de listados ii',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: false,
          currentScore: 0,
          level: 3,
          name: 'componentes de navegación ii',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: true,
          currentScore: 3,
          level: 3,
          name: 'componentes de contenido ii',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: true,
          currentScore: 0,
          level: 3,
          name: 'debounce i',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: false,
          currentScore: 0,
          level: 3,
          name: 'validación de formularios ii',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: false,
          currentScore: 0,
          level: 4,
          name: 'debounce ii',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: false,
          currentScore: 0,
          level: 4,
          name: 'mobile first',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
      ],
      name: 'desarrollo de vistas',
      id: '828d55ce-8336-4ee3-99a9-000d989558d3',
      firstLevel: 1,
    },
    {
      currentLevel: 3,
      currentEngagement: 33.33333333333333,
      approvedLevel: 2,
      subSkills: [
        {
          blocker: false,
          approved: true,
          currentScore: 0,
          level: 2,
          name: 'servicios i',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: true,
          currentScore: 2,
          level: 2,
          name: 'peticiones al servidor',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: true,
          currentScore: 3,
          level: 2,
          name: 'sdk i',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: false,
          currentScore: 2,
          level: 3,
          name: 'servicios ii',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: false,
          currentScore: 0,
          level: 3,
          name: 'variables de entorno',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: false,
          currentScore: 0,
          level: 3,
          name: 'sdk ii',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
      ],
      name: 'integración con apis',
      id: 'ee812abc-5d3f-4a04-95ac-7b15e8df9ed7',
      firstLevel: 2,
    },
    {
      currentLevel: 5,
      currentEngagement: 100,
      approvedLevel: 4,
      subSkills: [
        {
          blocker: false,
          approved: true,
          currentScore: 6,
          level: 2,
          name: 'redux i',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: true,
          currentScore: 0,
          level: 3,
          name: 'redux ii',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
      ],
      name: 'manejo del estado',
      id: '676bb65d-569b-4e12-8474-b32da8221a71',
      firstLevel: 2,
    },
    {
      currentLevel: 3,
      currentEngagement: 0,
      approvedLevel: 2,
      subSkills: [
        {
          blocker: false,
          approved: true,
          currentScore: 0,
          level: 3,
          name: 'tests i',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: false,
          currentScore: 0,
          level: 3,
          name: 'tests ii',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: false,
          currentScore: 0,
          level: 4,
          name: 'documentación i',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
        {
          blocker: false,
          approved: false,
          currentScore: 0,
          level: 4,
          name: 'documentación ii',
          initialScore: 0,
          description: 'A description of the subskill',
          acceptanceScore: 3,
        },
      ],
      name: 'testing',
      id: 'a7e9bd8f-3ba5-4a6b-ad8e-06b489e5f7d5',
      firstLevel: 3,
    },
  ],
};

const mockedAdvanceTechnology = {
  alkymetricsId: '1',
  skills: [
    {
      name: 'autenticacion',
      id: 'd87f66cc-b451-4512-9101-2827c8658ebb',
      subSkills: [
        {
          name: 'persistencia del token',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '2e7723c8-4b13-4257-8c3a-fa01538223f7',
          level: 1,
        },
        {
          name: 'verificacion de usuario',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '5841e0e3-9a5c-4532-9ab7-fb5d6df7afd7',
          level: 2,
        },
        {
          name: 'proteccion de rutas',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '2937713e-4de2-4946-aeb3-f9e025df7b0d',
          level: 3,
        },
      ],
    },
    {
      name: 'desarrollo de vistas',
      id: '9b9e7045-3be9-4b13-bb56-c6dfcdedba5e',
      subSkills: [
        {
          name: 'user feedback i',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '9ccbc750-f3fe-4af0-abeb-ffb9a1efda42',
          level: 1,
        },
        {
          name: 'validacion de formularios i',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '569c7168-9af2-44ac-bd1b-030c71eaaf29',
          level: 1,
        },
        {
          name: 'componentes de contenido i',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '048495ed-a0f8-4590-a3ce-fa226e6bea53',
          level: 1,
        },
        {
          name: 'componentes de listados i',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '4d1eaf04-e2f2-441f-81fb-1cccf23cce54',
          level: 1,
        },
        {
          name: 'componentes de navegacion i',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: 'aa49c5f3-f19d-4c4d-86c4-12fc1bee282c',
          level: 1,
        },
        {
          name: 'libreria de frontend',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '43ff68cd-aabe-48fc-a847-d117a919e3d9',
          level: 2,
        },
        {
          name: 'user feedback ii',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '9c469791-daa7-4b69-bca4-862f858fe651',
          level: 3,
        },
        {
          name: 'componentes de listados ii',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '6ab5081f-2614-432e-8642-d290c7ce6279',
          level: 3,
        },
        {
          name: 'componentes de navegacion ii',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '9c8b920e-cb34-4441-b179-04e82c01f564',
          level: 3,
        },
        {
          name: 'componentes de contenido ii',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: 'd95583a6-ec1d-4de8-be04-3418253261bc',
          level: 3,
        },
        {
          name: 'debounce',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '81a39262-5376-459b-8d08-227815d64947',
          level: 3,
        },
        {
          name: 'validacion de formularios ii',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '7ec0e135-8747-45da-ab77-26f47351ec79',
          level: 3,
        },
        {
          name: 'mobile first',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '0fdaf05b-3daa-4c59-9a52-f9d14f8c702a',
          level: 4,
        },
      ],
    },
    {
      name: 'integracion con apis',
      id: 'a902414f-58d5-4d0a-83a4-fa21b87ac99f',
      subSkills: [
        {
          name: 'peticiones http i',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 1,
          id: 'bb672461-977e-4a20-844b-a8858eca5195',
          level: 2,
        },
        {
          name: 'sdk i',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 2,
          id: 'a8970789-b1c4-4488-9ef0-73a5a850959d',
          level: 2,
        },
        {
          name: 'peticiones http ii',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: 'c9903eb5-c360-4c6e-8420-42280fd638a8',
          level: 3,
        },
        {
          name: 'variables de entorno',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '254d1ab6-84f0-4627-9fae-f8dbbacb013e',
          level: 3,
        },
        {
          name: 'sdk ii',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '6bb428a3-5caa-4681-a8ef-0ec8b50fd7f8',
          level: 3,
        },
      ],
    },
    {
      name: 'gestion del estado',
      id: '0482a277-3e88-4eef-bcac-aaa85a8c48ea',
      subSkills: [
        {
          name: 'gestion simple',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '9acf342b-b543-405c-a4e9-000555fdb439',
          level: 1,
        },
        {
          name: 'gestion con redux',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: '0d944c06-820d-4337-b7d1-efbd8ef6d9a7',
          level: 3,
        },
      ],
    },
    {
      name: 'testing',
      id: 'c4e5931e-215b-423a-9c37-13b0ae889cc9',
      subSkills: [
        {
          name: 'tests',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 3,
          id: 'da33be1c-1c8e-4d00-8453-9cb19450f6a5',
          level: 3,
        },
        {
          name: 'documentacion',
          description: 'A description of the subskill',
          blocker: false,
          acceptanceScore: 4,
          id: '15c0dbfc-e8db-4e46-8240-2e8a22156484',
          level: 3,
        },
      ],
    },
  ],
  id: 'f32e103c-118e-4b40-8f35-4433865114ae',
  name: 'reactjs-test',
  releaseDate: '2021-08-04T15:47:49.953Z',
};

const mockedNews = {
  communityEvents: [
    {
      id: 1,
      presentatorName: 'Juliana Weber',
      linkedinUrl: 'linkedin.com/asd',
      photoUrl: 'https://bit.ly/3wrZP3z',
      meetingUrl: 'asd.com',
      datetime: 'asd',
      title: '¿Cómo dar buen feedback?',
    },
  ],
  nextEvents: {
    summary: 'asd',
    timeZone: 'asd',
    items: [
      {
        id: 'asd',
        summary: 'asd',
        description: 'Clase de definicion de modelos',
        location: 'asd',
        start: '10:00',
        end: '11:00',
      },
      {
        id: 'asd',
        summary: 'asd',
        description: 'Daily',
        location: 'asd',
        start: '14:00',
        end: '14:10',
      },
    ],
  },
  tickets: [
    {
      id: 'asd',
      key: 'asd',
      title: 'Crear la vista de alta de usuarios',
      storyPoints: 2,
      link: 'asd',
      skill: 'DISEÑO DE VISTAS',
      subSkill: 'asd',
    },
    {
      id: 'asd',
      key: 'asd',
      title: 'Crear el modelo de clientes',
      storyPoints: 2,
      link: 'asd',
      skill: 'SEGURIDAD',
      subSkill: 'asd',
    },
  ],
  academy: {
    success: true,
    data: [
      {
        name: 'Checkpoint de HTML!',
        unit: 'Introduccion a HTML',
        due_date: null,
        course: 'Introduccion a HTML',
        skills: [],
      },
      {
        name: 'Título de evaluación',
        unit: 'Manipulación de datos',
        due_date: null,
        course: 'Introduccion a SQL',
        skills: [],
      },
    ],
  },
  group: {
    id: 1,
    careerId: 1,
    name: 'grupo teset',
    displayName: 'Grupo Teset',
    slack: 'https://slack.com/',
    jiraUrl: 'https://alkemy-labs.atlassian.net/browse/PAR',
    jiraName: 'PAR',
    github: 'https://github.com/',
    startDate: '2021-07-21T17:37:23.787Z',
    isActive: true,
    isAccelerating: true,
    zoomMeetings: null,
    calendarId: '1',
    deletedAt: null,
    technologyId: 1,
    companyId: 1,
    mentorId: 1,
    technology: {
      id: 1,
      name: 'react',
      displayName: 'React',
      deletedAt: null,
    },
    mentor: {
      id: 1,
      firstName: 'chandler',
      displayFirstName: 'Chandler',
      lastName: 'bing',
      displayLastName: 'Bing',
      email: 'chandlerbing@friends.com',
      birthdate: '1997-06-05T00:00:00.000Z',
      linkedinUrl: 'www.linkedin.com/es/chandlerbing',
      picture: 'https://bit.ly/3wrZP3z',
      deletedAt: null,
    },
    company: {
      id: 1,
      name: 'alkemy',
      displayName: 'Alkemy',
      primaryColor: '#000000',
      secondaryColor: '#FFFFFF',
      deletedAt: null,
    },
    profiles: [
      {
        id: 3,
        profileId: 'google-oauth2|111350093614041000144',
        groupId: 1,
        isActive: true,
        profile: {
          id: 'google-oauth2|111350093614041000144',
          firstName: 'Octavio',
          lastName: 'Mazzeo',
          email: 'Pepe.Sanchez@test.com',
          areaCode: 2346,
          phone: 541062,
          picture: 'https://bit.ly/3wrZP3z',
          linkedinUrl: 'https://linkedin.com/iwwrwr',
          gitUrl: 'https://linkedin.com/i',
          birthdate: '1997-06-20',
          hiredStatus: 'AVAILABLE',
          totalEngagement: null,
          approvedSkills: null,
          videoUrl: 'www.youtube.com',
          certificateUrl: null,
          assistance: '0.0000000000',
          deletedAt: null,
        },
      },
    ],
  },
};

const mockedContent = [
  {
    url: 'https://www.youtube.com/embed/aHDCrf2WwbY',
    displayName: 'Javascript',
  },
  {
    url: 'https://www.youtube.com/embed/aHDCrf2WwbY',
    displayName: 'Learn coding',
  },
  {
    url: 'https://www.youtube.com/embed/aHDCrf2WwbY',
    displayName: 'Coding jam',
  },
  {
    url: 'https://www.youtube.com/embed/aHDCrf2WwbY',
    displayName: 'Coding from zero',
  },
];

export {
  mockedSocialMedia,
  mockedLinkLms,
  mockedVideos,
  mockedProfile,
  mockedAdvance,
  mockedNews,
  mockedContent,
  mockedAdvanceTechnology,
  codeCountry,
};
