export const githubRegex = new RegExp(
  /(https?:\/\/)?((www|\w\w)\.)?((git(hu|la)b)|bitbucket)\.(com|org)(?:\/[^\s+]+){1}$/y,
);

export const linkedinRegex = new RegExp(/((https?:\/\/)?((www|\w\w)\.)?(linkedin)\.(com)(?:\/[^\s+]+){1})$/y);

export const efsetRegex = new RegExp(/(https?:\/\/)?((www|\w\w)\.)?((efset))\.(org\/)(cert\/)(?:[^\s+]+){1}$/g);

export const isEmail = new RegExp(/^[\w+-.]+@([\w-]+\.)+[\w-]{2,8}$/g);

export const iconsRegex = new RegExp(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu);

export const urlRegex = new RegExp(
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi,
);

export const regexLowerCase = new RegExp(/^(?=.*[a-z]).*$/);
export const regexUpperCase = new RegExp(/^(?=.*[A-Z]).*$/);
export const regexDigits = new RegExp(/^(?=.*[0-9]).*$/);
export const regexSpecialCharacters = new RegExp(/^(?=.*[¡¿?""*¨[\]`^~!«#$%&‘()*+,\-_./]).*$/);
export const regexSpecialCharName = new RegExp(/^(?=.*[¡¿?""*¨'[\]`^~!{}«#$%&‘()*+,\-_./]).*$/);

export const checkPassword = (regex, string) => {
  return regex.test(string);
};
