import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useTranslations from '../../../hooks/useTranslations';
import { useContextState } from '../../../redux/contextState';
import NewStyleInput from '../../NewStyleInput/NewStyleInput';
import editIcon from '../../../assets/edit.svg';
import deleteIcon from '../../../assets/delete.svg';
import { urlRegex } from '../../../constants/regexValidations';

const PortfolioCardForm = ({ value, update, remove, editable, portfoliosUrl, index }) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: value,
    reValidateMode: 'onChange',
  });
  const [edit, setEdit] = useState(!editable);
  const [displayName, setDisplayName] = useState('');
  const { t } = useTranslations();
  useEffect(() => {
    const url = new URL(!value.url.match(/^(http|https)/i) ? `https://${value.url}` : value.url);
    setDisplayName(url.pathname.length > 1 ? url.pathname : value.url);
  }, [value.url]);

  return (
    <div className='flex items-center w-fit mb-3 dark:text-white'>
      <img
        data-testid='img-show-url'
        src={`http://www.google.com/s2/favicons?domain=${value.url}`}
        className='w-[18px] h-[18px] mr-1'
      />
      {edit && (
        <NewStyleInput
          className={'w-2/4 mr-1'}
          register={register}
          validations={{
            pattern: urlRegex,
            required: {
              value: true,
              message: t('PORTFOLIO_URL_CANNOT_BE_EMPTY'),
            },
            validate: {
              noRepeated: currentValue => {
                return (
                  !portfoliosUrl.some((el, elIndex) => el.url === currentValue && elIndex !== index) ||
                  t('PORTFOLIO_URL_CANNOT_BE_REPEATED')
                );
              },
            },
          }}
          disabled={!edit}
          error={{
            message:
              (errors.url?.type === 'pattern' && t('PORTFOLIO_MUST_INTRODUCE_A_VALID_URL')) ||
              (errors.url?.type === 'required' && t('PORTFOLIO_URL_CANNOT_BE_EMPTY')) ||
              (errors.url?.type === 'noRepeated' && t('PORTFOLIO_URL_CANNOT_BE_REPEATED')),
          }}
          name='url'
        />
      )}
      {!edit && (
        <input
          disabled
          value={displayName}
          className='w-2/4 mr-1 outline-none text-xs border-1 font-semibold shadow-sm w-full rounded-lg py-1 bg-transparent px-2 truncate border-gray-300'
        />
      )}
      <button
        onClick={handleSubmit(() => {
          if (edit) {
            update(watch());
          }
          setEdit(value => !value);
        })}>
        <img src={editIcon} />
      </button>
      <button onClick={() => remove()}>
        <img src={deleteIcon} className='w-[14px] h-[18px] ml-2 hover:cursor-pointer' />
      </button>
    </div>
  );
};

export default PortfolioCardForm;
