export const TrackEvent = (action, data) => {
  if (process.env.RAZZLE_RUNTIME_ENV === 'production') {
    analytics.track(`DEV: ${action}`, data);
  }
};

export const PageLoad = (pathname, properties = {}) => {
  if (process.env.RAZZLE_RUNTIME_ENV === 'production') {
    analytics.page(pathname, properties);
  }
};

export const Identify = (id, data) => {
  if (process.env.RAZZLE_RUNTIME_ENV === 'production') {
    analytics.identify(id, data);
  }
};
