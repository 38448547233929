import useTranslations from '../../hooks/useTranslations';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import DiamondIcon from '../../assets/Diamond';

const JobCard = ({ job }) => {
  const { t } = useTranslations();
  const [countriesIsoCode, setCountriesIsoCode] = useState([]);

  useEffect(() => {
    if (job?.address?.length) {
      const isoCodes = [];
      job?.address?.forEach(a => {
        if (!isoCodes.includes(a?.country?.isoCode) && a?.country?.isoCode) {
          isoCodes.push(a?.country?.isoCode);
        }
      });
      setCountriesIsoCode(isoCodes);
    }
  }, []);

  const history = useHistory();
  return (
    <div
      className='bg-white dark:bg-dark-normal my-4 border-1 rounded-lg p-6 shadow-md flex flex-col gap-4 cursor-pointer hover:shadow-lg transition duration-300 ease-in-out'
      data-testid='Job-Card'
      onClick={() => {
        if (job?.hashId) history.push(`/job-posts/${job?.hashId}`);
      }}
      style={{ borderColor: job?.isPremium ? '#8E8146' : 'lightgray' }}
      key={job.id}>
      <div className='flex justify-between items-center'>
        <div className='flex flex-col'>
          <span className='inline-block text-alkemy-black text-sm dark:text-white'>{job?.createdAt}</span>
          <span
            data-testid='job-title'
            className='inline-block text-alkemy-black text-lg font-semibold dark:text-white'>
            {`${job?.title}`}
          </span>
          <span className='inline-block text-alkemy-black text-md dark:text-white' data-testid='job-title-seniority'>
            {t(job?.seniority)}
          </span>
        </div>
        <div className='flex flex-col items-center justify-center'>
          {job.company?.picture ? (
            <img src={job.company?.picture} className='w-[45px] h-auto ml-2.5' data-testid='job-company-picture' />
          ) : (
            <div
              data-testid='job-company-color'
              className='px-4 py-3 rounded-lg'
              style={{ backgroundColor: job.company?.primaryColor || '#58c1f5' }}>
              <span data-testid='job-company-initials' className='text-white font-bold'>
                {job.company?.displayName?.slice(0, 1)}
              </span>
            </div>
          )}
          <span className='italic dark:text-white' data-testid='job-company-name'>
            {job.company?.displayName}
          </span>
        </div>
      </div>

      <section className='flex gap-2'>
        <span className='inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10'>
          {t(job?.workModality)}
        </span>
        <span className='inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10'>
          {t(job?.modality)}
        </span>
      </section>
      <div className='font-medium text-sm text-grey'>
        {job?.technologies?.length > 0 && (
          <p className='block dark:text-white' data-testid='job-title-skills'>
            <span className='text-sm font-semibold '>{t('SKILLS')}</span>

            <span className='flex flex-wrap gap-2 mt-2' data-testid='job-tags-skills'>
              {job?.technologies?.map((tech, index) => (
                <span
                  key={'technology-' + index}
                  className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10'>
                  {tech?.displayName}
                </span>
              ))}
            </span>
          </p>
        )}
        {/* {job.tags?.length > 0 && (
          <p className='block dark:text-white' data-testid='job-title-skills'>
            {`${t('SKILLS')}: `}
            <span className='text-purple' data-testid='job-tags-skills'>
              {job.tags.join(' - ')}
            </span>
          </p>
        )} */}
        {/* <div className='flex justify-end items-end'>
          <a
            href={job.screeningUrl}
            className='w-fit px-5 py-1 mt-4 bg-maya-blue font-bold rounded-2xl text-white hover:cursor-pointer'
            data-testid='job-button-apply'>
            {t('APPLY')}
          </a>
        </div> */}
      </div>
      <div className='flex justify-between gap-2 items-center'>
        <div className='flex gap-2'>
          {job?.address &&
            countriesIsoCode.map(a => (
              <>
                <img
                  src={`${process.env.RAZZLE_RUNTIME_ASSETS}/assets/countries/${a}.svg`}
                  className='country-flag-img D-Flex'
                  aria-hidden='true'
                  key={a}
                />
              </>
            ))}
        </div>
        {job?.isPremium && <DiamondIcon className={'h-8'} />}
      </div>
    </div>
  );
};

export default JobCard;
