import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Skeleton } from 'antd';
import { notification, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { ActionTypes, useContextState } from '../../../redux/contextState';
import { getCertificatePublicUrl, postImageProfile, qrCodeGenerator, savePdfProfile } from '../../../api/userService';
import buttonEditImage from '../../../assets/profile/button-edit-image.svg';
import profileImageNull from '../../../assets/myGroup/profile.svg';
import useTranslations from '../../../hooks/useTranslations';
import SoftSkillVideo from '../../SoftSkillVideo/SoftSkillVideo';
import TextArea from 'antd/lib/input/TextArea';
import uploadIcon from '../../../assets/profile/cloud-upload-selected.svg';
import NewStyleInput from '../../NewStyleInput/NewStyleInput';
import ModalCustom from '../../ModalDisabledAccount/ModalDisabledAccount';
import 'antd/dist/antd.css';
import './ProfileMenu.scss';
import { FileName } from '../../../utils/constants';
import HorizontalLoader from '../../HorizontalLoader/HorizontalLoader';
import { downloadFile } from '../../../utils/profile/downloadFile';

const ProfileMenu = props => {
  const {
    profileImage,
    setPicture,
    firstName,
    lastName,
    linkedinUrl,
    formErrors,
    email,
    description,
    onChange,
    loading,
    register,
  } = props;
  const { contextState, setContextState } = useContextState();
  const { t } = useTranslations();
  const [modal, setModal] = useState(false);

  const onAction = async options => {
    const { onSuccess, onError, file } = options;
    if (file.size < 5000000) {
      const fmData = new FormData();
      fmData.append('file', file);
      postImageProfile(fmData)
        .then(response => {
          onSuccess('ok');
          setPicture(response.picture);
          setContextState({
            type: ActionTypes.SetProfile,
            value: { picture: response.picture },
          });
          notification.open({
            message: t('PROFILE_MENU_IMAGE_UPLOADED'),
            type: 'success',
          });
        })
        .catch(error => {
          Sentry.captureException(error);
          onError({ error });
        });
    } else {
      notification.open({
        message: t('PROFILE_MENU_IMAGE_TOO_HEAVY'),
        icon: <i className='fas fa-times' style={{ color: '#52C0F7' }} />,
      });
    }
  };
  const validateImage = file => {
    if (file.size > 5000000) {
      notification.open({
        message: t('PROFILE_MENU_IMAGE_TOO_HEAVY'),
        icon: <i className='fas fa-times' style={{ color: '#52C0F7' }} />,
      });
      return false;
    }
    const imageRegex = /(image\/)/g;
    if (!imageRegex.test(file.type)) {
      notification.open({
        message: t('PROFILE_MENU_IMAGE_INVALID_TYPE'),
        icon: <i className='fas fa-times' style={{ color: '#52C0F7' }} />,
      });
      return false;
    }
    return true;
  };
  const [cv, setCv] = useState();
  const [cvFileName, setCvFileName] = useState(null);
  const [certificateUrl, setCertificateUrl] = useState('');
  const [loadingQrDownload, setLoadingQrDownload] = useState(false);

  const uploadPdf = e => {
    if (e.target.files && e.target.files[0] && e.target.files[0].name && e.target.files[0].size < 5000000) {
      if (e.target.files[0].type !== 'application/pdf') {
        notification.open({
          message: t('PROFILE_MENU_CV_PDF_FORMAT'),
          icon: <i className='fas fa-times' style={{ color: '#F11010' }} />,
        });
        return;
      }

      const fmData = new FormData();
      fmData.append('file', e.target.files[0]);

      savePdfProfile(fmData)
        .then(response => {
          setCv(response.cvUrl);
          notification.open({
            message: t('PROFILE_MENU_CV_UPLOADED'),
            icon: <i className='fas fa-check' style={{ color: '#52C0F7' }} />,
          });
        })
        .catch(err => {
          notification.open({
            message: t('PROFILE_MENU_INVALID_FILE'),
            icon: <i className='fas fa-times' style={{ color: '#52C0F7' }} />,
          });
          Sentry.captureException(err);
        });
    } else {
      setCvFileName(null);
      notification.open({
        message: t('PROFILE_MENU_CV_TOO_HEAVY'),
        icon: <i className='fas fa-times' style={{ color: '#52C0F7' }} />,
      });
    }
  };

  useEffect(async () => {
    setCv(contextState?.profile?.cvUrl);
    const url = await getCertificatePublicUrl();
    setCertificateUrl(url);
  }, []);

  const disabledAccount = () => {
    setModal(true);
  };

  const downloadQrCode = async () => {
    try {
      setLoadingQrDownload(true);
      const qr = await qrCodeGenerator({ text: certificateUrl, fileName: FileName.CERTIFICATE });
      downloadFile(qr.data, qr.fileType, qr.fileName);
      setLoadingQrDownload(false);
    } catch (error) {
      notification.open({
        message: t('ERROR_DOWNLOADING_QR'),
        type: 'error',
      });
    } finally {
      setLoadingQrDownload(false);
    }
  };

  return (
    <div className='flex flex-col profile-menu'>
      <ModalCustom show={modal} setShow={setModal} />
      <div className='flex flex-col w-full mt-3 '>
        <div className='user-heading py-5 text-center mb-1 flex flex-col items-center profile-menu-container bg-white dark:bg-dark-bold'>
          <div className='flex justify-center user-heading-round rounded-full relative'>
            <ImgCrop rotate beforeCrop={validateImage}>
              <Upload
                id='upload-profile-img'
                accept='image/*'
                customRequest={onAction}
                name='avatar'
                showUploadList={false}
                maxCount={1}>
                <img src={buttonEditImage} className='absolute button-edit-image-profile right-0' />
              </Upload>
            </ImgCrop>
            {loading ? (
              <Skeleton.Image style={{ borderRadius: '50%' }} />
            ) : (
              <img
                className='user-heading-img rounded-full'
                src={profileImage || profileImageNull}
                alt={t('PROFILE_MENU_LOGO_USER')}
              />
            )}
          </div>
          {loading ? (
            <Skeleton active title={{ className: 'w-full' }} paragraph={{ rows: 0 }} />
          ) : (
            <span className='text-lg text-alkemy-black break-all  capitalize font-bold mt-3 dark:text-white'>
              {`${firstName} ${lastName}`}
            </span>
          )}
          {loading ? (
            <Skeleton active title={{ className: 'w-full my-0' }} paragraph={{ rows: 0 }} />
          ) : (
            <span className='email-card-width break-all mt-1 dark:text-white'>{email}</span>
          )}
        </div>
      </div>
      <div className='user-heading my-2 p-6 bg-white dark:bg-dark-bold'>
        <span className='video-upload-text text-alkemy-black text-base font-bold dark:text-white bg-white dark:bg-dark-normal'>
          {t('SHARE_URL_CERTIFICATE')}
        </span>
        {loadingQrDownload ? (
          <div className='flex justify-center'>
            <HorizontalLoader />
          </div>
        ) : (
          <button
            data-testid='button-share-url-certificate'
            onClick={downloadQrCode}
            className='bg-alkemy-blue px-6 py-2 mt-4 text-xs font-bold text-white rounded-full disabled:bg-opacity-60 margin-button-certificate'>
            {t('SHARE_URL_CERTIFICATE_BUTTON')}
          </button>
        )}
      </div>
      <div className='user-heading my-2 p-6 bg-white dark:bg-dark-bold'>
        {loading ? (
          <div className='flex justify-between'>
            <Skeleton className='w-50' title={false} active />
            <Skeleton.Button active />
          </div>
        ) : (
          <div className={`h-full`}>
            <>
              <span className='text-alkemy-black text-base font-bold dark:text-white bg-white dark:bg-dark-normal'>
                {t('PROFILE_ADD_CV_AND_LINKEDIN_URL')}
              </span>
              <span className='text-red-600 ml-1'>*</span>
              <div className='p-2 border rounded-lg mt-4'>
                <label htmlFor='pdf-upload' className={`relative pointer bg-white dark:bg-dark-bold w-full`}>
                  <input
                    data-testid='input-pdfUpload'
                    id='pdf-upload'
                    onChange={e => {
                      setCvFileName(e.target.files[0].name);
                      uploadPdf(e);
                    }}
                    name='pdf-upload'
                    type='file'
                    accept='application/pdf'
                    className='absolute invisible'
                    value=''
                  />
                  <div className='flex justify-between items-center'>
                    <span className='text-alkemy-black font-bold dark:text-white bg-white dark:bg-dark-normal text-xs truncate'>
                      {cvFileName ? cvFileName : t('PROFILE_UPLOAD_FILE')}
                    </span>
                    <img className='hover:cursor-pointer ml-3' src={uploadIcon} />
                  </div>
                </label>
              </div>
              {cv && (
                <div className='w-full mt-2'>
                  <a
                    href={cv}
                    target='_blank'
                    className='text-purple text-sm font-semibold dark:text-white'
                    rel='noreferrer'>
                    {t('PROFILE_MENU_SEE_CV')}
                  </a>
                </div>
              )}
              <NewStyleInput
                className={'italic mt-6 dark:text-white'}
                title={t('ENTER_URL_LINKEDIN')}
                register={register}
                required={true}
                type='text'
                validations={{
                  pattern: /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm,
                  required: {
                    value: cv ? false : true,
                    message: 'CV_OR_LINKEDIN_REQUIRED',
                  },
                }}
                value={linkedinUrl}
                disabled={false}
                name='linkedinUrl'
                error={{
                  message:
                    (formErrors.linkedinUrl?.type === 'pattern' && 'INVALID_LINKEDIN_URL') ||
                    (formErrors.linkedinUrl?.type === 'required' && 'CV_OR_LINKEDIN_REQUIRED'),
                }}
              />
            </>
          </div>
        )}
      </div>
      <div className='user-heading mt-2 mb-4 p-6 bg-white dark:bg-dark-bold'>
        <span className='video-upload-text text-alkemy-black text-base font-bold dark:text-white bg-white dark:bg-dark-normal'>
          {t('MY_DESCRIPTION')}
        </span>
        <div className='flex flex-col grow mt-1 justify-start bg-white dark:bg-dark-normal'>
          <TextArea
            showCount
            maxLength={255}
            value={description || ''}
            onChange={e => onChange(e.target.value, 'description')}
            name='description'
            style={{ height: 120 }}
            placeholder={t('PROFILE_TEXT_AREA_PLACEHOLDER')}
            autoSize={{ minRows: 4, maxRows: 4 }}
            className='mt-2  bg-white dark:bg-dark-normal dark:border-0 dark:text-white'
            allowClear
          />
        </div>
      </div>
      {contextState.profile?.videos && (
        <SoftSkillVideo video={contextState?.profile?.videos?.find(item => item.videoType === 'VIDEO_10')} />
      )}

      <div className='user-heading my-4 py-3 px-5 bg-white dark:bg-dark-bold'>
        <button
          data-testid='disabled-account-button'
          className='font-montserrat disabled-account-text bg-transparent border-0'
          onClick={disabledAccount}>
          {t('I_WANT_DISABLED_ACCOUNT')}
        </button>
      </div>
    </div>
  );
};
export default ProfileMenu;
