import React, { useEffect, useState } from 'react';
import CopyCertificate from '../../components/CopyCertificate/CopyCertificate';
import { useLocation } from 'react-router-dom';
import CertificateItem from '../../components/CertificateItem/CertificateItem';
import useTranslations from '../../hooks/useTranslations';
import Spinner from '../../components/Spinner/Spinner';
import useUserAdvance from '../../hooks/useUserAdvance';
import './Certificate.scss';
import { queryToObject } from '../../utils/processQueryString';
import { getEvaluationCertificate } from '../../api/userService';
import { useContextState } from '../../redux/contextState';

const Certificate = () => {
  const { t } = useTranslations();
  const { search } = useLocation();
  const { contextState } = useContextState();
  const [loadingCertificate, setLoadingCertificate] = useState(true);
  const [certificateData, setCertificateData] = useState(undefined);
  const [evaluationData, setEvaluationData] = useState(undefined);
  const { profile } = useUserAdvance();
  const isEvaluation = Boolean(queryToObject(search)?.invitationId);

  useEffect(() => {
    if (search && isEvaluation) {
      getEvaluationCertificate(queryToObject(search).invitationId)
        .then(res => {
          setCertificateData(res);
          setEvaluationData(
            contextState.screenings?.find(invitation => invitation.id === queryToObject(search)?.invitationId),
          );
        })
        .finally(() => setLoadingCertificate(false));
    } else {
      setLoadingCertificate(false);
    }
  }, []);

  const evaluationText = t('SHARE_EVALUATION_CERTIFICATE_RECOMMENDED_TEXT')
    .replace('{screeningName}', `<b>${evaluationData?.screeningName}</b>`)
    .replace('{company}', `<b>${evaluationData?.company?.displayName}</b>`)
    .replace('{score}', `<b>${evaluationData?.score} ${t('PTS_TEXT')}</b>`);

  return (
    <div
      data-testid='certificate-page-container'
      className='flex lg:flex-row flex-col items-center lg:items-start lg:justify-center w-full h-full pl-3 lg:px-10 pt-4 lg:pt-8 '>
      {loadingCertificate ? (
        <Spinner show />
      ) : (
        <>
          <div className='mr-4 flex flex-col certificate-info-container  dark:bg-dark-bold px-5'>
            <div className='py-2 text-center lg:text-left'>
              {isEvaluation ? (
                <span
                  data-testid='evaluation-certificate-title'
                  className='event-author block font-bold  dark:text-white'>
                  {t('CERTIFICATE_EVALUATION_TITLE')}
                </span>
              ) : (
                <>
                  <span
                    data-testid='acceleration-certificate-title'
                    className='event-author hidden lg:block font-bold  dark:text-white'>
                    🤓 ¡{profile?.alkemyCertificateUrl ? t('CERTIFICATE_CONGRATS') : t('CERTIFICATE_HELLO')}{' '}
                    {profile?.firstName}!
                  </span>
                  <span className='text-xl block lg:hidden font-bold  dark:text-white'>😎</span>
                </>
              )}
            </div>
            {isEvaluation ? (
              <>
                <span
                  data-testid='evaluation-certificate-first-subtitle'
                  className='text-alkemy-black-bold text-lg  font-bold pt-2 text-center hidden lg:block lg:text-left pb-1 dark:text-white mb-8'>
                  {t('CERTIFICATE_EVALUATION_DOWNLOAD_OR_SHARE')}
                </span>
              </>
            ) : (
              <>
                <span
                  data-testid='acceleration-certificate-first-subtitle'
                  className='text-alkemy-black-bold text-lg  font-bold pt-2 text-center hidden lg:block lg:text-left pb-1 dark:text-white'>
                  {profile?.alkemyCertificateUrl ? t('CERTIFICATE_READY') : t('CERTIFICATE_KEEP_STRIVING')}
                </span>
                <span
                  data-testid='acceleration-certificate-mobile-subtitle'
                  className='text-alkemy-black-bold   font-bold pt-2 text-center block lg:hidden lg:text-left pb-1 dark:text-white'>
                  ¡
                  {profile?.alkemyCertificateUrl
                    ? `¡${t('CERTIFICATE_CONGRATS')} ${profile?.firstName}!`
                    : t('CERTIFICATE_KEEP_STRIVING')}
                  !
                </span>
                <span
                  data-testid='acceleration-certificate-second-subtitle'
                  className='ceritificate-description-font text-base  text-center lg:text-left pt-2 pb-5 dark:text-white'>
                  {t('CERTIFICATE_THANK_YOU')}
                </span>
              </>
            )}
            <CertificateItem
              isEvaluation={isEvaluation}
              certificateData={certificateData}
              certificate={profile?.alkemyCertificateUrl}
              className='block lg:hidden mb-4'
            />
            {(Boolean(profile?.groups?.length) || isEvaluation) && (
              <CopyCertificate
                isEvaluation={isEvaluation}
                certificateData={certificateData}
                link={profile?.alkemyCertificateUrl}
                title={t('CERTIFICATE_SHARE_CERTIFICATE')}
                boldTitle={t('CERTIFICATE_SHARE_CERTIFICATE_BOLD')}
                text={
                  isEvaluation
                    ? evaluationText
                    : t('CERTIFICATE_SHARE_TEXT').replace('{tech}', profile?.groups[0]?.group?.technology?.displayName)
                }
              />
            )}
          </div>
          <CertificateItem
            isEvaluation={isEvaluation}
            certificateData={certificateData}
            certificate={profile?.alkemyCertificateUrl}
            className='hidden lg:block'
          />
        </>
      )}
    </div>
  );
};

export default Certificate;
