import { useHistory } from 'react-router-dom';
import useMissingData from '../../hooks/useMissingData';
import useTranslations from '../../hooks/useTranslations';
import profileImageNull from '../../assets/myGroup/profile.svg';
import { ActionTypes, useContextState } from '../../redux/contextState';
import Progress from '../Progress/Progress';

const DevProfile = () => {
  const { contextState, setContextState } = useContextState();
  const { percentage } = useMissingData();
  const { t } = useTranslations();
  const history = useHistory();

  return (
    <div
      className='px-[90px] pt-3 pb-2 bg-white rounded-lg dark:bg-dark-normal'
      data-testid='dev-profile-container-div'>
      <div className='flex flex-row'>
        <div>
          <img
            className='max-w-[100px] rounded-full'
            data-testid='dev-profile-picture-img'
            src={contextState?.profile?.picture ? contextState.profile.picture : profileImageNull}
          />
        </div>
        <div className='w-full p-4 items-stretch'>
          <span
            className='text-alkemy-black text-xl font-semibold dark:text-white capitalize'
            data-testid='dev-profile-welcome-span'>
            {t('PROFILE_SECTION_HELLO')} {contextState.profile.firstName}!
          </span>
          <Progress percent={percentage} className='my-3' />
          <span className='text-sm italic dark:text-white' data-testid='dev-profile-percentage-completed-span'>
            {t('PROFILE_SECTION_COMPLETED_PERCENTAGE')} {percentage}%
          </span>
        </div>
      </div>
      {percentage !== 100 && (
        <div className='flex flex-col'>
          <button
            onClick={() => {
              setContextState({
                type: ActionTypes.SetEditable,
                value: true,
              });
              history.push('/profile');
            }}
            className='p-2 bg-maya-blue self-end font-bold rounded-2xl text-white hover:cursor-pointer'
            data-testid='dev-profile-redirect-profile-button'>
            {t('PROFILE_SECTION_COMPLETE_PROFILE')}
          </button>
        </div>
      )}
    </div>
  );
};

export default DevProfile;
