import React, { useEffect, useState } from 'react';
import { answerType } from '../../constants/enums';
import { Radio, Space } from 'antd';
import './PrefilterTest.scss';
import Input from '../Input';

export default function Test({ question, onSubmit, isLast, isFirst, submission, t }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState();

  const disableButton =
    isLast &&
    ((question.answerType === answerType.RADIO && !selectedOption) ||
      (question.answerType === answerType.TEXT && !inputValue));

  const handleSubmit = next => {
    const data = { id: question?._id, type: question.answerType, response: undefined };
    if (question.answerType === answerType.RADIO && selectedOption) {
      data.response = selectedOption;
    } else if (question.answerType === answerType.TEXT) {
      data.response = inputValue;
    }
    clearData();
    onSubmit(data, next);
  };

  useEffect(() => {
    if (submission && submission?._id === question?.id) {
      if (submission.type === answerType.RADIO) {
        setSelectedOption(submission.response);
      } else if (submission.type === answerType.TEXT) {
        setInputValue(submission.response);
      }
    }
  }, [question, submission]);

  const clearData = () => {
    setSelectedOption(null);
    setInputValue('');
  };

  return (
    <div className='p-3'>
      <h3 className='p-4 mt-2 mb-5 border-1 border-alkemy-blue rounded'>{question.text}</h3>

      {question.answerType === answerType.RADIO && (
        <div>
          <Radio.Group onChange={e => setSelectedOption(e.target.value)} value={selectedOption}>
            <Space direction='vertical'>
              {question.options.map((option, index) => (
                <Radio
                  key={option.id}
                  value={option.id}
                  className='ant-radio-wrapper d-flex align-items-center py-2 px-3 bg-gray rounded mb-2 w-full border-1 border-gray-300'>
                  <span className='mr-4 font-bold'>{String.fromCharCode(65 + index)}.</span>
                  {option.text}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>
      )}

      {question.answerType === answerType.TEXT && (
        <div>
          <Input
            className='outline-none text-xs border-1 font-semibold shadow-sm w-full rounded-lg py-1 bg-transparent px-2 truncate border-gray-300'
            placeholder='Ingrese su respuesta aqui'
            type='number'
            onChange={e => setInputValue(e.target.value.replace(/[^0-9]/g, ''))}
            defaultValue={inputValue?.replace(/[^0-9]/g, '')}
          />
        </div>
      )}

      <div className='px-3'>
        {!isFirst && (
          <button
            onClick={() => handleSubmit(-1)}
            className='w-fit px-5 mr-5 py-1 m-auto mt-5 bg-maya-blue font-bold rounded-2xl text-white hover:cursor-pointer
          disabled:bg-grey disabled:cursor-not-allowed'>
            {t('BACK_PREFILTER_QUESTION')}
          </button>
        )}
        <button
          className='w-fit px-5 py-1 ml-5 m-auto mt-5 bg-maya-blue font-bold rounded-2xl text-white hover:cursor-pointer disabled:bg-grey disabled:cursor-not-allowed'
          onClick={() => (isLast ? handleSubmit(0) : handleSubmit(1))}
          disabled={disableButton}>
          {isLast ? t('SUBMIT_PREFILTER_QUESTION') : t('NEXT_PREFILTER_QUESTION')}
        </button>
      </div>
    </div>
  );
}
