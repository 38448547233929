import React from 'react';

const ArrowSmall = ({ className, width = '13', height = '8' }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 13 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.5 1.5L6.5 6.5L11.5 1.5'
        stroke='stroke-current'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ArrowSmall;
