import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt-br';
import 'moment/locale/en-gb';

export const formatDate = (translateFn, d, type = 'd/m/y') => {
  const date =
    moment(d)
      .format(`dddd DD/MM [${translateFn('AT')}] HH:mm`)[0]
      .toUpperCase() +
    moment(d)
      .format(`dddd DD/MM [${translateFn('AT')}] HH:mm`)
      .slice(1);
  if (type === 'eventsDate') {
    return date;
  }
  return moment(d).format('DD/MM/YYYY');
};

export const formatLocalizedDate = date => {
  const lang = localStorage.getItem('language') ?? 'es';
  moment.locale(lang);

  const formats = {
    es: 'D [de] MMMM',
    en: 'MMMM Do',
    pt: 'D [de] MMMM',
  };

  const formattedDate = moment(date).format(formats[lang]);

  const capitalizedDate = formattedDate
    .split(' ')
    .map((word, index) => (index === 2 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
    .join(' ');

  return capitalizedDate;
};
