import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useTranslations from '../../hooks/useTranslations';
import { useContextState } from '../../redux/contextState';
import downArrow from '../../assets/news/more-tickets-arrow.svg';
import flagIcon from '../../assets/myGroup/icon-flag.svg';
import Ticket from '../Ticket/Ticket';
import EmptyComponent from '../EmptyComponent/EmptyComponent';

const NewsNextObjectives = ({ className }) => {
  const { t } = useTranslations();
  const [show, setShow] = useState(false);
  const { contextState } = useContextState();

  return (
    <div className={`description-container bg-white dark:bg-dark-bold rounded mx-auto p-4 mt-3 ${className}`}>
      <span className='text-base  font-bold lg:mt-3 m-0 dark:text-white'>{t('NEWS_WEEKLY_OBJECTIVES')}</span>
      <div className='flex flex-col lg:flex-row justify-between pt-4'>
        {!!contextState?.profileNews?.tickets?.length || contextState?.profileNews?.academy?.data?.length ? (
          <>
            {!!contextState?.profileNews?.tickets?.length ? (
              <div
                className={`flex flex-col w-full p-4 lg:pb-5 lg:pr-5 mr-4 mb-3 lg:mb-0 dark:bg-dark-normal ${
                  contextState?.profileNews?.tickets && contextState?.profileNews?.tickets.length
                    ? ''
                    : 'border-lg-right'
                }`}>
                <span className='font-bold date-font text-base pb-2 flex dark:text-white'>
                  <img src={flagIcon} className='pr-2' />
                  {t('NEWS_TICKETS')}
                </span>
                {contextState?.profileNews?.tickets?.length && show
                  ? contextState?.profileNews?.tickets.map((item, index) => (
                      <span
                        key={index}
                        className='pl-4 ml-2 flex flex-col font-bold text-wrap pt-2 lg:pb-3 pb-2 text-sm'
                        data-testid='newsNextObjectivesOpened'>
                        <a
                          href={item.link}
                          target='_blank'
                          rel='noreferrer'
                          className='text-alkemy-black hover:underline hover:decoration-alkemy-black font-bold text-wrap dark:text-white text-xs'>
                          {item.title}
                        </a>
                        {item?.skill && (
                          <Ticket
                            text={item.skill}
                            className='ticket-technology-text uppercase font-roboto px-1 py-1 rounded self-start mt-1'
                          />
                        )}
                      </span>
                    ))
                  : contextState?.profileNews?.tickets.slice(0, 3).map((item, index) => (
                      <span
                        key={index}
                        className='pl-4 ml-2 flex flex-col font-bold text-wrap pt-2 lg:pb-3 pb-2'
                        data-testid='newsNextObjectivesClosed'>
                        <a
                          href={item.link}
                          target='_blank'
                          rel='noreferrer'
                          className='text-alkemy-black hover:underline hover:decoration-alkemy-black font-bold text-wrap dark:text-white text-xs'>
                          {item.title}
                        </a>
                        {item?.skill && (
                          <Ticket
                            text={item.skill}
                            className='ticket-technology-text uppercase font-roboto px-1 py-1 rounded self-start mt-1'
                          />
                        )}
                      </span>
                    ))}
                {contextState?.profileNews?.tickets?.length > 3 && (
                  <button
                    className='self-start ml-4 pl-2 font-small bg-transparent mt-4 uppercase border-0 font-roboto text-maya-blue font-bold flex items-center'
                    onClick={() => setShow(!show)}
                    data-testid='buttonShow'>
                    {show ? t('NEWS_SHOW_LESS_TICKETS') : t('NEWS_SHOW_MORE_TICKETS')}
                    <img className={`ml-2 normal-arrow ${show && 'rotate-arrow'}`} src={downArrow} />
                  </button>
                )}
              </div>
            ) : null}
            {contextState?.profileNews?.academy?.data?.length ? (
              <div className='flex flex-col p-4 w-full bg-white dark:bg-dark-normal'>
                <span className='font-bold text-base pb-2 dark:text-white flex'>
                  <img src={flagIcon} className='pr-2' />
                  {t('NEWS_ALKEMY_ACADEMY')}
                </span>
                {contextState?.profileNews?.academy?.data?.length &&
                  contextState?.profileNews?.academy?.data.map((item, index) => (
                    <span
                      key={index}
                      className='pl-4 ml-2 flex flex-col font-bold whitespace-nowrap pt-2 lg:pb-3 pb-2 dark:text-white text-xs'>
                      {item.name}
                      {!!item.skills.length &&
                        item.skills.map((item, index) => (
                          <Ticket
                            key={index}
                            text={item?.name ?? item ?? ''}
                            className='ticket-technology-text text-uppercase font-roboto px-1 py-1 self-start mt-1'
                          />
                        ))}
                    </span>
                  ))}
              </div>
            ) : null}
          </>
        ) : (
          <EmptyComponent
            className='py-5'
            title={t('WEEKLY_GOALS_EMPTY_OBJETIVES_TITLE')}
            subtitle={t('WEEKLY_GOALS_EMPTY_OBJETIVES_SUBTITLE')}
          />
        )}
        {Boolean(contextState?.profile?.groups?.length) && (
          <div className='flex flex-col lg:ml-3 p-4 rounded about-container h-full bg-gray dark:bg-dark-normal'>
            <span className='font-bold pb-1 text-base about-title dark:text-white'>{t('NEWS_SKILLS_TITLE')}</span>
            <span className='text-sm pb-4 dark:text-white'>
              {`${t('NEWS_SKILLS_SUBTITLE')} ${contextState?.profile?.groups[0]?.group?.technology?.displayName}`}.
            </span>
            <Link
              to='/jobs'
              className='job-ready-button bg-white dark:bg-dark-bold dark:text-white py-1 px-2 font-bold font-roboto font-small self-end lg:mr-2'>
              {t('NEWS_GO_TO_JOB_READY')}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsNextObjectives;
