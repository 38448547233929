import { useRef, useState } from 'react';
import downArrow from '../../assets/header/arrow-small.svg';
import useClickOutside from '../../hooks/useClickOutside';
import profileImg from '../../assets/header/profile.svg';
import './Dropdown.css';
import useTranslations from '../../hooks/useTranslations';

const Dropdown = ({ options, className, profile, editable, redirectProfile }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const { t } = useTranslations();
  useClickOutside(ref, () => setOpen(false), true);

  return (
    <div
      ref={ref}
      className={`rounded cursor-pointer font-roboto bg-transparent dark:bg-dark-normal font-bold non-selectable dropdown-select-container relative ${className}`}>
      <div onClick={() => setOpen(state => !state)} className='dropdown-select-button cursor-pointer px-2 flex'>
        <div
          className='dropdown-logo rounded-full'
          style={{
            backgroundImage: `url('${profile?.picture}'), url('${profileImg}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          data-testid='dropdown-image'
        />
        <span
          data-testid='dropdown-user-name'
          className='dropdown-name font-roboto capitalize font-bold dark:text-white'>
          {profile?.firstName && profile?.lastName && `${profile?.firstName} ${profile?.lastName}`}
        </span>
        <img
          src={downArrow}
          alt={t('ICON_DOWN_ARROW')}
          className={`dropdown-select-arrow ml-2 ${open && 'dropdown-select-arrow-open'}`}
        />
      </div>
      <ul
        className={`dropdown-select-options-container absolute mt-5 overflow-auto rounded bg-white dark:bg-dark-bold m-0 dropdown-container-width ${
          open && 'dropdown-select-options-container-open py-2 overflow-hidden'
        }`}>
        {options.map((option, i) =>
          option.link ? (
            <li className='p-1 px-3 cursor-pointer dropdown-selec-option dark:hover:bg-dark-normal' key={i}>
              <button
                onClick={() => redirectProfile(editable, option.link)}
                className='dropdown-link flex items-center bg-transparent border-0'
                data-testid={`redirect-btn-${option.title}`}>
                <div className='mr-3 text-grey'>{option.icon}</div>
                <span className={`${option.className} dark:text-white`}>{option.title}</span>
              </button>
            </li>
          ) : (
            <li
              className='p-1 px-3 cursor-pointer dropdown-selec-option dark:hover:bg-dark-normal'
              onClick={option.action}
              aria-hidden='true'
              key={i}>
              <button
                className='dropdown-link flex items-center bg-transparent border-0'
                type='button'
                data-testid={`action-btn-${option.title}`}>
                <div className='mr-3 text-grey'>{option.icon}</div>
                <span className={`${option.className} dark:text-white`}>{option.title}</span>
              </button>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};

export default Dropdown;
