import React, { useEffect, useState } from 'react';
import { getProfileNews } from '../../api/userService';
import Banner from '../../components/Banner/Banner';
import linkedinIcon from '../../assets/myGroup/icon_Linkedin.svg';
import { shuffle } from '../../utils/shuffleArray';
import { useContextState, ActionTypes } from '../../redux/contextState';
import EmptyComponent from '../../components/EmptyComponent/EmptyComponent';
import VideoModal from '../../components/VideoModal/VideoModal';
import useTranslations from '../../hooks/useTranslations';
import NewsNextEvents from '../../components/NewsNextEvents/NewsNextEvents';
import NewsNextObjectives from '../../components/NewsNextObjectives/NewsNextObjectives';
import NewsGroup from '../../components/NewsGroup/NewsGroup';
import NewsGoToModify from '../../components/NewsGoToModify/NewsGoToModify';
import NewsProfileCompleteStatus from '../../components/NewsProfileCompleteStatus/NewsProfileCompleteStatus';
import Container from '../../components/Container/Container';
import useUserAdvance from '../../hooks/useUserAdvance';
import './News.scss';

const News = () => {
  const { contextState, setContextState } = useContextState();
  const [shuffledTeam, setShuffledTeam] = useState([]);
  const { t } = useTranslations();
  useUserAdvance();

  useEffect(() => {
    if (!Object.keys(contextState.profileNews).length) {
      getProfileNews().then(res => {
        setContextState({
          type: ActionTypes.SetProfileNews,
          value: res,
        });
      });
    }
    if (contextState?.profileNews?.group?.profiles && !shuffledTeam.length) {
      setShuffledTeam(shuffle(contextState?.profileNews?.group?.profiles));
    }
  }, [contextState.profileNews.group]);

  return (
    <div className='relative w-full pb-5'>
      <VideoModal />
      <Banner
        text={`¡${t('NEWS_WELCOME')} ${
          typeof contextState.profile.firstName !== 'undefined' ? contextState.profile.firstName : ''
        }!`}
        icon={contextState?.whitelabel?.images?.BANNER_LOGO}
      />
      <Container>
        {(!!contextState?.profileNews?.tickets?.length || !!contextState?.profileNews?.academy?.data?.length) && (
          <NewsNextObjectives className='mb-3' />
        )}
        <NewsProfileCompleteStatus />
        <div className='description-container flex flex-col lg:flex-row mx-auto'>
          <div className='group-container w-full'>
            <NewsGroup shuffledTeam={shuffledTeam} />
            {contextState?.profileNews?.tickets?.length || contextState?.profileNews?.academy?.data?.length ? (
              <NewsGoToModify />
            ) : (
              <></>
            )}
          </div>
          <div className='flex flex-col w-full lg:ml-3 overflow-auto'>
            <div
              className={`bg-white dark:bg-dark-bold mt-2 shadow-lg lg:mt-0 py-4 lg:pt-4 lg:pb-5 px-4 rounded w-full h-full ${
                contextState?.profileNews?.tickets?.length ? 'self-start' : 'grow'
              }`}>
              <span className='text-base p-4  font-bold dark:text-white'>🙌 {t('NEXT_COMMUNITY_MEET')}</span>
              <div className='flex lg:ml-3 mt-3'>
                {contextState?.profileNews?.communityEvents?.length ? (
                  <>
                    <img src={contextState?.profileNews?.communityEvents[0]?.photoUrl} className='next-events-image' />
                    <div className='flex flex-col grow pt-3 lg:pt-1 pl-3'>
                      <h4 className='event-author  font-bold'>
                        {contextState?.profileNews?.communityEvents[0]?.presentatorName}
                      </h4>
                      <span className='next-event-name  font-bold'>
                        {contextState?.profileNews?.communityEvents[0]?.title}
                      </span>
                      {contextState?.profileNews?.communityEvents[0]?.linkedinUrl && (
                        <a
                          href={contextState?.profileNews?.communityEvents[0]?.linkedinUrl}
                          target='_blank'
                          rel='noreferrer'
                          className='flex items-center text-decoration-none linkedin-text text-break  pt-2'>
                          <img src={linkedinIcon} className='mr-2 linkedin-image' />
                        </a>
                      )}
                      <div className='h-full w-full flex justify-end'>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href={contextState?.profileNews?.communityEvents[0]?.meetingUrl}
                          className='text-white text-decoration-none calendar-link py-2 px-3 font-bold font-roboto font-small self-end lg:mr-2'>
                          {t('NEWS_GO_TO_EVENT')}
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                  <EmptyComponent
                    className='py-6 my-2'
                    title={t('NEWS_EMPTY_MEETING_TITLE')}
                    subtitle={t('NEWS_EMPTY_MEETING_SUBTITLE')}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {!contextState?.profileNews?.tickets?.length && !contextState?.profileNews?.academy?.data?.length && (
          <NewsNextObjectives />
        )}
        <NewsNextEvents className='my-3' />
      </Container>
    </div>
  );
};

export default News;
