import Ticket from '../../Ticket/Ticket';
import { useContextState } from '../../../redux/contextState';
import useRoles from '../../../hooks/useRoles';
import './informationPersonal.scss';
import useTranslations from '../../../hooks/useTranslations';

const InformationPersonal = props => {
  const { t } = useTranslations();
  const { name, technology, age, hiredStatus } = props;
  const { contextState } = useContextState();
  const { roles, isOneCampusRole } = useRoles();
  return (
    <>
      <div className='text-xl capitalize font-bold  text-center sm:text-left flex items-center flex-wrap justify-center sm:justify-start dark:text-white'>
        {name}
        {technology && <span className='technology-text text-xl  ml-1 dark:text-white'>({technology})</span>}
        {contextState?.whitelabel?.behaviors?.SHOW_ADVANCE_TICKET && !isOneCampusRole(roles.APPLICANT) && (
          <Ticket
            text={t(`HIRED_STATUS_AVAILABLE`)}
            className={`ticket-rounded-text font-roboto uppercase ml-3 hidden lg:inline-block ${
              hiredStatus === 'AVAILABLE' ? 'ticket-light-blue' : 'ticket-light-orange'
            }`}
          />
        )}
      </div>
      <div className='text-base  dark:text-white'>{age}</div>
    </>
  );
};

export default InformationPersonal;
