import Login from '../layouts/Login/Login';
import Profile from '../components/Profile/Profile';
import Avance from '../components/Avance/Avance';
import MyCalendar from '../components/Calendar/Calendar';
import Jobs from '../components/Jobs/Jobs';
import Methodology from '../pages/methodology/Methodology';
import Project from '../pages/project/Project';
import MyGroup from '../pages/MyGroup/MyGroup';
import News from '../pages/News/News';
import Error from '../pages/Error/Error';
import Welcome from '../pages/welcome/Welcome';
import Certificate from '../pages/Certificate/Certificate';
import Mentor from '../pages/Mentor/Mentor';
import Inscription from '../pages/Inscription/Inscription';
import Challenges from '../pages/challenges/Challenges';
import NewForm from '../pages/NewForm/NewForm';
import ChallengeOrScreening from '../pages/ChallengeOrScreening/ChallengeOrScreening';
import Maintenance from '../pages/maintenance/Maintenance';
import Unauthorizated from '../pages/Unauthorizated/Unauthorizated';
import { exceptRoles } from '../constants/roles';
import Available from '../pages/Available/Available';
import Error404 from '../pages/404/404';
import Home from '../pages/Home/Home';
import ResultsEvaluation from '../pages/ResultsEvaluation/ResultsEvaluation';
import Resources from '../pages/Resources/Resources';
import FinishedScreeningsView from '../pages/FinishedScreenings/FinishedScreenings';
import MultiplatformAccount from '../pages/MultiplatformAccount/MultiplatformAccount';
import Logout from '../pages/Logout/Logout';
import JobPosts from '../pages/JobPosts/JobPosts';
import JobDetail from '../pages/JobPosts/JobDetail/JobDetail';
import Certificates from '../pages/Certificate/Certificates';
import { roles } from '../constants/enums';

const routes = {
  loggedOut: [
    {
      path: '/login',
      component: Login,
      exact: true,
    },
    {
      path: '/logout',
      component: Logout,
      exact: true,
    },
    {
      path: '/register',
      component: Login,
      exact: true,
    },
    {
      path: '/forgot-password',
      component: Login,
      exact: true,
    },
  ],
  logged: [
    {
      path: '/steps',
      component: NewForm,
      exact: true,
      dashboard: false,
      roles: [roles.APPLICANT],
    },
    {
      path: '/404',
      component: Error404,
      exact: true,
      dashboard: false,
      roles: [roles.APPLICANT, roles.ALKYMER, roles.PREACCELERATION, roles.MELI],
    },
    {
      path: '/home',
      component: Home,
      exact: true,
      dashboard: true,
      roles: [roles.APPLICANT, roles.ALKYMER, roles.PREACCELERATION, roles.EXCLUSIVEUSER],
    },
    {
      path: '/inscription',
      component: Inscription,
      exact: true,
      dashboard: true,
      roles: [roles.APPLICANT, roles.ALKYMER, roles.PREACCELERATION],
      exceptRole: [roles.MELI, roles.EXCLUSIVEUSER],
    },
    {
      path: '/challenges',
      component: Challenges,
      exact: true,
      dashboard: true,
      roles: [roles.APPLICANT, roles.ALKYMER, roles.PREACCELERATION],
      exceptRole: [roles.MELI, roles.EXCLUSIVEUSER],
    },
    {
      path: '/challenges/:id',
      component: ChallengeOrScreening,
      exact: true,
      dashboard: true,
      roles: [roles.APPLICANT, roles.ALKYMER, roles.PREACCELERATION],
      exceptRole: [roles.MELI, roles.EXCLUSIVEUSER],
    },
    {
      path: '/profile',
      component: Profile,
      exact: true,
      roles: [roles.APPLICANT, roles.ALKYMER, roles.PREACCELERATION, roles.MELI, roles.EXCLUSIVEUSER],
      dashboard: true,
    },
    {
      path: '/methodology',
      component: Methodology,
      exact: true,
      roles: [roles.ALKYMER, roles.MELI, roles.PREACCELERATION],
      exceptRole: [roles.APPLICANT, roles.EXCLUSIVEUSER],
      dashboard: true,
    },
    {
      path: '/advance',
      component: Avance,
      exact: true,
      roles: [roles.ALKYMER, roles.PREACCELERATION],
      exceptRole: [roles.APPLICANT, roles.EXCLUSIVEUSER],
      dashboard: true,
    },
    {
      path: '/results',
      component: ResultsEvaluation,
      exact: true,
      roles: [roles.ALKYMER, roles.PREACCELERATION, roles.APPLICANT],
      dashboard: true,
    },
    {
      path: '/project',
      component: Project,
      exact: true,
      roles: [roles.ALKYMER, roles.MELI, roles.PREACCELERATION],
      exceptRole: [roles.APPLICANT, roles.EXCLUSIVEUSER],
      dashboard: true,
    },
    {
      path: '/my-group',
      component: MyGroup,
      exact: true,
      roles: [roles.ALKYMER, roles.MELI, roles.PREACCELERATION],
      exceptRole: [roles.APPLICANT, roles.EXCLUSIVEUSER],
      dashboard: true,
    },
    {
      path: '/calendar',
      component: MyCalendar,
      exact: true,
      roles: [roles.ALKYMER, roles.PREACCELERATION, roles.MELI],
      exceptRole: [roles.APPLICANT, roles.EXCLUSIVEUSER],
      dashboard: true,
    },
    {
      path: '/news',
      component: News,
      exact: true,
      roles: [roles.ALKYMER, roles.PREACCELERATION, roles.MELI],
      exceptRole: [roles.APPLICANT, roles.EXCLUSIVEUSER],
      dashboard: true,
    },
    {
      path: '/welcome',
      component: Welcome,
      exact: true,
      roles: [roles.APPLICANT, roles.MELI],
      dashboard: true,
    },
    {
      path: '/jobs',
      component: Jobs,
      exact: true,
      roles: [roles.ALKYMER, roles.MELI, roles.PREACCELERATION],
      exceptRole: [roles.APPLICANT, roles.EXCLUSIVEUSER],
      dashboard: true,
    },
    {
      path: '/certificate',
      component: Certificate,
      exact: true,
      roles: [roles.ALKYMER, roles.MELI, roles.PREACCELERATION, roles.APPLICANT],
      dashboard: true,
      exceptRole: [roles.EXCLUSIVEUSER],
    },
    {
      path: '/mentor',
      component: Mentor,
      exact: true,
      roles: [roles.ALKYMER],
      dashboard: true,
      exceptRole: [roles.EXCLUSIVEUSER],
    },
    {
      path: '/availability',
      component: Available,
      exact: true,
      dashboard: true,
      roles: [roles.APPLICANT, roles.ALKYMER, roles.PREACCELERATION, roles.MELI],
      exceptRole: [roles.EXCLUSIVEUSER],
    },
    {
      path: '/error',
      component: Error,
      exact: true,
      dashboard: false,
      roles: [roles.APPLICANT, roles.ALKYMER, roles.PREACCELERATION, roles.MELI],
    },
    {
      path: '/maintenance',
      component: Maintenance,
      exact: true,
      dashboard: false,
      roles: [roles.APPLICANT, roles.ALKYMER, roles.PREACCELERATION, roles.MELI],
    },
    {
      path: '/multi-account',
      component: MultiplatformAccount,
      exact: true,
      dashboard: false,
      roles: exceptRoles,
    },
    {
      path: '/unauthorizated',
      component: Unauthorizated,
      exact: true,
      dashboard: false,
      roles: exceptRoles,
    },
    {
      path: '/resources',
      component: Resources,
      exact: true,
      roles: [roles.ALKYMER, roles.PREACCELERATION, roles.APPLICANT],
      exceptRole: [roles.EXCLUSIVEUSER],
      dashboard: true,
    },
    {
      path: '/finished-screenings',
      component: FinishedScreeningsView,
      exact: true,
      roles: [roles.APPLICANT, roles.ALKYMER, roles.PREACCELERATION],
      dashboard: true,
    },
    {
      path: '/job-posts',
      component: JobPosts,
      exact: true,
      dashboard: true,
      roles: [roles.APPLICANT, roles.ALKYMER],
      exceptRole: [roles.EXCLUSIVEUSER],
    },
    {
      path: '/job-posts/:id',
      component: JobDetail,
      exact: true,
      dashboard: true,
      roles: [roles.APPLICANT, roles.ALKYMER],
      exceptRole: [roles.EXCLUSIVEUSER],
    },
    {
      path: '/certificates',
      component: Certificates,
      exact: true,
      roles: [roles.APPLICANT, roles.ALKYMER, roles.PREACCELERATION],
      exceptRole: [roles.EXCLUSIVEUSER],
      dashboard: true,
    },
  ],
};

export default routes;
