import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/formatDate';
import useTranslations from '../../hooks/useTranslations';
import bookMark from '../../assets/news/icon-bookmark-fill.svg';
import { useContextState } from '../../redux/contextState';
import EmptyComponent from '../EmptyComponent/EmptyComponent';

const NewsNextEvents = ({ className }) => {
  const { t } = useTranslations();
  const { contextState } = useContextState();
  return (
    <div className={`description-container bg-white dark:bg-dark-bold rounded mx-auto p-4 ${className}`}>
      <span className='text-base  font-bold lg:mt-3 m-0 dark:text-white'>{t('NEWS_NEXT_EVENTS')}</span>
      <div className='flex next-events-container flex-col lg:flex-row pt-4 pr-4'>
        {contextState?.profileNews?.nextEvents?.items?.length &&
        contextState?.profileNews?.nextEvents?.items?.filter(item => new Date() - new Date(item?.start?.dateTime) < 0)
          .length ? (
          <>
            {contextState?.profileNews?.nextEvents?.items
              ?.filter(item => new Date() - new Date(item?.start?.dateTime) < 0)
              .slice(0, 2)
              .map((item, index) => (
                <div
                  key={index}
                  className={`flex flex-col py-2 lg:pr-5 mr-4 self-start ${index === 0 && 'lg:border-right'}`}>
                  <span className=' font-bold date-font'>
                    <img src={bookMark} className='pr-2' />
                    {formatDate(t, new Date(item?.start?.dateTime), 'eventsDate')}
                  </span>
                  <span className='pl-4 ml-2 text-muted  font-small'>{item.summary}</span>
                </div>
              ))}
            <div className='flex grow justify-end items-end pr-4'>
              <Link to='/calendar' className='calendar-link px-3 py-2 font-bold text-white font-roboto font-small'>
                {t('GO_TO_CALENDAR')}
              </Link>
            </div>
          </>
        ) : (
          <EmptyComponent
            className='py-4'
            title={t('NEWS_EMPTY_EVENTS_TITLE')}
            subtitle={t('NEWS_EMPTY_EVENTS_SUBTITLE')}
          />
        )}
      </div>
    </div>
  );
};

export default NewsNextEvents;
