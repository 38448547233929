import { useState, useEffect } from 'react';
import LoginStep from '../../pages/NewForm/LoginStep/LoginStep';
import { imgs } from '../../constants/LoginImages';
import './login.scss';

const Login = () => {
  const [img, setImg] = useState();
  useEffect(() => {
    setImg(imgs[Math.floor(Math.random() * imgs.length)]);
  }, []);

  return (
    <div className='container-fluid'>
      <div className='flex flex-col lg:flex-row no-gutter logged-out-container'>
        <div className=' bg-white dark:bg-dark-bold flex w-full lg:w-2/5 justify-center p-0 m-0'>
          <LoginStep
            backStep={() => setPageScrollet(pageScroller - 1)}
            nextStep={() => setPageScrollet(pageScroller + 1)}
          />
        </div>
        <div
          className='hidden lg:block logged-out-bg-image relative w-3/5 h-full'
          style={{ backgroundImage: `url(${img})` }}>
          <svg xmlns='http://www.w3.org/2000/svg' className='absolute bottom-0 left-0' viewBox='0 0 1440 320'>
            <path
              fill='#52C0F7'
              fillOpacity='1'
              d='M0,288L48,277.3C96,267,192,245,288,229.3C384,213,480,203,576,197.3C672,192,768,192,864,202.7C960,213,1056,235,1152,234.7C1248,235,1344,213,1392,202.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Login;
