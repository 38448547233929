const isSomeRole = (roles, userRoles, exceptRole = []) => {
  let hasRoles;
  const foundExceptRoles = userRoles.some(role => exceptRole.includes(role));
  if (foundExceptRoles) {
    hasRoles = false;
  } else {
    const foundAcceptedRoles = userRoles.some(role => roles.includes(role));
    hasRoles = foundAcceptedRoles;
  }
  return hasRoles;
};

export default isSomeRole;
