import { DOCUMENT_TYPE_OPTIONS } from '../../constants/profile';
import { iconsRegex } from '../../constants/regexValidations';

export const nameValidations = {
  required: {
    value: true,
    message: 'PROFILE_NAME_REQUIRED_ERROR',
  },
  validate: value => !value.match(iconsRegex) || 'PROFILE_NAME_ONLY_LETTERS',
};

export const lastNameValidations = {
  required: {
    value: true,
    message: 'PROFILE_LASTNAME_REQUIRED_ERROR',
  },
  validate: value => !value.match(iconsRegex) || 'PROFILE_NAME_ONLY_LETTERS',
};

export const genderValidations = {
  required: {
    value: true,
    message: 'GENDER_REQUIRED_ERROR',
  },
};

export const birthdateValidations = {
  valueAsDate: true,
  required: {
    value: true,
    message: 'PROFILE_NAME_REQUIRED_ERROR',
  },
};

export const countryCodeValidations = {
  required: {
    value: true,
    message: 'PROFILE_COUNTRY_CODE_REQUIRED_ERROR',
  },
  maxLength: {
    value: 3,
    message: 'PROFILE_MAX_CHARACTERS_3',
  },
};

export const phoneValidations = {
  required: {
    value: true,
    message: 'PROFILE_PHONE_REQUIRED_ERROR',
  },
  minLength: {
    value: 6,
    message: 'MIN_CHARACTERS_PHONE',
  },
  maxLength: {
    value: 20,
    message: 'MIN_CHARACTERS_PHONE',
  },
};

export const countryValidations = {
  required: {
    value: true,
    message: 'COUNTRY_REQUIRED_ERROR',
  },
  valueAsNumber: true,
};

export const provinceValidations = {
  valueAsNumber: true,
  required: {
    value: true,
    message: 'PROFILE_PROVINCE_REQUIRED_ERROR',
  },
};

export const locationValidations = {
  required: {
    value: true,
    message: 'LOCATION_REQUIRED_ERROR',
  },
  minLength: {
    value: 2,
    message: 'MIN_CHARACTERS_TWO',
  },
};

export const documentTypeValidations = {
  required: {
    value: true,
    message: 'TYPE_DOCUMENT_REQUIRED_ERROR',
  },
  validate: value => DOCUMENT_TYPE_OPTIONS.map(item => item.value).includes(value) || 'INVALID_DOCUMENT_TYPE',
};

export const documentValidations = {
  required: {
    value: true,
    message: 'DOCUMENT_REQUIRED_ERROR',
  },
  minLength: {
    value: 7,
    message: 'MIN_CHARACTERS',
  },
  maxLength: {
    value: 20,
    message: 'MAX_CHARACTERS',
  },
  validate: value => /^\d+$/.test(value) || 'ONLY_NUMBERS',
};
