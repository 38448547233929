const AcademyIcon = () => {
  return (
    <svg
      className='fill-current stroke-current'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M5 13.18V17.18L12 21L19 17.18V13.18L12 17L5 13.18ZM12 3L1 9L12 15L21 10.09V17H23V9L12 3Z' />
    </svg>
  );
};

export default AcademyIcon;
