import { useState, useEffect } from 'react';
import useTranslations from './useTranslations';
import { useContextState } from '../redux/contextState';
import { roles } from '../constants/enums';

const useMissingData = () => {
  const [missingData, setMissingData] = useState([]);
  const [percentage, setPercentage] = useState();
  const { t } = useTranslations();
  const { contextState } = useContextState();
  const {
    birthdate,
    gender,
    countryCode,
    phone,
    address,
    formations,
    technologies,
    linkedinUrl,
    picture,
    cvUrl,
    languages,
    certificateUrl,
    videos,
    description,
    portfolios,
  } = contextState?.profile;

  const baseRequirements = [
    {
      validation: birthdate === null,
      message: t('PROFILE_MISSING_DATA_BIRTHDATE'),
    },
    {
      validation: gender === null,
      message: t('PROFILE_MISSING_DATA_GENDER'),
    },
    {
      validation: countryCode === null,
      message: t('PROFILE_MISSING_DATA_COUNTRY_CODE'),
    },
    {
      validation: phone === null || !phone?.length,
      message: t('PROFILE_MISSING_DATA_PHONE_NUMBER'),
    },
    {
      validation: address?.countryId === null,
      message: t('PROFILE_MISSING_DATA_COUNTRY'),
    },
    {
      validation: !address?.cityId,
      message: t('PROFILE_MISSING_DATA_PROVINCE'),
    },
    {
      validation: !address?.location,
      message: t('PROFILE_MISSING_DATA_CITY'),
    },
    {
      validation: !formations?.length || formations === null,
      message: t('PROFILE_MISSING_DATA_FORMATIONS'),
    },
    {
      validation: !portfolios?.length || portfolios === null,
      message: t('PROFILE_MISSING_DATA_PORTFOLIO'),
    },
    {
      validation:
        contextState?.roles?.includes(roles.ALKYMER) &&
        !technologies?.filter(technology => technology?.technologyType === 'KNOW')?.length,
      message: t('PROFILE_MISSING_DATA_TECHNOLOGIES'),
    },
    {
      validation: linkedinUrl === null,
      message: t('PROFILE_MISSING_DATA_LINKEDIN'),
    },
    {
      validation: cvUrl === null,
      message: t('PROFILE_MISSING_DATA_CV_URL'),
    },
    {
      validation: picture === null,
      message: t('PROFILE_MISSING_DATA_PROFILE_PICTURE'),
    },
    {
      validation: !languages?.length || languages === null,
      message: t('PROFILE_MISSING_DATA_LANGUAGES'),
    },
    {
      validation: !videos?.find(item => item.videoType === 'VIDEO_10'),
      message: t('PROFILE_MISSING_DATA_VIDEO_10'),
    },
    {
      validation: description === null || !description?.length,
      message: t('MY_DESCRIPTION'),
    },
  ];

  useEffect(() => {
    setMissingData(baseRequirements?.filter(item => item?.validation)?.map(item => item.message));
    setPercentage(
      Math.floor((baseRequirements?.filter(item => !item?.validation).length * 100) / baseRequirements.length),
    );
  }, [contextState.profile, contextState?.screenings]);

  return {
    missingData,
    percentage,
    baseRequirements: baseRequirements?.filter(item => item?.validation)?.map(item => item.message),
  };
};

export default useMissingData;
