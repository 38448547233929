export const removeEmptyStringsFromObject = object => {
  Object.entries(object).forEach(([key, value]) => {
    if (value && typeof value === 'object') removeEmptyStringsFromObject(value);
    if (
      (value && typeof value === 'object' && !Object.keys(value).length) ||
      value === null ||
      value === undefined ||
      value.length === 0
    ) {
      if (Array.isArray(object)) object.splice(key, 1);
    }
  });
  return object;
};
