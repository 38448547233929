import { useState } from 'react';
import downArrow from '../../../assets/news/more-tickets-arrow.svg';
import Ticket from '../../Ticket/Ticket';
import flagIcon from '../../../assets/myGroup/icon-flag.svg';
import EmptyComponent from '../../EmptyComponent/EmptyComponent';
import useTranslations from '../../../hooks/useTranslations';

const WeeklyGoals = ({ className, Goals }) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslations();
  return (
    <div className={`custom-card p-4 overflow-hidden bg-white dark:bg-dark-bold ${className}`}>
      <div className='description-container rounded'>
        <span className='text-alkemy-black text-base  font-bold mt-3 dark:text-white'>
          {t('WEEKLY_GOALS_OBJECTIVES')}
        </span>
        <div className='flex flex-col pt-4'>
          {Goals?.tickets?.length || Goals?.academy?.data?.length ? (
            <>
              {!!Goals?.academy?.data?.length && (
                <div className='flex flex-col py-2 grow'>
                  <span className=' font-bold text-alkemy-black-bold dark:text-white pb-2 flex flex-row'>
                    <img src={flagIcon} className='pr-2' />
                    {t('WEEKLY_GOALS_ALKEMY_ACADEMY')}
                  </span>
                  {Goals?.academy?.data?.map((item, index) => (
                    <div className='pl-4 ml-2 flex flex-col pt-2 pb-2' key={index}>
                      <span className=' font-bold font-small dark:text-white'>{item.name}</span>
                      {!!item.skills.length &&
                        item.skills.map((item, index) => (
                          <Ticket
                            key={index}
                            text={item?.name ?? item ?? ''}
                            className='ticket-technology-text uppercase font-roboto px-1 py-1 self-start mt-1 font-bold'
                          />
                        ))}
                    </div>
                  ))}
                </div>
              )}
              {!!Goals?.tickets?.length && (
                <div className='flex flex-col py-2 mb-3'>
                  <span className='font-bold text-alkemy-black-bold pb-2 dark:text-white flex'>
                    <img src={flagIcon} className='pr-2' />
                    {t('WEEKLY_GOALS_TICKETS')}
                  </span>
                  {show
                    ? Goals?.tickets.map((ticket, index) => (
                        <div className='pl-4 ml-2 flex flex-col pt-2 pb-2' key={index}>
                          <a
                            className='text-alkemy-black hover:underline hover:decoration-alkemy-black font-bold font-small dark:text-white'
                            href={ticket.link}
                            target='_blank'
                            rel='noreferrer'>
                            {ticket.title}
                          </a>
                          {ticket?.skill && (
                            <Ticket
                              text={ticket.skill}
                              className='ticket-technology-text uppercase font-roboto px-1 py-1 self-start mt-1 font-bold'
                            />
                          )}
                        </div>
                      ))
                    : Goals?.tickets.slice(0, 3).map((ticket, index) => (
                        <div className='pl-4 ml-2 flex flex-col pt-2 pb-2' key={index}>
                          <a
                            className='text-alkemy-black hover:underline hover:decoration-alkemy-black font-bold font-small dark:text-white'
                            href={ticket.link}
                            target='_blank'
                            rel='noreferrer'>
                            {ticket.title}
                          </a>
                          <Ticket
                            text={ticket.skill}
                            className='ticket-technology-text uppercase font-roboto px-1 py-1 self-start mt-1 font-bold'
                          />
                        </div>
                      ))}
                  {Goals?.tickets?.length > 3 && (
                    <button
                      className='self-start ml-4 pl-2 font-small bg-transparent mt-4 uppercase border-0 font-roboto text-maya-blue font-bold flex items-center'
                      onClick={() => setShow(!show)}>
                      {show ? t('WEEKLY_GOALS_SHOW_LESS_TICKETS') : t('WEEKLY_GOALS_SHOW_MORE_TICKETS')}
                      <img className={`ml-2 normal-arrow ${show && 'rotate-arrow'}`} src={downArrow} />
                    </button>
                  )}
                </div>
              )}
            </>
          ) : (
            <EmptyComponent
              title={t('WEEKLY_GOALS_EMPTY_OBJETIVES_TITLE')}
              className='p-3'
              subtitle={t('WEEKLY_GOALS_EMPTY_OBJETIVES_SUBTITLE')}
              size='sm'
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WeeklyGoals;
