import { ActionTypes, useContextState } from '../redux/contextState';
import { useEffect, useState } from 'react';
import { getProfileAdvance } from '../api/userService';

const useUserAdvance = () => {
  const { setContextState, contextState } = useContextState();
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    if (!contextState.profile.groups || !contextState.profile.feedback || !contextState.profile.profileScore) {
      try {
        const advance = await getProfileAdvance();
        setContextState({
          type: ActionTypes.SetProfile,
          value: { ...contextState.profile, profileScore: {}, ...advance },
        });
      } catch (error) {
        setContextState({
          type: ActionTypes.SetProfile,
          value: { ...contextState.profile, groups: [], feedback: [], profileScore: {} },
        });
      }
    }
    setLoading(false);
  }, []);

  return { loading, profile: contextState.profile };
};

export default useUserAdvance;
