export const queryToObject = query =>
  query
    ? Object.fromEntries(
        query
          ?.split('?')
          ?.join('')
          ?.split('&')
          ?.map(item => item.replace('#', '').split('=')),
      )
    : undefined;
