import React, { useState } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Input.scss';

const PASSWORD = 'password';
const TEXT = 'text';

const Input = ({
  register,
  name,
  type,
  error,
  placeholder,
  defaultValue,
  label,
  onChange,
  disabled,
  margin,
  disableMargin,
  editable,
  className,
  classNameInput,
}) => {
  const [viewPassword, setViewPassword] = useState(false);
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const [value, setValue] = useState(!!defaultValue);
  const changePassword = () => {
    setViewPassword(viewPassword => !viewPassword);
  };

  React.useEffect(() => {
    setValue(!!defaultValue);
  }, [defaultValue]);
  const handleChange = e => {
    if (e.target.value && !value) {
      setValue(true);
    } else if (!e.target.value && value) {
      setValue(false);
    }
    if (onChange) {
      onChange(e);
    }
  };
  return (
    <div className={`${className && className} ${!disableMargin ? (!margin ? 'my-3' : 'my-2') : ''}`}>
      <label
        className={`${editable && 'input-container'} mb-4 w-full ${focus ? 'input-container-active' : ''} ${
          hover ? 'input-container-hover' : ''
        } ${error?.message ? 'input-error' : ''}`}>
        {label ? (
          <span
            className={`custom-input-label absolute flex items-center  ${
              value || focus || placeholder ? 'truncate custom-input-label-active font-bold' : ''
            }`}>
            {label}
          </span>
        ) : null}
        <input
          type={type === PASSWORD ? (viewPassword ? TEXT : PASSWORD) : type}
          placeholder={placeholder}
          min={type === 'number' ? 0 : undefined}
          name={name}
          ref={register}
          value={defaultValue}
          disabled={disabled}
          className={`w-full  custom-input truncate ${label ? 'mt-4' : null} ${classNameInput && classNameInput}`}
          onFocus={() => setFocus(true)}
          onBlur={e => {
            setFocus(false);
            handleChange(e);
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onChange={handleChange}
        />
        {type === PASSWORD && (
          <button type='button' className='input-show-pass-btn' onClick={changePassword} tabIndex='-1'>
            <FontAwesomeIcon icon={viewPassword ? faEye : faEyeSlash} />
          </button>
        )}
      </label>
      {error && <p className='danger-text'>{error.message}</p>}
    </div>
  );
};

export default Input;
