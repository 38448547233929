import { useInfiniteQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { getJobs, getJobsFiltersData } from '../api/jobPostService';
import useTranslations from '../hooks/useTranslations';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
const fetchData = async ({ data, filters }) => {
  return await getJobs({ ...filters, limit: 6, offset: data.pageParam });
};

const filtersInitialState = {
  studyAreasId: [],
  seniorities: [],
  technologiesId: [],
  workModalities: [],
  modalities: [],
  rolesId: [],
  startDate: null,
  endDate: new Date().toISOString(),
  countryIds: null,
  cityIds: null,
  dateOption: null,
};

export const useJobPosts = () => {
  const { t } = useTranslations();
  const history = useHistory();
  const location = useLocation();

  const getQueryParam = param => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  const updateQueryParams = () => {
    const searchParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        searchParams.set(key, value.join(','));
      } else if (value !== null && value !== undefined) {
        searchParams.set(key, value);
      }
    });
    history.replace({ search: searchParams.toString() });
  };

  const parseQueryParams = () => {
    const queryParams = {
      studyAreasId: getQueryParam('studyAreasId') || [],
      seniorities: getQueryParam('seniorities') || [],
      technologiesId: getQueryParam('technologiesId') || [],
      workModalities: getQueryParam('workModalities') || [],
      modalities: getQueryParam('modalities') || [],
      rolesId: getQueryParam('rolesId') || [],
      startDate: getQueryParam('startDate') || null,
      endDate: getQueryParam('endDate') || new Date().toISOString(),
      countryIds: getQueryParam('countryIds') || null,
      cityIds: getQueryParam('cityIds') || null,
      dateOption: getQueryParam('dateOption') || null,
    };

    queryParams.studyAreasId = Array.isArray(queryParams.studyAreasId)
      ? queryParams.studyAreasId
      : queryParams.studyAreasId.split(',');

    queryParams.seniorities = Array.isArray(queryParams.seniorities)
      ? queryParams.seniorities
      : queryParams.seniorities.split(',');

    queryParams.technologiesId = Array.isArray(queryParams.technologiesId)
      ? queryParams.technologiesId
      : queryParams.technologiesId?.split(',')?.map(Number);

    queryParams.workModalities = Array.isArray(queryParams.workModalities)
      ? queryParams.workModalities
      : queryParams.workModalities.split(',');

    queryParams.modalities = Array.isArray(queryParams.modalities)
      ? queryParams.modalities
      : queryParams.modalities.split(',');

    queryParams.rolesId = Array.isArray(queryParams.rolesId) ? queryParams.rolesId : queryParams.rolesId.split(',');

    return queryParams;
  };

  const [filters, setFilters] = useState(parseQueryParams);

  const cleanFilters = () => {
    setFilters(filtersInitialState);
  };

  useEffect(() => {
    updateQueryParams();
  }, [filters]);

  const [filtersData, setFiltersData] = useState(null);
  const [loadingFiltersData, setLoadingFiltersData] = useState(true);
  const { data, error, isError, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ['jobs'],
      queryFn: data =>
        fetchData({
          data,
          filters,
        }),
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage?.collection?.length) return undefined;
        if (lastPage?.collection?.length < lastPage?.pagination?.limit) return undefined;
        return pages?.length * lastPage?.collection?.length;
      },
    });

  const fetchJobsFiltersData = async () => {
    const data = await getJobsFiltersData();
    for (const key in data) {
      data[key] = data[key]?.map(x => {
        return {
          ...x,
          id: x?.id,
          displayName: t(x?.displayName),
        };
      });
    }
    return data;
  };

  useEffect(() => {
    const fetchFiltersData = async () => {
      const data = await fetchJobsFiltersData();
      setFiltersData(data);
      setLoadingFiltersData(false);
    };
    fetchFiltersData();
  }, []);

  const debouncedApiCall = debounce(() => {
    refetch();
  }, 300);

  useEffect(() => {
    debouncedApiCall();
    return () => {
      debouncedApiCall.cancel();
    };
  }, [filters]);

  const onSelectFilters = (filterName, e) => {
    const filter = filters[filterName];
    const selectedOption = filter?.includes(e?.id);
    if (selectedOption) {
      setFilters({ ...filters, [filterName]: filter?.filter(x => x !== e?.id) });
    } else {
      setFilters({ ...filters, [filterName]: [...filter, e?.id] });
    }
  };

  const onChangeFilter = (filterName, value) => {
    setFilters({ ...filters, [filterName]: value });
  };
  const updateFilter = filterObject => {
    setFilters({ ...filters, ...filterObject });
  };

  return {
    jobs: data?.pages.flatMap(page => page.collection) ?? [],
    error,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    filters,
    filtersData,
    loadingFiltersData,
    onSelectFilters,
    onChangeFilter,
    updateFilter,
    cleanFilters,
  };
};
