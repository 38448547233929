import flags from '../../assets/countriesFlags';
import './CountryFlag.scss';

const CountryFlag = ({ countryIso, className = '', size = 'md' }) => (
  <img
    className={`country-flag ${className}`}
    src={flags[countryIso] ?? flags.UNKNOWN}
    alt={countryIso}
    data-size={size}
  />
);

export default CountryFlag;
