export const ticketClass = (technologyType = 'BACKEND') => {
  let className = 'ticket-sprint-text';
  switch (technologyType) {
    case 'FRONTEND':
      className = 'ticket-technology-frontend';
      break;
    case 'FULLSTACK':
      className = 'ticket-technology-fullstack';
      break;
    default:
      break;
  }
  return className;
};
