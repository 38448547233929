import { useState } from 'react';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import useTranslations from '../../../../hooks/useTranslations';
import './FormationCardDatePicker.scss';

const FormationCardDatePicker = ({
  name,
  title,
  control,
  editable,
  minDate,
  maxDate,
  error,
  className,
  setValue,
  clearErrors,
  register,
  validations,
  value,
}) => {
  const { t } = useTranslations();
  const [open, setOpen] = useState(false);
  return (
    <div className={`w-full relative text-sm bg-white dark:bg-transparent dark:text-white ${className}`}>
      <div className='mb-1'>
        <span className='text-sm italic whitespace-nowrap'>{title}</span>
      </div>
      <Controller
        name={name}
        control={control}
        {...register(name, validations)}
        render={() => {
          return (
            <div
              className={`flex styled-select bg-[calc(95%)_center] bg-no-repeat items-center relative border-1 px-2 shadow-sm w-full rounded-lg ${
                error?.message ? 'border-red-600' : 'border-gray-300'
              }`}>
              <DatePicker
                onSelect={e => {
                  setValue(name, moment(e).format('YYYY/MM/DD'));
                  clearErrors(name);
                }}
                id={`id-${name}`}
                selected={value ? new Date(moment(value).format('YYYY/MM/DD')) : undefined}
                className='outline-none text-xs font-semibold py-1 bg-transparent px-2 truncate w-100'
                locale='es'
                minDate={minDate ? new Date(moment(minDate)) : null}
                maxDate={maxDate ? new Date(moment(maxDate)) : null}
                dateFormat='MM/yyyy'
                showMonthYearPicker
                disabled={!editable}
                dropdownMode='select'
              />
            </div>
          );
        }}
      />
      {error?.message && <span className='text-red-600 text-xs'>{t(error.message)}</span>}
    </div>
  );
};

export default FormationCardDatePicker;
