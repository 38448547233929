import { useState, useEffect } from 'react';
import Spinner from '../../components/Spinner/Spinner';
import Banner from '../../components/Banner/Banner';
import { ActionTypes, useContextState } from '../../redux/contextState';
import { capitalizeSentence } from '../../utils/stringUtils';
import linkedin from '../../assets/mentor/linkedin.svg';
import logo from '../../assets/mentor/logo_alkemy.svg';
import SlackIcon from '../../assets/myGroup/slack_icon.svg';
import light from '../../assets/mentor/light.svg';
import ReactSelect from 'react-select';
import customStyles from '../../components/MultiSelect/customStyles';
import { getMentor } from '../../api/userService';
import profileImageNull from '../../assets/myGroup/profile.svg';
import './mentor.css';
import useTranslations from '../../hooks/useTranslations';
import Container from '../../components/Container/Container';
import useUserAdvance from '../../hooks/useUserAdvance';

const SelectKnow = ({ className, title, options }) => {
  return (
    <div className={`${className}`}>
      <span className=' font-bold my-3 font-size-small'>{title}</span>
      <ReactSelect
        className='custom-select custom-select-input mentor-select-option'
        name={title}
        autoComplete='off'
        autoCorrect='off'
        isMulti={true}
        isClearable={false}
        styles={customStyles} // TODO: Mover estos customStyles a otro lado
        value={options}
        options={options}
        isDisabled={true}
      />
    </div>
  );
};

const Mentor = () => {
  const { setContextState } = useContextState();
  const [mentor, setMentor] = useState({});
  const [loading, setLoading] = useState(false);
  const { loading: profileLoading, profile } = useUserAdvance();
  const { t } = useTranslations();

  useEffect(() => {
    if (
      !profileLoading &&
      profile.groups?.length &&
      profile?.groups[0]?.group?.mentorId &&
      !Object.keys(mentor).length
    ) {
      setLoading(true);
      getMentor(profile?.groups[0]?.group?.mentorId)
        .then(res => {
          setContextState({
            type: ActionTypes.SetMentor,
            value: res,
          });
          setMentor(res);
        })
        .catch(() => {
          setContextState({
            type: ActionTypes.SetMentor,
            value: {},
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [profile, profileLoading]);

  return (
    <div className='h-full w-full'>
      {(loading || profileLoading) && mentor ? (
        <div className='m-auto'>
          <div className='m-auto'>
            <Spinner show={loading} />
          </div>
        </div>
      ) : (
        <div className='relative w-full'>
          <Banner text={`😎 ${t('MENTOR_TITLE')}`} />
          <Container>
            <div className='flex flex-col w-full lg:flex-row mx-auto relative description-container px-4 lg:px-0 pb-5'>
              <div className='flex flex-col width-card-mentor'>
                <div className='user-heading py-5 text-center flex flex-col items-center profile-menu-container bg-white dark:bg-dark-bold'>
                  <span className='truncate  font-bold mt-2 text-base dark:text-white'>{t('MENTOR_TITLE')}</span>
                  <div className='flex justify-center user-heading-round rounded-full relative mt-3'>
                    <img
                      className='user-heading-img rounded-full'
                      src={mentor?.picture || profileImageNull}
                      alt={t('ICON_LOGO_USER')}
                    />
                  </div>
                  <div className='flex flex-row items-center'>
                    <span className='block lg:hidden  font-small dark:text-white text-center lg:text-left'>
                      {t('MY_GROUP_MY_MENTOR')}
                    </span>
                    <div className=' font-bold text-sm mt-1 flex items-center dark:text-white'>
                      {mentor?.slackUrl && (
                        <a href={mentor?.slackUrl} target='_blank' rel='noreferrer'>
                          <img src={SlackIcon} alt={t('SLACK_ALT')} className='mr-2 mentor-slack-icon' />
                        </a>
                      )}
                      {`${capitalizeSentence(mentor?.firstName)} ${capitalizeSentence(mentor?.lastName)}`}
                    </div>
                  </div>

                  {mentor?.linkedinUrl && (
                    <div className='flex flex-row mt-3'>
                      <a href={mentor?.linkedinUrl} target='_blank' rel='noreferrer'>
                        <img src={linkedin} />
                      </a>
                    </div>
                  )}
                </div>
                {mentor?.funFact && (
                  <div className='user-heading py-3 mt-3 flex flex-col px-4 bg-white dark:bg-dark-bold'>
                    <div className='flex flex-row items-center'>
                      <img src={light} className='mb-1' />
                      <span className='truncate  font-bold ml-2 dark:text-white'>{t('MENTOR_FUN_FACT')}</span>
                    </div>
                    <span className=' mt-3 text-gray-500 font-semibold dark:text-white'>“{mentor?.funFact}”</span>
                  </div>
                )}
              </div>
              <div className='flex flex-col width-variable justify-center items-center lg:justify-start lg:items-start ml-0 lg:ml-4 '>
                {(mentor?.teachedTechnologies?.length > 0 ||
                  mentor?.knownTechnologies?.length > 0 ||
                  mentor?.companies) && (
                  <div className='flex flex-col lg:flex-row w-full'>
                    {mentor?.teachedTechnologies?.length > 0 || mentor?.knownTechnologies?.length > 0 ? (
                      <div
                        className={`flex flex-col user-heading p-5  ${
                          mentor?.companies !== null ? 'width-know-card-50' : 'width-know-card-100'
                        }`}>
                        <span className='truncate  font-bold'>{t('MENTOR_KNOWLEDGE')}</span>
                        {mentor?.teachedTechnologies?.length > 0 && (
                          <SelectKnow
                            className='mt-4'
                            title={t('MENTOR_TECHNOLOGY')}
                            options={mentor?.teachedTechnologies?.map(technology => ({
                              value: technology?.id,
                              label: technology?.technology?.displayName,
                              isFixed: true,
                            }))}
                          />
                        )}
                        {mentor?.knownTechnologies?.length > 0 && (
                          <SelectKnow
                            className='mt-4'
                            title={t('MENTOR_TECHNOLOGY_KNOW')}
                            options={mentor?.knownTechnologies?.map(technology => ({
                              value: technology?.id,
                              label: technology?.technology?.displayName,
                            }))}
                          />
                        )}
                      </div>
                    ) : null}
                    {mentor?.companies ? (
                      <div
                        className={`flex flex-col user-heading bg-white dark:bg-dark-bold ml-0 p-5 ${
                          mentor?.teachedTechnologies?.length > 0 || mentor?.knownTechnologies?.length > 0
                            ? 'width-know-card-50 lg:ml-4'
                            : 'width-know-card-100'
                        }`}>
                        <span className=' font-bold dark:text-white'>{t('MENTOR_EXPERIENCE')}</span>
                        <div className='flex flex-col'>
                          <span className=' font-bold mt-2 font-size-small dark:text-white'>{t('MENTOR_COMPANY')}</span>
                          <div className='flex flex-col ml-3'>
                            {mentor?.companies?.split(',').map((company, index) => (
                              <span key={index} className=' text-gray-500 text-xs font-bold mt-2 dark:text-white'>
                                {company}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
                {mentor?.formations?.length ? (
                  <div
                    className={`flex flex-col mt-3 user-heading bg-white dark:bg-dark-bold p-5 w-full ${
                      mentor?.teachedTechnologies?.length > 0 ||
                      mentor?.knownTechnologies?.length > 0 ||
                      mentor?.companies
                        ? 'mt-3'
                        : 'mt-0'
                    }`}>
                    <span className='truncate font-bold text- dark:text-white'>{t('MENTOR_ACADEMIC_FORMATION')}</span>
                    {mentor?.formations?.map(formation => (
                      <div className='flex flex-col md:flex-row mt-3' key={formation.id}>
                        <div className='flex flex-col mr-5 w-1/2'>
                          <span className=' font-bold text-sm dark:text-white'>{t('MENTOR_INSTITUTE')}</span>
                          <span className=' font-semibold text-gray-500 dark:text-white'>
                            {(formation?.institute && formation?.institute?.displayName) || formation?.customInstitute}
                          </span>
                        </div>
                        <div className='flex flex-col w-1/2'>
                          <span className=' font-bold text-sm dark:text-white'>{t('MENTOR_CAREER')}</span>
                          <span className=' font-semibold text-gray-500 dark:text-white'>
                            {(formation?.formationCareer && formation?.formationCareer?.displayName) ||
                              formation?.customCareer}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
                <div
                  className={`flex flex-col user-heading p-4 w-full bg-white dark:bg-dark-bold ${
                    mentor?.teachedTechnologies?.length > 0 ||
                    mentor?.knownTechnologies?.length > 0 ||
                    mentor?.companies ||
                    mentor?.formations?.length
                      ? 'mt-3'
                      : 'mt-0'
                  }`}>
                  <div className='flex'>
                    <img src={logo} className='mb-1 mr-3' />
                    <span className=' font-bold text-sm dark:text-white'>{t('MENTOR_GROUPS')}</span>
                  </div>
                  <div className='grid grid-cols-2 mt-3'>
                    {mentor?.groups?.map(group => (
                      <span key={group.id} className='mt-3  text-gray-500 font-semibold text-sm dark:text-white'>
                        {group?.displayName}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default Mentor;
