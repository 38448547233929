const customStyles = {
  control: base => ({
    ...base,
    paddingRight: '40px',
    backgroundColor: 'none',
    boxShadow: 'none',
    border: 'none',
    minHeight: '0',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => ({
    alignSelf: 'flex-end',
  }),
  clearIndicator: base => ({
    ...base,
    padding: '4px',
    cursor: 'pointer',
  }),
  container: base => ({
    ...base,
    padding: '0px',
    position: 'unset',
    display: 'inline',
  }),
  valueContainer: base => ({
    ...base,
    padding: '0px',
  }),
  multiValue: base => ({
    ...base,
  }),
  menu: base => ({
    ...base,
    width: '100%',
    position: 'absolute',
    bottom: '0px',
    zIndex: '100',
  }),
  menuList: base => ({
    ...base,
    backgroundColor: '#fff',
  }),
};

export default customStyles;
