import { useFieldArray, useFormContext } from 'react-hook-form';
import { Skeleton } from 'antd';
import useTranslations from '../../../hooks/useTranslations';
import FormationCardForm from './FormationCardForm/FormationCardForm';

const FormationCard = props => {
  const { className, loading } = props;
  const { t } = useTranslations();

  const { register, control, errors, watch, setValue } = useFormContext();
  const { fields, update, remove } = useFieldArray({
    name: 'formations',
    control: control,
  });

  return (
    <div className={`flex flex-col ${className}`}>
      <div className='flex  flex-row justify-between'>
        <span className=' text-alkemy-black text-base font-bold my-auto dark:text-white'>
          {t('FORMATION_CARD_FORMATION')}
        </span>
      </div>
      {loading ? (
        <div className='flex flex-row justify-between'>
          <Skeleton active paragraph={{ rows: 1, width: '80%' }} />
          <Skeleton active paragraph={{ rows: 1, width: '80%' }} />
          <Skeleton active paragraph={{ rows: 1, width: '80%' }} />
        </div>
      ) : (
        <div className='flex flex-col'>
          {fields?.map((value, index) => {
            const fieldName = `formations.${index}`;
            return (
              <FormationCardForm
                showCancelButton={true}
                key={value.id}
                value={value}
                remove={() => remove(index)}
                control={control}
                index={index}
                editable={Object.keys(value).length === 1}
                register={register}
                watchParent={watch}
                fieldName={fieldName}
                update={object => update(index, object)}
              />
            );
          })}
          {!fields.length ? (
            <FormationCardForm
              remove={() => remove(0)}
              control={control}
              index={0}
              register={register}
              watchParent={watch}
              fieldName='formations.0'
              value={{}}
              editable={true}
              update={object => update(0, object)}
            />
          ) : (
            <button
              data-testid='add-formation-card-button'
              onClick={() => {
                update(fields.length, {});
              }}
              className='text-alkemy-purple font-semibold self-start'>
              {t('FORMATION_CARD_ADD_ANOTHER_FORMATION')}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default FormationCard;
