import React, { useRef } from 'react';
import useOnScreen from '../../hooks/useOnScreen';
import './progress.scss';

const Progress = props => {
  const ref = useRef();
  const onScreen = useOnScreen(ref, '0px');
  const { percent, className = '', height = '0.5rem' } = props;
  return (
    <div ref={ref} className={`relative progress-container ${className}`} data-testid='progress-container-div'>
      <div
        className='progress-training'
        style={{ width: `${onScreen ? percent : 0}%`, height, transition: 'ease-in 1s' }}
        data-testid='progress-width-div'
      />
      <div className='progress-training-bg w-full absolute' style={{ height }} data-testid='progress-width-bg' />
    </div>
  );
};

export default Progress;
