const CertificateIcon = () => {
  return (
    <svg
      className='fill-current stroke-current'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M4.51 4.00031V4C4.51 3.17287 5.1794 2.5 6 2.5H13.7929L19.5 8.20711V20C19.5 20.8239 18.8239 21.5 18 21.5H5.99C5.16945 21.5 4.50008 20.8272 4.5 20.0002C4.5 20.0001 4.5 20.0001 4.5 20L4.51 4.00031ZM18 20.5H18.5V20V9V8.5H18H13.5V4V3.5H13H6H5.5V4V20V20.5H6H18ZM10.5973 15.5244L10.9508 15.8763L11.3036 15.5236L15.19 11.6371L15.8929 12.34L10.94 17.2929L8.10836 14.4613L8.81958 13.755L10.5973 15.5244Z' />
    </svg>
  );
};

export default CertificateIcon;
