import React from 'react';
// Libraries
import { Skeleton } from 'antd';
import ReactPlayer from 'react-player';
// Hooks
import useTranslations from '../../hooks/useTranslations';
import useUploadVideo from '../../hooks/useUploadVideo';
// Components
import HorizontalLoader from '../HorizontalLoader/HorizontalLoader';
// Assets
import edit from '../../assets/edit.svg';
import cloudUploadSelected from '../../assets/profile/cloud-upload-selected.svg';
import './SoftSkillVideo.scss';

const SoftSkillVideo = ({ video }) => {
  const { t } = useTranslations();
  const { loading, uploadAction } = useUploadVideo('VIDEO_10');

  return (
    <div className='flex h-auto pt-0 flex-col items-center mt-4 md:mt-0'>
      <div className='flex flex-col justify-between w-full'>
        {loading ? (
          <div className='p-6'>
            <Skeleton className='w-75 my-2' active paragraph={false} />
          </div>
        ) : (
          <>
            <div className='bg-white dark:bg-dark-bold rounded p-6'>
              <div className='w-full flex justify-between'>
                <span className='text-dark text-base font-bold dark:text-white'>
                  {t('PROFILE_MORE_ABOUT_YOU_VIDEO')}
                </span>
              </div>
              <div className='mt-1'>
                <span className='font-[500] text-grey'>{t('PROFILE_MORE_ABOUT_YOU_VIDEO_SUBTEXT')}</span>
              </div>
              <div className='flex items-center justify-between p-2 mt-3 rounded-lg border'>
                <span className='text-xs font-semibold dark:text-white'>
                  {t('PROFILE_MORE_ABOUT_YOU_PERSONAL_PRESENTATION')}
                </span>
                <div>
                  <div>
                    <label htmlFor='upload-video'>
                      <img className='hover:cursor-pointer' src={video ? edit : cloudUploadSelected} />
                    </label>
                    <input
                      data-testid='input-upload-video'
                      id='upload-video'
                      onChange={uploadAction}
                      type='file'
                      className='hidden'
                    />
                  </div>
                </div>
              </div>
            </div>
            {loading && <HorizontalLoader />}
          </>
        )}
      </div>
      {loading ? (
        <div className='flex justify-center items-center player-size-profile skeleton-heigth'>
          <HorizontalLoader />
        </div>
      ) : (
        video && (
          <div className='mt-4 py-6 bg-white dark:bg-dark-bold rounded'>
            <div className='flex justify-between px-6 mb-4'>
              <span className='text-base text-dark font-bold dark:text-white'>
                {t('PROFILE_MORE_ABOUT_YOU_MY_PRESENTATION')}
              </span>
            </div>
            <ReactPlayer
              url={video.videoUrl}
              className={'presentation-video-container w-fit h-fit rounded-xl px-6'}
              controls
            />
          </div>
        )
      )}
    </div>
  );
};

export default SoftSkillVideo;
