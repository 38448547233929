import React, { useState } from 'react';
import PasswordEye from '../../assets/newSteps/password-eye.svg';
import PasswordEyeOff from '../../assets/newSteps/password-eye-off.svg';
import CheckIcon from '../../assets/newSteps/CheckIcon';
import './LoginInput.scss';

const LoginInput = ({ placeholder, onChange, name, value, error, type = 'text', isPassword, Icon, view }) => {
  const [focus, setFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className='my-2 w-full'>
      <div
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        className={`login-input-container relative rounded flex w-full dark:bg-dark-normal ${
          focus ? 'login-input-focused' : 'login-input-blur '
        } ${error && 'border-danger'}`}>
        {Icon && (
          <Icon selected={focus || value.length} className='input-login-image self-center dark:bg-dark-normal ml-3' />
        )}
        <input
          id={view ? `${view}-${name}-input` : null}
          data-testid={view ? `${view}-${name}-input` : null}
          type={showPassword ? 'text' : type}
          onChange={onChange}
          name={name}
          value={value}
          className='login-input outline-none bg-transparent w-full border-0 relative inline-block font-semibold text-gray-500 dark:bg-dark-normal dark:text-white'
          placeholder={placeholder}
        />
        {isPassword && (
          <img
            id={view ? `${view}-passEye-img` : null}
            src={!showPassword ? PasswordEyeOff : PasswordEye}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            className='input-login-image mr-2 hover:cursor-pointer'
          />
        )}
        {Boolean(value.length && !error) && (
          <div
            id={view ? `${view}-${name}-checkIcon-div` : null}
            className='input-login-image self-center dark:bg-dark-normal mr-2'>
            <CheckIcon />
          </div>
        )}
      </div>
      {error && (
        <p
          data-testid={`error-field-${name}`}
          id={view ? `${view}-${name}-error-message-p` : null}
          className='danger-text m-0 mt-1 p-0'>
          {error}
        </p>
      )}
    </div>
  );
};

export default LoginInput;
