import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getTechnologies, getUserProgress } from '../../api/userService';
import Banner from '../../components/Banner/Banner';
import Container from '../../components/Container/Container';
import EmptyComponent from '../../components/EmptyComponent/EmptyComponent';
import ResultsSection from '../../components/Results/Results';
import Spinner from '../../components/Spinner/Spinner';
import useTranslations from '../../hooks/useTranslations';
import { ActionTypes, useContextState } from '../../redux/contextState';

const ResultsEvaluation = () => {
  const { t } = useTranslations();
  const { contextState, setContextState } = useContextState();
  const history = useHistory();
  const [results, setResults] = useState([]);
  const finishedInvitations = contextState.screenings?.filter(item => item.status === 'FINISHED');
  const [loading, setLoading] = useState(true);

  const fetchUserProgress = async technologies => {
    await getUserProgress()
      .then(progress => {
        const parsedResults = progress?.technologiesAdvances?.map(singleProgress => {
          return {
            ...singleProgress,
            technologyData: technologies?.find(technology => technology?.id === singleProgress?.technologyId),
          };
        });
        setResults(parsedResults);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchResults = async () => {
      if (!contextState.screenings) {
        await getScreeningInvitation().then(res => {
          setContextState({
            type: ActionTypes.SetScreenings,
            value: res,
          });
        });
      }
      if (!contextState.technologies.length) {
        await getTechnologies('all')
          .then(technologies => {
            fetchUserProgress(technologies);
            setContextState({
              type: ActionTypes.SetTechnologies,
              value: technologies,
            });
          })
          .catch(err => {});
      } else {
        await fetchUserProgress(contextState.technologies);
      }
    };

    fetchResults();
  }, []);

  return (
    <div className='relative w-full pb-5'>
      <Banner text={t('RESULTS_SECTION_TITLE')} withSpacing={false} />
      <Container>
        <div className='description-container bg-white dark:bg-dark-bold rounded mx-auto mb-3'>
          {loading && <Spinner show />}
          {!loading && results?.length === 0 && !finishedInvitations?.length && (
            <>
              <div className='py-6 pt-8 px-4 mt-4 bg-[#f4f4f4] dark:bg-dark-bold flex justify-center items-center'>
                <EmptyComponent
                  title={t('NO_CHALLENGES_CORRECTED_YET')}
                  button={{
                    text: t('NO_CHALLENGES_BUTTON_TEXT'),
                    onClick: () => history.push('/home'),
                    className:
                      'mt-3 bg-white border-maya-blue border-2 mx-auto rounded-2xl w-fit px-4 py-1 text-alkemy-blue font-bold',
                  }}
                />
              </div>
            </>
          )}
          {!loading && (Boolean(results?.length) || Boolean(finishedInvitations?.length)) && (
            <ResultsSection results={results} setResults={setResults} />
          )}
        </div>
      </Container>
    </div>
  );
};

export default ResultsEvaluation;
