import React, { useEffect, useState } from 'react';
import { getProfileNews, getRecording } from '../../api/userService';
import { ActionTypes, useContextState } from '../../redux/contextState';
import useTranslations from '../../hooks/useTranslations';
import useUserAdvance from '../../hooks/useUserAdvance';
// Libraries
import * as Sentry from '@sentry/react';
import moment from 'moment';
import es from 'moment/locale/es';
import { Calendar, momentLocalizer } from 'react-big-calendar';
// Components
import EmptyComponent from '../../components/EmptyComponent/EmptyComponent';
import Spinner from '../../components/Spinner/Spinner';
import Container from '../Container/Container';
import Banner from '../Banner/Banner';
import Accordion from '../Accordion/Accordion';
// Assets
import redDotIcon from '../../assets/red-dot.svg';
import arrowIcon from '../../assets/arrow-medium.svg';
import './Calendar.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment, es);

const MyCalendar = () => {
  const { t } = useTranslations();
  const { contextState, setContextState } = useContextState();
  const [active, setActive] = useState(true);
  const [key, setKey] = useState();
  const [loading, setLoading] = useState(false);
  const [meetings, setMeetings] = useState(contextState?.meetings);
  const { loading: profileLoading, profile } = useUserAdvance();

  function toggleAccordion(e) {
    setKey(e);
    setActive(active => (e === key ? !active : true));
  }

  useEffect(() => {
    if (!Object.keys(contextState.profileNews)?.length) {
      setLoading(true);
      getProfileNews()
        .then(res => {
          setContextState({
            type: ActionTypes.SetProfileNews,
            value: res,
          });
        })
        .catch(err => {
          Sentry.captureException(err);
          setContextState({
            type: ActionTypes.SetProfileNews,
            value: [],
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    const meetings = profile?.groups?.flatMap(item => item.group.zoomMeetings).filter(meeting => meeting);
    if (meetings?.length > 0 && !contextState?.meetings?.length) {
      setLoading(true);
      getRecording(meetings)
        .then(res => {
          const groups = {};
          res?.forEach(record => {
            if (groups[record.id]) {
              groups[record.id].push(record);
            } else {
              groups[record.id] = [record];
            }
          });
          setContextState({
            type: ActionTypes.SetMeetings,
            value: Object.values(groups)?.sort(
              (first, second) => new Date(first[0].start_time) - new Date(second[0].start_time),
            ),
          });
          setMeetings(
            Object.values(groups)?.sort(
              (first, second) => new Date(first[0].start_time) - new Date(second[0].start_time),
            ),
          );
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [profile?.groups]);

  const eventStyle = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: '#52c0f7',
      color: 'white',
    };

    return { style: style };
  };

  return (
    <div className='w-full h-full'>
      <Banner text={`${t('CALENDAR_TITLE')}`} icon={contextState?.whitelabel?.images?.BANNER_LOGO} />
      {profileLoading ? (
        <Spinner show />
      ) : (
        <Container>
          <div className='description-container mx-auto'>
            <div className='px-4 bg-white dark:bg-dark-bold rounded py-5' style={{ height: '33.125rem' }}>
              <Calendar
                localizer={localizer}
                defaultView={'week'}
                events={
                  contextState?.profileNews?.nextEvents?.items
                    ?.filter(event => event?.end?.dateTime && event?.start?.dateTime && event?.summary && event?.id)
                    .map(event => ({
                      allDay: false,
                      description: event.description,
                      end: new Date(event.end.dateTime),
                      id: event.id,
                      start: new Date(event.start.dateTime),
                      title: event.summary,
                      location: event.location,
                    })) || []
                }
                min={new Date(2020, 0, 1, 8, 0)} // 8.00 AM
                max={new Date(2020, 0, 1, 23, 0)} // Max will be 6.00 PM!
                eventPropGetter={eventStyle}
                /* components={{
                    month: {
                      event: props => <CalendarInfo {...props} isMonth={true} />,
                    },
                    event: props => <CalendarInfo {...props} />,
                  }} */
                messages={{
                  month: t('CALENDAR_MONTH'),
                  week: t('CALENDAR_WEEK'),
                  day: t('CALENDAR_DAY'),
                  today: t('CALENDAR_TODAY'),
                  agenda: t('CALENDAR_AGENDA'),
                  next: t('CALENDAR_NEXT'),
                  previous: t('CALENDAR_BACK'),
                  noEventsInRange: t('CALENDAR_NO_EVENTS'),
                }}
                showMultiDayTimes
              />
            </div>

            <div className='bg-white dark:bg-dark-bold rounded mt-3 w-full p-4 mb-4'>
              <h2 className=' text-base font-bold py-3 dark:text-white'>👀 {t('CALENDAR_MY_RECORDINGS')}</h2>
              {loading ? (
                <Spinner show={loading} />
              ) : meetings?.length ? (
                <>
                  {meetings?.map((item, index) => (
                    <div
                      key={index}
                      className='cursor-pointer px-2 pt-3 pb-2 w-full flex flex-col my-2 rounded bg-gray bg-white dark:bg-dark-normal'>
                      <div
                        onClick={() => toggleAccordion(index)}
                        className='w-full flex justify-between pr-3 pl-2 pb-2'>
                        <span className={`font-roboto font-bold flex items-center font-small dark:text-white`}>
                          <img src={redDotIcon} className='mr-2' />
                          {item[0].topic}
                        </span>
                        <img src={arrowIcon} className={`normal-arrow ${active && index === key && 'rotate-arrow'}`} />
                      </div>
                      <div>
                        <Accordion open={active && index === key} py='p-0'>
                          <ul className='list-group flex flex-wrap flex-col list-unstyled'>
                            {item?.map((it, index2) => (
                              <li
                                key={index2}
                                className=' flex justify-between items-center flex-col lg:flex-row p-3 bg-white dark:bg-dark-normal'>
                                <div className='flex mb-3 flex-col md:flex-row lg:mb-0'>
                                  <div className='flex flex-col mr-5'>
                                    <span className=' mb-1 font-bold list-font dark:text-white'>
                                      {t('CALENDAR_START_TIME')}
                                    </span>
                                    <span className=' text-muted font-small dark:text-white'>
                                      {moment(it?.start_time).format('YYYY-MM-DD HH:mm')}
                                    </span>
                                  </div>
                                  <div className='flex flex-col mr-5'>
                                    <span className=' mb-1 font-bold list-font dark:text-white'>
                                      {t('CALENDAR_TOPIC')}
                                    </span>
                                    <span className=' text-muted font-small dark:text-white'>{it?.topic}</span>
                                  </div>
                                  <div className='flex flex-col mr-5'>
                                    <span className=' mb-1 font-bold list-font dark:text-white'>
                                      {t('CALENDAR_MEETING_ID')}
                                    </span>
                                    <span className=' text-muted font-small dark:text-white'>{it?.id}</span>
                                  </div>
                                  <div className='flex flex-col mr-5'>
                                    <span className=' mb-1 font-bold list-font dark:text-white'>
                                      {t('PASSWORD_PLACEHOLDER')}:
                                    </span>
                                    <span className=' text-muted font-small dark:text-white'>{it?.password}</span>
                                  </div>
                                </div>
                                <div>
                                  <button
                                    onClick={() => window.open(it?.share_url, '_blank')}
                                    className='border-0 calendar-link text-white py-2 px-3 text-decoration-none font-roboto font-bold font-small'>
                                    {t('CALENDAR_WATCH_RECORDING')}
                                  </button>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </Accordion>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <EmptyComponent
                  className='py-5'
                  title={t('CALENDAR_EMPTY_RECORDINGS_TITLE')}
                  subtitle={t('CALENDAR_EMPTY_RECORDINGS_SUBTITLE')}
                />
              )}
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

export default MyCalendar;
