import React from 'react';
import useTranslations from '../../hooks/useTranslations';

const NewStyleInput = ({ title, required, disabled, validations, register, type, value, error, name, className }) => {
  const { t } = useTranslations();
  return (
    <div className={`flex flex-col w-full ${className} dark:text-white`}>
      {title && (
        <span className='flex items-center mb-1 dark:text-white italic truncate custom-input-label-active text-sm'>
          {title}
          {required && <span className='text-red-600 ml-1'>*</span>}
        </span>
      )}
      <input
        data-testid={`input-${name}`}
        {...register(name, validations)}
        className={`outline-none text-xs border-1 font-semibold shadow-sm w-full rounded-lg py-1 bg-transparent px-2 truncate ${
          error.message ? 'border-red-600' : 'border-gray-300'
        }`}
        type={type}
        name={name}
        disabled={disabled}
      />
      {error.message && <span className='text-red-600 text-xs'>{t(error.message)}</span>}
    </div>
  );
};

export default NewStyleInput;
