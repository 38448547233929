import React from 'react';
import useTranslations from '../../hooks/useTranslations';
import './Switch.css';

const Switch = ({ onChange, classNames, isActive, isBlack }) => {
  const { t } = useTranslations();

  const classes = isActive
    ? isBlack
      ? 'text-alkemy-black hover:text-alkemy-black'
      : 'text-white'
    : isBlack
    ? 'unselected-option-black'
    : 'unselected-option';

  return (
    <>
      <span className={`font-bold font-small font-roboto ${classes}`} data-testid='switch-preacceleration-span'>
        {t('PRE_ACCELERATION')}
      </span>
      <label className={`switch relative inline-block ${classNames && classNames}`}>
        <input type='checkbox' onChange={onChange} data-testid='switch-checkbox-input' />
        <span className='slider absolute cursor-pointer' data-testid='switch-checkbox-input-text' />
      </label>
      <span className={`font-bold font-small font-roboto ${classes}`} data-testid='switch-acceleration-span'>
        {t('ACCELERATION')}
      </span>
    </>
  );
};

export default Switch;
