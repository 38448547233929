import { useState } from 'react';
import Banner from '../../components/Banner/Banner';
import Ticket from '../../components/Ticket/Ticket';
import { queryToObject } from '../../utils/processQueryString';
import { useEffect } from 'react';
import {
  getAnnoucements,
  getProfileChallenges,
  postAlkymerEnrollment,
  getTechnologies,
  getScreenings,
  getMyScreenings,
} from '../../api/userService';
import { ActionTypes, useContextState } from '../../redux/contextState';
import moment from 'moment';
import { Button, notification, Modal, Tooltip } from 'antd';
import isComplete from '../../utils/steps/isComplete';
import { ticketClass } from '../../utils/ticketClass';
import { useHistory } from 'react-router-dom';
import useTranslations from '../../hooks/useTranslations';
import Spinner from '../../components/Spinner/Spinner';
import Container from '../../components/Container/Container';
import { Window } from '../../helpers/window';
import { handleRedirect } from '../../utils/handleRedirect';
import './inscription.css';
import { TrackEvent } from '../../utils/segmentUtils';
import ArrowSmall from '../../assets/profile/ArrowSmall';
import ModalCustom from '../../components/Modal/Modal';

const { confirm } = Modal;

const Challenges = () => {
  const { t } = useTranslations();
  const history = useHistory();
  const { contextState, setContextState } = useContextState();
  const [challengeByUrl, setChallengeByUrl] = useState(undefined);
  const [detailChallenge1, setDetailChallenge1] = useState(false);
  const [detailChallenge2, setDetailChallenge2] = useState(false);
  const [detailChallenge3, setDetailChallenge3] = useState(false);
  const [detailChallenge4, setDetailChallenge4] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const welcomePage = contextState?.whitelabel?.routes?.includes('WELCOME');
  const [loading, setLoading] = useState(true);
  const lastChallenge = Object.values(contextState?.profileChallenges)
    ?.flat()
    ?.filter(challenge => challenge?.technology?.evaluationFormat === 'CHALLENGE')
    ?.sort((a, b) => new Date(b.datetime) - new Date(a.datetime))[0];

  useEffect(async () => {
    if (welcomePage && contextState?.whitelabel?.behaviors?.HIDE_INSCRIPTION_OPTION) {
      history.push('welcome');
    }

    await getScreenings('TECHNICAL').then(async res => {
      const technologies = await getTechnologies();
      const screeningWithTechnology = res.map(item => ({
        ...item,
        technology: technologies.find(tech => tech.id === item.technologyId),
      }));

      setContextState({
        type: ActionTypes.SetAnnouncements,
        value: [...screeningWithTechnology],
      });
    });
    if (queryToObject(history.location.search)?.challenge && res?.length) {
      if (
        res?.filter(
          item =>
            item?.technology?.id === Number(queryToObject(history.location.search)?.challenge) &&
            item?.technology?.evaluationFormat !== 'SCREENING',
        ).length
      ) {
        applyChallenge(Number(queryToObject(history.location.search)?.challenge));
      } else {
        notification.open({
          message: t('INSCRIPTION_EMPTY_ANNOUNCEMENTS_FOR_TECHNOLOGY'),
        });
      }
    }
    if (!contextState.profileChallenges.length > 0) {
      await getProfileChallenges().then(res => {
        setContextState({
          type: ActionTypes.SetProfileChallenges,
          value: { ...res, challenges: res?.challenges?.filter(res => res?.reviewedAt) },
        });
      });
    }

    if (history.location.search && !challengeByUrl) {
      setChallengeByUrl({
        ...queryToObject(history.location.search),
        ...(queryToObject(history.location.search)?.challenge && {
          challenge: queryToObject(history.location.search)?.challenge,
        }),
        email: decodeURIComponent(queryToObject(history.location.search)?.email),
      });
    }
    if (queryToObject(history.location.search)?.challenge && contextState?.announcements?.length) {
      if (
        contextState?.announcements?.filter(item => item?.technology?.id === Number(challengeByUrl?.challenge)).length
      ) {
        applyChallenge(Number(challengeByUrl?.challenge));
      } else {
        notification.open({
          message: t('INSCRIPTION_EMPTY_ANNOUNCEMENTS_FOR_TECHNOLOGY'),
        });
      }
    }
    setLoading(false);
  }, []);
  const applyChallenge = challenge => {
    if (!lastChallenge || lastChallenge.reviewedAt) {
      postAlkymerEnrollment({ technologyId: challenge }).then(res => {
        const prev = contextState.profileChallenges;
        if (!Object.keys(res).length) {
          notification.open({
            message: t('INSCRIPTION_ERROR'),
            icon: <i className='fas fa-minus' style={{ color: '#52C0F7' }} />,
          });
        } else {
          setContextState({
            type: ActionTypes.SetProfileChallenges,
            value: { ...prev, challenges: [...prev.challenges, res] },
          });
          history.push(`/challenges/${res.id}`);
        }
      });
    } else {
      if (lastChallenge?.technology?.id === challenge) {
        history.push(`/challenges/${lastChallenge?.id}`);
      }
      notification.open({
        message:
          lastChallenge?.technology?.id === challenge
            ? t('INSCRIPTION_ALREADY_INSCRIPTED')
            : `${t('INSCRIPTION_TO_INSCRIBE')} ${
                contextState?.announcements?.map(item => item?.technology).find(item => item?.id === challenge)
                  ?.displayName
              }, ${t('INSCRIPTION_UNSUBSCRIBE_TO')} ${lastChallenge?.technology?.displayName} ${t(
                'INSCRIPTION_CHALLENGE_VIEW',
              )}`,
        icon: <i className='fas fa-times' style={{ color: '#52C0F7' }} />,
        btn:
          lastChallenge?.technology?.id === challenge ? (
            <Button type='primary' size='small' onClick={() => history.push(`/challenges/${lastChallenge?.id}`)}>
              {t('GO_TO_CHALLENGE')}
            </Button>
          ) : null,
      });
    }
  };

  const applyScreenings = async challenge => {
    TrackEvent('screening-start', { userId: contextState.profile?.id });
    handleRedirect(
      `${process.env.RAZZLE_RUNTIME_SCREENINGS}?technologyId=${challenge?.technologyId}&levelId=${challenge?.levelId}&userId=${contextState.profile.id}&token=`,
      t,
    );
  };

  return (
    <div className='relative w-full pb-5'>
      {loading ? (
        <Spinner show />
      ) : (
        <>
          <Banner text={t('INSCRIPTION_LOWER')} svg='✅' />
          <Container>
            <div className='description-container rounded bg-white dark:bg-dark-bold shadow-lg m-auto p-4 pb-6'>
              <div className='flex flex-col items-center pb-6'>
                <span className=' title-challenge-3 mt-1 dark:text-white text-center font-bold lg:text-left pb-4'>
                  {t('CHALLENGES_WELCOME_TITLE')}
                </span>
                <div className='flex flex-col'>
                  <span className='text-alkemy mt-1 dark:text-white text-base text-aling-left lg:text-left pb-4'>
                    {t('CHALLENGES_WELCOME_SUBTITLE')}
                  </span>
                  <ModalCustom
                    show={modalDetail}
                    setShow={setModalDetail}
                    closeOnClickOutside={true}
                    contentContainerClassName='content-className pt-0'
                    content={
                      <>
                        <span className=' title-challenge-3 mt-1 dark:text-white text-center font-bold lg:text-left pb-6 color-text-title'>
                          {t('CHALLENGES_FAQS_ALKEMY')}
                        </span>
                        <div className='flex flex-row pt-4'>
                          <span
                            className='text-alkemy mt-1 dark:text-white text-base text-aling-left lg:text-left pb-2 cursor-pointer'
                            onClick={() => setDetailChallenge1(!detailChallenge1)}>
                            <b>{t('CHALLENGES_FAQS_ALKEMY_MODAL_TITLE1')}</b>
                          </span>
                          <ArrowSmall
                            className={`ml-3 stroke-current dropdown-arrow mt-3 ${
                              detailChallenge1 && 'dropdown-arrow-open'
                            }`}
                            width='10'
                          />
                        </div>
                        <div className='text-aling-left'>
                          {detailChallenge1 && (
                            <>
                              <span className='text-alkemy mt-1 dark:text-white text-base pl-2 text-aling-left lg:text-left'>
                                {t('CHALLENGES_FAQS_ALKEMY_MODAL_SUBTITLE11')}
                              </span>
                              <span className='text-alkemy mt-1 dark:text-white text-base pl-2 text-aling-left lg:text-left'>
                                {t('CHALLENGES_FAQS_ALKEMY_MODAL_SUBTITLE12')}
                              </span>
                              <ul>
                                <li>
                                  <span className='text-alkemy mt-1 dark:text-white text-base pl-4 text-aling-left lg:text-left'>
                                    {t('CHALLENGES_FAQS_ALKEMY_MODAL_SUBTITLE13')}
                                  </span>
                                </li>
                                <li>
                                  <span className='text-alkemy mt-1 dark:text-white text-base pl-4 text-aling-left lg:text-left'>
                                    {t('CHALLENGES_FAQS_ALKEMY_MODAL_SUBTITLE14')}
                                  </span>
                                </li>
                              </ul>
                            </>
                          )}
                        </div>
                        <div className='flex flex-row pt-4'>
                          <span
                            className='text-alkemy mt-1 dark:text-white text-base text-aling-left lg:text-left pb-2 cursor-pointer'
                            onClick={() => setDetailChallenge2(!detailChallenge2)}>
                            <b>{t('CHALLENGES_FAQS_ALKEMY_MODAL_TITLE2')}</b>
                          </span>
                          <ArrowSmall
                            className={`ml-3 stroke-current dropdown-arrow mt-3 ${
                              detailChallenge2 && 'dropdown-arrow-open'
                            }`}
                            width='10'
                          />
                        </div>
                        <div className='text-aling-left'>
                          {detailChallenge2 && (
                            <>
                              <span className='text-alkemy mt-1 dark:text-white text-base pl-2 text-aling-left lg:text-left'>
                                {t('CHALLENGES_FAQS_ALKEMY_MODAL_SUBTITLE21')}
                              </span>
                              <ul>
                                <li>
                                  <span className='text-alkemy mt-1 dark:text-white text-base pl-4 text-aling-left lg:text-left'>
                                    {t('CHALLENGES_FAQS_ALKEMY_MODAL_SUBTITLE22')}
                                  </span>
                                </li>
                                <li>
                                  <span className='text-alkemy mt-1 dark:text-white text-base pl-4 text-aling-left lg:text-left'>
                                    {t('CHALLENGES_FAQS_ALKEMY_MODAL_SUBTITLE23')}
                                  </span>
                                </li>
                                <li>
                                  <span className='text-alkemy mt-1 dark:text-white text-base pl-4 text-aling-left lg:text-left'>
                                    {t('CHALLENGES_FAQS_ALKEMY_MODAL_SUBTITLE24')}
                                  </span>
                                </li>
                                <li>
                                  <span className='text-alkemy mt-1 dark:text-white text-base pl-4 text-aling-left lg:text-left'>
                                    {t('CHALLENGES_FAQS_ALKEMY_MODAL_SUBTITLE25')}
                                  </span>
                                </li>
                              </ul>
                            </>
                          )}
                        </div>
                        <div className='flex flex-row pt-4'>
                          <span
                            className='text-alkemy mt-1 dark:text-white text-base text-aling-left lg:text-left pb-2 cursor-pointer'
                            onClick={() => setDetailChallenge3(!detailChallenge3)}>
                            <b>{t('CHALLENGES_FAQS_ALKEMY_MODAL_TITLE3')}</b>
                          </span>
                          <ArrowSmall
                            className={`ml-3 stroke-current dropdown-arrow mt-3 ${
                              detailChallenge3 && 'dropdown-arrow-open'
                            }`}
                            width='10'
                          />
                        </div>
                        <div className='text-aling-left'>
                          {detailChallenge3 && (
                            <>
                              <span className='text-alkemy mt-1 dark:text-white text-base pl-2 text-aling-left lg:text-left'>
                                {t('CHALLENGES_FAQS_ALKEMY_MODAL_SUBTITLE31')}
                              </span>
                            </>
                          )}
                        </div>
                        <div className='flex flex-row pt-4'>
                          <span
                            className='text-alkemy mt-1 dark:text-white text-base text-aling-left lg:text-left pb-2 cursor-pointer'
                            onClick={() => setDetailChallenge4(!detailChallenge4)}>
                            <b>{t('CHALLENGES_FAQS_ALKEMY_MODAL_TITLE4')}</b>
                          </span>
                          <ArrowSmall
                            className={`ml-3 stroke-current dropdown-arrow mt-3 ${
                              detailChallenge4 && 'dropdown-arrow-open'
                            }`}
                            width='10'
                          />
                        </div>
                        <div className='text-aling-left'>
                          {detailChallenge4 && (
                            <>
                              <span className='text-alkemy mt-1 dark:text-white text-base pl-2 text-aling-left lg:text-left'>
                                {t('CHALLENGES_FAQS_ALKEMY_MODAL_SUBTITLE41')}
                              </span>
                            </>
                          )}
                        </div>
                      </>
                    }
                  />
                  <span
                    className='text-alkemy mt-1 dark:text-white text-base text-aling-left lg:text-left pb-2 cursor-pointer'
                    onClick={() => setModalDetail(!modalDetail)}>
                    <b>{t('CHALLENGES_WELCOME_FAQS')}</b>
                  </span>
                </div>
              </div>
              <div className='flex flex-col items-center'>
                <span className=' font-weight-500 text-alkemy-black-bold title-challenge-3 mt-1 dark:text-white text-center text-base lg:text-left'>
                  {t('CHALLENGES_SUBTITLE')}
                </span>
              </div>
              <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-12 mt-2'>
                {contextState?.announcements
                  ?.sort((a, b) => {
                    if (a?.technology?.order < b?.technology?.order) return 1;
                    if (a?.technology?.order > b?.technology?.order) return -1;
                    if (a?.technology?.displayName > b?.technology?.displayName) {
                      return 1;
                    }
                    return -1;
                  })
                  .filter(announcement => announcement?.technology?.isCertificable)
                  .map((challenge, index) => (
                    <div className='p-0 my-2' key={index}>
                      <div
                        className='h-full bg-white dark:bg-dark-normal pt-3 px-3 pb-1 challenges-cards flex flex-col border-[#828282] border-2 rounded-lg dark:border-gray-500'
                        key={index}>
                        <div className=' flex flex-row justify-between'>
                          <span className='font-semibold text-alkemy-black dark:text-white text-base text-center'>
                            {`
                              ${t('MY_CHALLENGES_CARD_TITLE')} ${
                              challenge?.technology?.displayName ?? challenge?.screeningsName
                            }`}
                          </span>
                        </div>
                        <div className='pt-4 h-full relative flex justify-center items-end'>
                          <img className='h-[2.813rem] w-auto relative' src={challenge?.technology?.imageUrl} />
                        </div>
                        <div className='w-full flex justify-center mt-4 mb-2'>
                          {challenge?.levelId ? (
                            isComplete(contextState.profile) >= 2 ? (
                              <button
                                className='button-apply-challenge py-2 px-5'
                                onClick={() => applyScreenings(challenge)}>
                                <span className='button-apply-challenge-text font-roboto font-bold text-white text-decoration-none'>
                                  {t('WANT_APPLY')}!
                                </span>
                              </button>
                            ) : (
                              <button
                                className='button-apply-challenge py-2 px-5'
                                onClick={() =>
                                  confirm({
                                    title: t('REMINDER_COMPLETE_PROFILE'),
                                    okText: t('COMPLETE_NOW'),
                                    cancelText: t('REMIND_ME_LATER'),
                                    onOk() {
                                      setContextState({
                                        type: ActionTypes.SetEditable,
                                        value: true,
                                      });
                                      history.push('/profile');
                                    },
                                    onCancel() {
                                      applyScreenings(challenge);
                                    },
                                  })
                                }>
                                <span className='button-apply-challenge-text font-roboto font-bold text-white'>
                                  {t('WANT_APPLY')}!
                                </span>
                              </button>
                            )
                          ) : (
                            <button
                              className='button-apply-challenge py-2 px-5'
                              onClick={() =>
                                isComplete(contextState.profile) >= 2
                                  ? applyChallenge(challenge.technology.id)
                                  : confirm({
                                      title: t('REMINDER_COMPLETE_PROFILE'),
                                      okText: t('COMPLETE_NOW'),
                                      cancelText: t('REMIND_ME_LATER'),
                                      onOk() {
                                        setContextState({
                                          type: ActionTypes.SetEditable,
                                          value: true,
                                        });
                                        history.push('/profile');
                                      },
                                      onCancel() {
                                        applyChallenge(challenge.technology.id);
                                      },
                                    })
                              }>
                              <span className='button-apply-challenge-text font-roboto font-bold text-white'>
                                {t('WANT_APPLY')}!
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Container>
        </>
      )}
    </div>
  );
};

export default Challenges;
