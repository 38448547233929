import { useEffect } from 'react';
import { Window } from '../helpers/window';
import { ActionTypes, useContextState } from '../redux/contextState';

function useTranslations() {
  const { contextState, setContextState } = useContextState();
  const { texts } = contextState.whitelabel ?? {};
  const languageKey = contextState.ux.language;

  const changeLanguageKey = key => {
    setContextState({
      type: ActionTypes.SetUxLanguage,
      value: key,
    });
    Window()?.localStorage.setItem('language', key);
  };

  const t = (name, defaultEmpty = false) => {
    const texts = contextState.whitelabel?.texts;
    const language = contextState.ux.language;
    const values = name?.props?.id ?? defaultEmpty ? '' : name;
    if (texts && texts[language] && texts[language][name]) {
      return texts[language][name];
    }
    if (texts && texts?.es && texts?.es[name]) {
      return texts?.es[name];
    }
    return values;
  };

  useEffect(() => {
    changeLanguageKey(languageKey);
  }, []);

  return {
    key: languageKey,
    texts: texts ? texts[languageKey] : null,
    changeLanguageKey,
    t,
  };
}

export default useTranslations;
