import useTranslations from '../../hooks/useTranslations';
import notFound404 from '../../assets/404.svg';
import './404.scss';

const Error404 = ({ text = t('404_PAGE_NOT_FOUND') }) => {
  const { t } = useTranslations();
  return (
    <div className='error-page-container flex flex-col py-5 mx-auto'>
      <h2 className='font-bold error-page-text text-center'>{text}</h2>
      <div className='text-center'>
        <a href='./' className=' mr-2'>
          <button className='bg-maya-blue border-0 text-white font-bold  p-2 px-3 rounded-full'>{t('404_BACK')}</button>
        </a>
        <a href='./' className='button primary mr-2'>
          <button className='bg-maya-blue border-0 text-white font-bold  p-2 px-3 rounded-full'>
            {t('404_GO_TO_START')}
          </button>
        </a>
      </div>
      <img src={notFound404} alt={t('ERROR')} className='w-full mt-2 mw-100' />
    </div>
  );
};

export default Error404;
