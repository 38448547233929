import { useState, useEffect } from 'react';
import DatePicker from '../../../components/DatePicker/DatePicker';
import alkemyLogo from '../../../assets/newSteps/logoAlkemy.svg';
import es from 'date-fns/locale/es';
import moment from 'moment';
import { saveProfile } from '../../../api/userService';
import { ActionTypes, useContextState } from '../../../redux/contextState';
import { registerLocale } from 'react-datepicker';
import useTranslations from '../../../hooks/useTranslations';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import './step1.css';
registerLocale('es', es);

const Step1 = ({ nextStep, toggleLock }) => {
  const { t } = useTranslations();
  const invitationCompany = JSON.parse(window?.localStorage?.getItem('prevUrlLogin'))?.invitationId;
  const [date, setDate] = useState({
    day: null,
    month: null,
    year: null,
  });
  const history = useHistory();
  const { contextState, setContextState } = useContextState();
  const changeDate = async day => {
    if (
      !(
        day === new Date(contextState.profile.birthdate).getDate() + 1 &&
        date.month === new Date(contextState.profile.birthdate).getMonth() + 1 &&
        date.year === new Date(contextState.profile.birthdate).getFullYear()
      )
    ) {
      const birthdateValue = moment(
        `${date.year}-${date.month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
      ).format('YYYY-MM-DD');
      if (
        birthdateValue <=
        moment(
          new Date(
            new Date().getFullYear() - (invitationCompany ? 17 : 18),
            new Date().getMonth(),
            new Date().getDate(),
          ),
        ).format('YYYY-MM-DD')
      ) {
        const dataToUpdate = { birthdate: birthdateValue };
        await saveProfile(dataToUpdate).then(() => {
          history.push('steps?page=2');
          setContextState({
            type: ActionTypes.SetProfile,
            value: dataToUpdate,
          });
        });
      } else {
        notification.open({
          message: invitationCompany ? t('OVER_YEARS_OLD_18').replace('18', '17') : t('OVER_YEARS_OLD_18'),
          icon: <i className='fas fa-times' style={{ color: '#52C0F7' }} />,
        });
      }
    } else {
      nextStep();
    }
  };

  useEffect(() => {
    if (contextState.profile.birthdate) {
      const birthdate = moment(`${contextState.profile.birthdate}`);
      setDate({
        day: Number(birthdate.format('DD')),
        month: Number(birthdate.format('MM')),
        year: Number(birthdate.format('YYYY')),
      });
    }
  }, [contextState.profile.birthdate]);

  useEffect(() => {
    if (date.day && date.month && date.year) {
      const dateValid = moment(
        `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`,
      ).isValid();
      if (dateValid) {
        changeDate(date.day);
      } else {
        setDate({
          ...date,
          day: null,
        });
      }
    }
  }, [date]);

  return (
    <div className='flex flex-col p-8 pt-11 min-vh-100 form-step-container'>
      <img src={alkemyLogo} className='step-logo-alkemy' alt='logo-alkemy' data-testid='step1-logo-img' />
      <div className='flex  flex-row step1-text mt-11'>
        <span>👋&nbsp;</span>
        <span className=' font-bold dark:text-white' data-testid='step1-welcome-message-span'>
          ¡{t('STEP_HELLO')} {contextState?.profile?.firstName ? ` ${contextState?.profile?.firstName}!` : '!'}
        </span>
      </div>
      <span className=' text-muted mt-3 step1-span' data-testid='step1-finish-message-span'>
        {t('STEP_MESSAGE_FINISH')}
      </span>
      <div className='mt-5'>
        <span className=' font-bold step1-text dark:text-white' data-testid='step1-birthdate-message-span'>
          {t('STEP_MESSAGE_BIRTHDATE')}
        </span>
        <div className='mt-3'>
          <DatePicker date={date} setDate={setDate} changeDate={changeDate} toggleLock={toggleLock} />
        </div>
      </div>
    </div>
  );
};

export default Step1;
