import React from 'react';
import { Controller } from 'react-hook-form';
import Creatable from 'react-select/creatable';
import useTranslations from '../../hooks/useTranslations';

const CreatableSelect = ({ options, control, name, className, title, register, validations, error }) => {
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      height: '1.625rem',
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: '0px',
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: '600',
      fontSize: '0.75rem',
      padding: '0.375rem 0.25rem',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      color: 'black',
      opacity: '1',
      padding: '0px .5rem',
      fontSize: '0.75rem',
      fontWeight: '600',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: '0.75rem',
      fontWeight: '600',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: '0px',
      background: 'transparent',
      display: 'flex',
      borderRadius: '.5rem',
      alignItems: 'center',
      height: '100%',
      borderColor: error ? 'red' : '#D1D5DB',
      '&:hover': {
        borderColor: error ? 'red' : '#D1D5DB',
      },
      boxShadow: 'none',
    }),
    indicatorContainer: (provided, state) => ({
      ...provided,
      padding: '0px 0.313rem 0.25rem',
      color: '#58c1f5',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#58c1f5',
      padding: '0px 0.25rem',
      '&:hover': {
        color: '#58c1f5',
      },
    }),
  };

  const { t } = useTranslations();
  return (
    <div className={`w-full relative text-sm bg-white shadow-sm dark:bg-transparent dark:text-white ${className}`}>
      <div className='mb-1'>
        <span className='text-sm italic whitespace-nowrap'>{title}</span>
      </div>
      <Controller
        control={control}
        name={name}
        {...register(name, validations ?? {})}
        render={({ field }) => (
          <Creatable
            inputId={`id-${name}`}
            placeholder=''
            noOptionsMessage={() => t('NO_OPTIONS')}
            styles={customStyles}
            formatCreateLabel={value => `${t('CREATE')} ${value}`}
            className={`text-xs h-[1.625rem] ${error && 'border-error'}`}
            options={options}
            {...field}
          />
        )}
      />
      {error && <span className='text-xs text-red-600'>{t(error.message)}</span>}
    </div>
  );
};

export default CreatableSelect;
