import Banner from '../../components/Banner/Banner';
import { ActionTypes, useContextState } from '../../redux/contextState';
import welcomeSvg from '../../assets/welcome/welcome.svg';
import { Link, useHistory } from 'react-router-dom';
import useTranslations from '../../hooks/useTranslations';
import { useEffect, useState } from 'react';
import { queryToObject } from '../../utils/processQueryString';
import { postAvailability } from '../../api/userService';
import './available.css';
import Container from '../../components/Container/Container';

const Available = () => {
  const [available, setAvailable] = useState(false);
  const { contextState, setContextState } = useContextState();
  const { t } = useTranslations();
  const history = useHistory();

  useEffect(async () => {
    setContextState({
      type: ActionTypes.SetLoading,
      value: true,
    });
    if (queryToObject(history.location.search)?.available === 'false') {
      setAvailable(false);
    } else {
      setAvailable(true);
    }
    setContextState({
      type: ActionTypes.SetLoading,
      value: false,
    });
    await postAvailability({ available: queryToObject(history.location.search)?.available !== 'false' });
  }, []);
  return (
    <div className='relative w-full'>
      <Banner
        text={`¡${t('STEP_HELLO')} ${
          typeof contextState.profile?.firstName !== 'undefined' ? contextState.profile?.firstName : ''
        }!`}
        svg='👋'
        icon={contextState?.whitelabel?.images?.BANNER_LOGO}
      />
      <Container>
        <div className='description-container bg-white rounded m-auto pb-4 pl-3 lg:pl-5 flex flex-col items-center py-5'>
          <h2 className='events-title font-bold lg:mt-3 m-0'>¡{available ? t('PERFECT') : t('WHAT_A_PITY')}!</h2>
          <h2 className='events-title font-bold text-center mt-2 m-0'>
            {available ? t('AVAILABLE_TITLE') : t('NO_AVAILABLE_TITLE')}
          </h2>
          <span className='available-subtitle  text-center  mt-3 '>
            {available ? (
              t('AVAILABLE_SUBTITLE')
            ) : (
              <>
                {t('NO_AVAILABLE_SUBTITLE')}{' '}
                <a href='mailto:contacto@alkemy.org' className='text-maya-blue hiperlink-email-available'>
                  contacto@alkemy.org.
                </a>
              </>
            )}
          </span>
          <img src={welcomeSvg} className='mt-3' />
          {available && (
            <>
              <span className='available-subtitle  mt-4 text-center m-0'>{t('AVAILABLE_SUBTITLE_1')}</span>
              <button type='button' className='welcome-button border-0 mt-4'>
                <Link to='/profile' className='welcome-button-text flex items-center font-bold'>
                  😎 {t('AVAILABLE_BUTTON')}
                </Link>
              </button>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Available;
