import { Window } from '../helpers/window';

export const auth0Client = () => {
  if (Window() !== null) {
    const auth0 = require('auth0-js');

    return new auth0.WebAuth({
      clientID: process.env.RAZZLE_RUNTIME_AUTH_ZERO_CLIENT_ID,
      domain: process.env.RAZZLE_RUNTIME_AUTH_ZERO_DOMAIN,
      redirectUri: `${window.location.origin}/login`,
      audience: process.env.RAZZLE_RUNTIME_AUTH_ZERO_AUDIENCE,
      scope: 'read:current_user',
      responseType: 'token id_token',
    });
  }
  return {
    checkSession: () => {},
  };
};
