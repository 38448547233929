import { alkymetricsClient } from './alkymetricsClient';
import { getSearchParams } from '../utils/api/searchParams';

export const getJobs = async filters => {
  const params = getSearchParams(filters);
  const { data } = await alkymetricsClient.get(`/jobs?${params.toString()}`);
  return data;
};

export const getJob = async id => {
  const { data } = await alkymetricsClient.get(`/jobs/${id}`);
  return data;
};

export const getJobsFiltersData = async () => {
  try {
    const { data } = await alkymetricsClient.get(`/jobs/filters`);
    return data;
  } catch (error) {
    return [];
  }
};
