import React, { useEffect, useState } from 'react';
import ModalCustom from '../Modal/Modal';
import { notification, Spin, Steps } from 'antd';
import { getPrefilterQuestionTest, sendSubmission } from '../../api/userService';
import Test from './Test';
import './PrefilterTest.scss';
import { CheckCircleOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { formatLocalizedDate } from '../../utils/formatDate';
import useTranslations from '../../hooks/useTranslations';

export default function PrefilterTest({ testId, showModal, setShowModal, invitationId, company, handleResults }) {
  const [test, setTest] = useState();
  const [showTest, setShowTest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [stepStatus, setStepStatus] = useState([test ?? []]?.map(() => 'incomplete'));
  const [submission, setSubmission] = useState({
    prefilterTestInstanceId: '',
    testGroupId: '',
    invitationId: '',
    tests: [],
  });
  const [sendingSubmission, setSendingSubmission] = useState(false);
  const [result, setResult] = useState();
  const { t } = useTranslations();

  const handleStartTest = async () => {
    setShowModal(false);
    setShowTest(true);
    setIsLoading(true);
    try {
      const instanceRecovered = await getPrefilterQuestionTest(testId, invitationId);
      setSubmission({
        ...submission,
        prefilterTestInstanceId: instanceRecovered.screeningInstanceId,
        testGroupId: instanceRecovered.testGroupId,
        invitationId: invitationId,
      });
      setTest(instanceRecovered.tests);
    } catch (e) {
      if (e?.response?.status === 404) {
        notification.open({ message: t('ERROR_INCOMPLETE_TEST'), type: 'warning' });
      } else {
        notification.open({ message: `${t('ERROR_TITLE')}  ${t('ERROR_SUBTITLE')}`, type: 'error' });
      }
      setShowTest(false);
    }
    setIsLoading(false);
  };

  const handleSubmitResponse = (data, move) => {
    const copySubmission = submission;
    const oldResponseIndex = copySubmission.tests.findIndex(test => test.id === data.id);
    if (oldResponseIndex >= 0) {
      copySubmission.tests[oldResponseIndex] = data;
    } else {
      copySubmission.tests.push(data);
    }
    setSubmission(copySubmission);
    handleMoveStep(data, step + move);
    if (move === 0) handleSendSubmission();
  };

  const handleMoveStep = (currentQuestion, index) => {
    const newStatus = [...stepStatus];
    if (currentQuestion.response && currentQuestion.response !== '' && currentQuestion.id !== '') {
      newStatus[step] = 'finish';
    } else {
      newStatus[step] = 'process';
    }
    setStep(index);
    setStepStatus(newStatus);
  };

  const handleCloseTest = () => {
    setTest(null);
    setSubmission({ testId: '', tests: [] });
    setShowTest(false);
    setStepStatus([]);
    setStep(0);
  };

  const handleSendSubmission = async () => {
    const invalidFields =
      submission.tests.some(t => !t.response || t.response === '') || submission.tests.length < test.length;
    if (invalidFields) {
      notification.open({
        message: t('PREFILTER_INVALID_INPUTS'),
        type: 'error',
      });
    } else {
      try {
        setSendingSubmission(true);
        const submissionResponse = await sendSubmission(submission);
        setResult(submissionResponse);
        if (submissionResponse?.delayResponseUntil) {
          const today = new Date();
          const responseDate = new Date(submissionResponse.delayResponseUntil);
          handleResults(
            <>
              <div className='d-flex align-items-center justify-content-center my-4'>
                {today.getDate() === responseDate.getDate() && today.getMonth() === responseDate.getMonth() ? (
                  <>
                    <h2 className='my-2'>{t('NEGATIVE_RESPONSE_MESSAGE_TODAY')}.</h2>
                  </>
                ) : (
                  <>
                    <h2 className='my-2'>{t('NEGATIVE_RESPONSE_MESSAGE')}</h2>
                    <span className='my-2'>
                      <b>{formatLocalizedDate(responseDate)}. </b>
                    </span>
                  </>
                )}
              </div>
            </>,
          );
        } else {
          handleResults('ok');
        }
        setSendingSubmission(false);
        setShowTest(false);
        setShowModal(false);
      } catch (e) {
        notification.open({ message: t('ERROR_SENDING_TEST'), type: 'error' });
        setShowTest(false);
        setShowModal(false);
      }
    }
  };

  const { Step } = Steps;

  return (
    <div>
      <ModalCustom
        setShow={setShowModal}
        show={showModal}
        onOk={handleStartTest}
        onOkText={t('PREFILTER_CONTINUE')}
        title={`${company ? company.displayName : 'Alkemy'} ${t('PREFILTER_COMPANY_TITLE')}`}
        subtitle={`${company?.displayName} ${t('PREFILTER_COMPANY_DESCRIPTION')}`}
      />

      <ModalCustom
        show={showTest}
        setShow={handleCloseTest}
        title={t('PREFILTER_QUESTIONS')}
        closeOnClickOutside={false}
        isLoading={sendingSubmission}
        content={
          isLoading || sendingSubmission ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 64, margin: '2em', color: 'maya-blue' }} spin />} />
          ) : (
            <>
              <Steps className='steps-prefilter-questions mt-5' current={step}>
                {test?.map((test, index) => (
                  <Step
                    key={test?._id}
                    status={stepStatus[index]}
                    icon={
                      stepStatus[index] === 'finish' ? (
                        <CheckCircleOutlined />
                      ) : stepStatus[index] === 'process' ? (
                        <WarningOutlined fill='red' />
                      ) : null
                    }
                  />
                ))}
              </Steps>
              {test && (
                <Test
                  question={test[step]}
                  key={test[step]?.id}
                  submission={submission?.tests[step]}
                  isLast={step === test.length - 1}
                  isFirst={step === 0}
                  onSubmit={handleSubmitResponse}
                  t={t}
                />
              )}
            </>
          )
        }
      />
    </div>
  );
}
