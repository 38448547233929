import { useState } from 'react';
import { updateAdvanceVisibility } from '../../api/userService';
import Content from './Content/Content';
import './Content/Content.scss';
import './Collapse.scss';
import DropdownButton from './Dropdown/DropdownButton';
import useTranslations from '../../hooks/useTranslations';
import moment from 'moment';
import { oneDecimal } from '../../utils/numbersUtils';
import { notification, Tooltip } from 'antd';
import { useEffect } from 'react';
import { addTruncate } from '../../utils/addTruncate';
import ArrowSmall from '../../assets/profile/ArrowSmall';
import { technologyTypes } from './TechnologyTypes';

const Collapse = ({ collapsedSelected, setCollapsedSelected, index, options, type, setResults, results }) => {
  const { t } = useTranslations();
  const maxEngagement = t('RESULTS_MAX_ENGAGEMENT');
  const [dropdownOptions, setSelectedTechnology] = useState([
    {
      id: 1,
      name: t('RESULTS_PRIMARY_TECHNOLOGY'),
      isSelected: options?.profileOptions?.isPrimary,
    },
  ]);

  const [visibilityOptions, setVisibilityOptions] = useState([
    { id: 1, name: t('RESULTS_DISPLAY_TECHNOLOGY'), isSelected: options?.profileOptions?.isVisible, value: true },
    { id: 2, name: t('RESULTS_HIDE_TECNOLOGY'), isSelected: options?.profileOptions?.isVisible, value: false },
  ]);

  useEffect(() => {
    setSelectedTechnology(state =>
      state.map(item => {
        return { ...item, isSelected: options?.profileOptions?.isPrimary };
      }),
    );
  }, [options]);

  const handleTechnologyPriority = selectedOption => {
    const currentState = dropdownOptions.find(option => option.id === selectedOption.id)?.isSelected;
    const newState = {
      id: options?.profileOptions?.technologyId,
      isPrimary: !currentState,
      isVisible: true,
    };

    updateAdvanceVisibility([newState]).then(res => {
      const state = [...results];
      setResults(
        state.map(item => {
          if (!currentState) {
            item.isPrimary = false;
          }
          if (item.technologyData.id === options?.profileOptions?.technologyId) {
            return {
              ...item,
              isPrimary: !currentState,
              isVisible: true,
            };
          }
          return item;
        }),
      );
      notification.open({
        message: t('RESULTS_PRIORITY_HAS_BEEN_ESTABLISHED').replace(
          '{priority}',
          currentState ? t('RESULTS_PRIORITY_SECONDARY_WORD') : t('RESULTS_PRIORITY_PRIMARY_WORD'),
        ),
      });
    });
  };

  const handleTechnologyVisibility = selectedOption => {
    const currentState = visibilityOptions.find(option => option.id === selectedOption.id)?.value;
    const newState = {
      id: options?.profileOptions?.technologyId,
      isPrimary: options?.profileOptions?.isPrimary,
      isVisible: selectedOption.value,
    };

    updateAdvanceVisibility([newState]).then(res => {
      const state = [...results];
      setResults(
        state.map(item => {
          if (item.technologyData.id === options?.profileOptions?.technologyId) {
            return {
              ...item,
              isVisible: selectedOption.value,
            };
          }
          return item;
        }),
      );
      notification.open({
        message: t('RESULTS_VISIBILITY_HAS_BEEN_ESTABLISHED').replace(
          '{action}',
          !currentState ? t('RESULTS_VISIBILITY_HIDE') : t('RESULTS_VISIBILITY_SHOW'),
        ),
      });
    });
  };

  const handleShow = index => {
    if (collapsedSelected.includes(index)) {
      setCollapsedSelected(collapsedSelected?.filter(selected => selected !== index));
    } else {
      setCollapsedSelected([...collapsedSelected, index]);
    }
  };

  const conditionToDisable = dropdownOptions.find(option => option.isSelected)?.id === 1;

  return (
    <>
      <div className='dark:bg-dark-bold position-relative description-container mt-3 p-0 bg-white rounded-lg w-full border-1 border-alkemy-blue'>
        <div
          className='d-flex flex-row justify-content-between cursor-pointer px-1 py-2 position-relative'
          aria-hidden='true'>
          <div
            className={`dark:text-white  text-lightGray flex justify-between text-center items-center ${
              type !== technologyTypes.CERTIFICABLE && 'pl-[0.438rem]'
            }`}>
            <div
              className={`flex ${
                type !== technologyTypes.CERTIFICABLE ? 'pl-1 min-w-[5.5rem] w-[5.5rem]' : 'min-w-[6rem] w-24'
              }`}>
              {type === technologyTypes.CERTIFICABLE && (
                <img src={options?.headerContent?.technologyImage} width='15' className='mr-1 object-contain' />
              )}
              <span className='font-medium text-xs truncate capitalize'>
                <Tooltip
                  placement='topLeft'
                  title={
                    options?.headerContent?.technologyName.charAt(0).toUpperCase() +
                    options?.headerContent?.technologyName.slice(1)
                  }>
                  {options?.headerContent?.technologyName}
                </Tooltip>
              </span>
            </div>
            <span className='font-medium text-xs w-24 min-w-[6rem]'>
              {oneDecimal(options?.headerContent?.bestScore)}/{maxEngagement}
            </span>
            <span className='font-medium text-xs hide-table-content w-[7.813rem] min-w-[7.813rem] text-center'>
              {moment(options?.headerContent?.lastSubmission).format('DD/MM/YYYY')}
            </span>
            {type === technologyTypes.CERTIFICABLE ? (
              <div className={`text-xs hide-table-content justify-center items-center h-fit`}>
                <DropdownButton
                  options={dropdownOptions}
                  disabled={conditionToDisable}
                  setState={handleTechnologyPriority}
                  contentText={
                    dropdownOptions.find(option => option.isSelected)?.name ?? t('RESULTS_SECONDARY_TECHNOLOGY')
                  }
                  contentStyles={'w-[11rem] min-w-[4rem]'}
                />
              </div>
            ) : (
              <div className='max-w-[10.871rem] min-w-[4rem] w-full h-0 hide-table-content' />
            )}
            <div className='text-xs hide-table-content justify-center items-center h-fit'>
              <DropdownButton
                options={visibilityOptions}
                disabled={conditionToDisable}
                setState={handleTechnologyVisibility}
                contentText={
                  visibilityOptions.find(option => option.value === options?.profileOptions?.isVisible)?.name ??
                  t('RESULTS_HIDE_TECNOLOGY')
                }
                contentStyles={'w-[9.883rem] min-w-[4rem]'}
              />
            </div>
            <button
              className='w-[6.25rem] min-w-[1rem] pl-[0.031rem] text-purple flex justify-center items-center view-more-button'
              onClick={() => handleShow(index)}>
              <span className='text-xs font-bold truncate view-more-button-text'>
                {collapsedSelected.includes(index) ? t('RESULTS_CLOSE_DETAIL') : t('RESULTS_VIEW_HISTORY')}
              </span>
              <ArrowSmall
                className={`mx-1 ml-2 stroke-current dropdown-arrow ${
                  collapsedSelected.includes(index) && 'dropdown-arrow-open'
                }`}
              />
            </button>
          </div>
        </div>
      </div>
      <Content open={collapsedSelected.includes(index)} maxHeight='100'>
        {options?.content}
      </Content>
    </>
  );
};

export default Collapse;
