const DownloadIcon = ({ className, fill = '#5F5F5F' }) => (
  <svg className={className} width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.9 4.39273C12.4467 1.88364 10.4267 0 8 0C6.07333 0 4.4 1.19273 3.56667 2.93818C1.56 3.17091 0 5.02545 0 7.27273C0 9.68 1.79333 11.6364 4 11.6364H12.6667C14.5067 11.6364 16 10.0073 16 8C16 6.08 14.6333 4.52364 12.9 4.39273ZM11.3333 6.54545L8 10.1818L4.66667 6.54545H6.66667V3.63636H9.33333V6.54545H11.3333Z'
      fill={fill}
    />
  </svg>
);

export default DownloadIcon;
