import React, { useEffect, useState } from 'react';
import LoginInput from '../LoginInput/LoginInput';
import { ActionTypes, useContextState } from '../../redux/contextState';
import { auth0Client } from '../../auth0/auth0';
import loginValidations from '../../hooks/validations/login/loginValidations';
import useTranslations from '../../hooks/useTranslations';
import { queryToObject } from '../../utils/processQueryString';
import { notification } from 'antd';
import { Window } from '../../helpers/window';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import PasswordIcon from '../../assets/newSteps/PasswordIcon.jsx';
import MailIcon from '../../assets/newSteps/MailIcon';
import UserIcon from '../../assets/newSteps/UserIcon';
import ModalCustom from '../Modal/Modal';
import Spinner from '../Spinner/Spinner';
import './LoginForm.scss';
import useTermsAndConditions from '../../hooks/useTermsAndConditions';
import PDFViewer from '../PDFViewer/PDFViewer';
import { getGoogleValidAccount } from '../../api/userService.js';

const LoginForm = ({ haveAccount = false, setHaveAccount = () => {} }) => {
  const { t } = useTranslations();
  const { contextState, setContextState } = useContextState();
  const [loginErrors, setLoginErrors] = useState({});
  const [recoverPassword, setRecoverPassword] = useState(false);
  const history = useHistory();
  const defaultUser = {
    password: '',
    verifypassword: '',
    name: '',
    lastName: '',
    ...qs.parse(history.location.search),
    email: /forgot-password/.test(location.pathname) ? '' : queryToObject(history.location.search)?.email || '',
  };

  const [user, setUser] = useState(defaultUser);
  const [error, setError] = useState('');
  const { validationsFn } = loginValidations(t);

  const {
    acceptedTermsAndConditions,
    setAcceptedTermsAndConditions,
    showModal,
    setShowModal,
    closeModal,
    showTermsAndConditionsModal,
    termsAndConditionsData,
    termsAndConditionsLoading,
  } = useTermsAndConditions();

  useEffect(() => {
    if (/login/.test(location.pathname)) {
      setHaveAccount(true);
      setRecoverPassword(false);
    }
    if (/forgot-password/.test(location.pathname)) {
      setHaveAccount(true);
      setRecoverPassword(true);
    }
    if (/register/.test(location.pathname)) {
      setHaveAccount(false);
      setRecoverPassword(false);
    }
  }, [location.pathname]);

  const validatePasswordValidation = Boolean(
    user.password.length && user.verifypassword.length && user.password !== user.verifypassword,
  );
  const onChange = e => {
    if (validationsFn(e.target.name, e.target.value)?.length > 0) {
      setLoginErrors({ ...loginErrors, [e.target.name]: validationsFn(e.target.name, e.target.value) });
    } else {
      delete loginErrors[e.target.name];
    }
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const login = async () => {
    const redirectURL = new URL(`${window.location.origin}/login`);
    if (Window()) {
      setContextState({
        type: ActionTypes.SetLoginLoading,
        value: true,
      });
      if (contextState.screeningRedirection.screeningId) {
        redirectURL.searchParams.append('screeningId', contextState.screeningRedirection.screeningId);
      }
      if (contextState.screeningRedirection.technologyId && contextState.screeningRedirection.levelId) {
        redirectURL.searchParams.append('technologyId', contextState.screeningRedirection.technologyId);
        redirectURL.searchParams.append('levelId', contextState.screeningRedirection.levelId);
      }
      auth0Client().login(
        {
          realm: 'Username-Password-Authentication',
          username: user.email,
          password: user.password,
          redirectUri: redirectURL.toString(),
        },
        e => {
          setContextState({
            type: ActionTypes.SetLoginLoading,
            value: false,
          });
          setError(e?.description || '');
        },
      );
    }
  };
  const handleRecover = async () => {
    if (Window()) {
      getGoogleValidAccount(user.email)
        .then(res => {
          if (!res.data) {
            notification.open({
              message: t('ERROR_GOOGLE_ACCOUNT'),
              type: 'error',
            });
          } else {
            auth0Client().changePassword(
              {
                connection: 'Username-Password-Authentication',
                email: user.email,
              },
              err => {
                if (err) {
                  notification.open({
                    message: t('RECOVER_PASSWORD_ERROR'),
                    type: 'error',
                  });
                } else {
                  notification.open({
                    message: t('RECOVER_PASSWORD_SUCCESS'),
                    type: 'success',
                  });
                }
              },
            );
          }
        })
        .catch(() => {
          notification.open({
            message: t('RECOVER_PASSWORD_ERROR'),
            type: 'error',
          });
        });
    }
  };

  const register = () => {
    setContextState({
      type: ActionTypes.SetLoginLoading,
      value: true,
    });
    if (Window()) {
      auth0Client().redirect.signupAndLogin(
        {
          connection: 'Username-Password-Authentication',
          username: user?.email,
          email: user?.email,
          password: user?.password,
          given_name: user?.name,
          family_name: user?.lastName,
          termsAndConditionsAcceptance: new Date().toISOString(),
          app_metadata: {
            exclusiveInvitation: contextState.exclusiveInvitation,
          },
        },
        e => {
          notification.open({
            message: t('REGISTER_ERROR'),
            type: 'error',
            description: t('REGISTER_ERROR_TEXT'),
          });
          setContextState({
            type: ActionTypes.SetLoginLoading,
            value: false,
          });
        },
      );
    }
  };

  const resetForm = (state = defaultUser) => {
    const user = state;
    setAcceptedTermsAndConditions(false);
    setUser(user);
    if (user.email && validationsFn('email', user.email).length) {
      setLoginErrors({ email: validationsFn('email', user.email) });
    }
    if (user.name && validationsFn('name', user.name).length) {
      setLoginErrors({ name: validationsFn('name', user.name) });
    }
    if (user.lastName && validationsFn('lastName', user.lastName).length) {
      setLoginErrors({ lastName: validationsFn('lastName', user.lastName) });
    }
  };
  useEffect(() => {
    const user = defaultUser;
    if (user.email) {
      setUser({ ...user, email: user.email });
      if (validationsFn('email', user.email).length) {
        setLoginErrors({
          ...loginErrors,
          email: validationsFn('email', user.email),
        });
      }
    }
    if (user.name) {
      setUser({ ...user, name: user.name });
      if (validationsFn('name', user.name).length) {
        setLoginErrors({
          ...loginErrors,
          name: validationsFn('name', user.name),
        });
      }
    }
    if (user.lastName) {
      setUser({ ...user, lastName: user.lastName });
      if (validationsFn('lastName', user.lastName).length) {
        setLoginErrors({
          ...loginErrors,
          lastName: validationsFn('lastName', user.lastName),
        });
      }
    }
  }, []);

  return haveAccount ? (
    recoverPassword ? (
      <form
        id='recoverPassword-form'
        onSubmit={e => e.preventDefault()}
        className='flex flex-col justify-start items-center mx-10 mt-4'>
        <span className='text-center mb-4 font-small dark:text-white' data-testid='recover-password-disclaimer-span'>
          {t('RECOVER_PASSWORD_DISCLAIMER')}
        </span>
        <LoginInput
          placeholder={t('EMAIL_PLACEHOLDER')}
          name='email'
          value={user?.email}
          onChange={onChange}
          Icon={MailIcon}
          error={loginErrors?.email && loginErrors?.email[0]}
          view='recoverPassword'
        />
        <div className='flex justify-around w-full'>
          <button
            id='recoverPassword-cancel-button'
            onClick={() => {
              resetForm();
              history.push('/login' + history.location.search);
            }}
            type='button'
            className='bg-transparent text-maya-blue font-bold border-0'
            data-testid='recover-password-cancel-btn'>
            {t('CANCEL')}
          </button>
          <button
            id='recoverPassword-submit-button'
            onClick={() => {
              handleRecover();
            }}
            type='submit'
            disabled={!user?.email || !!loginErrors?.email}
            className='bg-maya-blue border-0 text-white font-bold  p-2 px-3 rounded-full'
            data-testid='recover-password-submit-btn'>
            {t('RECOVER_PASSWORD_SEND_EMAIL')}
          </button>
        </div>
      </form>
    ) : (
      <form
        id='loginView-login-form'
        onSubmit={e => e.preventDefault()}
        className='flex mx-16 mt-4 flex-col justify-start items-center'>
        <LoginInput
          placeholder={t('EMAIL_PLACEHOLDER')}
          name='email'
          value={user?.email}
          onChange={onChange}
          error={loginErrors?.email && loginErrors?.email[0]}
          Icon={MailIcon}
          view='login'
        />
        <LoginInput
          placeholder={t('PASSWORD_PLACEHOLDER')}
          type='password'
          name='password'
          value={user?.password}
          Icon={PasswordIcon}
          onChange={onChange}
          error={loginErrors?.password && loginErrors?.password?.length > 1 && loginErrors?.password[0]}
          isPassword={true}
          view='login'
        />
        {error && (
          <span id='login-wrong-email-pass-error-span' className='danger-text pb-3'>
            {t('WRONG_EMAIL_OR_PASSWORD')}
          </span>
        )}
        <div className='flex flex-col-reverse md:flex-row mt-3 justify-around w-full'>
          <button
            data-testid='dont-have-account-button'
            id='login-donthaveAccount-button'
            onClick={() => {
              resetForm();
              history.push('/register' + history.location.search);
            }}
            type='button'
            className='bg-transparent  mt-3 md:mt-0 text-maya-blue text-sm font-medium border-0'>
            {t('DONT_HAVE_ACCOUNT')}
          </button>
          <button
            data-testid='login-button'
            id='login-submitData-button'
            onClick={() => {
              login();
            }}
            type='submit'
            disabled={!user?.email || !user?.password || !!loginErrors?.email || loginErrors?.password?.length > 1}
            className={`border-0 text-white font-bold  p-2 px-3 rounded-full ${
              !user?.email || !user?.password || !!loginErrors?.email || loginErrors?.password?.length > 1
                ? 'bg-gray-500'
                : 'bg-maya-blue'
            }`}>
            {t('LOGIN_IN_TITLE')}
          </button>
        </div>
        <div className='mt-3'>
          <button
            data-testid='login-forgot-password-button'
            id='login-forgotPass-button'
            onClick={() => {
              resetForm({ ...defaultUser, email: '' });
              history.push('/forgot-password' + history.location.search);
            }}
            type='button'
            className='bg-transparent  mt-3 md:mt-0 text-maya-blue text-sm font-medium border-0'>
            {t('FORGOT_MY_PASSWORD')}
          </button>
        </div>
      </form>
    )
  ) : (
    <>
      <ModalCustom
        contentContainerClassName='content-className pt-0'
        wrapClassname={termsAndConditionsData && 'h-full modal-tac-content'}
        closeOnClickOutside={false}
        content={
          <>
            {termsAndConditionsLoading ? (
              <Spinner show />
            ) : termsAndConditionsData ? (
              <div className='flex flex-col h-full'>
                <span
                  id='modal-register-termAndCondition-title-span'
                  className='dark:text-white text-start font-semibold text-base mb-2'>
                  {t('TERMS_AND_CONDITIONS_MODAL_TITLE')}
                </span>

                <PDFViewer pdf={termsAndConditionsData?.link} />

                <button
                  id='modal-register-termAndCondition-title-button'
                  onClick={() => closeModal()}
                  className='border-0 text-white font-bold p-2 rounded-full bg-maya-blue w-fit px-4 mt-3'>
                  {t('TERMS_AND_CONDITIONS_GO_BACK')}
                </button>
              </div>
            ) : (
              <span>{t('CANNOT_LOAD_TERMS_AND_CONDITIONS')}</span>
            )}
          </>
        }
        show={showModal}
        setShow={setShowModal}
      />
      <form
        id='registerView-register-form'
        onSubmit={e => e.preventDefault()}
        className='flex w-75 mt-4 mx-16 flex-col justify-start items-center'>
        <LoginInput
          placeholder={t('PROFILE_NAME')}
          value={user?.name}
          name='name'
          onChange={onChange}
          Icon={UserIcon}
          error={loginErrors?.name && loginErrors?.name[0]}
          view='register'
        />
        <LoginInput
          placeholder={t('PROFILE_LASTNAME')}
          value={user?.lastName}
          name='lastName'
          onChange={onChange}
          error={loginErrors?.lastName && loginErrors?.lastName[0]}
          Icon={UserIcon}
          view='register'
        />
        <LoginInput
          placeholder={t('EMAIL_PLACEHOLDER')}
          value={user?.email}
          name='email'
          onChange={onChange}
          error={loginErrors?.email && loginErrors?.email[0]}
          Icon={MailIcon}
          view='register'
        />
        <LoginInput
          placeholder={t('PASSWORD_PLACEHOLDER')}
          name='password'
          value={user?.password}
          type='password'
          error={
            (loginErrors?.password && loginErrors?.password?.length > 1 && loginErrors?.password[0]) ||
            (Boolean(user?.password?.length && user?.password?.length < 8) && t('PASSWORD_VALIDATION_LENGTH'))
          }
          onChange={onChange}
          Icon={PasswordIcon}
          isPassword={true}
          view='register'
        />
        <LoginInput
          placeholder={t('VERIFY_PASSWORD_PLACEHOLDER')}
          name='verifypassword'
          value={user?.verifypassword}
          Icon={PasswordIcon}
          type='password'
          error={
            (loginErrors?.verifypassword && loginErrors?.verifypassword[0]) ||
            (validatePasswordValidation && t('PASSWORD_CONFIRMATION_ERROR'))
          }
          onChange={onChange}
          isPassword={true}
          view='register'
        />
        <div className='flex justify-center items-center mt-2'>
          <input
            data-testid='terms-and-conditions-checkbox'
            id='register-acceptedTerms-checkbox'
            type='checkbox'
            onChange={() => setAcceptedTermsAndConditions(state => !state)}
            value={acceptedTermsAndConditions}
            defaultChecked={acceptedTermsAndConditions}
          />
          <span className='ml-2 font-montserrat text-lightGray font-medium text-sm'>
            {t('HAVE_READ_AND_ACCEPTED')}
            <button
              id='register-termsAndConditions-openModal-button'
              onClick={() => showTermsAndConditionsModal()}
              className='ml-1 border-0 cursor-pointer text-alkemy-purple'>
              {t('TERMS_AND_CONDITIONS')}
            </button>
          </span>
        </div>
        <div className='flex flex-col-reverse md:flex-row my-3 justify-around w-full'>
          <button
            data-testid='already-have-account-button'
            id='register-alreadyAccount-button'
            onClick={() => {
              resetForm();
              history.push('/login' + history.location.search);
            }}
            type='button'
            className='bg-transparent  mt-3 md:mt-0 text-maya-blue text-sm font-medium border-0'>
            {t('ALREADY_HAVE_ACCOUNT')}
          </button>
          <button
            data-testid='register-button'
            id='register-submit-button'
            onClick={() => {
              register();
            }}
            autoFocus
            type='submit'
            disabled={
              !user?.email ||
              !user?.password ||
              !user?.verifypassword ||
              user?.password !== user?.verifypassword ||
              !user?.name ||
              !user.lastName ||
              !!loginErrors?.email ||
              loginErrors?.password?.length > 1 ||
              user?.password?.length < 8 ||
              !!loginErrors?.name ||
              !!loginErrors?.lastName ||
              !acceptedTermsAndConditions
            }
            className={`border-0 text-white font-bold  p-2 px-3 rounded-full ${
              Boolean(
                !user?.email ||
                  !user?.password ||
                  !user?.verifypassword ||
                  user?.password !== user?.verifypassword ||
                  !user?.name ||
                  !user.lastName ||
                  !!loginErrors?.email ||
                  loginErrors?.password?.length > 1 ||
                  user?.password?.length < 8 ||
                  !!loginErrors?.name ||
                  !!loginErrors?.lastName ||
                  !acceptedTermsAndConditions,
              )
                ? 'bg-gray-500'
                : 'bg-maya-blue'
            }`}>
            {t('CREATE_ACCOUNT')}
          </button>
        </div>
      </form>
    </>
  );
};

export default LoginForm;
