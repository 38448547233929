import './trainingAssistance.scss';

const TrainingAssistance = ({ title, percent, className, color = '#5EDCA7' }) => (
  <div className={`card-profile flex flex-row items-center justify-between ${className}`}>
    <span className='assistance-title text-alkemy-black flex items-center  font-bold dark:text-white'>
      {title}
    </span>
    <span
      className='assistance-content text-alkemy-black font-roboto font-bold dark:text-white'
      style={{ background: color }}>
      {percent > 100 ? 100 : percent}%
    </span>
  </div>
);

export default TrainingAssistance;
