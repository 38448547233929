import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { notification } from 'antd';
import LoginForm from '../../../components/LoginForm/LoginForm';
import CustomSVG from '../../../components/CustomSVG/CustomSVG';
import Spinner from '../../../components/Spinner/Spinner';
import { auth0Client } from '../../../auth0/auth0';
import { Window } from '../../../helpers/window';
import { Auth0Error } from '../../../utils/constants';
import { ActionTypes, useContextState } from '../../../redux/contextState';
import useTranslations from '../../../hooks/useTranslations';
import gmailIcon from '../../../assets/newSteps/gmail-icon.svg';
import './LoginStep.scss';
import HorizontalLoader from '../../../components/HorizontalLoader/HorizontalLoader';
import AlkemyLogo from '../../../assets/newSteps/alkemy-logo';
import { queryToObject } from '../../../utils/processQueryString';
import { createPendingInvitation, resendEmailVerifyAccount } from '../../../api/userService';

const LoginStep = () => {
  const { contextState, setContextState } = useContextState();
  const location = useLocation();
  const history = useHistory();
  const [verifyEmail, setVerifyEmail] = useState(!contextState.isVerify && contextState.isAuthenticated);
  const [haveAccount, setHaveAccount] = useState(false);
  const { t } = useTranslations();
  const loginWithGoogle = () => {
    const redirectURL = new URL(`${window.location.origin}/login`);
    if (Window()) {
      if (contextState.screeningRedirection.screeningId) {
        redirectURL.searchParams.append('screeningId', contextState.screeningRedirection.screeningId);
      }
      if (contextState.screeningRedirection.technologyId && contextState.screeningRedirection.levelId) {
        redirectURL.searchParams.append('technologyId', contextState.screeningRedirection.technologyId);
        redirectURL.searchParams.append('levelId', contextState.screeningRedirection.levelId);
      }
      auth0Client().authorize({
        connection: 'google-oauth2',
        redirectUri: redirectURL.toString(),
        app_metadata: {
          exclusiveInvitation: contextState.exclusiveInvitation,
        },
      });
    }
  };
  const loginWithGithub = () => {
    if (Window()) {
      auth0Client().authorize({
        connection: 'github',
        returnTo: window.location.origin,
      });
    }
  };

  useEffect(() => {
    if (location.hash) {
      const queryParams = qs.parse(location.hash?.split('#')[1]);
      const actions = {
        [Auth0Error.VALIDATE_EMAIL]: () => {
          setContextState({
            type: ActionTypes.SetLoginLoading,
            value: false,
          });
          setVerifyEmail(false);
        },
        [Auth0Error.LINKING_ACCOUNTS]: () =>
          notification.open({
            message: t('LINKING_ACCOUNTS_NOTIFICATION'),
            type: 'success',
          }),
        [Auth0Error.USER_EXISTS]: () => {
          setContextState({
            type: ActionTypes.SetLoginLoading,
            value: false,
          });
          notification.open({
            message: t('DUPLICATED_EMAIL_VALIDATION_ERROR'),
            type: 'error',
          });
          history.push({
            hash: '',
          });
          localStorage.removeItem('prevHash');
        },
        [Auth0Error.USER_EXISTS_GOOGLE]: () => {
          setContextState({
            type: ActionTypes.SetLoginLoading,
            value: false,
          });
          notification.open({
            message: t('DUPLICATED_EMAIL_VALIDATION_ERROR_GOOGLE'),
            type: 'error',
          });
          history.push({
            hash: '',
          });
          localStorage.removeItem('prevHash');
        },
        [Auth0Error.USER_EXISTS_PASSWORD]: () => {
          setContextState({
            type: ActionTypes.SetLoginLoading,
            value: false,
          });
          notification.open({
            message: t('DUPLICATED_EMAIL_VALIDATION_ERROR_PASSWORD'),
            type: 'error',
          });
          history.push({
            hash: '',
          });
          localStorage.removeItem('prevHash');
        },
      };
      if (Auth0Error[queryParams.error_description]) actions[Auth0Error[queryParams.error_description]]();
    }
  }, [location.hash]);

  useEffect(() => {
    if (
      Window() &&
      verifyEmail &&
      window.localStorage.getItem('prevRecruitSessionInvitation') &&
      Object.keys(contextState.profile)?.length
    ) {
      createPendingInvitation({
        email: contextState.profile?.email,
        firstName: contextState.profile?.firstName,
        lastName: contextState.profile?.lastName,
        recruitSessionStageId: window.localStorage.getItem('prevRecruitSessionInvitation'),
      }).then(() => {
        window.localStorage.removeItem('prevRecruitSessionInvitation');
        window.localStorage.removeItem('prevUrl');
      });
    }
  }, []);

  const sendEmailVerifyAccount = () => {
    try {
      resendEmailVerifyAccount();
      notification.open({
        message: t('RESEND_EMAIL_VERIFY_ACCOUNT_SUCCES_CAMP'),
        type: 'success',
      });
    } catch (error) {
      notification.open({
        message: t('RESEND_EMAIL_VERIFY_ACCOUNT_ERROR_CAMP'),
        type: 'error',
      });
    }
  };

  const backToLogin = () => {
    auth0Client().logout({ returnTo: `${window.location.origin}/login` });
  };

  return contextState.loading ? (
    <Spinner show />
  ) : (
    <div className='flex flex-col px-3 md:px-5 py-10 steps-children-container min-vh-100'>
      <CustomSVG onClick={() => {}} alt={t('ICON_NOTIFICATIONS')} className='flex items-end relative cursor-pointer'>
        {(queryToObject(history.location.search)?.invitationId ||
          queryToObject(history.location.search)?.recruitSessionStageId ||
          queryToObject(history.location.search)?.invitation) &&
        contextState?.whitelabel?.images?.HEADER_LOGO &&
        !/forgot-password/.test(location.pathname) ? (
          <div className='flex items-center' id='login-div-poweredBy' data-testid='custom-whitelabel-logo'>
            <img className='w-32 mr-2' src={contextState?.whitelabel?.images?.HEADER_LOGO} />
            <span className='italic'>{t('POWERED_BY')}</span>
            <AlkemyLogo className='w-16 ml-2' />
          </div>
        ) : (
          <AlkemyLogo />
        )}
      </CustomSVG>
      {verifyEmail ? (
        <div className='mt-5 flex flex-col justify-center'>
          <div id='verify-account-div' className='mt-16 text-center p-8'>
            <h1 id='verify-account-title' className='font-bold text-xl dark:text-white'>
              {t('CONFIRM_EMAIL_TITLE')}
            </h1>
            <h2
              data-testid='verify-account-subtitle-email'
              id='verify-account-subtitle'
              className='text-sm my-6 text-center text-grey font-semibold dark:text-white px-8'>
              {t('VERIFY_ACCOUNT_TEXT_FIRST_PART')}
              <span className='text-sm text-black font-bold'>{contextState.emailVerify}</span>
              {t('VERIFY_ACCOUNT_TEXT_SECOND_PART')}
            </h2>
            <p className='mt-8 font-weight-600 text-xs text-grey font-semibold dark:text-white'>
              {t('VERIFY_ACCOUNT_SEND_EMAIL')}
              <button
                data-testid='verify-account-send-email-button'
                id='verify-account-send-email-button'
                type='button'
                className='bg-transparent outline-none border-0 text-xs text-purple dark:text-white'
                onClick={sendEmailVerifyAccount}>
                {t('VERIFY_ACCOUNT_SEND_EMAIL_LINK')}
              </button>
            </p>
            <button
              data-testid='verify-account-back-login-button'
              id='verify-account-back-login-button'
              onClick={backToLogin}
              type='submit'
              className='bg-maya-blue rounded-full border-0 mx-auto mt-10 text-white text-center font-bold p-2 px-3'>
              {t('VERIFY_ACCOUNT_BACK_LOGIN')}
            </button>
          </div>
        </div>
      ) : (
        <>
          <div id='login-register-header-div' className='mt-5 pt-4 px-2'>
            <h1
              id='login-register-title'
              data-testid='login-register-title'
              className='font-bold text-center text-xl dark:text-white'>
              {t('LOGIN_TITLE').replaceAll(
                '{subject}',
                queryToObject(history.location.search)?.name
                  ? decodeURI(queryToObject(history.location.search)?.name).replaceAll('+', ' ')
                  : t('TO_ALKEMY'),
              )}
            </h1>
            <h2
              id='login-register-subtitle'
              data-testid='login-register-subtitle'
              className='mx-auto text-center font-semibold text-base text-gray-700 w-[21rem] mt-5 dark:text-white'>
              {t('NEW_LOGIN_SUBTITLE')}
            </h2>
          </div>

          <LoginForm haveAccount={haveAccount} setHaveAccount={setHaveAccount} />
          {contextState.loginLoading && (
            <div className='flex justify-center' data-testid='form-submitted-loader'>
              <HorizontalLoader />
            </div>
          )}
          <span
            id='login-register-with-social-span-text'
            className='whitespace-nowrap font-medium font-montserrat text-sm login-section-divider text-alkemy-black relative flex items-center justify-center text-center'>
            {haveAccount ? t('LOGIN_WITH_SOCIAL') : t('REGISTER_WITH_SOCIAL')}
          </span>
          <div className='text-center'>
            <button
              data-testid='login-with-social-btn'
              id='login-register-with-social-button'
              onClick={loginWithGoogle}
              className='bg-transparent border-0'>
              <img src={gmailIcon} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default LoginStep;
