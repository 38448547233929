import { useState } from 'react';
import Collapse from '../Collapse/Collapse';
import './Results.scss';
import moment from 'moment';
import { oneDecimal } from '../../utils/numbersUtils';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { technologyTypes } from '../Collapse/TechnologyTypes';
import useTranslations from '../../hooks/useTranslations';
import { Tooltip } from 'antd';
import { useContextState } from '../../redux/contextState';
import DownloadIcon from '../../assets/results/DownloadIcon';
import { getUserChallengeCertificates } from '../../api/userService';

const ResultsSection = ({ results, setResults }) => {
  const { t } = useTranslations();
  const [collapsedSelected, setCollapsedSelected] = useState([]);
  const maxEngagement = t('RESULTS_MAX_ENGAGEMENT');
  const { contextState } = useContextState();
  const finishedInvitations = contextState.screenings?.filter(item => item.status === 'FINISHED');
  const profile = contextState.profile;
  const history = useHistory();
  const optionsCollapsed = results
    .filter(technology => technology?.technologyData?.isCertificable)
    .map(result => {
      return {
        profileOptions: {
          isPrimary: result?.isPrimary,
          isVisible: result?.isVisible,
          technologyId: result?.technologyData?.id,
        },
        headerContent: {
          technologyImage: result?.technologyData?.imageUrl,
          technologyName: result?.technologyData?.name,
          bestScore: result?.advances?.sort((a, b) => b.totalEngagement - a.totalEngagement)[0]?.totalEngagement,
          lastSubmission: result?.advances?.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0]?.createdAt,
        },
        content: (
          <>
            {result?.advances
              ?.sort((a, b) => b?.totalEngagement ?? 0 - a?.totalEngagement ?? 0)
              .map((advance, i) => (
                <div
                  key={i}
                  className='dark:text-white text-lightGray flex justify-between text-center items-center px-px pt-4'>
                  <div className='flex w-24 min-w-[6rem]'>
                    <span className='text-xs truncate ml-[1.313rem] capitalize font-medium'>
                      <Tooltip
                        placement='topLeft'
                        title={
                          result?.technologyData?.name.charAt(0).toUpperCase() + result?.technologyData?.name.slice(1)
                        }>
                        {result?.technologyData?.name}
                      </Tooltip>
                    </span>
                  </div>
                  <span className='text-xs w-24 ml-[0.188rem] min-w-[6rem] font-medium '>
                    {`${oneDecimal(advance?.totalEngagement ?? 0)}/${maxEngagement}`}
                  </span>
                  <span className='ml-px font-medium  text-xs hide-table-content w-[7.813rem] min-w-[7.813rem] text-center hidden-element'>
                    {moment(advance.createdAt).format('DD/MM/YYYY')}
                  </span>
                  <div
                    className='hide-table-content h-0 hidden-element invisible w-full max-w-[10.834rem] min-w-[4rem]'
                    tabIndex={-1}
                  />
                  <div className='hide-table-content h-0 invisible w-full max-w-[10rem] min-w-[4rem]' tabIndex={-1} />
                  <span className='text-xs w-[6.5rem] invisible font-medium' tabIndex={-1}></span>
                </div>
              ))}
          </>
        ),
      };
    });

  const noCertificables = results
    .filter(technology => !technology?.technologyData?.isCertificable)
    .map(result => {
      return {
        profileOptions: {
          isVisible: result?.isVisible,
          technologyId: result?.technologyData?.id,
        },
        headerContent: {
          technologyName: result?.technologyData?.name,
          bestScore: result?.advances?.sort((a, b) => b.totalEngagement - a.totalEngagement)[0]?.totalEngagement,
          lastSubmission: result?.advances?.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0]?.createdAt,
        },
        content: (
          <>
            {result?.advances
              ?.sort((a, b) => b?.totalEngagement ?? 0 - a?.totalEngagement ?? 0)
              .map((advance, i) => (
                <div
                  key={i}
                  className='dark:text-white text-lightGray flex justify-between text-center items-center px-px pl-[0.5rem] pt-4'>
                  <div className='flex w-[5.5rem] min-w-[5.5rem] pl-1'>
                    <span className='text-xs truncate pl-px font-medium  capitalize'>
                      <Tooltip
                        placement='topLeft'
                        title={
                          result?.technologyData?.name.charAt(0).toUpperCase() + result?.technologyData?.name.slice(1)
                        }>
                        {result?.technologyData?.name}
                      </Tooltip>
                    </span>
                  </div>
                  <span className='text-xs w-24 font-medium min-w-[6rem]'>{`${oneDecimal(
                    advance?.totalEngagement ?? 0,
                  )}/${maxEngagement}`}</span>
                  <span className='m-0 min-w-[7.813rem] text-xs hide-table-content font-medium w-[7.813rem] text-center hidden-element pr-[0.188rem]'>
                    {moment(advance?.createdAt).format('DD/MM/YYYY')}
                  </span>
                  <div
                    className='text-xs hide-table-content justify-center items-center h-0 hidden-element invisible w-full max-w-[10.871rem] min-w-[4rem]'
                    tabIndex={-1}>
                    <span className='font-medium text-sm invisible hide-table-content max-w-[10.871rem] w-full min-w-[4rem]'></span>
                  </div>
                  <div
                    className='text-xs hide-table-content justify-center items-center h-0 invisible w-full max-w-[10rem] min-w-[4rem]'
                    tabIndex={-1}>
                    <span className='font-medium text-sm invisible hide-table-content w-full max-w-[10rem]'></span>
                  </div>
                  <span className='text-xs invisible close-btn-hidden font-medium' tabIndex={-1}></span>
                </div>
              ))}
          </>
        ),
      };
    });

  const [hasCertificate, setHasCertificate] = useState(false);
  useEffect(() => {
    const fetchCertificates = async () => {
      const certificates = await getUserChallengeCertificates(profile.id);
      if (certificates.length > 0) {
        setHasCertificate(true);
      }
    };
    fetchCertificates();
  }, [profile]);

  return (
    <div className='py-6 px-4 bg-white dark:bg-dark-bold'>
      {Boolean(finishedInvitations.length) && (
        <div data-testid='finished-invitations-section-container' className='mb-6'>
          <span data-testid='finished-invitations-section-title' className='font-semibold text-base dark:text-white'>
            {t('RESULTS_EVALUATION_HISTORY_TITLE')}
          </span>
          <div className='w-full flex justify-between mt-1 px-4 py-2 text-center table-header relative dark:text-white'>
            <span
              data-testid='finished-invitations-section-evaluation-header'
              className='font-medium text-sm w-24 relative left-[-0.938rem]'>
              {t('RESULTS_EVALUATION_HISTORY_EVALUATION_TITLE')}
            </span>
            <span className='font-medium text-sm invisible hide-table-content w-[13.124rem]'></span>
            <span className='font-medium text-sm invisible hide-table-content w-40'></span>
            <span
              data-testid='finished-invitations-section-result-header'
              className='font-medium text-sm w-24 relative left-[-2rem]'>
              {t('RESULTS_TABLE_MY_RESULTS')}
            </span>
            <span
              data-testid='finished-invitations-section-enddate-header'
              className='font-medium text-sm w-24 whitespace-nowrap relative left-[-2rem]'>
              {t('RESULTS_TABLE_SUBMISSION_DATE')}
            </span>
          </div>
          {finishedInvitations.map(evaluation => (
            <div
              key={evaluation.id}
              data-testid='finished-invitations-item'
              className='dark:bg-dark-bold position-relative description-container mt-2 p-3 bg-white rounded-lg w-full border-1 border-alkemy-blue flex justify-between'>
              <span className='text-xs font-medium'>{evaluation.stage.name}</span>
              <div className='flex justify-between evaluation-result-width'>
                <div className='w-24 relative left-[-2.4rem] flex items-center'>
                  <span className='text-xs font-medium'>
                    {evaluation.stage.recruitSession.allowCertificateDownload
                      ? `${Math.round(evaluation?.score)}/${t('RESULTS_MAX_ENGAGEMENT')}`
                      : t('PARTNER_NOT_ALLOW_RESULTS')}
                  </span>
                  {evaluation.stage.recruitSession.allowCertificateDownload && (
                    <button
                      data-testid='download-certificate-button'
                      onClick={() => {
                        history.push(`/certificate?invitationId=${evaluation.id}`);
                      }}
                      className='ml-2'>
                      <DownloadIcon />
                    </button>
                  )}
                </div>
                <span className='w-24 whitespace-nowrap relative left-[-1rem] text-xs font-medium'>
                  {moment(evaluation?.endDate).format('DD/MM/YYYY')}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
      {Boolean(results.length) && (
        <>
          <span className='font-semibold text-base dark:text-white'>{t('RESULTS_HISTORY_OF_MY_CHALLENGES')}</span>
          <div className='mt-4'>
            <span className='font-medium text-sm text-input-dark dark:text-gray-100'>
              {t('RESULTS_TABLE_TECHNICAL_SKILLS')}
            </span>
            <div className='w-full flex justify-between mt-1 px-4 py-2 text-center table-header relative dark:text-white'>
              <span className='font-medium text-sm w-24 relative left-[-0.938rem]'>
                {t('RESULTS_TABLE_TECHNOLOGY')}
              </span>
              <span className='font-medium text-sm min-w-[6rem] mr-2 ml-[0.313rem]'>
                {t('RESULTS_TABLE_MY_RESULTS')}
              </span>
              <span className='font-medium text-sm hide-table-content w-32 truncate ml-[0.313rem]'>
                {t('RESULTS_TABLE_SUBMISSION_DATE')}
              </span>
              <span className='font-medium text-sm invisible hide-table-content w-[13.124rem]'></span>
              <span className='font-medium text-sm invisible hide-table-content w-40'></span>
              <span className='font-medium text-sm invisible hide-table-content w-24'>{t('RESULTS_TABLE_CLOSE')}</span>
              <div className='absolute right-0'>
                {hasCertificate && (
                  <button
                    onClick={() => history.push('/certificates')}
                    className='w-40 mr-2 text-sm font-bold py-1 mx-auto rounded-3xl bg-alkemy-blue text-white hide-certificate-button'>
                    {t('SCREENING_CERTIFICATES')}
                  </button>
                )}
                <button
                  onClick={() => history.push('/resources')}
                  className='w-40  text-sm font-bold py-1 mx-auto rounded-3xl bg-alkemy-blue text-white'>
                  {t('RESULTS_TABLE_GO_TO_RESOURCES')}
                </button>
              </div>
              <div className='header-spacing' />
            </div>
            {optionsCollapsed.map((options, i) => {
              return (
                <Collapse
                  type={technologyTypes.CERTIFICABLE}
                  index={`certificables-${i}`}
                  options={options}
                  results={results}
                  setResults={setResults}
                  key={i}
                  collapsedSelected={collapsedSelected}
                  setCollapsedSelected={setCollapsedSelected}
                />
              );
            })}
          </div>
          <div className='mt-8'>
            {Boolean(noCertificables?.length) && (
              <span className='font-medium text-sm text-input-dark dark:text-white'>
                {t('RESULTS_TABLE_OTHER_KNOWLEDGES')}
              </span>
            )}
            {noCertificables.map((options, i) => {
              return (
                <Collapse
                  type={technologyTypes.NOT_CERTIFICABLE}
                  index={`not-certificables-${i}`}
                  options={options}
                  results={results}
                  setResults={setResults}
                  key={i}
                  collapsedSelected={collapsedSelected}
                  setCollapsedSelected={setCollapsedSelected}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ResultsSection;
