import { useEffect, useState } from 'react';
import { useContextState } from '../redux/contextState';
import { roles } from '../constants/enums';

const useRoles = () => {
  const [userRoles, setUserRoles] = useState([]);
  const { contextState } = useContextState();

  useEffect(() => {
    setUserRoles(contextState.roles);
  }, []);

  const isRole = role => userRoles?.includes(role);

  const isSomeRole = roles => {
    let hasRoles;
    roles.forEach(role => {
      if (userRoles.includes(role)) hasRoles = true;
    });

    return hasRoles;
  };

  const isRoles = roles => {
    let hasRoles = true;
    roles.forEach(role => {
      if (!userRoles.includes(role)) hasRoles = false;
    });

    return hasRoles;
  };

  const isOneRole = role => (userRoles.length === 1 ? userRoles?.includes(role, 0) : false);

  const isOneCampusRole = role => {
    const campusRoles = userRoles.filter(role =>
      [roles.ALKYMER, roles.APPLICANT, roles.PREACCELERATION].includes(role),
    );
    return campusRoles.length === 1 && campusRoles.includes(role);
  };

  return {
    isRole,
    isRoles,
    isSomeRole,
    isOneRole,
    userRoles,
    isOneCampusRole,
  };
};

export default useRoles;
