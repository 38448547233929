export const formatDefaultValues = profile => {
  return {
    ...profile,
    formations:
      profile.formations?.map(item => ({
        ...item,
        institute: {
          ...item?.institute,
          label: item?.institute?.displayName,
        },
        studyArea: {
          ...item?.studyArea,
          label: item?.studyArea?.displayName,
        },
      })) ?? [],
    countryCode: profile.countryCode?.includes('+') ? profile.countryCode?.slice(1) : profile.countryCode,
    birthdate: new Date(`${profile.birthdate}T00:00:00`),
  };
};
