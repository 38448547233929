const AlkemyLogo = ({ className }) => {
  return (
    <svg
      id='svg-logo-alkemy'
      xmlns='http://www.w3.org/2000/svg'
      width='122px'
      height='23'
      viewBox='0 0 280 60'
      className={`fill-[#222222] dark:fill-white ${className}`}>
      <path d='M90.6956 39.8787C84.4306 39.8787 79.3535 34.767 79.3535 28.4646C79.3535 22.2277 84.3265 17.1596 90.5005 17.0593C90.5656 17.0549 90.6306 17.0549 90.6956 17.0549C90.7604 17.0549 90.8257 17.0549 90.8907 17.0593C97.0648 17.1596 102.038 22.2277 102.038 28.4646C102.038 34.767 96.9607 39.8787 90.6956 39.8787ZM102.038 11.2466V15.5851C99.0202 12.8941 95.0484 11.2585 90.6956 11.2585C81.2482 11.2585 73.5913 18.9653 73.5913 28.4646C73.5913 37.9683 81.2482 45.6751 90.6956 45.6751C95.0484 45.6751 99.0202 44.0395 102.038 41.3485V45.6597H107.822V11.2466H102.038Z' />
      <path d='M90.8906 17.0593C90.8256 17.055 90.7603 17.055 90.6955 17.055C90.6305 17.055 90.5655 17.055 90.5005 17.0593C90.5655 17.0634 90.6305 17.068 90.6955 17.068C90.7603 17.068 90.8256 17.0634 90.8906 17.0593Z' />
      <path d='M139.23 28.3736L156.351 11.1503H148.17L133.892 25.5127H130.97V0.00261819H125.186V45.662H130.97V31.331H133.832L135.059 32.5694L147.975 45.5623H156.156L139.152 28.4568L139.23 28.3736' />
      <path d='M245.877 28.471V45.6597H240.093V25.6448C240.093 20.9038 236.273 17.057 231.556 17.057C226.843 17.057 223.019 20.9038 223.019 25.6448V28.471V45.6597H217.235V28.471L217.252 25.8541C217.257 25.7843 217.257 25.7145 217.257 25.6448C217.257 25.575 217.257 25.5052 217.252 25.4354C217.252 25.3133 217.248 25.1955 217.235 25.0778C216.949 20.6029 213.242 17.057 208.72 17.057C204.007 17.057 200.183 20.9038 200.183 25.6448V45.6597H194.399V11.2474H200.183V14.0824C202.563 12.2986 205.516 11.2474 208.711 11.2474H208.724C211.915 11.2518 214.855 12.3029 217.235 14.0781C218.332 14.898 219.307 15.8706 220.136 16.9697C220.955 15.875 221.931 14.9067 223.019 14.0911C225.403 12.3029 228.356 11.2474 231.556 11.2474C234.756 11.2474 237.708 12.3029 240.093 14.0911C243.596 16.7124 245.868 20.9125 245.868 25.6448L245.877 28.471' />
      <path d='M119.4 1.73112e-06H113.616V45.6597H119.4V1.73112e-06' />
      <path d='M160.506 25.5296C161.776 20.7148 166.103 17.1512 171.271 17.0642C171.336 17.0599 171.401 17.0599 171.466 17.0599C171.531 17.0599 171.596 17.0599 171.661 17.0642C176.83 17.1512 181.161 20.7148 182.431 25.5296H160.506ZM188.332 31.3481C188.488 30.4104 188.57 29.4509 188.57 28.4695C188.57 27.4664 188.484 26.4851 188.319 25.5296C186.936 17.4303 179.916 11.2631 171.466 11.2631C162.023 11.2631 154.362 18.9702 154.362 28.4695C154.362 37.9733 162.023 45.6797 171.466 45.6797C177.762 45.6797 183.259 42.2603 186.229 37.1661H178.806C176.83 38.863 174.263 39.8836 171.466 39.8836C168.665 39.8836 166.103 38.863 164.126 37.1661C162.374 35.6748 161.082 33.651 160.488 31.3481H188.332' />
      <path d='M251.966 59.978V59.9823H251.805C251.858 59.9823 251.914 59.9823 251.966 59.978Z' />
      <path d='M273.609 11.2474L266.912 25.5575L264.183 31.3758L263.547 32.7406L262.906 31.3758L260.183 25.5575L253.484 11.2474H247.089L253.788 25.5575L256.509 31.3758L258.582 35.7983L259.238 37.194L260.348 39.571L257.498 45.6597L255.813 49.2623C255.795 49.3143 255.769 49.3669 255.743 49.4193C254.364 52.2412 251.481 54.1821 248.147 54.1821H245.877V59.82C246.604 59.9346 247.348 60 248.108 60C249.07 60 250.011 59.9043 250.917 59.7212C252.99 59.3068 254.906 58.4389 256.55 57.2307C256.645 57.165 256.735 57.0955 256.827 57.0214C257.876 56.2058 258.813 55.2506 259.602 54.1821C260.053 53.5714 260.455 52.9216 260.81 52.2412L261.276 51.2468C261.284 51.2337 261.287 51.2206 261.293 51.2075L263.542 46.3968L263.889 45.6597L266.741 39.5666L267.851 37.194L270.574 31.3758L273.303 25.5575L280 11.2474H273.609' />
      <path
        d='M0 45.662L21.3747 0.00261603H27.9563L31.0953 6.70624L34.2907 13.532L49.331 45.662H42.9367L31.0953 20.3621L27.8999 13.5363L24.6655 6.62774L6.39458 45.662H0Z'
        fill='#58C1F5'
      />
      <path
        d='M12.8286 45.662L34.2031 0.0026131H40.7846L43.9237 6.70623L47.1191 13.532L62.1556 45.6539L55.7714 45.6751L43.9237 20.3621L40.7283 13.5363L37.4938 6.62774L19.2229 45.662H12.8286'
        fill='#58C1F5'
      />
    </svg>
  );
};
export default AlkemyLogo;
