export const months = [
  {
    name: 'JANUARY',
    id: 1,
  },
  {
    name: 'FEBRUARY',
    id: 2,
  },
  {
    name: 'MARCH',
    id: 3,
  },
  {
    name: 'APRIL',
    id: 4,
  },
  {
    name: 'MAY',
    id: 5,
  },
  {
    name: 'JUNE',
    id: 6,
  },
  {
    name: 'JULY',
    id: 7,
  },
  {
    name: 'AUGUST',
    id: 8,
  },
  {
    name: 'SEPTEMBER',
    id: 9,
  },
  {
    name: 'OCTOBER',
    id: 10,
  },
  {
    name: 'NOVEMBER',
    id: 11,
  },
  {
    name: 'DECEMBER',
    id: 12,
  },
];

export const languagesList = [
  { label: 'Español', value: 'es', iso: 'ESP' },
  { label: 'Português', value: 'pt', iso: 'BRA' },
  { label: 'English', value: 'en', iso: 'USA' },
];

export const Auth0Error = {
  VALIDATE_EMAIL: 'VALIDATE_EMAIL',
  LINKING_ACCOUNTS: 'LINKING_ACCOUNTS',
  USER_EXISTS: 'USER_EXISTS',
  USER_EXISTS_GOOGLE: 'USER_EXISTS_GOOGLE',
  USER_EXISTS_PASSWORD: 'USER_EXISTS_PASSWORD',
};

export const AcceptAssessmentInvitationStatus = {
  ALREADY_EXPIRED: 'ALREADY_EXPIRED',
  ALREADY_ACCEPTED: 'ALREADY_ACCEPTED',
  ACCEPTED_SUCCESSFULLY: 'ACCEPTED_SUCCESSFULLY',
  CLOSED_SEARCH: 'CLOSED_SEARCH',
  SEARCH_FULL_CANDIDATES: 'SEARCH_FULL_CANDIDATES',
  EXISTENT_ASSESSMENT_INVITATION: 'EXISTENT_ASSESSMENT_INVITATION',
};

export const ServerErrors = {
  INVITATION_NOT_FOUND: 'SERVER_ERROR_INVITATION_NOT_FOUND',
  RECRUIT_SESSION_NOT_FOUND: 'SERVER_ERROR_RECRUIT_SESSION_NOT_FOUND',
  RECRUIT_SESSION_STAGE_NOT_FOUND: 'SERVER_ERROR_RECRUIT_SESSION_STAGE_NOT_FOUND',
};

export const FileName = {
  CERTIFICATE: 'certificate',
};
