function useOutsideAlerter(ref, callback, context = false) {
  if (context) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
  }
}

export default useOutsideAlerter;
