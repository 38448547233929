import { useRef } from 'react';
import useOnScreen from '../../../hooks/useOnScreen';
import Progress from '../../Progress/Progress';
import rocketIcon from '../../../assets/profile/rocket.svg';
import goalIcon from '../../../assets/profile/goal.svg';
import './AdvanceBar.scss';
import useTranslations from '../../../hooks/useTranslations';

const AdvanceBar = ({
  className,
  engagement = 1000,
  objectiveIcon = goalIcon,
  advanceIcon = rocketIcon,
  advanceTitle,
  percentage,
}) => {
  const ref = useRef();
  const { t } = useTranslations();
  const onScreen = useOnScreen(ref);
  return (
    <div ref={ref} className={`custom-card bg-white dark:bg-dark-bold ${className}`}>
      <div className='py-1'>
        <div className='mb-2 pb-1 flex items-center justify-between'>
          <div
            className='flex justify-between advance-bar-bike-container'
            style={{
              width: `${onScreen ? engagement : 0}%`,
              transition: 'all ease-in 1s',
            }}>
            <span className='text-alkemy-black text-base  font-bold mr-2 dark:text-white'>
              {advanceTitle ?? t('MENU_ITEM_5')}
            </span>
            <span className='text-alkemy-black text-sm advance-bar-bike  font-bold flex items-center dark:text-white'>
              {Math.round(engagement)} {percentage ? '%' : t('POINTS')}
              <img src={advanceIcon} className='mx-2' />
            </span>
          </div>
          <img src={objectiveIcon} />
        </div>
        <Progress percent={engagement} height='0.75rem' />
      </div>
    </div>
  );
};

export default AdvanceBar;
