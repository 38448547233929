import useTranslations from '../../hooks/useTranslations';
import './NewStyleSelect.scss';

const NewStyleSelect = ({
  options,
  name,
  register,
  title,
  editable,
  required,
  error,
  validations,
  className,
  value,
}) => {
  const { t } = useTranslations();

  return (
    <div className={`flex flex-col dark:text-white relative ${className}`}>
      <span className='custom-input-label flex items-center font-light mb-1 italic dark:text-white text-sm truncate custom-input-label-active'>
        {title}
        {required && <span className='text-red-600 ml-1'>*</span>}
      </span>
      <div
        className={`outline-none flex justify-between w-full items-center px-2 shadow-sm font-semibold border-1 rounded-lg ${
          error.message ? 'border-red-600' : 'border-gray-300'
        }`}>
        <select
          data-testid={`select-${name}`}
          {...register(name, validations)}
          className='styled-select bg-transparent appearance-none outline-none bg-[calc(100%)_center] bg-no-repeat truncate text-xs py-1 pr-2 w-[calc(100%_-_1rem)] flex-grow font-semibold'
          name={name}>
          <option className='hidden' value=''></option>
          {options &&
            options?.map((option, index) => (
              <option
                className={`font-semibold w-full ${option.disabled && 'hidden'}  dark:text-black`}
                value={option.id}
                key={option.id}>
                {t(option.name) ?? option.name}
              </option>
            ))}
        </select>
      </div>
      {error.message && <span className='text-red-600 text-xs'>{t(error.message)}</span>}
    </div>
  );
};

export default NewStyleSelect;
