import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { stringify } from 'query-string';
import { ActionTypes, useContextState } from '../../../redux/contextState';
import useTranslations from '../../../hooks/useTranslations';
import { getCountries } from '../../../api/addressService';
import { saveProfile } from '../../../api/userService';
import arrowRight from '../../../assets/arrow-small.svg';
import alkemyLogo from '../../../assets/newSteps/logoAlkemy.svg';
import './step2.css';
import InputSearch from '../../../components/Input/InputSearch';

const Step2 = () => {
  const { t } = useTranslations();
  const [countries, setCountries] = useState([]);
  const [countriesFiltered, setCountriesFiltered] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const [countrySelected, setCountrySelected] = useState();
  const history = useHistory();
  const { contextState, setContextState } = useContextState();
  const welcomePage = contextState?.whitelabel?.routes?.includes('WELCOME');

  useEffect(() => {
    getCountries().then(response => {
      setContextState({
        type: ActionTypes.SetCountries,
        value: response,
      });
      setCountries(response?.filter(country => country.isCertifiable));
      setCountriesFiltered(response?.filter(country => country.isCertifiable));
    });
  }, []);

  const changeCountry = async value => {
    setCountrySelected(value);
    const dataToUpdate = { address: { countryId: Number(value) } };
    await saveProfile(dataToUpdate).then(() => {
      history.push('steps?page=3');
      setContextState({
        type: ActionTypes.SetProfile,
        value: dataToUpdate,
      });
    });
  };

  useEffect(() => {
    const results = countries.filter(country => country.displayName.toLowerCase().includes(textSearch.toLowerCase()));
    setCountriesFiltered(results);
  }, [textSearch]);

  return (
    <div className='flex flex-col p-8 pt-11' style={{ height: '100%' }}>
      <div className='flex' data-testid='step2-container-logo-div'>
        <img src={alkemyLogo} className='step-logo-alkemy' alt='logo-alkemy' data-testid='step2-logo-img' />
        <img
          src={arrowRight}
          aria-hidden='true'
          onClick={() => history.push('/steps?page=1')}
          className='ml-auto float-right img-arrow-back cursor-pointer'
          data-testid='step2-arrow-back-img'
        />
      </div>
      <div className='mt-5 h-full'>
        <span className='font-bold step1-text dark:text-white' data-testid='step2-your-country-span'>
          {t('STEP_WE_ARE_YOU_FROM')}
        </span>
        <InputSearch textSearch={textSearch} setTextSearch={setTextSearch} />
        <div
          className='flex flex-col countries-container p-2 form-step-container 2xl:mt-12 max-h-[25rem]'
          data-testid='step2-countries-div'>
          {countriesFiltered.map(country => (
            <button
              className={`container-country flex flex-row p-3 mt-2 relative cursor-pointer border-0 dark:bg-alkemy-black-bold ${
                String(country?.id) === String(countrySelected) ? 'selectedOptions' : 'unselectedOptions'
              }`}
              key={country.id}
              value={country.id}
              onClick={e => changeCountry(e.target.value)}
              data-testid={`step2-country-${country.id}`}>
              <img
                src={`${process.env.RAZZLE_RUNTIME_ASSETS}/assets/countries/${country?.isoCode}.svg`}
                className='mr-3 mt-1 country-flag-img'
                aria-hidden='true'
                onClick={() => changeCountry(String(country?.id))}
              />
              <span className='country-text' onClick={() => changeCountry(String(country?.id))}>
                {country?.displayName}
              </span>
              <img
                src={arrowRight}
                onClick={() => changeCountry(String(country?.id))}
                className='absolute right-0 mt-1 countries-arrow'
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Step2;
