import { ActionTypes, useContextState } from '../redux/contextState';

const useSidebar = () => {
  const { contextState, setContextState } = useContextState();

  const setCollapsed = collapsed => {
    setContextState({
      type: ActionTypes.SetSidebarCollapsed,
      value: collapsed,
    });
  };

  return { setCollapsed, collapsed: contextState?.ux?.sidebar?.collapsed ?? true };
};

export default useSidebar;
