import {
  checkPassword,
  isEmail,
  iconsRegex,
  regexDigits,
  regexLowerCase,
  regexSpecialCharacters,
  regexUpperCase,
  regexSpecialCharName,
} from '../../../constants/regexValidations';

export const validations = t => ({
  name: value => {
    const errors = [];
    if (isEmpty(value)) {
      errors.push(t('PROFILE_NAME_REQUIRED_ERROR'));
    }
    if (isMin(value, 3)) {
      errors.push(t('PROFILE_MINIMUM_CHARACTERS'));
    }
    if (isMax(value, 20)) {
      errors.push(t('PROFILE_MAXIMUM_CHARACTERS'));
    }
    if (value.match(iconsRegex)) {
      errors.push(t('PROFILE_NAME_ONLY_LETTERS'));
    }
    if (regexSpecialCharName.test(value)) {
      errors.push(t('PROFILE_NO_SPECIAL_CHAR_ERROR'));
    }
    return errors;
  },
  lastName: value => {
    const errors = [];
    if (isEmpty(value)) {
      errors.push(t('PROFILE_LASTNAME_REQUIRED_ERROR'));
    }
    if (isMin(value, 3)) {
      errors.push(t('PROFILE_MINIMUM_CHARACTERS'));
    }
    if (isMax(value, 20)) {
      errors.push(t('PROFILE_MAXIMUM_CHARACTERS'));
    }
    if (value.match(iconsRegex)) {
      errors.push(t('PROFILE_LASTNAME_ONLY_LETTERS'));
    }
    if (regexSpecialCharName.test(value)) {
      errors.push(t('PROFILE_NO_SPECIAL_CHAR_ERROR'));
    }
    return errors;
  },
  email: value => {
    const errors = [];
    if (isEmpty(value)) {
      errors.push(t('EMAIL_REQUIRED_ERROR'));
    }
    if (!value.match(isEmail)) {
      errors.push(t('PROFILE_EMAIL_INVALID'));
    }
    return errors;
  },
  password: value => {
    const errors = [];
    if (isEmpty(value)) {
      errors.push(t('PASSWORD_REQUIRED_ERROR'));
    }
    if (isMin(value, 8)) {
      errors.push(t('PASSWORD_VALIDATION_LENGTH'));
    }
    if (!checkPassword(regexLowerCase, value)) {
      errors.push(t('PASSWORD_VALIDATION_MINUSCULE'));
    }
    if (!checkPassword(regexUpperCase, value)) {
      errors.push(t('PASSWORD_VALIDATION_CAPITAL_LETTER'));
    }
    if (!checkPassword(regexDigits, value)) {
      errors.push(t('PASSWORD_VALIDATION_NUMBER'));
    }
    if (!checkPassword(regexSpecialCharacters, value)) {
      errors.push(t('PASSWORD_VALIDATION_SPECIAL_CHARACTER'));
    }
    return errors;
  },
  verifypassword: value => {
    const errors = [];
    if (isEmpty(value)) {
      errors.push(t('VERIFY_PASSWORD_REQUIRED_ERROR'));
    }
    return errors;
  },
});
export const validationsFn = t => (key, value) => {
  return validations(t)[key](value);
};

export const isEmpty = value => {
  return value?.length === 0;
};

export const isMin = (value, min) => {
  return value?.length < min;
};

export const isMax = (value, max) => {
  return value?.length > max;
};
