import React from 'react';
import checkIcon from '../../assets/profile/capsule-check.svg';
import useTranslations from '../../hooks/useTranslations';
import './EnrollmentsCapsules.scss';

const EnrollmentsCapsules = ({ capsule }) => {
  const { t } = useTranslations();
  return (
    <div className='bg-white dark:bg-dark-bold rounded capsule-container py-3 mb-3 px-4'>
      <div className='flex items-center pb-2'>
        <span className='mr-2 font-bold  genres-chart-text dark:text-white'>{capsule?.course}</span>
        <img src={checkIcon} alt={t('ICON_CHECK')} />
      </div>
      <span className=' font-small text-wrap pr-4 dark:text-white'>{capsule?.completed_text}</span>
    </div>
  );
};

export default EnrollmentsCapsules;
