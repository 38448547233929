import { alkymetricsClient } from './alkymetricsClient';

export async function getFormationsInstitutes() {
  return alkymetricsClient.get('/institute').then(response => {
    return response.data;
  });
}

export async function getFormationsInstitutesPaginated({ offset = 0, limit = 15, search = '' }) {
  return alkymetricsClient
    .get('/institute/paginated', {
      params: {
        offset,
        limit,
        search,
      },
    })
    .then(response => {
      return response.data;
    });
}

export async function getFormations() {
  return alkymetricsClient.get('/formationType').then(response => {
    return response.data;
  });
}

export async function getFormationStatus() {
  return alkymetricsClient.get('/formationStatus').then(response => {
    return response.data;
  });
}
