const CrossSidebarIcon = () => {
  return (
    <svg
      className='stroke-current fill-transparent'
      width='25'
      height='25'
      viewBox='0 0 25 25'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.4999 22.9166C18.2529 22.9166 22.9166 18.2529 22.9166 12.5C22.9166 6.74701 18.2529 2.08331 12.4999 2.08331C6.74695 2.08331 2.08325 6.74701 2.08325 12.5C2.08325 18.2529 6.74695 22.9166 12.4999 22.9166Z'
        strokeWidth='2'
      />
      <path
        className='fill-current'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.05111 15.4758C7.66058 15.8663 7.66058 16.4994 8.05111 16.89L8.11003 16.9489C8.50056 17.3394 9.13372 17.3394 9.52425 16.9489L12.4999 13.9732L15.4756 16.9489C15.8661 17.3394 16.4993 17.3394 16.8898 16.9489L16.9487 16.89C17.3392 16.4994 17.3392 15.8663 16.9487 15.4758L13.973 12.5001L16.9489 9.52427C17.3394 9.13375 17.3394 8.50058 16.9489 8.11006L16.8899 8.05113C16.4994 7.66061 15.8663 7.66061 15.4757 8.05113L12.4999 11.027L9.52409 8.05114C9.13356 7.66061 8.5004 7.66061 8.10988 8.05114L8.05095 8.11006C7.66043 8.50059 7.66043 9.13375 8.05095 9.52428L11.0268 12.5001L8.05111 15.4758Z'
      />
    </svg>
  );
};
export default CrossSidebarIcon;
