import { Modal, notification, Skeleton } from 'antd';
import { ActionTypes, useContextState } from '../../../redux/contextState';
import { useEffect } from 'react';
import { getScreenings, getTechnologies } from '../../../api/userService';
import useTranslations from '../../../hooks/useTranslations';
import isComplete from '../../../utils/steps/isComplete';
import { Link, useHistory } from 'react-router-dom';
import { handleRedirect } from '../../../utils/handleRedirect';
import { useState } from 'react';
import { TrackEvent } from '../../../utils/segmentUtils';
import './Challenges.scss';
import useCompleteProfileModal from '../../../hooks/useCompleteProfileModal/useCompleteProfileModal';
import { requireCompleteProfile } from '../../../constants/requireCompleteProfile';

const Challenges = ({ invitations }) => {
  const [loading, setLoading] = useState(false);
  const { contextState, setContextState } = useContextState();
  const { profile, screenings, profileChallenges } = contextState;
  const { challenges } = profileChallenges;
  const { t } = useTranslations();

  const { renderCompleteProfileModal } = useCompleteProfileModal();

  useEffect(() => {
    if (!contextState?.announcements?.length) {
      setLoading(true);
      getScreenings('TECHNICAL').then(async res => {
        const technologies = contextState.technologies.length
          ? contextState.technologies
          : await getTechnologies('all');
        const screeningWithTechnology = res?.map(item => ({
          ...item,
          technology: technologies?.find(tech => tech.id === item.technologyId),
        }));

        setContextState({
          type: ActionTypes.SetTechnologies,
          value: technologies,
        });

        setContextState({
          type: ActionTypes.SetAnnouncements,
          value: [...screeningWithTechnology],
        });
        setLoading(false);
      });
    }
  }, []);

  const applyScreenings = async challenge => {
    TrackEvent('screening-start', { userId: profile.id });
    handleRedirect(
      `${process.env.RAZZLE_RUNTIME_SCREENINGS}?technologyId=${challenge?.technologyId}&levelId=${challenge?.levelId}&userId=${contextState.profile.id}&token=`,
      t,
    );
  };

  return (
    <div
      className='description-container bg-white dark:bg-dark-bold rounded mx-auto p-4 mb-3'
      data-testid='challenges-container-div'>
      <div className='flex flex-col mt-1'>
        {!screenings?.length ||
        !invitations?.filter(invitation => {
          return (
            new Date(invitation.stage?.endDate).getTime() > new Date().getTime() &&
            invitation.status !== 'IN_PROGRESS' &&
            invitation.status !== 'FINISHED'
          );
        })?.length ? (
          <>
            {challenges?.length > 0 ? (
              <span
                className='text-base font-bold text-alkemy-black dark:text-white'
                data-testid='challenges-title-span'>
                {t('CHALLENGES')}
              </span>
            ) : (
              <span
                className='text-xl font-bold text-alkemy-black dark:text-white'
                data-testid='challenges-welcome-span'>
                ¡{t('NEWS_WELCOME')} {t('TO_ALKEMY')}!
              </span>
            )}
          </>
        ) : (
          <span
            className='text-base font-bold text-alkemy-black dark:text-white'
            data-testid='challenges-challenges-title-span'>
            {t('CHALLENGES')}
          </span>
        )}
        <span
          className='text-sm font-medium text-[#5E5E5E] dark:text-white mt-2'
          data-testid='challenges-subtitle-span'>
          {t('HOME_CHALLENGES_SUBTITLE')}
        </span>
        <Link
          to='/inscription'
          className='w-fit text-sm font-medium text-purple dark:text-white pointer mt-3'
          data-testid='challenges-see-all-a'>
          {t('SEE_ALL')}
        </Link>
      </div>
      <div
        className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-2 py-2'
        data-testid='challenges-card-container-div'>
        {!loading
          ? contextState?.announcements
              ?.sort((a, b) => {
                if (a?.technology?.order < b?.technology?.order) return 1;
                if (a?.technology?.order > b?.technology?.order) return -1;
                if (a?.technology?.displayName > b?.technology?.displayName) {
                  return 1;
                }
                return -1;
              })
              ?.filter(announcement => announcement?.technology?.isCertificable)
              ?.filter((_, index) => index <= 3)
              ?.map((challenge, index) => (
                <div className='my-2 p-2' key={index}>
                  <div
                    className='h-full bg-white dark:bg-dark-normal pt-3 px-3 pb-1 flex flex-col border-[#828282] border-2 rounded-lg dark:border-gray-500'
                    key={index}>
                    <span
                      className='font-semibold text-alkemy-black dark:text-white break-words text-base text-center'
                      data-testid='challenges-card-title-span'>
                      {`${t('MY_CHALLENGES_CARD_TITLE')} ${
                        challenge?.technology?.displayName ?? challenge?.screeningsName
                      }`}
                    </span>
                    <div className='pt-4 h-full relative flex justify-center items-end'>
                      <img
                        className='h-[2.813rem] w-auto relative'
                        src={challenge?.technology?.imageUrl}
                        data-testid='challenges-card-technology-img'
                      />
                    </div>
                    <div className='w-full flex justify-center mt-3 mb-2'>
                      <button
                        className='button-apply-challenge py-2 px-5'
                        onClick={() => {
                          if (requireCompleteProfile === 'true') {
                            if (isComplete(contextState?.profile) >= 2) {
                              applyScreenings(challenge);
                            } else {
                              renderCompleteProfileModal();
                            }
                          } else {
                            applyScreenings(challenge);
                          }
                        }}
                        data-testid='challenges-card-apply-button'>
                        <span
                          className='button-apply-challenge-text font-roboto font-bold text-white text-decoration-none'
                          data-testid='challenges-card-apply-button-text-span'>
                          ¡{t('CERTIFY')}!
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              ))
          : [1, 2, 3].map(it => (
              <div className='p-0 my-2' key={it}>
                <div className='bg-white dark:bg-dark-normal p-3 challenges-cards flex flex-col dark:border-gray-500'>
                  <Skeleton.Input
                    active={true}
                    size='small'
                    className='w-24 h-5'
                    data-testid='challenges-skeleton-input-one'
                  />
                  <Skeleton.Input
                    active={true}
                    size='small'
                    className='w-14 mt-2 h-4'
                    data-testid='challenges-skeleton-input-two'
                  />
                  <Skeleton.Input
                    active={true}
                    size='small'
                    className='w-32 mt-3 h-4'
                    data-testid='challenges-skeleton-input-three'
                  />
                  <Skeleton.Input
                    active={true}
                    size='small'
                    className='w-20 mt-3 mb-4 h-6 mx-auto'
                    data-testid='challenges-skeleton-input-four'
                  />
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default Challenges;
