import { useEffect, useState } from 'react';
import Banner from '../../components/Banner/Banner';
import { getProfileChallenges } from '../../api/userService';
import { ActionTypes, useContextState } from '../../redux/contextState';
import { useHistory } from 'react-router-dom';
import useTranslations from '../../hooks/useTranslations';
import EmptyComponent from '../../components/EmptyComponent/EmptyComponent';
import Spinner from '../../components/Spinner/Spinner';
import ChallengeCard from './ChallengeCard/ChallengeCard';
import ModalCustom from '../../components/Modal/Modal';
import { Window } from '../../helpers/window';
import './inscription.css';
import Container from '../../components/Container/Container';

const Challenges = () => {
  const { t } = useTranslations();
  const history = useHistory();
  const { contextState, setContextState } = useContextState();
  const [show, setShow] = useState(false);
  const [modalActions, setModalActions] = useState({
    title: '',
    subtitle: '',
    onOk: '',
    onCancel: '',
    onOkText: '',
    onCancelText: '',
    wrapClassname: '',
  });
  const { title, subtitle, onOk, onCancel, onOkText, onCancelText, wrapClassname } = modalActions;
  useEffect(() => {
    if (contextState.isAuthenticated) {
      if (!contextState.profileChallenges.length > 0) {
        getProfileChallenges().then(res => {
          setContextState({
            type: ActionTypes.SetProfileChallenges,
            value: { ...res, challenges: res?.challenges?.filter(res => res?.reviewedAt) },
          });
        });
      }
    }
  }, []);

  const handleShow = show => {
    setShow(show);
    if (Window()) {
      window.localStorage.setItem('showMoreInfoModal', 'false');
    }
  };

  return (
    <div className='relative w-full pb-5'>
      <Banner text={t('MY_CHALLENGES')} svg='🤓' />
      <Container>
        {contextState?.loading ? (
          <Spinner show={contextState?.loading} />
        ) : !!contextState?.profileChallenges?.challenges?.length ||
          !!contextState?.profileChallenges?.screenings?.length ? (
          <div className='description-container relative bg-white dark:bg-dark-bold shadow-lg rounded m-auto p-4'>
            <ModalCustom
              title={title}
              subtitle={subtitle}
              show={show}
              wrapClassname={wrapClassname}
              setShow={handleShow}
              onOk={onOk}
              onOkDisabled={false}
              onCancel={onCancel}
              onCancelText={onCancelText}
              onOkText={onOkText}
            />
            <div className='grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 p-1 md:px-5'>
              {!!contextState?.profileChallenges?.challenges?.length &&
                contextState?.profileChallenges?.challenges?.map((challenge, index) => (
                  <ChallengeCard challenge={challenge} index={index} key={index} />
                ))}
              {!!contextState?.profileChallenges?.screenings?.length &&
                contextState?.profileChallenges?.screenings?.map((screening, index) => (
                  <ChallengeCard challenge={screening} index={index} key={index} />
                ))}
            </div>
          </div>
        ) : (
          <div className='description-container bg-white dark:bg-dark-bold rounded m-auto p-4'>
            <EmptyComponent
              className='py-5'
              title={t('NOT_REGISTER_CHALLENGE')}
              subtitle={t('APPLY_TO_ONE')}
              button={{
                className: 'mt-4 button-unsuscribe button-unsuscribe-text font-roboto font-bold px-4 mx-auto py-2',
                text: t('CERTIFY'),
                onClick: () => history.push('/inscription'),
              }}
            />
          </div>
        )}
      </Container>
    </div>
  );
};

export default Challenges;
