import { useRef, useState } from 'react';
import { changeDisabledAccount } from '../../api/userService';
import useClickOutside from '../../hooks/useClickOutside';
import { useContextState } from '../../redux/contextState';
import CloseIcon from '../../assets/profile/CloseIcon';
import './modalDisabledAccount.scss';
import { auth0Client } from '../../auth0/auth0';
import useTranslations from '../../hooks/useTranslations';

const ModalCustom = ({ show, setShow }) => {
  const modalRef = useRef(null);
  const { t } = useTranslations();
  useClickOutside(modalRef, () => setShow(false));
  const { contextState } = useContextState();
  const [stepDisabledAccount, setStepDisabledAccount] = useState(0);
  const [checkBoxValue, setCheckBoxValue] = useState(false);

  const disabledAccount = () => {
    changeDisabledAccount(contextState.profile.id, true).then(res => {
      setShow(false);
      setCheckBoxValue(false);
      setTimeout(() => {
        setStepDisabledAccount(0);
      }, 1000);
      auth0Client().logout({ returnTo: window.location.origin });
    });
  };
  return (
    <div className={`modal_custom flex justify-center fixed top-0 left-0 my-0 mx-auto ${show ? 'visible' : ''}`}>
      <div
        className='modal__wrap_custom flex flex-col container-modal-screening w-full text-center my-auto dark:bg-alkemy-black-bold'
        ref={modalRef}>
        <span
          className='absolute top-0 right-0 mt-2 cursor-pointer dark:text-white'
          aria-hidden='true'
          onClick={() => {
            setShow(false);
            setTimeout(() => {
              setStepDisabledAccount(0);
            }, 1000);
          }}>
          <CloseIcon className='position-absolute right-0 top-0 mt-3 mr-4 cursor-pointer button-close-modal font-weight-bold' />
        </span>
        <div className='flex flex-col pt-4 container-components-modal my-10'>
          <span className='h3 font-bold dark:text-white'>{t('DISABLED_ACCOUNT')}</span>
          {!stepDisabledAccount ? (
            <div className='flex flex-col'>
              <span className='font-montserrat text-sm text-black mt-3'>{t('DO_YOU_WANT_DISABLED_ACCOUNT')}</span>
              <span className='font-montserrat text-xxs text-black mt-2'>
                {t('DO_YOU_WANT_DISABLED_ACCOUNT_DESCRIPTION')}
              </span>
              <button
                className='bg-transparent border-0 mt-2'
                data-testid='button-modal-disabled-account'
                onClick={() => {
                  setStepDisabledAccount(1);
                  setCheckBoxValue(false);
                }}>
                <span className='font-montserrat text-xxs text-purple mt-1'>
                  {t('DO_YOU_WANT_DISABLED_ACCOUNT_BUTTON')}
                </span>
              </button>
            </div>
          ) : (
            <div className='flex flex-col'>
              <span className='font-montserrat text-xs text-black mt-3 text-left'>
                {t('DISABLED_ACCOUNT_STEP_TWO_TITLE')}
              </span>
              <span className='font-montserrat text-xs text-black text-left'>
                {t('DISABLED_ACCOUNT_STEP_TWO_DESCRIPTION')}
              </span>
              <span className='font-montserrat text-xs text-black my-3 text-left'>
                {t('DISABLED_ACCOUNT_STEP_TWO_DESCRIPTION_1')}
              </span>
              <span className='font-montserrat text-xs text-black text-left'>
                {t('DISABLED_ACCOUNT_STEP_TWO_DESCRIPTION_2')}
              </span>
              <div className='flex flex-col mt-2'>
                <label className='text-left non-selectable text-xxs flex items-center'>
                  <input
                    data-testid='checkbox-disabled-account'
                    type='checkbox'
                    id='checkBoxDisabledAccount'
                    className='mr-2'
                    checked={checkBoxValue}
                    onChange={() => setCheckBoxValue(!checkBoxValue)}
                  />
                  {t('DISABLED_ACCOUNT_STEP_TWO_CHECKBOX')}
                </label>
                <button
                  data-testid='button-confirm-disabled-account'
                  className={`text-center mt-4 button-disabled-account mx-auto p-3 button-disabled-account-active`}
                  disabled={!checkBoxValue}
                  onClick={disabledAccount}>
                  <span className='font-montserrat font-weight-bold text-xs text-white'>
                    {t('DISABLED_ACCOUNT_STEP_TWO_BUTTON')}
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalCustom;
