export const imgs = [
  `${process.env.RAZZLE_RUNTIME_ASSETS}/login/1b3d8251-dac9-46ef-9ef4-ae7889585b9a.webp`,
  `${process.env.RAZZLE_RUNTIME_ASSETS}/login/04bd3c20-fe06-4fed-a7b4-fbdadbc3ceb4.webp`,
  `${process.env.RAZZLE_RUNTIME_ASSETS}/login/17b12564-457d-4907-9474-db51e4e22e29.webp`,
  `${process.env.RAZZLE_RUNTIME_ASSETS}/login/91e8b9ee-18bd-4bf3-889b-5f7eb809e42d.webp`,
  `${process.env.RAZZLE_RUNTIME_ASSETS}/login/531cb089-f554-48f5-bbc3-530615faff98.webp`,
  `${process.env.RAZZLE_RUNTIME_ASSETS}/login/6958c3b2-0d8d-45b9-a1cb-ddb89c5aca94.webp`,
  `${process.env.RAZZLE_RUNTIME_ASSETS}/login/b142e60b-e037-40e7-911d-e22f98696ee1.webp`,
  `${process.env.RAZZLE_RUNTIME_ASSETS}/login/ff321e54-afdc-4500-a3a3-5eca2362b57a.webp`,
];
