import React, { useState, useEffect } from 'react';
import useTranslations from '../../hooks/useTranslations';
import './SideBarDropDown.css';

const SideBarDropDown = ({
  options,
  icon,
  collapsed,
  setCollapsed = () => {},
  buttonText,
  imgClassName,
  selected,
  onClick,
  responsive,
  redirectProfile,
  editable,
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (collapsed) {
      setShow(false);
    }
  }, [collapsed]);

  const { t } = useTranslations();

  return (
    <div
      className={`relative cursor-pointer ${collapsed ? 'py-2' : 'py-0'} ${selected && 'sidebar-active'}`}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      onClick={onClick}>
      <div className={`nav-links__item ml-1 position-relative flex items-center text-white overflow-hidden`}>
        <div
          className={`mr-3 pr-1 ${selected ? 'text-alkemy-blue' : 'text-white'} ${
            imgClassName ? imgClassName : 'ml-4'
          }`}>
          {icon}
        </div>
        <div
          className={`list-button border-0 py-2 relative flex justify-between items-center bg-transparent whitespace-nowrap w-full ${
            collapsed ? 'hidden ml-3' : 'pr-3'
          }`}>
          <span
            className={`link_name font-roboto ${collapsed && 'collapsed overflow-hidden'}`}
            style={{ marginLeft: '0' }}>
            {buttonText}
          </span>
        </div>
        {collapsed && (
          <ul
            className={`link-list collapsed-list absolute overflow-hidden top-0 list-unstyled py-2 
            ${show && 'show-list px-3'}
          `}>
            <span className={`text-white whitespace-nowrap	 ml-0 font-bold block text-left ${options && 'pb-1'}`}>
              {buttonText}
            </span>
            {options &&
              options.map((option, index) => (
                <li key={index} className={`m-0 py-2 list-item text-white font-bold`}>
                  <button
                    className='font-roboto font-small bg-transparent border-0 whitespace-nowrap'
                    onClick={() => {
                      redirectProfile(editable, option?.path);
                      setCollapsed(true);
                    }}>
                    {option.label}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      {!collapsed && (
        <ul
          className={`link-list list-unstyled pl-4 w-full ml-5 ${!collapsed && ''} ${responsive && 'bg-transparent'}`}>
          {options &&
            options.map((option, index) => (
              <li
                key={index}
                className={`m-0 py-2 pl-4 list-item text-white font-bold w-max text-start ${collapsed && 'hidden'}`}>
                {' '}
                <button
                  className='font-roboto pl-2 ml-1 font-small bg-transparent border-0 whitespace-nowrap'
                  onClick={() => {
                    redirectProfile(editable, option?.path);
                    setCollapsed(true);
                  }}>
                  {option.label}
                </button>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default SideBarDropDown;
