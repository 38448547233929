import React, { useEffect, useState } from 'react';
import { useContextState, ActionTypes } from '../../redux/contextState';
import { getProfileTeam } from '../../api/userService';
import { formatDate } from '../../utils/formatDate';
import useTranslations from '../../hooks/useTranslations';
// Libraries
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import moment from 'moment';
// Components
import Avatar from '../../components/Avatar/Avatar';
import Spinner from '../../components/Spinner/Spinner';
import Ticket from '../../components/Ticket/Ticket';
import Banner from '../../components/Banner/Banner';
import Container from '../../components/Container/Container';
// Assets
import SlackIcon from '../../assets/myGroup/slack_icon.svg';
import flagIcon from '../../assets/myGroup/icon-flag.svg';
import boltIcon from '../../assets/myGroup/icon-bolt.svg';
import linkedinIcon from '../../assets/myGroup/icon_Linkedin.svg';
import githubIcon from '../../assets/myGroup/icon_Github.svg';
import './myGroup.css';

const MyGroup = () => {
  const { contextState, setContextState } = useContextState();
  const [loading, setLoading] = useState(false);
  const [isAcceleration, setIsAcceleration] = useState(true);
  const teamSorted = contextState?.teams.length
    ? contextState.teams.sort((a, b) => b.group?.isAccelerating - a.group?.isAccelerating)
    : [];
  const team = isAcceleration ? teamSorted[0] : teamSorted[1];
  const { t } = useTranslations();

  useEffect(() => {
    if (!contextState?.teams?.length) {
      setLoading(true);
      getProfileTeam()
        .then(res =>
          setContextState({
            type: ActionTypes.SetProfileTeam,
            value: res,
          }),
        )
        .catch(err => {
          setContextState({
            type: ActionTypes.SetProfileTeam,
            value: [],
          });
          Sentry.captureException(err);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  return (
    <div className='px-0 mb-5 pb-5 container-fluid w-full'>
      {loading ? (
        <Spinner show={loading} />
      ) : (
        <>
          <div className='relative w-full hero-container'>
            <Banner
              text={`${t('MY_GROUP_TITLE')}`}
              isSwitch={contextState.teams.length < 2 ? false : true}
              isAcceleration={isAcceleration}
              icon={contextState?.whitelabel?.images?.BANNER_LOGO}
              toggleAcceleration={() => setIsAcceleration(!isAcceleration)}
            />
            <Container>
              <div className='relative team-info w-full flex flex-col items-center px-2'>
                <div className='description-container w-full bg-white dark:bg-dark-bold flex flex-col lg:flex-row items-center p-4 rounded'>
                  <div className='flex justify-between lg:hidden'>
                    {team?.group?.isAccelerating && (
                      <Ticket
                        text={
                          team?.group?.currentSprint === -1
                            ? t('MY_GROUP_STATUSES_ENDED')
                            : `${t('MY_GROUP_SPRINT')} ${team?.group?.currentSprint} ${t('MY_GROUP_OF')} ${
                                team?.group?.sprints
                              }`
                        }
                        className='ticket-sprint-text uppercase font-bold font-roboto'
                      />
                    )}
                    {team?.group?.slack && (
                      <a href={team?.group?.slack} target='_blank' rel='noreferrer'>
                        <img src={SlackIcon} alt={t('SLACK_ALT')} width='32' height='32' />
                      </a>
                    )}
                  </div>
                  <div className='flex flex-col'>
                    <span className='text-base  font-bold flex items-center dark:text-white'>
                      {t('MY_GROUP_TEAM')}
                      <Ticket
                        text={
                          team?.group?.currentSprint === -1
                            ? t('MY_GROUP_STATUSES_ENDED')
                            : `${team?.group?.isAccelerating ? t('MY_GROUP_SPRINT') : t('MY_GROUP_WEEK')} ${
                                team?.group?.currentSprint
                              } ${t('MY_GROUP_OF')} ${team?.group?.sprints}`
                        }
                        className='ticket-sprint-text uppercase hidden lg:block ml-4 mr-5 font-bold font-roboto'
                      />
                    </span>
                    {team?.group?.name && <span className='text-muted  dark:text-white'>{team.group.displayName}</span>}
                  </div>
                  <div className='hidden lg:flex flex-col border-x-4  border-grey-500 px-4 py-2 mx-4'>
                    <div className='flex font-bold  dark:text-white'>
                      <img src={boltIcon} alt={t('VOLT_ALT')} className='mr-2' />
                      {team?.group?.technology && team?.group?.technology?.displayName}
                    </div>
                    <span className='text-muted  font-small pl-3 ml-1 dark:text-white'>{t('MY_GROUP_TECHNOLOGY')}</span>
                  </div>
                  <div className='hidden lg:flex justify-between grow py-2 ml-2'>
                    <div className='flex flex-col'>
                      <div className='flex font-bold  dark:text-white'>
                        <img src={flagIcon} alt={t('FLAG_ALT')} className='mr-2 font-bold fecha-inicio' />
                        {formatDate(t, moment(team?.group?.startDate))}
                      </div>
                      <span className='text-muted  font-small ml-4 pl-2 dark:text-white'>
                        {t('MY_GROUP_START_DATE')}
                      </span>
                    </div>
                    {team?.group?.slack && (
                      <a href={team?.group?.slack} target='_blank' rel='noreferrer'>
                        <img src={SlackIcon} alt={t('SLACK_ALT')} width='40' height='40' />
                      </a>
                    )}
                  </div>
                </div>
                <div className='relative bg-white flex justify-center py-3 pl-lg-1 lg:hidden rounded w-full mt-2'>
                  <span className='px-4 py-2 font-bold  border-right'>
                    <img src={boltIcon} alt={t('VOLT_ALT')} className='mr-2' />
                    {team?.group?.technology && team?.group?.technology?.displayName}
                  </span>
                  <span className='px-4 py-2  font-bold'>
                    <img src={flagIcon} alt={t('FLAG_ALT')} className='mr-1' />
                    {formatDate(t, moment(team?.group?.startDate))}
                  </span>
                </div>
              </div>
              <div className='px-2 pt-2 pt-lg-3'>
                <div className='description-container relative w-full flex flex-col lg:flex-row justify-center m-auto'>
                  <div className='relative mentor-container self-start w-full mr-3'>
                    <div className='bg-white dark:bg-dark-bold rounded flex flex-col items-center p-4  font-bold'>
                      <span className='hidden lg:block text-base  mb-3 dark:text-white'>{t('MY_GROUP_MY_MENTOR')}</span>
                      <Avatar
                        img={team?.group?.mentor?.picture}
                        capitalLetters={`${team?.group?.mentor?.first_name?.length ? member?.first_name[0] : ''} ${
                          team?.group?.mentor?.last_name?.length ? member?.last_name[0] : ''
                        }`}
                        size='xs'
                      />
                      <div className='flex flex-col py-3 ml-3 lg:ml-0'>
                        <span className='block lg:hidden  font-small dark:text-white text-center lg:text-left'>
                          {t('MY_GROUP_MY_MENTOR')}
                        </span>
                        <div className=' font-bold text-base flex items-center dark:text-white'>
                          {team?.group?.mentor?.slackUrl && (
                            <a href={team?.group?.mentor?.slackUrl} target='_blank' rel='noreferrer'>
                              <img src={SlackIcon} alt={t('SLACK_ALT')} className='mr-2 mentor-slack-icon' />
                            </a>
                          )}
                          {team?.group?.mentor && team?.group?.mentor?.displayFirstName}{' '}
                          {team?.group?.mentor && team?.group?.mentor?.displayLastName}
                        </div>
                      </div>
                      <div className='hidden lg:block mb-5'>
                        {team?.group?.mentor?.linkedinUrl && (
                          <a href={team?.group?.mentor?.linkedinUrl} target='_blank' rel='noreferrer'>
                            <img src={linkedinIcon} alt={t('LINKEDIN_ALT')} className='mr-2' />
                          </a>
                        )}
                        {team?.group?.mentor?.githubUrl && (
                          <a href={team?.group?.mentor?.githubUrl} target='_blank' rel='noreferrer'>
                            <img src={githubIcon} alt={t('GITHUB_ALT')} className='mr-2' />
                          </a>
                        )}
                      </div>
                      <Link
                        to='/mentor'
                        className='px-3 py-2 text-decoration-none font-roboto bg-maya-blue rounded-full text-white font-small'>
                        {t('MY_GROUP_MORE_DETAILS')}
                      </Link>
                    </div>
                    <div className='flex mt-2 lg:mt-3'>
                      {Boolean(team?.group?.assistants?.length) &&
                        team?.group?.assistants.map((item, index) => (
                          <div
                            key={item?.id}
                            className={`bg-white dark:bg-dark-bold rounded w-full px-3 py-4 flex flex-col items-center ${
                              team?.group?.assistants?.length > 1 && index === 0 && 'mr-3'
                            }`}>
                            <Avatar
                              size='xs'
                              img={item?.assistant?.picture}
                              initials={item?.assistant?.firstName[0] + item?.assistant?.lastName[0]}
                            />
                            <div className='font-small font-roboto font-bold pt-2 hidden lg:flex text-center dark:text-white'>
                              <a href={item?.assistant?.slackUrl} target='_blank' rel='noreferrer'>
                                <img className='mr-1 slack-icon-small' src={SlackIcon} alt={t('SLACK_ALT')} />
                              </a>
                              {item?.assistant?.firstName} {item?.assistant?.lastName}
                            </div>
                            <Ticket
                              className={`profile-item-sprint mt-2 p-1 rounded text-white font-roboto font-bold font-tiny ${
                                item?.assistant?.company?.name ? 'bg-dark-green' : 'bg-maya-blue'
                              }`}
                              text={`${t('MY_GROUP_REFERENT')} ${item?.assistant?.company?.displayName ?? 'Alkemy'}`}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className='relative team-container w-full bg-white dark:bg-dark-bold mt-2 lg:mt-0 rounded p-4'>
                    <span className=' font-team font-bold text-base dark:text-white'>
                      {t('MY_GROUP_INTEGRANTS')}
                      <span className='text-muted  text-base ml-1 dark:text-white'>
                        ({team?.group?.profiles?.length} {t('MY_GROUP_ALKYMERS')})
                      </span>
                    </span>
                    <div className='w-full flex mt-3 flex-wrap gap-8'>
                      {team &&
                        team?.group?.profiles?.map((member, index) => (
                          <div key={member.id} className='flex flex-col items-center w-1/5 mb-4 lg:mb-5'>
                            <Avatar
                              img={member?.profile?.picture}
                              initials={`${member?.profile?.firstName?.length ? member?.profile?.firstName[0] : ''}
                              ${member?.profile?.lastName?.length ? member?.profile?.lastName[0] : ''}`}
                              size='xs'
                            />
                            <span className='font-small font-roboto font-bold pt-2 hidden lg:block text-center dark:text-white'>
                              {member?.profile?.slackUrl && (
                                <a href={member?.profile?.slackUrl} target='_blank' rel='noreferrer'>
                                  <img className='mr-1' src={SlackIcon} alt={t('SLACK_ALT')} height='16' width='16' />
                                </a>
                              )}
                              {member.profile.firstName} {member.profile.lastName}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </>
      )}
    </div>
  );
};

export default MyGroup;
