import React from 'react';
import 'react-quill/dist/quill.snow.css';

const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

const SafeContainer = ({ content }) => {
  return typeof window !== 'undefined' && ReactQuill && <ReactQuill value={content} readOnly={true} theme='bubble' />;
};

export default SafeContainer;
