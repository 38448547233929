import { Link } from 'react-router-dom';
import FinishedScreeningCard from '../../../components/FinishedScreeningCard/FinishedScreeningCard';
import useTranslations from '../../../hooks/useTranslations';

const FinishedScreenings = ({ screenings }) => {
  const { t } = useTranslations();

  return (
    <>
      {!!screenings?.length ? (
        <div
          className='description-container bg-white dark:bg-dark-bold rounded mx-auto p-4 mb-3'
          data-testid='finish-screening-container-div'>
          <div className='flex flex-col mb-2'>
            <span className='text-base font-bold dark:text-white' data-testid='finish-screening-title-span'>
              {t('FINISHED_SCREENINGS_SECTION_TITLE')}
            </span>
            {screenings.length > 3 ? (
              <Link
                to='/finished-screenings'
                className='w-fit text-sm font-medium text-purple dark:text-white pointer mt-3'
                data-testid='finish-screening-view-more-a'>
                {t('SEE_ALL')}
              </Link>
            ) : null}
          </div>
          <div className='flex justify-center flex-wrap' data-testid='finish-screening-cards-div'>
            {screenings
              .filter((_, index) => index < 3)
              .map(screening => (
                <FinishedScreeningCard {...screening} key={screening.id} />
              ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FinishedScreenings;
