import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import EditPen from '../../../../assets/profile/EditPen';
import DeleteForever from '../../../../assets/profile/DeleteForever';
import useTranslations from '../../../../hooks/useTranslations';
import NewStyleInput from '../../../NewStyleInput/NewStyleInput';
import NewStyleSelect from '../../../NewStyleSelect/NewStyleSelect';
import FormationCardDatePicker from '../FormationCardDatePicker/FormationCardDatePicker';
import { Popconfirm } from 'antd';
import CreatableSelect from '../../../CreatableSelect/CreatableSelect';
import { useContextState } from '../../../../redux/contextState';
import moment from 'moment';
import { urlRegex } from '../../../../constants/regexValidations';
import { Tooltip } from 'antd';
import CreatableSelectAsync from '../../../CreatableSelectAsync/CreatableSelectAsync';
import { getFormationsInstitutesPaginated } from '../../../../api/formationService';

const FormationCardForm = ({
  fieldName,
  watchParent,
  update,
  editable,
  index,
  remove,
  value,
  key,
  showCancelButton,
}) => {
  const [edit, setEdit] = useState(editable);
  const { t } = useTranslations();
  const { contextState } = useContextState();

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: value,
  });

  const fullInstituteName =
    watchParent(`${fieldName}.institute.label`) ??
    watchParent(`${fieldName}.institute.displayName`) ??
    watch(`institute.label`) ??
    '-';
  const fullTitleName = watchParent(`${fieldName}.title`) ?? '-';
  const fullStudyAreaName =
    watchParent(`${fieldName}.studyArea.label`) ??
    watchParent(`${fieldName}.studyArea.displayName`) ??
    watch(`studyArea.label`) ??
    '-';

  const handleLoadOptions = e => {
    return new Promise(resolve => {
      getFormationsInstitutesPaginated({ search: e }).then(response => {
        resolve(
          response?.collection?.map(item => ({
            value: item.id,
            label: item.displayName,
          })),
        );
      });
    });
  };

  return (
    <div
      key={key}
      className={` mt-3 pb-3 ${
        watchParent('formations').length > 1 && index !== watchParent('formations').length - 1 && 'border-b-2'
      }`}>
      {edit ? (
        <>
          <div data-testid='formation-form-card' className='grid gap-4 grid-cols-formationCard' key={index}>
            <CreatableSelectAsync
              title={t('FORMATION_CARD_INSTITUTION')}
              name='institute'
              register={register}
              options={contextState?.formations?.collection?.map(item => ({
                value: item.id,
                label: item.displayName,
              }))}
              handleLoadOptions={handleLoadOptions}
              loadingText='LOADING_INSTITUTES'
              validations={{
                required: {
                  value: true,
                  message: 'FORMATION_CARD_INSTITUTE_REQUIRED',
                },
              }}
              error={errors.institute && errors.institute}
              control={control}
            />
            <NewStyleInput
              className=' xl:mx-auto'
              name='title'
              register={register}
              validations={{
                required: {
                  value: true,
                  message: 'FORMATION_CARD_TITLE_REQUIRED',
                },
              }}
              title={t('FORMATION_CARD_TITLE')}
              error={errors?.title ?? false}
            />
            <CreatableSelect
              title={t('FORMATION_CARD_STUDY_AREA')}
              name='studyArea'
              register={register}
              options={contextState?.studyAreas?.map(item => ({
                value: item.id,
                label: item.displayName,
              }))}
              validations={{
                required: {
                  value: true,
                  message: 'FORMATION_CARD_STUDY_AREA_REQUIRED',
                },
                validate: value => Object.keys(value).length > 1 || 'FORMATION_CARD_STUDY_AREA_REQUIRED',
              }}
              control={control}
              error={errors.studyArea && errors.studyArea}
            />
            <NewStyleSelect
              editable
              error={errors?.formationStatusId ?? false}
              register={register}
              validations={{
                valueAsNumber: true,
                required: {
                  value: true,
                  message: 'FORMATION_CARD_STATUS_REQUIRED',
                },
              }}
              name='formationStatusId'
              value={value?.formationStatusId ?? ''}
              options={contextState.formation_status?.map(item => ({
                id: item.id,
                name: item.displayName,
              }))}
              title={t('FORMATION_CARD_STATUS')}
            />
            <FormationCardDatePicker
              control={control}
              register={register}
              validations={{
                required: {
                  value: true,
                  message: 'FORMATION_CARD_START_DATE_REQUIRED',
                },
              }}
              editable
              error={errors?.startDate ?? false}
              setValue={setValue}
              clearErrors={clearErrors}
              title={t('FORMATION_CARD_START_DATE')}
              maxDate={watch('endDate') ?? ''}
              name='startDate'
              value={watch('startDate') ?? ''}
              watch={watch}
            />
            <FormationCardDatePicker
              control={control}
              register={register}
              setValue={setValue}
              clearErrors={clearErrors}
              validations={{
                required: {
                  value: watch('formationStatusId') === 1,
                  message: 'FORMATION_CARD_END_DATE_REQUIRED',
                },
              }}
              error={errors?.endDate ?? false}
              editable
              title={t('FORMATION_CARD_END_DATE')}
              minDate={watch('startDate') ?? ''}
              name='endDate'
              value={watch('endDate') ?? ''}
              watch={watch}
            />
          </div>
          <NewStyleInput
            className='mt-4'
            name='certificateUrl'
            register={register}
            title={t('FORMATION_CARD_CERTIFICATE_URL')}
            validations={{
              pattern: {
                value: urlRegex,
                message: 'FORMATION_CARD_INVALID_CERTIFICATE_URL',
              },
            }}
            error={errors?.certificateUrl ?? false}
            value={watch('certificateUrl') ?? ''}
          />
          <div className='w-100 flex  mt-4 justify-end items-center'>
            {showCancelButton && (
              <button
                className='text-red-600 h-full mr-4 font-bold'
                onClick={() => {
                  if (Object.keys(value).length === 1) {
                    remove();
                  } else {
                    update(watchParent(fieldName));
                  }
                }}>
                {t('CANCEL')}
              </button>
            )}
            <button
              data-testid='button-add-formation'
              className='card-button-edit bg-white dark:bg-dark-bold rounded-full font-semibold px-5 text-alkemy-button border-maya-blue'
              onClick={handleSubmit(() => {
                update(watch());
                setEdit(false);
              })}>
              {t('FORMATION_CARD_ADD_BUTTON')}
            </button>
          </div>
        </>
      ) : (
        <div data-testid='formation-added-card' className='flex justify-between items-center flex-wrap'>
          <div className='lg:w-50 xl:w-32'>
            <div className='flex lg:w-50 xl:w-32 flex-col my-2'>
              <span className='flex items-center mb-1 dark:text-white font-light italic truncate custom-input-label-active text-sm'>
                {t('FORMATION_CARD_INSTITUTION')}
              </span>
              <span className='block items-center mb-1 dark:text-white font-bold truncate text-xs'>
                <Tooltip placement='topLeft' title={fullInstituteName}>
                  {fullInstituteName}
                </Tooltip>
              </span>
            </div>
            <div className='flex flex-col my-2'>
              <span className='flex items-center mb-1 dark:text-white font-light italic truncate custom-input-label-active text-sm'>
                {t('FORMATION_CARD_STATUS')}
              </span>
              <span className='flex items-center mb-1 dark:text-white font-bold truncate text-xs'>
                {t(
                  contextState.formation_status?.find(item => item.id === watchParent(`${fieldName}.formationStatusId`))
                    ?.displayName ?? '',
                )}
              </span>
            </div>
          </div>
          <div className='lg:w-50 xl:w-40 mx-2'>
            <div className='flex flex-col my-2'>
              <span className='flex items-center mb-1 dark:text-white font-light italic truncate custom-input-label-active text-sm'>
                {t('FORMATION_CARD_TITLE')}
              </span>
              <span className='block items-center mb-1 dark:text-white font-bold truncate text-xs'>
                <Tooltip placement='topLeft' title={fullTitleName}>
                  {fullTitleName}
                </Tooltip>
              </span>
            </div>
            <div className='flex flex-col my-2'>
              <span className='flex items-center mb-1 dark:text-white font-light italic truncate custom-input-label-active text-sm'>
                {t('FORMATION_CARD_START_DATE')}
              </span>
              <span className='block items-center mb-1 dark:text-white font-bold truncate text-xs'>
                {moment(watchParent(`${fieldName}.startDate`)) &&
                  moment(watchParent(`${fieldName}.startDate`)).format('MM/yyyy')}
              </span>
            </div>
          </div>
          <div className='flex lg:flex-row lg:w-full lg:justify-between xl:w-40 xl:block'>
            <div className='flex flex-col my-2'>
              <span className='flex items-center mb-1 dark:text-white font-light italic truncate custom-input-label-active text-sm'>
                {t('FORMATION_CARD_STUDY_AREA')}
              </span>
              <span className='block items-center mb-1 max dark:text-white font-bold truncate text-xs'>
                <Tooltip placement='topLeft' title={fullStudyAreaName}>
                  {fullStudyAreaName}
                </Tooltip>
              </span>
            </div>
            <div className='flex flex-col my-2'>
              <span className='flex items-center mb-1 dark:text-white font-light italic truncate custom-input-label-active text-sm'>
                {t('FORMATION_CARD_END_DATE')}
              </span>
              <span className='block items-center mb-1 dark:text-white font-bold truncate text-xs'>
                {watchParent(`${fieldName}.endDate`)
                  ? moment(watchParent(`${fieldName}.endDate`)).format('MM/yyyy')
                  : '-'}
              </span>
            </div>
          </div>
          <div className='flex justify-center items-center mx-auto w-[11.2rem]'>
            {watchParent(`${fieldName}.certificateUrl`) && (
              <a
                target='_blank'
                rel='noreferrer'
                href={watchParent(`${fieldName}.certificateUrl`)}
                className='mx-1 font-bold text-alkemy-purple'>
                {t('FORMATION_CARD_SEE_CERTIFICATE')}
              </a>
            )}
            <button data-testid='edit-button-formation' className='mx-1' onClick={() => setEdit(true)}>
              <EditPen />
            </button>
            <button data-testid='delete-button-formation' className='mx-1'>
              <Popconfirm
                id='confirm-delete-button'
                title={t('FORMATION_POP_CONFIRM_DELETE')}
                onConfirm={() => remove()}
                okText={t('YES')}
                cancelText={t('NO')}
                className='my-auto'>
                <div>
                  <DeleteForever />
                </div>
              </Popconfirm>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormationCardForm;
