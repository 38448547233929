import { useState } from 'react';
import fileToArrayBuffer from 'file-to-array-buffer';
import { getVideoUrl, postSoftSkillConfirmation, postSoftSkillVideo } from '../api/userService';
import { useContextState, ActionTypes } from '../redux/contextState';
import { notification } from 'antd';
import useTranslations from './useTranslations';

const useUploadVideo = videoEnum => {
  const { t } = useTranslations();
  const [loading, setLoading] = useState(false);
  const { setContextState } = useContextState();

  const uploadAction = async e => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === 'video/mp4') {
        if (file.size / 1024 / 1024 < 50) {
          const data = await getVideoUrl();
          const bufferArray = await fileToArrayBuffer(file);
          notification.open({
            message: t('UPLOADING_VIDEO_PLEASE_WAIT'),
          });
          setLoading(true);
          await postSoftSkillVideo({ videoUrl: data.videoUrl, fmData: bufferArray })
            .then(
              async () =>
                await postSoftSkillConfirmation({ videoId: data.videoId, videoType: videoEnum }).then(response => {
                  setContextState({
                    type: ActionTypes.SetProfile,
                    value: { videos: response.videos },
                  });
                  notification.open({
                    message: t('SUCCESS_VIDEO_UPLOAD').replace('{video}', t(`PROFILE_${videoEnum}`)),
                  });
                }),
            )
            .catch(err =>
              notification.open({
                message: t('FAILED_VIDEO_UPLOAD'),
              }),
            )
            .finally(() => setLoading(false));
        } else {
          notification.open({
            message: t('INVALID_VIDEO_SIZE'),
          });
        }
      } else {
        notification.open({
          message: t('INVALID_VIDEO_FILE'),
        });
      }
    }
  };

  return { uploadAction, loading };
};

export default useUploadVideo;
