import Address from '../../../utils/address';
import InformationPersonal from '../InformationPersonal/InformationPersonal';
import Ticket from '../../Ticket/Ticket';
import Avatar from '../../Avatar/Avatar';
import getYears from '../../../utils/getYears';
import useTranslations from '../../../hooks/useTranslations';

const Profile = ({ profile, className }) => {
  const { t } = useTranslations();
  return (
    <div className={`custom-card bg-white dark:bg-dark-bold p-4 ${className}`}>
      <div className='flex flex-col text-center sm:flex-row relative sm:text-center md:text-left'>
        <div className='flex justify-center'>
          <Avatar img={profile?.picture} initials={`${profile?.firstName[0]}${profile?.lastName[0]}`} size='md' />
        </div>
        <div className='flex flex-col sm:ml-4 sm:pl-2 my-4'>
          <div className='flex flex-row justify-center sm:justify-start flex-wrap'>
            {profile?.current_sprint && (
              <Ticket
                className='ticket-sprint-text font-bold font-roboto uppercase mr-3'
                text={`Sprint ${profile?.current_sprint} de 6`}
              />
            )}
          </div>
          <div className='flex flex-col items-center sm:items-start'>
            <InformationPersonal
              name={`${profile?.firstName} ${profile?.lastName}`}
              technology={
                !!profile?.groups?.length && profile?.profileScore?.technology?.displayName
                  ? profile?.profileScore?.technology?.displayName
                  : null
              }
              age={
                profile && Address(profile)
                  ? `${getYears(profile?.birthdate)} ${t('PROFILE_YEARS')}, ${Address(profile)}`
                  : null
              }
              hiredStatus={profile?.hiredStatus}
            />
          </div>
          <div className='flex flex-row mt-3 justify-center sm:justify-start flex-wrap'>
            {profile?.approvedSkills &&
              profile?.approvedSkills.map((skill, i) => (
                <Ticket
                  key={i}
                  className='ticket-rounded-text font-bold font-roboto uppercase mr-2 my-1'
                  text={skill}
                  check
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
