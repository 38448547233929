const BookmarkIcon = () => {
  return (
    <svg
      className='fill-current'
      width='24'
      height='24'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M12 4C9.79 4 8 5.79 8 8V38C8 41.2899 10.7101 44 14 44H40V40H14C12.8699 40 12 39.1301 12 38C12 36.8699 12.8699 36 14 36H40V34V32V4H32V24L26 20L20 24V4H12Z' />
    </svg>
  );
};

export default BookmarkIcon;
