import React, { useState, useEffect } from 'react';
import Select from '../../Select/Select';
import useTranslations from '../../../hooks/useTranslations';
import './LanguageCard.scss';
import { Skeleton } from 'antd';
import NewStyleSelect from '../../NewStyleSelect/NewStyleSelect';
import NewStyleInput from '../../NewStyleInput/NewStyleInput';
import { useFieldArray, useFormContext } from 'react-hook-form';
import LanguageCardForm from './LanguageCardForm/LanguageCardForm';

const LanguageCard = props => {
  const {
    className,
    languages,
    certificateError,
    watch,
    setCertificateError,
    levels,
    editable,
    onChange,
    profile,
    control,
    errors,
    register,
    loading,
  } = props;
  const [prevLang, setPrevLang] = useState(undefined);
  const { t } = useTranslations();
  const handleChange = e => {
    const language = languages?.find(item => item.name === e.target.name);
    const level = levels?.find(item => item.id === Number(e.target.value));
    if (e.target.type === 'select-one') {
      onChange(
        e.target.value === '' ? null : { language, languageId: language?.id, level, levelId: level?.id },
        'selectEnglish',
      );
    }
  };
  const { fields, update, remove } = useFieldArray({
    name: 'languages',
    control: control,
  });

  useEffect(() => {
    if (editable) {
      if (profile?.languages && !prevLang) {
        setPrevLang(profile?.languages[0] ?? { level: { id: 'Select' } });
      }
      if (
        !!profile?.languages[0] &&
        prevLang?.level?.id !== profile?.languages[0]?.level?.id &&
        !profile?.certificateUrl
      ) {
        setCertificateError(true);
      } else {
        setCertificateError(false);
      }
    }
  }, [profile?.certificateUrl, profile?.languages]);
  return (
    <div className={`flex flex-col ${className}`}>
      <div className='flex  flex-row justify-between'>
        <span className=' text-base text-alkemy-black font-bold my-auto dark:text-white'>{t('LANGUAGES')}</span>
      </div>
      {loading ? (
        <div className='mt-3'>
          <div className='flex'>
            <Skeleton active paragraph={{ rows: 1, width: '60%' }} />
            <Skeleton active title={{ width: '0%' }} paragraph={{ rows: 1, width: '80%' }} />
          </div>
          <Skeleton className='mt-4' active title={false} paragraph={{ rows: 1, width: '80%' }} />
        </div>
      ) : (
        fields.map((item, index) => {
          const fieldName = `languages.${index}`;
          return (
            <LanguageCardForm
              key={item.id}
              value={item}
              watchParent={watch}
              fieldName={fieldName}
              index={index}
              showCancelButton
              update={object => update(index, object)}
              remove={() => remove(index)}
            />
          );
        })
      )}
      {!fields.length ? (
        <LanguageCardForm
          value={{}}
          editable
          index={0}
          watchParent={watch}
          fieldName={`languages.0`}
          update={object => update(0, object)}
        />
      ) : (
        fields.length !== languages.length && (
          <div
            onClick={() => update(fields.length, {})}
            className='flex justify-start text-alkemy-purple font-semibold text-sm'>
            <button>{t('LANGUAGE_CARD_ADD_LANGUAGE')}</button>
          </div>
        )
      )}
    </div>
  );
};

export default LanguageCard;
