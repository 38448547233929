import React, { useEffect, useState } from 'react';
import { getProfileChallenges } from '../../api/userService';
import Spinner from '../../components/Spinner/Spinner';
import { ActionTypes, useContextState } from '../../redux/contextState';
import Challenge from '../Challenge/Challenge';
import Screenings from '../Screenings/Screenings';
import { useHistory } from 'react-router-dom';

const ChallengeOrScreening = () => {
  const { contextState, setContextState } = useContextState();
  const [loading, setLoading] = useState(false);
  const [orderedChallenges, setOrderedChallenges] = useState();
  const history = useHistory();
  const findChallengeById = (context, id) => {
    return context.find(challenge => String(challenge.id) === id);
  };
  useEffect(() => {
    if (contextState.isAuthenticated) {
      if (!contextState.profileChallenges.length) {
        setLoading(true);
        getProfileChallenges()
          .then(res => {
            setContextState({
              type: ActionTypes.SetProfileChallenges,
              value: { ...res, challenges: res?.challenges?.filter(res => res?.reviewedAt) },
            });
            if (
              Boolean(history?.location?.pathname?.split('/')[2]) &&
              findChallengeById(Object.values(res).flat(), history?.location?.pathname?.split('/')[2])
            ) {
              setOrderedChallenges(
                findChallengeById(Object.values(res).flat(), history?.location?.pathname?.split('/')[2]),
              );
            } else {
              history.push('/inscription');
            }
            setLoading(false);
          })
          .finally(() => setLoading(false));
      } else {
        if (
          (Boolean(history?.location?.pathname?.split('/')[2]),
          findChallengeById(contextState.profileChallenges, history?.location?.pathname?.split('/')[2]))
        ) {
          setOrderedChallenges(
            findChallengeById(
              Object.values(contextState.profileChallenges).flat(),
              history?.location?.pathname?.split('/')[2],
            ),
          );
        } else {
          history.push('/inscription');
        }
        setLoading(false);
      }
    }
  }, []);

  return loading || !orderedChallenges ? (
    <Spinner show />
  ) : orderedChallenges?.endDate ? (
    <Screenings screening={orderedChallenges} />
  ) : (
    <Challenge challenge={orderedChallenges} />
  );
};

export default ChallengeOrScreening;
