import React, { useRef } from 'react';
import useOnScreen from '../../../hooks/useOnScreen';
import { oneDecimal } from '../../../utils/numbersUtils';
import Steps from '../Steps/Steps';
import './technicalSkills.scss';

const TechnicalSkills = props => {
  const ref = useRef();
  const onScreen = useOnScreen(ref, '0px');
  const { skills, className } = props;
  return (
    <div ref={ref} className={`custom-card bg-white dark:bg-dark-bold ${className}`}>
      {skills.map((step, i) => (
        <Steps
          key={i}
          skill={step.skill}
          featured={step?.isFeatured}
          onScreen={onScreen}
          step={onScreen ? oneDecimal(step.value) : 0}
          className='my-4'
        />
      ))}
    </div>
  );
};

export default TechnicalSkills;
