import React, { useEffect, useState } from 'react';
import Banner from '../../components/Banner/Banner';
import moment from 'moment';
import useTranslations from '../../hooks/useTranslations';
import Spinner from '../../components/Spinner/Spinner';
import { ticketClass } from '../../utils/ticketClass';
import Ticket from '../../components/Ticket/Ticket';
import { useContextState } from '../../redux/contextState';
import Container from '../../components/Container/Container';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getUserChallengeCertificates } from '../../api/userService';

const Screenings = props => {
  const { t } = useTranslations();
  const { contextState } = useContextState();
  const { screening } = props;
  const [hasCertificate, setHasCertificate] = useState(false);

  useEffect(() => {
    const fetchCertificates = async () => {
      const certificates = await getUserChallengeCertificates(screening.profileId);
      const matchingCertificate = certificates.find(
        certificate => certificate._id === screening.id && certificate.certificateUrl,
      );
      if (matchingCertificate) {
        setHasCertificate(true);
      }
    };
    fetchCertificates();
  }, []);

  return (
    <div className='relative w-full pb-5'>
      <Banner text={t('MY_SCREENING_TITLE')} svg='🚀' />
      <Container>
        <div className='description-container bg-white rounded m-auto pt-4 pb-5 px-5'>
          {contextState.loading ? (
            <Spinner show />
          ) : (
            <>
              <div className='flex  flex-row justify-between'>
                <Ticket
                  text={screening?.technology?.technologyType || 'Backend'}
                  className={`${ticketClass(
                    screening?.technology?.technologyType,
                  )} ticket-padding font-bold font-roboto uppercase mr-3`}
                />
                <span className='font-medium'>🚀</span>
              </div>
              <div className='flex flex-col lg:flex-row justify-between mt-4 mb-5 pb-5'>
                <div className='flex flex-col width-know-card-50 pr-0 pr-lg-5'>
                  <span className=' font-bold title-technology'>{t('SCREENING_TECHNOLOGY')}</span>
                  <span className=' font-bold text-alkemy-black'>{screening?.technology?.displayName}</span>
                  <div className='mt-4 mb-2'>
                    <span className=' text-alkemy-black-bold font-bold title-repository'>
                      {t('TECHNICAL_SCREENING')}
                    </span>
                    <div className='flex  flex-row mt-3'>
                      <Ticket
                        text={!screening?.endDate ? t('CHALLENGE_WITHOUT_SENDING') : t('CHALLENGE_CORRECTED')}
                        className={`${
                          !screening?.endDate ? 'ticket-not-send-text' : 'ticket-sprint-text'
                        } ticket-padding font-bold font-roboto uppercase mr-3`}
                      />
                    </div>
                    {screening?.endDate && (
                      <div className='mt-2'>
                        <span className=' font-weight-500 title-cohort'>{t('SHIPPING_DATE')}: </span>
                        <span className=' font-bold title-cohort'>
                          {moment(new Date(screening?.endDate)).format('DD/MM/YYYY')}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                {screening?.endDate && (
                  <div className='flex  flex-row width-know-card-50'>
                    <div className='flex flex-col pdf-container w-full p-3'>
                      <span className=' font-bold pdf-title text-alkemy-black-bold mt-2 mb-3'>
                        {t('SCREENING_COMPLETED_TITLE').replace('{tech}', screening?.technology?.displayName)}
                      </span>
                      <span className=' font-small mt-2 mb-3'>{t('SCREENING_COMPLETED_SUBTITLE')}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='flex justify-end w-full gap-x-4'>
                <Link to='/results' className='button-apply-challenge py-2 px-5'>
                  <span className='button-apply-challenge-text font-roboto font-bold text-white text-decoration-none'>
                    {t('SCREENING_RESULTS')}
                  </span>
                </Link>
                {hasCertificate && (
                  <Link to='/certificates' className='button-apply-challenge py-2 px-5 '>
                    <span className='button-apply-challenge-text font-roboto font-bold text-white text-decoration-none'>
                      {t('SCREENING_CERTIFICATES')}
                    </span>
                  </Link>
                )}
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Screenings;
