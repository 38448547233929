import React from 'react';
import { Link } from 'react-router-dom';
import useTranslations from '../../hooks/useTranslations';
import { useContextState } from '../../redux/contextState';
import Avatar from '../Avatar/Avatar';
import ScrollContainer from '../ScrollContainer/ScrollContainer';

const NewsGroup = ({ shuffledTeam }) => {
  const { t } = useTranslations();
  const { contextState } = useContextState();
  return (
    <div
      className={`bg-white dark:bg-dark-bold p-4 rounded ${
        !contextState?.profileNews?.tickets?.length && !contextState?.profileNews?.academy?.data?.length && 'h-full'
      }`}>
      <span className='mb-0 mt-3  text-base font-bold dark:text-white'>🤓 {t('MENU_ITEM_8')}</span>
      <div className='flex items-center my-4 py-2'>
        <Avatar
          img={contextState?.profileNews?.group?.mentor?.picture}
          initials={`
        ${
          contextState?.profileNews?.group?.mentor?.firstName?.length
            ? contextState?.profileNews?.group?.mentor?.firstName[0]
            : ''
        }
        ${
          contextState?.profileNews?.group?.mentor?.lastName?.length
            ? contextState?.profileNews?.group?.mentor?.lastName[0]
            : ''
        }`}
          size='mentor-s'
        />
        <div className='flex flex-col pl-3'>
          <span className=' font-bold text-sm dark:text-white'>{t('NEWS_MY_MENTOR')}</span>
          <span className=' font-bold text-base dark:text-white'>
            {contextState?.profileNews?.group?.mentor &&
              `${contextState?.profileNews?.group?.mentor?.displayFirstName}
                ${contextState?.profileNews?.group?.mentor?.displayLastName}`}
          </span>
        </div>
      </div>
      <div className='about-container flex justify-center w-full rounded'>
        <ScrollContainer className='py-3'>
          {shuffledTeam &&
            shuffledTeam.map((member, index) => (
              <div key={index}>
                <Avatar
                  img={member?.profile?.picture}
                  initials={`${member?.profile?.firstName?.length ? member?.profile?.firstName[0] : ''}
          ${member?.profile?.lastName?.length ? member?.profile?.lastName[0] : ''}`}
                  size='team-member'
                  className='mx-2'
                  key={index}
                />
              </div>
            ))}
        </ScrollContainer>
      </div>
      <div className='flex justify-end mt-4'>
        <Link
          className='calendar-link px-3 py-2 font-bold text-white font-roboto font-small hover:text-white'
          to='/my-group'>
          {t('NEWS_GO_TO_MY_GROUP')}
        </Link>
      </div>
    </div>
  );
};

export default NewsGroup;
