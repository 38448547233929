import { alkymetricsClient } from './alkymetricsClient';

export const readNotifications = async (userId, ids) => {
  const requestData = {
    notifications: ids,
  };
  try {
    const { data } = await alkymetricsClient.post(`/notification/me/read`, requestData);
    return data;
  } catch (error) {
    return {};
  }
};
