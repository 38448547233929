import { useState, useEffect } from 'react';
import { auth0Client } from '../../auth0/auth0';
import useTranslations from '../../hooks/useTranslations';
import { createMultiplatformAccount } from '../../utils/createMultiplatformAccount';
import { useContextState } from '../../redux/contextState';
import alkemyLogo from '../../assets/header/logo.svg';
import defaultProfileImage from '../../assets/header/profile.svg';
import { Arrow } from '../../assets/arrow';
import './MultiplatformAccount.scss';

const MultiplatformAccount = () => {
  const { t } = useTranslations();
  const [loading, setLoading] = useState(false);
  const { contextState } = useContextState();

  useEffect(() => {
    if (!contextState.tokenPayload?.ask_create_multiplatform_account) window.location.replace('/');
  }, [contextState.tokenPayload]);

  const createAccount = () => {
    setLoading(true);
    createMultiplatformAccount(t)
      .then(() => {
        window.location.replace('/');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const logout = path => {
    auth0Client().logout({ returnTo: `${window.location.origin}${path}` });
  };

  const goPartners = () => window.location.replace(process.env.RAZZLE_RUNTIME_PARTNERS_URL);

  return (
    <div className='multiplatform-bg'>
      <div className='flex justify-center'>
        <div className='multiplatform-box flex flex-col items-center overflow-hidden text-center rounded-lg my-6 md:my-16 pt-20 pb-16'>
          <img src={alkemyLogo} className='multiplatform-logo mb-11' alt={t('LOGO')} />
          <h2 className='font-montserrat text-xl font-bold mb-2'>{t('MULTIPLATFORM_ACCOUNT_TITLE')}</h2>
          <h3 className='font-montserrat text-base font-bold multiplatform-subtitle'>
            {t('MULTIPLATFORM_ACCOUNT_SUBTITLE')}
          </h3>
          <div className='mt-8 mb-12 flex flex-col'>
            <span className='text-sm text-darkGrey mb-4 font-medium'>{t('MULTIPLATFORM_CONTINUE_AS')}</span>
            <button
              data-testid='multiplatform-continue'
              disabled={loading}
              className='flex items-center p-2 rounded-lg hover:bg-greyMercury transition-colors'
              onClick={createAccount}>
              <img src={defaultProfileImage} className='h-8 w-8 rounded-full mr-4' />
              <div className='flex flex-col items-start'>
                <span className='text-alkemy-black font-medium'>
                  {contextState.tokenPayload?.first_name ?? ''} {contextState.tokenPayload?.last_name ?? ''}
                </span>
                <span className='text-xs text-alkemy-black font-medium'>{contextState.tokenPayload?.email ?? ''}</span>
              </div>
            </button>
            <hr className='my-4' />
            <div className='mb-4'>
              <button
                data-testid='multiplatform-other-account'
                disabled={loading}
                className='text-darkGrey font-semibold'
                onClick={() => logout('/login')}>
                {t('MULTIPLATFORM_USE_OTHER_ACCOUNT')}
              </button>
            </div>
            <div>
              <button
                data-testid='multiplatform-create-account'
                disabled={loading}
                className='text-darkGrey font-semibold'
                onClick={() => logout('/register')}>
                {t('MULTIPLATFORM_CREATE_ACCOUNT')}
              </button>
            </div>
          </div>
          <button
            data-testid='multiplatform-go-partners'
            disabled={loading}
            className='flex items-center'
            onClick={goPartners}>
            <Arrow color='#757575' />
            <span className='text-darkGrey font-semibold ml-2'>{t('MULTIPLATFORM_GO_PARTNERS')}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MultiplatformAccount;
