import { notification } from 'antd';
import { auth0Client } from '../auth0/auth0';

export const createMultiplatformAccount = async t => {
  return await new Promise((resolve, reject) => {
    auth0Client().checkSession(
      {
        audience: process.env.RAZZLE_RUNTIME_AUTH_ZERO_AUDIENCE,
        scope: 'read:current_user',
        createMultiplatformAccount: true,
        getProfileData: true,
      },
      function (err, result) {
        if (err === null && result.accessToken) {
          resolve(result);
        } else {
          notification.open({
            message: t('SOMETHING_WENT_WRONG_TRY_AGAIN'),
            type: 'error',
          });
          reject(err);
        }
      },
    );
  });
};
