import React, { useEffect } from 'react';
import { ActionTypes, useContextState } from '../../redux/contextState';
import { getAdvance, getProfileNews, getIntegratorsInfo } from '../../api/userService';
import useTranslations from '../../hooks/useTranslations';
import useUserAdvance from '../../hooks/useUserAdvance';

// Components
import Profile from './Profile/Profile';
import TrainingSkills from './TrainingSkills/TrainingSkills';
import TechnicalSkills from './TechnicalSkills/TechnicalSkills';
import Feedback from './Feedback/Feedback';
import Spinner from '../Spinner/Spinner';
import Banner from '../Banner/Banner';
import AdvanceBar from './AdvanceBar/AdvanceBar';
import WeeklyGoals from './WeeklyGoals/WeeklyGoals';
import TrainingIntegrator from '../TrainingIntegrator/TrainingIntegrator';
import EnrollmentsCapsules from '../EnrollmentsCapsules/EnrollmentsCapsules';
import formatSoftSkills from '../../utils/profile/formatSoftSkills';
import TrainingAssistance from '../TrainingAssistance/TrainingAssistance';
import Container from '../Container/Container';
import './Advance.scss';

const Avance = () => {
  const { t } = useTranslations();
  const { loading, profile } = useUserAdvance();
  const { contextState, setContextState } = useContextState();

  useEffect(() => {
    if (!Object.keys(contextState.profileNews).length) {
      getProfileNews()
        .then(res => {
          setContextState({
            type: ActionTypes.SetProfileNews,
            value: res,
          });
          setContextState({
            type: ActionTypes.SetLoading,
            value: false,
          });
        })
        .catch(error => {
          setContextState({
            type: ActionTypes.SetProfileNews,
            value: false,
          });
        });
    }
    getAdvance(contextState?.profile?.id)
      .then(response => {
        setContextState({
          type: ActionTypes.SetAdvance,
          value: response,
        });
      })
      .catch(() => {
        setContextState({
          type: ActionTypes.SetAdvance,
          value: {},
        });
      });
    getIntegratorsInfo()
      .then(res => {
        setContextState({
          type: ActionTypes.SetIntegrators,
          value: res,
        });
      })
      .catch(err => {
        setContextState({
          type: ActionTypes.SetIntegrators,
          value: {},
        });
      });
  }, []);

  const accelerationFinished = profile => !!profile?.groups?.length && profile?.groups[0].group?.currentSprint === -1;

  return (
    <div className='w-full h-full pb-4'>
      <Banner icon={contextState?.whitelabel?.images?.BANNER_LOGO} text={`${t('ADVANCE_TITLE')}`} />
      {contextState.loading || loading ? (
        <Spinner show />
      ) : (
        <>
          <Container>
            <div className='flex flex-col description-container mx-auto'>
              <Profile profile={profile} />
              <AdvanceBar
                className='mt-2 sm:mt-3 p-4 sm:px-5'
                engagement={profile?.profileScore?.totalEngagement ?? 0}
              />
              <div className='flex flex-col lg:flex-row'>
                <div className='flex flex-col lg:mr-3 advance-left-column'>
                  <WeeklyGoals className='mt-2 sm:mt-3' Goals={contextState?.profileNews} />
                  {profile?.hackerRank && (
                    <TrainingAssistance
                      title={t('HACKER_RANKS')}
                      color='#F2C94C'
                      className='p-4 mt-2 sm:mt-3 bg-white dark:bg-dark-bold rounded'
                      percent={profile?.hackerRank}
                    />
                  )}
                </div>
                <div className='flex flex-col grow ml-0 advance-right-column'>
                  {!!contextState?.integratorsInfo?.integrators?.length && (
                    <TrainingIntegrator
                      integrators={contextState?.integratorsInfo?.integrators?.slice(0, 2)}
                      className='bg-white mt-3 rounded py-4 px-5'
                    />
                  )}
                  {!!contextState?.advance && !!Object.keys(contextState?.advance).length && (
                    <TrainingSkills
                      className='p-4 sm:px-5 sm:pt-4 mt-2 sm:mt-3'
                      skills={contextState?.advance?.skills ?? []}
                      waiting={!contextState?.advance?.userId}
                      finish={accelerationFinished(contextState.profile)}
                    />
                  )}
                  {!!Object.keys(contextState?.advance).length && (
                    <TechnicalSkills
                      className='px-4 sm:px-5 py-3 mt-2 sm:mt-3'
                      skills={[
                        { skill: 'Velocity', value: contextState?.advance?.velocity?.current ?? 0 },
                        { skill: 'Code Quality', value: contextState?.advance?.codeQuality?.current ?? 0 },
                      ]}
                    />
                  )}
                  {contextState?.advance?.softSkills &&
                    Array.isArray(contextState?.advance?.softSkills) &&
                    Boolean(contextState?.advance?.softSkills?.length) && (
                      <TechnicalSkills
                        className='px-4 sm:px-5 py-3 mt-2 sm:mt-3'
                        skills={formatSoftSkills(contextState?.advance?.softSkills, t)}
                      />
                    )}
                  <Feedback className='mt-2 sm:mt-3' feedback={profile?.feedback} />
                  {!!contextState?.integratorsInfo?.enrollments?.length && (
                    <div className='mt-3 flex flex-wrap justify-between'>
                      {contextState?.integratorsInfo?.enrollments?.map(item => (
                        <EnrollmentsCapsules key={item?.id} capsule={item} />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </>
      )}
    </div>
  );
};

export default Avance;
