import React from 'react';
import { Link } from 'react-router-dom';
import useTranslations from '../../hooks/useTranslations';

const NewsGoToModify = () => {
  const { t } = useTranslations();
  return (
    <div className='bg-white dark:bg-dark-bold mt-2 lg:mt-3 py-4 flex justify-between items-center px-4 rounded'>
      <span className=' font-bold font-small dark:text-white'>{t('NEWS_MODIFY_DATA')}</span>
      <Link to='/profile' className='calendar-link px-3 py-2 font-bold text-white font-roboto font-small self-end'>
        {t('NEWS_GO_TO_MODIFY')}
      </Link>
    </div>
  );
};

export default NewsGoToModify;
