import ReactIf from './ReactIf';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = props => {
  const { component: Component, isLogin, isAuthorized, ...rest } = props;
  return (
    <Route
      {...rest}
      render={() => (
        <div>
          <ReactIf condition={isLogin}>
            <Redirect to='/' />
          </ReactIf>
          <ReactIf condition={isAuthorized}>
            <Component />
          </ReactIf>
        </div>
      )}
    />
  );
};

export default PublicRoute;
