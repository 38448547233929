import { useState } from 'react';
import { getTermsAndConditions, saveProfile } from '../api/userService';
import { ActionTypes, useContextState } from '../redux/contextState';

const useTermsAndConditions = () => {
  const { contextState, setContextState } = useContextState();
  const { showModal, termsAndConditionsLoading, termsAndConditionsData } = contextState.termsAndConditions;
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false);
  const [modalContentLoading, setModalContentLoading] = useState(false);

  const showTermsAndConditionsModal = async () => {
    setContextState({
      type: ActionTypes.SetTermsAndConditions,
      value: { ...contextState.termsAndConditions, showModal: true },
    });
    if (!termsAndConditionsData) {
      setContextState({
        type: ActionTypes.SetTermsAndConditions,
        value: {
          ...contextState.termsAndConditions,
          termsAndConditionsLoading: true,
          showModal: true,
        },
      });
      await getTermsAndConditions().then(r => {
        setContextState({
          type: ActionTypes.SetTermsAndConditions,
          value: {
            ...contextState.termsAndConditions,
            termsAndConditionsLoading: false,
            termsAndConditionsData: r,
            showModal: true,
          },
        });
      });
    }
  };

  const acceptTermsAndConditions = async () => {
    setModalContentLoading(true);

    const termsAndConditionsAcceptance = new Date().toISOString();
    await saveProfile({ termsAndConditionsAcceptance });
    setContextState({
      type: ActionTypes.SetProfile,
      value: { ...contextState.profile, termsAndConditionsAcceptance },
    });
  };

  const closeModal = () => {
    setContextState({
      type: ActionTypes.SetTermsAndConditions,
      value: { ...contextState.termsAndConditions, showModal: false },
    });
  };

  const setShowModal = value => {
    setContextState({
      type: ActionTypes.SetTermsAndConditions,
      value: { ...contextState.termsAndConditions, showModal: value },
    });
  };

  return {
    showModal,
    setShowModal,
    termsAndConditionsLoading,
    termsAndConditionsData,
    acceptedTermsAndConditions,
    modalContentLoading,
    showTermsAndConditionsModal,
    acceptTermsAndConditions,
    setAcceptedTermsAndConditions,
    closeModal,
  };
};

export default useTermsAndConditions;
