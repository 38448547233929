import Ticket from '../../../components/Ticket/Ticket';
import { ticketClass } from '../../../utils/ticketClass';
import useTranslations from '../../../hooks/useTranslations';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useContextState, ActionTypes } from '../../../redux/contextState';
import { handleRedirect } from '../../../utils/handleRedirect';

const ChallengeCard = ({ challenge, index }) => {
  const { t } = useTranslations();
  const { setContextState, contextState } = useContextState();
  const history = useHistory();

  return (
    <div className='h-auto col col-12 m-3 col-sm-6 col-md-4 col-lg-3 p-0 my-2 ' key={index}>
      <div
        className='bg-white h-full max-h-[17rem] dark:bg-dark-normal border-alkemy-blue border-2 p-3 challenges-cards m-0 card-3 flex flex-col'
        key={index}>
        <div className='flex flex-row flex-grow justify-between h-auto text-center'>
          <span className='font-semibold text-alkemy-black dark:text-white text-base text-center'>
            {`${t('MY_CHALLENGES_CARD_TITLE')} ${challenge?.technology?.displayName ?? challenge?.screeningsName}`}
          </span>
        </div>
        <div>
          <div className='flex flex-col justify-between mt-1'>
            <span className='font-montserrat font-medium text-xs dark:text-white'>{t('STATUS')}:</span>
            <Ticket
              text={
                challenge?.reviewedAt || (challenge?.startDate && challenge?.endDate)
                  ? t('CHALLENGE_CORRECTED')
                  : !challenge?.url || (challenge?.startDate && !challenge?.endDate)
                  ? t('CHALLENGE_WITHOUT_SENDING')
                  : t('CHALLENGE_IN_REVIEW')
              }
              className={`${
                (challenge?.reviewedAt && challenge?.technology?.evaluationFormat === 'CHALLENGE') ||
                (challenge?.startDate && challenge?.endDate)
                  ? 'ticket-sprint-text'
                  : !challenge?.url || (challenge?.startDate && !challenge?.endDate)
                  ? 'ticket-not-send-text'
                  : 'ticket-review-text'
              } ticket-padding font-bold font-roboto capitalize mr-auto mt-1`}
            />
          </div>
          <div className='flex flex-col mt-2 h-7'>
            {challenge?.datetime && challenge?.url ? (
              <>
                <span className='font-montserrat dark:text-white text-xs font-semibold'>{t('SHIPPING_DATE')}:</span>
                <span className='font-bold text-blue-800 dark:text-white text-xs'>
                  {moment(challenge?.datetime).format('DD/MM/YYYY')}
                </span>
              </>
            ) : (
              challenge?.endDate && (
                <>
                  <span className='font-montserrat dark:text-white text-xs font-semibold'>{t('SHIPPING_DATE')}:</span>
                  <span className='font-bold text-blue-800 dark:text-white text-xs'>
                    {moment(challenge?.endDate).format('DD/MM/YYYY')}
                  </span>
                </>
              )
            )}
          </div>
          <div className='flex grow items-end w-full justify-center mt-5'>
            {challenge?.startDate && !challenge?.endDate ? (
              <button
                onClick={() =>
                  handleRedirect(
                    `${process.env.RAZZLE_RUNTIME_SCREENINGS}?technologyId=${challenge?.technology?.id}&userEmail=${contextState.profile.email}&token=`,
                    t,
                  )
                }
                className='button-apply-challenge-text text-center button-apply-challenge grow font-roboto font-bold text-white text-decoration-none'>
                {t('GO_TO_SCREENING')}
              </button>
            ) : (
              <button
                className='button-apply-challenge grow'
                onClick={() => {
                  setContextState({
                    type: ActionTypes.SetCurrentResult,
                    value: challenge,
                  });
                  history.push(`/challenges/${challenge.id}`);
                }}>
                <span className='button-apply-challenge-text font-roboto font-bold text-white'>
                  {t('GO_TO_CHALLENGE')}
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeCard;
