import React from 'react';
import useTranslations from '../../hooks/useTranslations';
import './CertificateItem.scss';

const CertificateItem = ({ certificate, className, isEvaluation, certificateData }) => {
  const { t } = useTranslations();
  const formattedCertificate = encodeURI(certificate + '.pdf');
  const cerificationName = encodeURI(t('CERTIFICATE_ACCELERATION_NAME'));
  return (
    <div
      className={`p-3 certificate-container bg-link bg-white dark:bg-dark-bold  w-full rounded ${className} ${
        (!certificate || (isEvaluation && !certificateData)) && 'h-full empty-container-size'
      }`}>
      {certificate || (isEvaluation && certificateData) ? (
        <div className='w-full'>
          <div>
            <img
              data-testid='certificate-image'
              className='certificate-image'
              src={isEvaluation ? certificateData?.pngUrl : certificate + '.png'}
            />
          </div>
          {!isEvaluation && (
            <div className='w-full text-right mt-5'>
              <a
                href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${cerificationName}&organizationId=42854645&issueYear=2021&issueMonth=8&certUrl=${encodeURI(
                  formattedCertificate,
                )}&certId=${certificate?.split('/').pop()}&credentialDoesNotExpire=1`}
                target='_blank'
                rel='noreferrer'
                className='text-decoration-none truncate'>
                <span className='rounded-full text-maya-blue bg-white dark:bg-dark-bold font-bold font-roboto py-2 px-3 add-certification-button'>
                  {t('CERTIFICATE_ADD_AS_LINKEDIN_CERTIFICATE')}
                </span>
              </a>
            </div>
          )}
        </div>
      ) : (
        <div className='w-full h-full border-empty-certificate flex flex-col justify-center items-center'>
          <span className='muscle-icon'>💪</span>
          <span data-testid='certificate-good-way' className='text-muted good-way dark:text-white'>
            {t('CERTIFICATE_GOOD_WAY')}
          </span>
        </div>
      )}
    </div>
  );
};

export default CertificateItem;
