import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import App from './App';
import { ContextProvider as ContextProvider, initialState } from './redux/contextState';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

if (process.env.RAZZLE_RUNTIME_ENV !== 'dev') {
  Sentry.init({
    dsn: 'https://d81237d0adb54ed8aebc7e41d2f72eeb@o966663.ingest.sentry.io/5939753',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.RAZZLE_RUNTIME_ENV,
    tracesSampleRate: 1,
  });
}
if (window) {
  hydrate(
    <ContextProvider initial={{ ...initialState, whitelabel: window.whitelabel }}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ContextProvider>,
    document.getElementById('root'),
  );
}

if (module.hot) {
  module.hot.accept();
}
