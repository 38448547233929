/**
 * Converts a filters object into a URLSearchParams object.
 *
 * @param {Object} filters - The filters object to be converted.
 * @returns {URLSearchParams} - The URLSearchParams object containing the converted parameters.
 */
export const getSearchParams = filters => {
  // Create a new instance of URLSearchParams to store the parameters
  const params = new URLSearchParams();

  // Iterate through each key in the filters object
  for (const key in filters) {
    // Skip undefined, null, and empty arrays
    if (filters[key] == undefined || filters[key] == null || (Array.isArray(filters[key]) && !filters[key]?.length))
      continue;

    // Check if the value for the current key is an array
    if (Array.isArray(filters[key])) {
      // If the key already exists in params, update its value with the array
      if (params.has(key)) {
        params.set(key, filters[key]);
      } else {
        // If the key is not present, join the array elements into a string and append it
        const arrayAsString = filters[key].filter(x => x).join(',');
        params.append(key, arrayAsString);
      }
    } else {
      // If the value is not an array, directly append it to the params
      params.append(key, filters[key]);
    }
  }

  // Return the final URLSearchParams object
  return params;
};
