import './avatar.css';

const Avatar = ({ img, size, className = '', initials }) => {
  return (
    <div
      className={`avatar-name flex justify-center items-center relative font-bold rounded-full avatar-name-${size} ${className}`}>
      <span>{initials?.toUpperCase()}</span>
      {img ? (
        <div
          className='inline-block rounded-full'
          style={{
            backgroundImage: `url('${img}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      ) : null}
    </div>
  );
};

export default Avatar;
