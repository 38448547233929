import moment from 'moment';
import useTranslations from '../../hooks/useTranslations';
import { refreshToken } from '../../utils/refreshToken';
import { TrackEvent } from '../../utils/segmentUtils';

const StartedScreeningCard = ({ company, stage, userId, levelId, id, technologyId, companyId, workspaceId }) => {
  const { t } = useTranslations();
  const { startDate, endDate } = stage;

  const momentStartDate = moment(startDate).utc('-3');
  const momentEndDate = moment(endDate).utc('-3');

  const formattedStartHour = momentStartDate.format('HH:mm');
  const formattedEndHour = momentEndDate.format('HH:mm');

  const startDay = momentStartDate.format('DD/MM/Y');
  const endDay = momentEndDate.format('DD/MM/Y');

  const getScreeningsUrl = async () => {
    const isProctoringEnable = stage?.proctoringEnabled;
    const proctoringKey = window?.location?.hash?.substring(1);
    const redirectURL = new URL(process.env.RAZZLE_RUNTIME_SCREENINGS);
    const token = await refreshToken();
    redirectURL.search = new URLSearchParams({
      token: token,
      userId: userId,
      isProctoringEnable: isProctoringEnable,
    });
    if (levelId) {
      redirectURL.searchParams.append('levelId', levelId);
    }
    if (id) {
      redirectURL.searchParams.append('invitationId', id);
    }
    if (technologyId) {
      redirectURL.searchParams.append('technologyId', technologyId);
    } else {
      redirectURL.searchParams.append('companyId', companyId);
      redirectURL.searchParams.append('workspaceId', workspaceId);
    }
    if (proctoringKey) {
      redirectURL.hash = `#${proctoringKey}`;
    }
    return redirectURL;
  };

  return (
    <div
      className='w-full bg-white dark:bg-dark-normal my-4 border-2 rounded-lg border-alkemy-blue p-3'
      data-testid='screening-card-container-div'>
      <div className='flex w-full justify-between items-center'>
        <span className='italic dark:text-white' data-testid='screening-card-title-span'>
          {company?.displayName} {t('SCREENINGS_IS_EVALUATING_YOU_IN')}:
        </span>
        <img src={company?.picture} className='w-[45px] h-auto ml-2.5' data-testid='screening-card-company-img' />
      </div>
      <div className='font-medium text-sm text-grey'>
        <span
          className='inline-block mb-4 text-alkemy-black text-sm font-semibold dark:text-white'
          data-testid='screening-card-name-span'>
          {stage.recruitSession.name}
        </span>
        {!stage.emptyDates && (
          <p className='block dark:text-white' data-testid='screening-card-start-date-p'>
            {`${t('SCREENINGS_INVITATION_FROM')} `}
            <span className='text-purple' data-testid='screening-card-start-date-span'>
              {startDay} {`${t('SCREENINGS_INVITATION_AT_START_END')} `} {formattedStartHour}
            </span>
          </p>
        )}
        {!stage.emptyDates && (
          <p className='block dark:text-white' data-testid='screening-card-end-date-p'>
            {`${t('SCREENINGS_INVITATION_UNTIL')} `}
            <span className='text-purple' data-testid='screening-card-end-date-span'>
              {endDay} {`${t('SCREENINGS_INVITATION_AT_START_END')} `}
              {formattedEndHour}
            </span>
          </p>
        )}
        <div className='w-full flex justify-end items-end'>
          <button
            onClick={async () => {
              TrackEvent('screening-start', { userId });
              const redirectUrl = await getScreeningsUrl();
              window.location.href = redirectUrl.toString();
            }}
            className='w-fit px-5 py-1 mt-4 bg-maya-blue font-bold rounded-2xl text-white hover:cursor-pointer'
            data-testid='screening-card-start-evaluation-button'>
            {t('CONTINUE_SCREENING')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StartedScreeningCard;
