import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getProfileChallenges, getTechnologies } from '../../api/userService';
import Banner from '../../components/Banner/Banner';
import Container from '../../components/Container/Container';
import EmptyComponent from '../../components/EmptyComponent/EmptyComponent';
import Spinner from '../../components/Spinner/Spinner';
import useTranslations from '../../hooks/useTranslations';
import { ActionTypes, useContextState } from '../../redux/contextState';
import { handleRedirect } from '../../utils/handleRedirect';
import './Resources.scss';

const Resources = () => {
  const { t } = useTranslations();
  const history = useHistory();
  const { setContextState, contextState } = useContextState();
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);

  const formatResources = (challengeResponse, technologies) => {
    if (challengeResponse.challenges.length || challengeResponse.screenings.length) {
      setContextState({
        type: ActionTypes.SetProfileChallenges,
        value: { ...challengeResponse, challenges: challengeResponse?.challenges?.filter(res => res?.reviewedAt) },
      });
    }
    setContextState({
      type: ActionTypes.SetTechnologies,
      value: technologies,
    });

    const completedChallengesTechnologies = [
      ...challengeResponse?.challenges?.filter(res => res?.reviewedAt),
      ...challengeResponse.screenings,
    ].map(challenge => challenge?.technology?.id);

    const technologiesWithCourses = technologies.filter(technology => {
      return technology?.courseId;
    });

    setResources(technologiesWithCourses);
    setLoading(false);
  };

  useEffect(() => {
    const getResources = () =>
      getProfileChallenges()
        .then(res => {
          if (!contextState.technologies.length) {
            getTechnologies('all').then(technologies => {
              formatResources(res, technologies);
            });
          } else {
            formatResources(res, contextState.technologies);
          }
        })
        .catch(err => {});

    getResources();
  }, []);

  const viewCourse = courseId => {
    handleRedirect(`${process.env.RAZZLE_RUNTIME_ACADEMY_URL}?screeningId=${courseId}&token=`);
  };

  return (
    <div className='relative w-full pb-5'>
      <Banner text={t('MY_RESOURCES_PAGE_TITLE_BANNER')} withSpacing={false} />
      <Container>
        <div className='description-container bg-white dark:bg-dark-bold rounded mx-auto mb-3'>
          {loading && <Spinner show />}
          {!loading && resources?.length === 0 && (
            <div className='py-6 pt-8 px-4 mt-4 bg-[#f4f4f4] dark:bg-dark-bold flex justify-center items-center'>
              <EmptyComponent
                title={t('NO_RESOURCES_AVAILABLE_YET')}
                button={{
                  text: t('NO_CHALLENGES_BUTTON_TEXT'),
                  onClick: () => history.push('/home'),
                  className:
                    'mt-3 bg-white border-maya-blue border-2 mx-auto rounded-2xl w-fit px-4 py-1 text-alkemy-blue font-bold',
                }}
              />
            </div>
          )}
          {!loading && resources?.length > 0 && (
            <div className='description-container rounded bg-white dark:bg-dark-bold shadow-lg m-auto p-4 pb-6'>
              <div className='flex flex-col items-center pb-6'>
                <span className=' title-challenge-3 mt-1 dark:text-white text-center font-bold lg:text-left pb-4 pt-2'>
                  {t('RESOURCES_WELCOME_TITLE')}
                </span>
                <div className='flex flex-col'>
                  <span className='text-alkemy mt-1 dark:text-white text-base mx-5 text-aling-left lg:text-left pb-4'>
                    {t('RESOURCES_WELCOME_SUBTITLE')}
                  </span>
                </div>
              </div>
              <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-2 p-2'>
                {resources.map((resource, index) => {
                  return (
                    <div className='p-0 my-2' key={index}>
                      <div
                        className='bg-white dark:bg-dark-normal pt-2 px-3 pb-1 challenges-cards flex flex-col justify-between border-[#828282] border-2 dark:border-gray-500'
                        key={index}>
                        <span className='capitalize font-semibold text-alkemy-black dark:text-white truncate text-base'>
                          {resource.name}
                        </span>
                        <div className='pt-3'>
                          <img className='card-technology-image' src={resource.imageUrl} />
                        </div>
                        <div className='w-full flex justify-center mt-3 mb-2'>
                          <button
                            className='button-apply-challenge py-2 px-5'
                            onClick={() => viewCourse(resource.courseId)}>
                            <span className='button-apply-challenge-text font-roboto font-bold text-white text-decoration-none'>
                              {t('VIEW_RESOURCES_CARD_TEXT')}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Resources;
