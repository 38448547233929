import React, { useEffect, useState } from 'react';
import { getDecimalAsInt, oneDecimal } from '../../../utils/numbersUtils';
import useTranslations from '../../../hooks/useTranslations';
import { Tooltip } from 'antd';
import starIcon from '../../../assets/profile/yellow-star.svg';
import './steps.scss';

const Steps = ({ skill, step, className, onScreen, featured }) => {
  const { t } = useTranslations();
  const steps = [1, 2, 3, 4, 5];
  const [number, setNumber] = useState(0);
  useEffect(() => {
    if (Math.round(number * 100) / 100 < Math.round(step * 100) / 100) {
      setTimeout(() => {
        setNumber(number => number + step / 5);
      }, 300);
    }
  }, [number, onScreen]);

  return (
    <div className={`flex flex-col ${className}`}>
      <span className='technical-skill text-alkemy-black font-weight-500 flex  dark:text-white'>
        {skill}
        <span className='technical-skill-number  font-weight-500 ml-1 dark:text-white'>
          ({oneDecimal(step)})
        </span>
        {featured && (
          <Tooltip title={t('ADVANCE_FEATURED_SOFT_SKILL')}>
            <div className='ml-2'>
              <img src={starIcon} />
            </div>
          </Tooltip>
        )}
      </span>
      <div className='flex  flex-row mt-2'>
        {steps.map((currStep, i) => (
          <div
            className={`step ml-1 w-full ${currStep === 1 ? 'first-step' : ''} ${currStep === 5 ? 'last-step' : ''}`}
            data-percent={number < currStep - 1 ? 0 : number < currStep ? getDecimalAsInt(number) : 10}
            data-data={JSON.stringify({ currStep, number })}
            key={i}
          />
        ))}
      </div>
    </div>
  );
};

export default Steps;
