import { useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router-dom';
import { useContextState } from '../../redux/contextState';
import closeIcon from '../../assets/news/close-small.svg';
import alkemyLogo from '../../assets/logo.svg';
import './VideoModal.scss';
import useVideoModal from '../../hooks/useVideoModal';
import useTranslations from '../../hooks/useTranslations';

const VideoModal = ({ action, invitationId }) => {
  const { contextState } = useContextState();
  const location = useLocation();
  const { closeModal } = useVideoModal();
  const { t } = useTranslations();

  useEffect(() => {
    const root = document.getElementById('root');
    if (location.hash !== '#video' || !contextState?.videoSelected) {
      root.classList.remove('overflow-hidden');
      root.style.height = 'auto';
    }
  }, [location.hash, contextState?.videoSelected]);

  return (
    <div
      className={`video-modal-container fixed top-0 left-0 w-full h-full flex overflow-auto flex-col ${
        Object.keys(contextState?.videoSelected)?.length &&
        location.hash === '#video' &&
        invitationId === contextState?.videoSelected?.invitationId
          ? 'video-modal-open'
          : 'video-modal-closed'
      }`}>
      <div className='mx-5 my-4'>
        <span onClick={closeModal} className='cursor-pointer'>
          <img src={alkemyLogo} />
        </span>
      </div>
      <div className='mx-auto video-modal-max-width relative'>
        <div className='absolute w-full top-0 left-0 pb-5'>
          <div className='flex justify-between'>
            <button
              className='video-modal-close-button font-small font-roboto font-bold py-2 px-3'
              onClick={closeModal}>
              <span className='flex align-items-center ml-2'>
                <img src={closeIcon} className='mr-2' />
                {t('VIDEO_MODAL_CLOSE')}
              </span>
            </button>

            <div className={`my-1 ${action ? '' : 'd-none'}`}>
              <button
                onClick={() => action(contextState?.videoSelected?.invitationId)}
                className='video-modal-close-button font-small font-roboto font-bold py-2 px-3'>
                <span data-testid='modal-accept-button-span'>{t('GO_TO_EXAM_AFTER_VIDEO')}</span>
              </button>
            </div>
          </div>
          <div className='video-modal-player-wrapper relative my-4'>
            <ReactPlayer
              url={contextState?.videoSelected?.assetUrl}
              height='100%'
              width='100%'
              controls
              className='absolute top-0 left-0'
            />
          </div>
          <div>
            <p className='video-modal-title text-white '>{contextState?.videoSelected?.title}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
