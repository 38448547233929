import React from 'react';

const CheckIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='16' height='16' rx='8' fill='#5EDCA7' />
      <path d='M4 7.5L7 10.5L12 5.5' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export default CheckIcon;
