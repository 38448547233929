function Address(profile) {
  const province = profile?.address?.city?.displayName;
  const country = profile?.address?.country?.displayName;
  switch (true) {
    case !!province && !!country:
      return `${province}, ${country}`;
    case !!province && !country:
      return province;
    case !province && !!country:
      return country;
    default:
      return undefined;
  }
}

export default Address;
