import ARG from './ARG.svg';
import BRA from './BRA.svg';
import ESP from './ESP.svg';
import USA from './USA.svg';

export default {
  ARG,
  BRA,
  ESP,
  USA,
};
