import { useFieldArray } from 'react-hook-form';
import useTranslations from '../../../hooks/useTranslations';
import PortfolioCardForm from './PortfolioForm';
import PortfolioCardFirstInput from './PortolioFirstInput';

const PortfolioCard = ({ watch, control, errors, profile, setProfile }) => {
  const { fields, remove, update } = useFieldArray({
    control,
    name: 'portfolios',
  });
  const { t } = useTranslations();
  return (
    <div className='flex flex-col'>
      <span className='text-alkemy-black text-base font-bold my-auto dark:text-white'>
        {t('PORTFOLIO_SECTION_TITLE')}
      </span>

      <PortfolioCardFirstInput
        value={{}}
        update={object => update(fields.length ?? 0, object)}
        editable
        index={fields.length ?? 0}
        watchParent={watch}
        portfoliosUrl={watch('portfolios')}
        fieldName={`portfolios.${fields.length ?? 0}`}>
        <div className={`flex flex-wrap`}>
          {fields.map((item, index) => {
            const fieldname = `portfolios.${index}`;
            return (
              <PortfolioCardForm
                key={item.id}
                portfoliosUrl={watch('portfolios')}
                update={object => update(index, object)}
                editable
                index={index}
                profile={profile}
                setProfile={setProfile}
                remove={() => remove(index)}
                fieldName={fieldname}
                watchParent={watch}
                value={item}
              />
            );
          })}
        </div>
      </PortfolioCardFirstInput>
    </div>
  );
};

export default PortfolioCard;
