import { useRef } from 'react';
import useClickOutside from '../../hooks/useClickOutside';
import { useContextState } from '../../redux/contextState';
import './modal.scss';
import Spinner from '../Spinner/Spinner';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const ModalCustom = ({
  title,
  subtitle,
  show,
  setShow,
  onOk,
  onCancel,
  onOkDisabled,
  onCancelText,
  content,
  onOkText,
  wrapClassname,
  contentContainerClassName,
  hideCloseCross = false,
  closeOnClickOutside = true,
  okButtonClassname = '',
  okButtonTextClassname = undefined,
  isLoading = false,
  datatestid = 'modal-container-div',
}) => {
  const modalRef = useRef(null);

  useClickOutside(
    modalRef,
    () => {
      if (show) {
        setShow(false);
      }
    },
    closeOnClickOutside,
  );

  return (
    <div
      className={`modal flex justify-center fixed top-0 left-0 my-0 mx-auto ${show ? 'visible' : 'non-visible'}`}
      data-testid={datatestid}>
      <div
        className={`modal__wrap flex flex-col container-modal-screening w-full text-center my-auto dark:bg-alkemy-black-bold ${
          wrapClassname ?? 'py-5'
        }`}
        ref={modalRef}>
        {!hideCloseCross && (
          <span
            className='absolute top-0 right-0 mt-0 mr-4 cursor-pointer h-fit dark:text-white text-alkemy-blue text-[3.125rem] font-medium font-montserrat'
            aria-hidden='true'
            data-testid='modal-exit-button-span'
            onClick={() => setShow(false)}>
            &times;
          </span>
        )}

        <div
          className={`flex flex-col pt-4 pb-3 container-components-modal ${contentContainerClassName}`}
          data-testid='modal-container-div'>
          {Boolean(title) && (
            <span className='h3 font-bold dark:text-white mx-10' data-testid='modal-title-span'>
              {title}
            </span>
          )}
          {Boolean(subtitle) && (
            <span className='mt-4 mb-1 px-2 lg:px-1 h5 text-sm dark:text-white' data-testid='modal-subtitle-span'>
              {subtitle}
            </span>
          )}
          {Boolean(content) && content}
          {(onOkText && onOk) || (onCancelText && onCancel) ? (
            <div className='flex flex-row justify-center mt-3' data-testid='modal-actions-container'>
              {onCancelText && onCancel && (
                <button
                  type='button'
                  onClick={onCancel}
                  className='button-right-modal px-1 lg:px-4 mr-3 lg:mr-5'
                  data-testid='modal-cancel-button'>
                  <span
                    className='text-button-right-modal flex justify-center items-center text-alkemy-black dark:text-white'
                    data-testid='modal-cancel-button-span'>
                    {onCancelText}
                  </span>
                </button>
              )}
              {onOkText && onOk && (
                <button
                  type='button'
                  onClick={onOk}
                  className={`button-right-modal px-1 lg:px-4 ${okButtonClassname}`}
                  disabled={onOkDisabled}
                  data-testid='modal-accept-button'>
                  {isLoading ? (
                    <div className={`mx-4`}>
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} />
                    </div>
                  ) : (
                    <span
                      className={`text-button-right-modal flex justify-center items-center  dark:text-white ${
                        okButtonTextClassname ?? 'text-alkemy-black'
                      }`}
                      data-testid='modal-accept-button-span'>
                      {onOkText}
                    </span>
                  )}
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ModalCustom;
