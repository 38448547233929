import SideBarDropDown from '../SideBarDropDown/SideBarDropDown';
import useSidebar from '../../hooks/useSidebar';
import { useSidebarRoutes } from '../../constants/sidebarRoutes';
import useTranslations from '../../hooks/useTranslations';
import { auth0Client } from '../../auth0/auth0';
import { Window } from '../../helpers/window';
import DropdownLanguage from '../DropdownLanguage/DropdownLanguage';
import { ActionTypes, useContextState } from '../../redux/contextState';
import closeIcon from '../../assets/header/close.svg';
import './menuResponsive.scss';
import CrossSidebarIcon from '../../assets/header/icon-check-sidebar';
import DarkModeIcon from '../../assets/header/dark-mode';
import LightModeIcon from '../../assets/header/light-mode';

const MenuResponsive = ({ editable, redirectProfile }) => {
  const { t } = useTranslations();
  const { mobileRoutes } = useSidebarRoutes(t);
  const { contextState, setContextState } = useContextState();
  const { collapsed, setCollapsed } = useSidebar();

  const renderRoutes = routes => {
    if (
      (routes[0].path === '/inscription' || routes[0].path === '/challenges') &&
      !!contextState?.screenings?.length &&
      !contextState?.screenings?.every(e => e.endDate)
    ) {
      return routes.filter(route => route.path !== '/inscription');
    }
    return routes;
  };

  return (
    <div
      className={`fixed lg:hidden h-full dropdown-menu-responsive py-4 pb-20 pr-4 top-0 right-0 ${
        !collapsed ? 'dropdown-active' : ''
      }`}>
      <button className='bg-transparent border-0 float-right px-2' type='button' onClick={() => setCollapsed(true)}>
        <img src={closeIcon} alt={t('SIDEBAR_ICON_COLLAPSED')} className='mt-3' />
      </button>
      <DropdownLanguage mobile />
      <ul className='nav-links list-unstyled overflow-auto h-full mt-4'>
        {mobileRoutes.map((route, index) => (
          <li className={`my-3 ${!collapsed && 'overflow-hidden'}`} aria-hidden='true' key={index}>
            {route.path ? (
              route?.isAcademy ? (
                <a href={route?.path + localStorage.getItem('token')} target='_blank' rel='noreferrer' className='m-0'>
                  <SideBarDropDown
                    icon={route?.icon}
                    buttonText={route?.label}
                    collapsed={collapsed}
                    selected={route?.selected}
                    options={route?.subRoutes ?? []}
                    setCollapsed={setCollapsed}
                    editable={editable}
                    redirectProfile={redirectProfile}
                  />
                </a>
              ) : route.isScreening ? (
                <a href={route?.path + localStorage.getItem('token')} target='_blank' rel='noreferrer' className='m-0'>
                  <SideBarDropDown
                    icon={route?.icon}
                    buttonText={route?.label}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    selected={route?.selected}
                    options={route?.subRoutes ?? []}
                    editable={editable}
                    redirectProfile={redirectProfile}
                  />
                </a>
              ) : (
                <div
                  className='m-0 bg-transparent border-0'
                  aria-hidden={true}
                  onClick={() => {
                    redirectProfile(editable, route?.path);
                    setCollapsed(true);
                  }}>
                  <SideBarDropDown
                    icon={route?.icon}
                    buttonText={route?.label}
                    collapsed={collapsed}
                    selected={route?.selected}
                    setCollapsed={setCollapsed}
                    options={route?.subRoutes ?? []}
                    editable={editable}
                    redirectProfile={redirectProfile}
                  />
                </div>
              )
            ) : (
              <SideBarDropDown
                icon={route?.icon}
                buttonText={route.label}
                collapsed={collapsed}
                selected={route?.selected}
                setCollapsed={setCollapsed}
                options={renderRoutes(route?.subRoutes) ?? []}
                editable={editable}
                redirectProfile={redirectProfile}
              />
            )}
          </li>
        ))}
        <SideBarDropDown
          icon={contextState?.theme === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
          buttonText={contextState?.theme === 'light' ? t('DARK_MODE') : t('LIGHT_MODE')}
          collapsed={collapsed}
          editable={editable}
          setCollapsed={setCollapsed}
          redirectProfile={redirectProfile}
          onClick={() => {
            if (contextState?.theme === 'light') {
              setContextState({
                type: ActionTypes.SetTheme,
                value: 'dark',
              });
            } else {
              setContextState({
                type: ActionTypes.SetTheme,
                value: 'light',
              });
            }
          }}
        />
        <SideBarDropDown
          icon={<CrossSidebarIcon />}
          buttonText={t('HEADER_SIGN_OUT')}
          collapsed={collapsed}
          editable={editable}
          redirectProfile={redirectProfile}
          onClick={() => {
            if (Window()) {
              auth0Client().logout({ returnTo: window.location.origin });
            }
          }}
        />
      </ul>
    </div>
  );
};

export default MenuResponsive;
