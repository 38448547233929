/**
 * Calculates the date range based on the selected option.
 *
 * @param {number} selectedOption - An integer representing the selected time range option.
 *   1: Ultimas 24 hs
 *   2: Ultima semana
 *   3: Ultimo mes
 *   4: Más de 1 mes
 *
 * @returns {Object} An object containing the calculated start and end dates for the selected time range.
 * @throws {Error} Throws an error if an invalid option is provided.
 */
export function calculateDateRange(selectedOption) {
  // Get the current date and initialize variables for start and end dates
  const currentDate = new Date();
  let startDate, endDate;

  // Calculate start and end dates based on the selected option
  switch (selectedOption) {
    case 1: // Ultimas 24 hs
      startDate = new Date(currentDate - 24 * 60 * 60 * 1000);
      endDate = currentDate;
      break;
    case 2: // Ultima semana
      startDate = new Date(currentDate - 7 * 24 * 60 * 60 * 1000);
      endDate = currentDate;
      break;
    case 3: // Ultimo mes
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
      endDate = currentDate;
      break;
    case 4: // Más de 1 mes
      //startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, currentDate.getDate());
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
      break;
    default:
  }

  // Return the calculated start and end dates
  return { startDate, endDate };
}
