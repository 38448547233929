import Banner from '../../components/Banner/Banner';
import { useContextState } from '../../redux/contextState';
import welcomeSvg from '../../assets/welcome/welcome.svg';
import { Link } from 'react-router-dom';
import useTranslations from '../../hooks/useTranslations';
import Container from '../../components/Container/Container';
import './welcome.scss';

const Welcome = () => {
  const { contextState } = useContextState();
  const { t } = useTranslations();

  return (
    <div className='relative w-full'>
      <Banner
        text={`¡${t('WELCOME_SECTION_TITLE')} ${
          typeof contextState.profile?.firstName !== 'undefined' ? contextState.profile?.firstName : ''
        }!`}
        icon={contextState?.whitelabel?.images?.BANNER_LOGO}
      />
      <Container>
        <div className='description-container bg-white dark:bg-dark-bold rounded m-auto pb-4 pl-3 lg:pl-5 flex flex-col items-center py-5'>
          <h2 className='events-title  font-bold lg:mt-3 m-0 dark:text-white'>{t('WELCOME_TITLE')}</h2>
          <span className='welcome-subtitle  lg:mt-3  dark:text-white'>{t('WELCOME_SUBTITLE')}</span>
          <img src={welcomeSvg} className='mt-3' />
          <span className='welcome-subtitle  lg:mt-4 m-0 dark:text-white'>{t('WELCOME_SUBTITLE_1')}</span>
          <button type='button' className='welcome-button border-0 mt-4'>
            <Link to='/profile' className='welcome-button-text flex items-center font-bold'>
              😎 {t('WELCOME_BUTTON')}
            </Link>
          </button>
        </div>
      </Container>
    </div>
  );
};

export default Welcome;
