import useRoles from '../hooks/useRoles';
import { useContextState } from '../redux/contextState';
import isComplete from './steps/isComplete';

const whereIGo = profile => {
  const { isRoles, roles } = useRoles();
  const { contextState } = useContextState();
  const welcomePage = contextState?.whitelabel?.routes?.includes('WELCOME');
  let route = {
    pathname: '/home',
    hash: window.location.hash ?? '',
  };

  if (isComplete(profile) > 0) {
    if (isRoles([roles?.APPLICANT]) && welcomePage) {
      route = {
        pathname: '/welcome',
        hash: window.location.hash ?? '',
      };
    }
  } else {
    route = {
      pathname: '/steps',
      hash: window.location.hash ?? '',
    };
  }
  return route;
};

export default whereIGo;
