import React, { useRef } from 'react';

function Content(props) {
  const content = useRef(null);
  const { open, children, fixWidth, maxHeightPercent } = props;

  return (
    <div
      className={`accordion__section relative -top-[0.063rem] m-0 rounded-lg w-full ${
        open && 'border-1'
      } border-alkemy-blue `}
      style={{ width: fixWidth }}>
      <div
        ref={content}
        style={{
          maxHeight: open ? (maxHeightPercent ? `${maxHeightPercent}%` : `${content?.current?.scrollHeight}px`) : '0px',
        }}
        className='accordion__content overflow-hidden'>
        <div className='pb-4 px-[0.063rem] flex flex-col justify-between text-center'>{children}</div>
      </div>
    </div>
  );
}

export default Content;
