import { Popconfirm } from 'antd';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import EditPen from '../../../../assets/profile/EditPen';
import DeleteForever from '../../../../assets/profile/DeleteForever';
import useTranslations from '../../../../hooks/useTranslations';
import { useContextState } from '../../../../redux/contextState';
import NewStyleInput from '../../../NewStyleInput/NewStyleInput';
import NewStyleSelect from '../../../NewStyleSelect/NewStyleSelect';
import { urlRegex } from '../../../../constants/regexValidations';

const LanguageCardForm = ({ value, editable, update, watchParent, fieldName, remove, showCancelButton, index }) => {
  const { contextState } = useContextState();
  const { t } = useTranslations();
  const profileLanguageIds = watchParent('languages')?.map(item => item.languageId) ?? [];
  const [edit, setEdit] = useState(editable);
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: value,
  });

  useEffect(() => {
    if (Object.keys(value).length === 1) {
      setEdit(true);
    }
  }, []);

  return edit ? (
    <div
      className={`mt-2 lg:pr-2 ${
        watchParent('languages').length > 1 && index !== watchParent('languages').length - 1 && 'pb-2 border-b-2'
      }`}>
      <div className='flex'>
        <NewStyleSelect
          error={errors.languageId ?? false}
          validations={{
            required: {
              value: true,
              message: t('LANGUAGE_CARD_LANGUAGE_REQUIRED'),
            },
            valueAsNumber: true,
          }}
          className='w-1/3 mr-4'
          editable
          register={register}
          title={t('LANGUAGE_CARD_LANGUAGE_LANGUAGE_TITLE')}
          value={watch(`languageId`)}
          options={contextState.languages?.map(item => ({
            disabled: profileLanguageIds.includes(item.id) && value.languageId !== item.id,
            id: item.id,
            name: t(item.displayName),
          }))}
          name={`languageId`}
        />
        <NewStyleSelect
          error={errors.levelId ?? false}
          validations={{
            required: {
              value: true,
              message: t('LANGUAGE_CARD_LEVEL_REQUIRED'),
            },
            valueAsNumber: true,
          }}
          className='w-1/3'
          editable
          register={register}
          title={t('LANGUAGE_CARD_LEVEL_TITLE')}
          value={watch(`levelId`)}
          options={contextState.levels?.map(item => ({
            id: item.id,
            name: t(item.displayName),
          }))}
          name={`levelId`}
        />
      </div>
      <NewStyleInput
        className='mt-3'
        register={register}
        validations={{
          pattern: {
            value: urlRegex,
            message: 'LANGUAGE_CARD_INVALID_CERTIFICATE_URL',
          },
        }}
        error={errors.certificateUrl ?? false}
        title={t('LANGUAGE_CARD_CERTIFICATE_TITLE')}
        name={`certificateUrl`}
      />
      {watch('languageId') === 3 && (
        <div className='mt-2'>
          <span className='text-xs font-semibold italic text-gray-500'>{t('LANGUAGE_CARD_CERTIFICATE_URL_1')}</span>
          <a
            data-testid='link-efset-certificate'
            target='_blank'
            rel='noreferrer'
            href={t('EFSET_CERTIFICATE_LINK')}
            className='text-alkemy-purple text-xs font-semibold ml-1 italic'>
            {t('LANGUAGE_CARD_CERTIFICATE_URL_2')}
          </a>
        </div>
      )}
      <div className='mt-4 flex justify-end items-center'>
        {showCancelButton && (
          <button
            className='text-red-600 h-full mr-4 font-bold'
            onClick={() => {
              if (Object.keys(value).length === 1) {
                remove();
              } else {
                update(watchParent(fieldName));
              }
            }}>
            {t('CANCEL')}
          </button>
        )}
        <button
          data-testid='button-add-language'
          className='card-button-edit bg-white dark:bg-dark-bold rounded-full font-bold px-5 text-alkemy-button border-maya-blue'
          onClick={handleSubmit(() => {
            update(watch());
            setEdit(false);
          })}>
          {t('LANGUAGE_CARD_ADD_BUTTON')}
        </button>
      </div>
    </div>
  ) : (
    <div
      data-testid='card-language-data'
      className={`flex justify-between flex-wrap ${
        watchParent('languages')?.length > 1 && index !== watchParent('languages')?.length - 1 && 'pb-2 border-b-2'
      }`}>
      <div className='flex flex-grow'>
        <div className='flex flex-col my-2 mr-16 w-20'>
          <span className='flex items-center mb-1 dark:text-white font-light italic truncate custom-input-label-active text-sm'>
            {t('LANGUAGE_CARD_LANGUAGE_LANGUAGE_TITLE')}
          </span>
          <span className='block items-center mb-1 dark:text-white font-bold truncate text-xs'>
            {t(contextState.languages.find(item => item.id === watchParent(`${fieldName}.languageId`))?.displayName)}
          </span>
        </div>
        <div className='flex flex-col my-2'>
          <span className='flex items-center mb-1 dark:text-white font-light italic truncate custom-input-label-active text-sm'>
            {t('LANGUAGE_CARD_LEVEL_TITLE')}
          </span>
          <span className='flex items-center mb-1 dark:text-white font-bold truncate text-xs'>
            {t(contextState.levels.find(item => item.id === watchParent(`${fieldName}.levelId`))?.displayName)}
          </span>
        </div>
      </div>
      <div className='flex justify-center items-center mx-auto flex-shrink-0 w-1/3 xl:mr-3'>
        {watchParent(`${fieldName}.certificateUrl`) && (
          <a
            target='_blank'
            rel='noreferrer'
            href={watchParent(`${fieldName}.certificateUrl`)}
            className='mx-1 font-bold text-alkemy-purple whitespace-nowrap'>
            {t('FORMATION_CARD_SEE_CERTIFICATE')}
          </a>
        )}
        <button className='mx-1' onClick={() => setEdit(true)}>
          <EditPen />
        </button>
        <button className='mx-1'>
          <Popconfirm
            title={t('LANGUAGE_POP_CONFIRM_DELETE')}
            onConfirm={() => remove()}
            okText={t('YES')}
            cancelText={t('NO')}
            className='my-auto'>
            <div>
              <DeleteForever />
            </div>
          </Popconfirm>
        </button>
      </div>
    </div>
  );
};

export default LanguageCardForm;
