import { roles } from './enums';

export const exceptRoles = [
  'PARTNER',
  'BACKOFFICE',
  'BACKOFFICE.ACCELERATION',
  'BACKOFFICE.HR/TA',
  'BACKOFFICE.SALES',
  'MENTOR',
  'TESTSCREENINGSPARTNER',
];

export const requiredRoles = [roles.APPLICANT, roles.ALKYMER, roles.PREACCELERATION];
