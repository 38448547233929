import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { saveProfile } from '../../api/userService';
import useTranslations from '../../hooks/useTranslations';
import { ActionTypes, useContextState } from '../../redux/contextState';
import { languagesList } from '../../utils/constants';
import CountryFlag from '../CountryFlag/CountryFlag.js';
import ModalCustom from '../Modal/Modal';
function ModalPreferredLanguage() {
  const { t } = useTranslations();
  const { contextState, setContextState } = useContextState();
  const [preferredLanguage, setPreferredLanguage] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!contextState?.profile?.preferredLanguage) setOpen(true);
  }, [contextState]);

  const onClickOk = async () => {
    const data = {
      preferredLanguage,
    };
    try {
      setLoadingSubmit(true);
      await saveProfile(data);
      setContextState({
        type: ActionTypes.SetProfile,
        value: data,
      });
      notification.open({
        message: t('PROFILE_EDIT_SUCCESS'),
        type: 'success',
      });
      setOpen(false);
    } catch (error) {
      notification.open({
        message: t('PROFILE_EDIT_FAILED'),
        type: 'error',
      });
    } finally {
      setLoadingSubmit(false);
    }
  };

  return (
    <ModalCustom
      datatestid='modal-preferred-language'
      show={open}
      setShow={setOpen}
      closeOnClickOutside={false}
      isLoading={loadingSubmit}
      onOkText={t('PROFILE_MENU_SAVE')}
      onOk={onClickOk}
      onOkDisabled={loadingSubmit || !preferredLanguage}
      okButtonClassname={'bg-maya-blue text-white'}
      okButtonTextClassname={'text-white'}
      title={t('MODAL_PREFERRED_LANGUAGE_TITLE')}
      subtitle={t('MODAL_PREFERRED_LANGUAGE_SUBTITLE')}
      hideCloseCross={true}
      content={
        <>
          <div className='flex flex-col p-2 form-step-container my-4 max-h-[25rem] ' data-testid='step3-countries-div'>
            {languagesList.map(option => (
              <button
                className={`container-reason flex flex-row items-center p-3 mt-2 relative cursor-pointer border-0  ${
                  preferredLanguage == option?.value ? 'selectedOptions' : 'unSelectedOptions'
                }`}
                key={option?.iso}
                onClick={() => setPreferredLanguage(option?.value)}
                data-testid={`lang-option-${option?.iso}`}>
                <CountryFlag countryIso={option.iso} size='sm' className='mx-2' />
                <span className='option-text font-semibold m-0 mx-2'>{t(option?.label)}</span>
              </button>
            ))}
          </div>
        </>
      }
    />
  );
}

ModalPreferredLanguage.propTypes = {};

export default ModalPreferredLanguage;
