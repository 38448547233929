import { percent } from '../../utils/numbersUtils';
import checkgreen from '../../assets/profile/checkgreen.svg';
import useTranslations from '../../hooks/useTranslations';
import { Tooltip } from 'antd';

const SubSkillItem = ({ subSkill }) => {
  const { t } = useTranslations();
  return (
    <Tooltip placement='top' title={t(subSkill?.key ? `${subSkill?.key}-DES` : subSkill?.description)}>
      <li className='list-group-item border-0 px-0 py-2 mr-1 mr-md-3 item-collapse flex items-center'>
        <div className='flex'>
          <span
            className={`align-content-lg-startticket-training-job-ready uppercase flex items-center ${
              subSkill?.approved ? 'skill-completed' : 'skill-incompleted skill-custom-advance-background'
            }`}
            data-percent={percent(subSkill?.currentScore, subSkill?.acceptanceScore)}>
            <div
              className={`font-bold font-roboto flex ${!subSkill?.approved ? 'skill-custom-advance' : ''}`}
              data-percent={percent(subSkill?.currentScore, subSkill?.acceptanceScore)}>
              {t(subSkill?.key ?? subSkill?.displayName ?? subSkill?.name)}
              {subSkill?.blocker && '*'}
              {subSkill?.approved && <img src={checkgreen} className='ml-2' alt={t('CHECK')} />}
            </div>
          </span>
        </div>
      </li>
    </Tooltip>
  );
};
export default SubSkillItem;
