import React, { useState } from 'react';
import { useEffect } from 'react';
import useTranslations from '../../../hooks/useTranslations';
import { Skeleton } from 'antd';
import SearchableSelect from '../../SearchableSelect/SearchableSelect';
import DeleteForever from '../../../assets/profile/DeleteForever';
import './techCard.scss';

const TechCard = props => {
  const { className, technologies, options, onChange, messageError, loading } = props;
  const { t } = useTranslations();
  const [optionsMap, setOptionsMap] = useState({});

  useEffect(() => {
    const map = {};
    options.forEach(option => {
      map[option.id] = option.displayName;
    });
    setOptionsMap(map);
  }, [options]);

  const handleChange = e => {
    const selected = { technologyId: e?.value, technologyType: 'KNOW', technology: { displayName: e?.label } };
    onChange([...technologies, selected], 'technologies');
  };

  const handleRemove = technologyId => {
    const newTechnologies = technologies.filter(tech => tech.technologyId !== technologyId);
    onChange(newTechnologies, 'technologies');
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <span className='text-alkemy-black text-base  font-bold dark:text-white'>
        {t('TECH_CARD_SECONDARY_TECHNOLOGY')}
      </span>
      <div className='flex flex-col mt-4'>
        {loading ? (
          <Skeleton active title={false} className='w-75' />
        ) : (
          <>
            <span className='flex items-center mb-1 dark:text-white italic truncate custom-input-label-active text-sm'>
              {t('TECH_CARD_TECHNOLOGY')}
            </span>
            <SearchableSelect
              className='md:w-1/2 mb-4'
              handleChange={handleChange}
              options={options
                ?.map(technology => ({ value: technology.id, label: technology.displayName }))
                .filter(option => technologies.every(tech => tech.technologyId !== option.value))}
              error={messageError}
            />
            <ul className='flex flex-wrap'>
              {Boolean(options.length) &&
                technologies
                  ?.filter(technology => technology.technologyType === 'KNOW')
                  .map(item => (
                    <li key={item.technologyId} className='selected-technology'>
                      <span className='text-xs'>{optionsMap[item.technologyId]}</span>
                      <div className='cursor-pointer ml-1' onClick={() => handleRemove(item.technologyId)}>
                        <DeleteForever width='21' />
                      </div>
                    </li>
                  ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default TechCard;
