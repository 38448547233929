import React from 'react';
import Select from 'react-select';
import useTranslations from '../../hooks/useTranslations';

const SearchableSelect = ({ options, className, error, handleChange, showSelected = true, placeholder = '' }) => {
  const { t } = useTranslations();

  const customStyles = {
    container: provided => ({
      ...provided,
      height: '1.625rem',
    }),
    menu: provided => ({
      ...provided,
      marginTop: '0px',
    }),
    option: provided => ({
      ...provided,
      fontWeight: '600',
      fontSize: '0.75rem',
      padding: '0.375rem 0.25rem',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    valueContainer: provided => ({
      ...provided,
      color: 'black',
      opacity: '1',
      padding: '0px .5rem',
      fontSize: '0.75rem',
      fontWeight: '600',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: '0.75rem',
      fontWeight: '600',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    control: provided => ({
      ...provided,
      minHeight: '0px',
      background: 'transparent',
      display: 'flex',
      borderRadius: '.5rem',
      alignItems: 'center',
      height: '100%',
      borderColor: error ? 'red' : '#D1D5DB',
      '&:hover': {
        borderColor: error ? 'red' : '#D1D5DB',
      },
      boxShadow: 'none',
    }),
    indicatorContainer: provided => ({
      ...provided,
      padding: '0px 0.313rem 0.25rem',
      color: '#58c1f5',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: '#58c1f5',
      padding: '0px 0.25rem',
      '&:hover': {
        color: '#58c1f5',
      },
    }),
  };

  return (
    <div className={`w-full relative text-sm bg-white shadow-sm dark:bg-transparent dark:text-white ${className}`}>
      <Select
        placeholder={placeholder}
        noOptionsMessage={() => t('NO_OPTIONS')}
        styles={customStyles}
        className={`text-xs h-[1.625rem] ${error && 'border-error'}`}
        options={options}
        onChange={handleChange}
        value={0}
      />
      {error && <span className='text-xs text-red-600'>{t(error.message)}</span>}
    </div>
  );
};

export default SearchableSelect;
