import React, { useEffect } from 'react';
import useTranslations from '../../hooks/useTranslations';
import { auth0Client } from '../../auth0/auth0';
import { Window } from '../../helpers/window';
import { ActionTypes, useContextState } from '../../redux/contextState';
import Header from '../../components/Header/Header';

const Error = ({ showHeader = true }) => {
  const { t } = useTranslations();
  const { contextState, setContextState } = useContextState();

  const redirectProfile = (editable, route) => {
    if (editable) {
      setContextState({
        type: ActionTypes.SetShow,
        value: true,
      });
      setContextState({
        type: ActionTypes.SetRoute,
        value: route,
      });
    } else {
      setContextState({
        type: ActionTypes.SetEditable,
        value: false,
      });
      history.push(route);
    }
  };
  return (
    <>
      {showHeader && (
        <Header
          profile={contextState}
          editable={contextState.editable}
          redirectProfile={redirectProfile}
          maintenance={true}
        />
      )}

      <div className='flex flex-col items-center dark:bg-alkemy-black-bold error-container-height'>
        <span className='maintenance-title text-alkemy-black-bold text-center font-bold mt-7 pt-5'>
          {t('ERROR_TITLE')}
        </span>
        <span className='maintenance-subtitle text-center mt-2'>{t('ERROR_SUBTITLE')}</span>
        <button
          className='maintenance-button border-0 mt-5'
          onClick={() => {
            if (Window()) {
              auth0Client().logout({ returnTo: window.location.origin });
            }
          }}>
          <span className='maintenance-button-text font-roboto font-bold'>🤓 {t('MAINTENANCE_BUTTON')}</span>
        </button>
      </div>
    </>
  );
};

export default Error;
