import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Banner from '../../components/Banner/Banner';
import Container from '../../components/Container/Container';
import EmptyComponent from '../../components/EmptyComponent/EmptyComponent';
import FinishedScreeningCard from '../../components/FinishedScreeningCard/FinishedScreeningCard';
import Spinner from '../../components/Spinner/Spinner';
import useTranslations from '../../hooks/useTranslations';
import { useContextState } from '../../redux/contextState';

const FinishedScreeningsView = () => {
  const { t } = useTranslations();
  const history = useHistory();
  const { contextState } = useContextState();
  const [screeningsLoading, setScreeningsLoading] = useState(true);
  const [finishedScreenings, setFinishedScreenings] = useState([]);

  useEffect(() => {
    if (Boolean(contextState?.screenings.length)) {
      setFinishedScreenings(
        contextState?.screenings.filter(
          screening =>
            screening.status === 'FINISHED' || new Date(screening.stage?.endDate).getTime() < new Date().getTime(),
        ),
      );
      setScreeningsLoading(false);
    }
  }, []);

  return (
    <div className='relative w-full pb-5'>
      <Banner text={t('FINISHED_SCREENINGS_VIEW_TITLE')} withSpacing={false} />
      <Container>
        <div className='description-container bg-white dark:bg-dark-bold rounded mx-auto mb-3'>
          {screeningsLoading && <Spinner show />}
          {!screeningsLoading && finishedScreenings?.length === 0 && (
            <div className='py-8 px-4 mt-4 bg-[#f4f4f4] dark:bg-dark-bold flex justify-center items-center'>
              <EmptyComponent
                title={t('NO_FINISHED_SCREENINGS_AVAILABLE')}
                button={{
                  text: t('NO_CHALLENGES_BUTTON_TEXT'),
                  onClick: () => history.push('/home'),
                  className:
                    'mt-3 bg-white border-maya-blue border-2 mx-auto rounded-2xl w-fit px-4 py-1 text-alkemy-blue font-bold',
                }}
              />
            </div>
          )}
          {!screeningsLoading && finishedScreenings?.length > 0 && (
            <div className='flex justify-center flex-wrap'>
              {finishedScreenings.map(screening => {
                return <FinishedScreeningCard {...screening} key={screening.id} />;
              })}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default FinishedScreeningsView;
