import React, { useRef } from 'react';
import './Accordion.scss';

function Accordion(props) {
  const content = useRef(null);

  const { open, children, py } = props;
  return (
    <div className='accordion__section'>
      <div
        ref={content}
        style={{ maxHeight: open ? `${content.current.scrollHeight}px` : '0px' }}
        className='accordion__content bg-white dark:bg-alkemy-black-bold overflow-hidden'>
        <div className={`accordion__text text-base ${py ? py : 'py-4'}`}>{children}</div>
      </div>
    </div>
  );
}

export default Accordion;
