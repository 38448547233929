import React from 'react';
import useMissingData from '../../hooks/useMissingData';
import shieldIcon from '../../assets/news/green-shield.svg';
import flagIcon from '../../assets/myGroup/icon-flag.svg';
import ligthningIcon from '../../assets/myGroup/icon-bolt.svg';
import AdvanceBar from '../Avance/AdvanceBar/AdvanceBar';
import { Link } from 'react-router-dom';
import useTranslations from '../../hooks/useTranslations';

const NewsProfileCompleteStatus = () => {
  const { missingData, percentage } = useMissingData();
  const { t } = useTranslations();
  return !missingData.length ? null : (
    <div className='description-container bg-white dark:bg-dark-bold rounded mx-auto p-4 mb-3'>
      <span className='text-base  font-bold dark:text-white'>
        {t('NEWS_PERCENTAGE_OF_COMPLETE_PROFILE').replace('{percentage}', percentage)}
      </span>
      <AdvanceBar
        percentage
        objectiveIcon={shieldIcon}
        className='sm:pr-5 pb-3 pr-4'
        advanceTitle=''
        engagement={percentage}
      />
      <div className='flex flex-col'>
        <span className='font-bold text-base pb-1 dark:text-white flex flex-row'>
          <img src={flagIcon} className='pr-2' />
          {t('NEWS_DONT_FORGET_TO_COMPLETE')}
        </span>
        <ul className='flex flex-col sm:flex-row list-unstyled mt-1 flex-wrap justify-start'>
          {missingData.map((item, index) => (
            <li className={`my-2 flex whitespace-nowrap dark:text-white ${index === 0 ? 'pr-3' : 'px-3'}`} key={index}>
              <img className='mr-2' src={ligthningIcon} />
              {item}
            </li>
          ))}
        </ul>
        <Link
          to='profile'
          className='calendar-link px-3 py-2 decoration-none font-bold text-white hover:text-white font-roboto font-small mr-4 self-end mt-3'>
          {t('NEWS_COMPLETE_MY_DATA')}
        </Link>
      </div>
    </div>
  );
};

export default NewsProfileCompleteStatus;
