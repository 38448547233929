import './emptyComponent.scss';

const EmptyComponent = ({ className, title, subtitle, size = 'md', button }) => {
  return (
    <div
      data-testid='empty-component'
      className={`flex flex-col justify-center text-center align-ctext-base lg:ontent-center w-full empty-container dark:bg-dark-normal  ${className}`}>
      <span className='text-alkemy-black text-sm  font-semibold dark:text-white' data-size={size}>
        {title}
      </span>
      <span className='empty-subtitle text-xs  mt-3 dark:text-white' data-size={size}>
        {subtitle}
      </span>
      {button && (
        <button className={button?.className} onClick={button?.onClick}>
          {button?.text}
        </button>
      )}
    </div>
  );
};

export default EmptyComponent;
