import collapseIcon from '../../assets/sideBar/icons/noCollapse.svg';
import SideBarDropDown from '../SideBarDropDown/SideBarDropDown';
import useSidebar from '../../hooks/useSidebar';
import { useSidebarRoutes } from '../../constants/sidebarRoutes';
import useTranslations from '../../hooks/useTranslations';
import './sidebar.css';
import { handleRedirect } from '../../utils/handleRedirect';

const SideBar = ({ editable, redirectProfile }) => {
  const { t } = useTranslations();
  const { desktopRoutes } = useSidebarRoutes(t);
  const { collapsed, setCollapsed } = useSidebar();

  const onClick = async () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={`sidebar fixed non-selectable ${collapsed ? 'close-sidebar' : ''}`}>
      <div className='my-3 flex justify-end px-3' aria-hidden='true'>
        <button
          type='button'
          className='nav-links__item flex items-center menu-collapses justify-end unstyled-button p-2'
          onClick={onClick}>
          <img className='sidebar-icon-rotate' src={collapseIcon} alt={t('SIDEBAR_ICON_COLLAPSED')} />
        </button>
      </div>
      <ul className={`nav-links custom-scrollbar ${!collapsed && 'overflow-auto'}`}>
        {desktopRoutes.map((route, index) => (
          <li className={`my-3 ${!collapsed && 'overflow-hidden'}`} aria-hidden='true' key={index}>
            {route.path ? (
              route?.isAcademy ? (
                <span onClick={() => handleRedirect(route?.path, t, true)} aria-hidden='true' className='m-0 p-0'>
                  <SideBarDropDown
                    icon={route?.icon}
                    buttonText={route?.label}
                    collapsed={collapsed}
                    selected={route?.selected}
                    options={route?.subRoutes ?? []}
                    editable={editable}
                    redirectProfile={redirectProfile}
                  />
                </span>
              ) : route.isScreening ? (
                <a href={route?.path + localStorage.getItem('token')} target='_blank' rel='noreferrer' className='m-0'>
                  <SideBarDropDown
                    icon={route?.icon}
                    buttonText={route?.label}
                    collapsed={collapsed}
                    selected={route?.selected}
                    options={route?.subRoutes ?? []}
                    editable={editable}
                    redirectProfile={redirectProfile}
                  />
                </a>
              ) : (
                <div
                  className='m-0 bg-transparent border-0'
                  aria-hidden={true}
                  onClick={() => {
                    redirectProfile(editable, route?.path);
                  }}>
                  <SideBarDropDown
                    icon={route?.icon}
                    buttonText={route?.label}
                    collapsed={collapsed}
                    selected={route?.selected}
                    options={route?.subRoutes ?? []}
                    editable={editable}
                    redirectProfile={redirectProfile}
                  />
                </div>
              )
            ) : (
              <SideBarDropDown
                icon={route?.icon}
                buttonText={route.label}
                collapsed={collapsed}
                selected={route?.selected}
                options={route?.subRoutes}
                editable={editable}
                redirectProfile={redirectProfile}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideBar;
