import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { getProfile } from '../../api/userService';
import { ActionTypes, useContextState } from '../../redux/contextState';
import SideBar from '../../components/SideBar/SideBar';
import Header from '../../components/Header/Header';
import MenuResponsive from '../../components/MenuResponsive/MenuResponsive';
import useSidebar from '../../hooks/useSidebar';
import './dashboard.scss';
import { useHistory } from 'react-router-dom';
import ModalCustom from '../../components/Modal/Modal';
import Spinner from '../../components/Spinner/Spinner';
import useTranslations from '../../hooks/useTranslations';
import useTermsAndConditions from '../../hooks/useTermsAndConditions';
import PDFViewer from '../../components/PDFViewer/PDFViewer';
import ModalPreferredLanguage from '../../components/ModalPreferredLanguage/ModalPreferredLanguage';
const Dashboard = ({ children: Children }) => {
  const history = useHistory();
  const { contextState, setContextState } = useContextState();
  const { t } = useTranslations();
  const { collapsed, setCollapsed } = useSidebar();
  React.useEffect(() => {
    if (!Object.keys(contextState.profile).length) {
      setContextState({
        type: ActionTypes.SetLoading,
        value: true,
      });
      getProfile()
        .then(response => {
          setContextState({
            type: ActionTypes.SetProfile,
            value: response,
          });
        })
        .catch(error => {
          Sentry.captureException(error);
          setContextState({
            type: ActionTypes.SetLoading,
            value: false,
          });
        });
    }
  }, []);

  const redirectProfile = (editable, route) => {
    if (window.location.hash) {
      history.push(route + window.location.hash);
    } else {
      history.push(route);
    }
  };

  const {
    closeModal,
    termsAndConditionsData,
    termsAndConditionsLoading,
    acceptTermsAndConditions,
    acceptedTermsAndConditions,
    showTermsAndConditionsModal,
    setAcceptedTermsAndConditions,
    showModal,
    modalContentLoading,
  } = useTermsAndConditions();

  useEffect(() => {
    showTermsAndConditionsModal();
  }, []);

  return (
    <>
      {contextState.loading || termsAndConditionsLoading || !termsAndConditionsData ? (
        <Spinner show />
      ) : (
        <>
          {!contextState?.profile?.termsAndConditionsAcceptance ||
          termsAndConditionsData?.createdAt > contextState?.profile?.termsAndConditionsAcceptance ? (
            <div className='not-accepted-tyc-container'>
              <div className='modal-bg-image'></div>
              <ModalCustom
                hideCloseCross
                show={true}
                setShow={closeModal}
                closeOnClickOutside={false}
                contentContainerClassName='content-className pt-0'
                wrapClassname={termsAndConditionsData && 'h-full modal-tac-content'}
                content={
                  <>
                    {modalContentLoading ? (
                      <Spinner show />
                    ) : termsAndConditionsData ? (
                      <div className='flex flex-col h-full'>
                        <span className='dark:text-white text-start font-semibold text-base mb-2'>
                          {t('TERMS_AND_CONDITIONS_HAS_BEEN_UPDATED')}
                        </span>
                        <PDFViewer pdf={termsAndConditionsData?.link} />
                        <div className='flex items-center mt-4'>
                          <div>
                            <input
                              className='cursor-pointer'
                              type='checkbox'
                              onChange={() => setAcceptedTermsAndConditions(state => !state)}
                              value={acceptedTermsAndConditions}
                              defaultChecked={acceptedTermsAndConditions}
                            />
                            <span className='ml-2 font-montserrat text-lightGray font-medium text-sm'>
                              {t('HAVE_READ_AND_ACCEPTED')}
                              <span className='ml-1 border-0 text-alkemy-purple'>{t('TERMS_AND_CONDITIONS')}</span>
                            </span>
                          </div>
                          <button
                            disabled={!acceptedTermsAndConditions}
                            onClick={() => acceptTermsAndConditions()}
                            className='disabled:bg-gray-500 ml-auto cursor-pointer disabled:cursor-not-allowed border-0 text-white font-bold p-2 rounded-full bg-maya-blue w-fit px-4'>
                            {t('ACCEPT_TERMS_AND_CONDITIONS')}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <span>{t('CANNOT_LOAD_TERMS_AND_CONDITIONS')}</span>
                    )}
                  </>
                }
              />
            </div>
          ) : (
            <>
              <Header profile={contextState} editable={contextState.editable} redirectProfile={redirectProfile} />
              <MenuResponsive editable={contextState.editable} redirectProfile={redirectProfile} />
              <SideBar editable={contextState.editable} redirectProfile={redirectProfile} />
              <div
                className={!collapsed ? 'fixed w-full h-full top-0 right-0 left-0 bottom-0 overlay lg:hidden' : ''}
                onClick={() => setCollapsed(true)}
              />
              <div className='home-section relative bg-light-normal dark:bg-dark-normal flex with-sidebar'>
                <div className='home-content flex justify-center'>
                  <Children redirectProfile={redirectProfile} />
                  <ModalPreferredLanguage />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
