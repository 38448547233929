import { githubRegex, linkedinRegex, efsetRegex, iconsRegex } from '../../constants/regexValidations';

export const validations = (step, t) => ({
  firstName: value => {
    const errors = [];
    if (isEmpty(value)) {
      errors.push(t('PROFILE_NAME_REQUIRED_ERROR'));
    }
    if (value.match(iconsRegex)) {
      errors.push(t('PROFILE_NAME_ONLY_LETTERS'));
    }
    return errors;
  },
  lastName: value => {
    const errors = [];
    if (isEmpty(value)) {
      errors.push(t('PROFILE_LASTNAME_REQUIRED_ERROR'));
    }
    if (value.match(iconsRegex)) {
      errors.push(t('PROFILE_LASTNAME_ONLY_LETTERS'));
    }
    return errors;
  },
  gender: value => !value && [t('GENDER_REQUIRED_ERROR')],
  nationality: value => !value && [t('NATIONALITY_REQUIRED_ERROR')],
  birthdate: value => !value && [t('BIRTHDATE_REQUIRED_ERROR')],
  areaCode: value => {
    let errors = [];
    switch (step) {
      case 1:
        if (isMin(value, 1)) {
          errors.push(t('MIN_DIGITS_AREACODE'));
        }
        if (value.length === 0) {
          errors = [];
        }
        return errors;
      case 2:
        if (isEmpty(value)) {
          errors.push(t('PROFILE_AREACODE_REQUIRED_ERROR'));
        }
        if (isMin(value, 1)) {
          errors.push(t('MIN_DIGITS_AREACODE'));
        }
        return errors;
      default:
        return value?.length > 0;
    }
  },
  countryCode: value => !value?.id,
  phone: value => {
    let errors = [];
    switch (step) {
      case 1:
        if (isMin(value, 6)) {
          errors.push(t('MIN_CHARACTERS_PHONE'));
        }
        if (isMax(value, 20)) {
          errors.push(t('MAX_CHARACTERS_PHONE'));
        }
        if (value.length === 0) {
          errors = [];
        }
        return errors;
      case 2:
        if (isEmpty(value)) {
          errors.push(t('PHONE_REQUIRED_ERROR'));
        }
        if (isMin(value, 6)) {
          errors.push(t('MIN_CHARACTERS_PHONE'));
        }
        if (isMax(value, 20)) {
          errors.push(t('MAX_CHARACTERS_PHONE'));
        }
        return errors;
      default:
        return value.length > 5 && value.length < 11;
    }
  },
  countryId: value => !value && [t('COUNTRY_REQUIRED_ERROR')],
  cityId: value => !value && [t('PROFILE_PROVINCE_REQUIRED_ERROR')],
  location: value => {
    let errors = [];
    switch (step) {
      case 1:
        if (isMin(value, 2)) {
          errors.push(t('MIN_CHARACTERS_TWO'));
        }
        if (value.length === 0) {
          errors = [];
        }
        return errors;
      case 2:
        if (isEmpty(value)) {
          errors.push(t('LOCATION_REQUIRED_ERROR'));
        }
        if (isMin(value, 2)) {
          errors.push(t('MIN_CHARACTERS_TWO'));
        }
        return errors;
      default:
        return value?.length > 0;
    }
  },
  technologies: value => {
    switch (step) {
      case 1:
        return null;
      case 2:
        return !value.length > 0 && [t('MUST_CHOOSE_TECHNOLOGY')];
      default:
        return !value.length > 0 && [t('MUST_CHOOSE_TECHNOLOGY')];
    }
  },
  gitUrl: value => {
    let errors = [];
    githubRegex.lastIndex = 0;
    const valid = githubRegex.test(value);
    switch (step) {
      case 1:
        if (!valid) {
          errors.push(t('URL_NOT_PROFILE_GITHUB'));
        }
        if (value.length === 0) {
          errors = [];
        }
        return errors;
      case 2:
        if (isEmpty(value)) {
          errors.push(t('PROFILE_GITHUB_IS_REQUIRED'));
        }
        if (!valid) {
          errors.push(t('URL_NOT_PROFILE_GITHUB'));
        }
        return errors;
      default:
        if (!valid) {
          errors.push(t('URL_NOT_PROFILE_GITHUB'));
        }
        return errors;
    }
  },
  linkedinUrl: value => {
    let errors = [];
    switch (step) {
      case 1:
        if (!value.match(linkedinRegex)) {
          errors.push(t('URL_NOT_LINKEDIN'));
        }
        if (value.length === 0) {
          errors = [];
        }
        return errors;
      case 2:
        if (isEmpty(value)) {
          errors.push(t('PROFILE_LINKEDIN_IS_REQUIRED'));
        }
        if (!value.match(linkedinRegex)) {
          errors.push(t('URL_NOT_LINKEDIN'));
        }
        return errors;
      default:
        if (!value.match(linkedinRegex)) {
          errors.push(t('URL_NOT_LINKEDIN'));
        }
        return errors;
    }
  },
  description: value => false,
  formations: value => false,
  languages: value => false,
  workNo: value => false,
  selectEnglish: value => !value?.id,
  certificateUrl: value => {
    let errors = [];
    switch (step) {
      case 1:
        if (isMin(value, 1)) {
          errors.push(t('LANGUAGE_CARD_MISSING_CERTIFICATE'));
        }
        if (value.length === 0) {
          errors = [];
        }
        if (!value.match(efsetRegex)) {
          errors.push(t('VALIDATION_MAKE_SURE_CERTIFICATE_IS_CORRECT'));
        }
        return errors;
      case 2:
        if (!value.match(efsetRegex)) {
          errors.push(t('VALIDATION_MAKE_SURE_CERTIFICATE_IS_CORRECT'));
        }
        return errors;
      default:
        return value?.length > 0;
    }
  },
});
export const validationsFn = (step, translateFn) => (key, value) => {
  return validations(step, translateFn)[key](value);
};

export const isEmpty = value => {
  return value?.length === 0;
};

export const isMin = (value, min) => {
  return value?.length < min;
};

export const isMax = (value, max) => {
  return value?.length > max;
};
